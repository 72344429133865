export default {
    byEvent(id) {
        return this.transformer.many(
            this.try(
                this.baseQuery().where('eventId', id).get()
            )
        )
    },

    byEventMaster(id) {
        return this.transformer.many(
            this.try(
                this.baseQuery().where('eventId', id).include('translations').get()
            )
        )
    }
}
