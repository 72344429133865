export default {
    type: 'statistics',
    name: 'Statistics',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleStatistics',
            loader: () => import('./ModuleStatistics')
        }
    }
};
