<template>
    <svg
        class="icon-eye-cancel"
        viewBox="0 0 27 16.101"
        xmlns="http://www.w3.org/2000/svg"
    ><path
        d="M647.7,507l-1.2,1.1a21.739,21.739,0,0,1,5.2,4c-1.5,1.5-6.3,5.9-11.9,5.9a11.418,11.418,0,0,1-3.3-.5l-1.3,1.2a13.886,13.886,0,0,0,4.6.8c7.4,0,13.3-6.7,13.5-6.9v-1A23.674,23.674,0,0,0,647.7,507Z"
        transform="translate(-626.3 -504.214)"
    /><path
        d="M627.9,512.1c1.5-1.5,6.3-5.9,11.9-5.9a10.3,10.3,0,0,1,3.5.6l1.3-1.2a15.144,15.144,0,0,0-4.8-.9c-7.4,0-13.3,6.7-13.5,6.9v1a23.747,23.747,0,0,0,5.8,4.7l1.2-1.1A21.52,21.52,0,0,1,627.9,512.1Z"
        transform="translate(-626.3 -504.214)"
    /><path
        d="M639.9,514.3l-1.4,1.3a5.067,5.067,0,0,0,1.3.2,3.714,3.714,0,0,0,3.7-3.8,2.959,2.959,0,0,0-.2-1l-1.4,1.3A2.074,2.074,0,0,1,639.9,514.3Z"
        transform="translate(-626.3 -504.214)"
    /><path
        d="M636.1,512a5.853,5.853,0,0,0,.2,1.3l1.3-1.2V512a2.22,2.22,0,0,1,2.2-2.2h.2l1.3-1.2a4.013,4.013,0,0,0-1.5-.3A3.691,3.691,0,0,0,636.1,512Z"
        transform="translate(-626.3 -504.214)"
    /><rect
        height="1.5"
        transform="translate(-803.77 69.761) rotate(-42.998)"
        width="22.001"
        x="628.857"
        y="511.514"
    /></svg>
</template>
