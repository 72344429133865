import BaseRepository from "@/services/publication/repository";
import queryByKeys from "@/services/resources/mixins/query-by-keys";
import Context from "@/services/context";

class Repository extends BaseRepository {
    static #fetchPromises = {};

    static make(model, schema) {
        return new Repository(model, schema).$mix(queryByKeys);
    }

    record(id) {
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('translations')
                    .include('person-affiliations')
                    .include('person-keywords')
                    .include('person-sectors')
                    .include('person-specialisations')
                    .include('programme-area-people')
                    .include('project-people')
                    .find(id)
            )
        );
    }

    collection() {
        return this.transformer.many(
            this.try(
                this.baseQuery().sort('lastName').sort('firstName').get()
            )
        );
    }

    all() {
        if (Repository.#fetchPromises[Context.getLanguage()]) {
            return Repository.#fetchPromises[Context.getLanguage()];
        }

        Repository.#fetchPromises[Context.getLanguage()] = this.transformer.many(
            this.try(
                this.baseQuery().get()
            )
        );

        return Repository.#fetchPromises[Context.getLanguage()];
    }

    getByJobGroupId(ids) {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .where('job_group_id', 'in', ids)
                    .include('job-title')
                    .get().then(collection => {
                        return collection.sort(function(a, b){
                            return ids.indexOf(a.jobGroupId) - ids.indexOf(b.jobGroupId);
                        });
                    })
            )
        );
    }

    getByJobTitleId(ids) {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .where('job_title_id', 'in', ids)
                    .include('programme-area-people')
                    .include('project-people')
                    .include('job-title')
                    .get().then(collection => {
                        return collection.sort(function(a, b){
                            return ids.indexOf(a.jobTitleId) - ids.indexOf(b.jobTitleId);
                        });
                    })
            )
        );
    }
}

export default Repository;
