<template>
    <div
        class="filters-modal"
        :class="modalClasses"
    >
        <div
            class="filters-modal__blocker"
            @click.stop="modalClose"
        />

        <div class="filters-modal__container">
            <div class="filters-modal__container__header">
                <p><strong>{{ filterByTranslation }}</strong></p>
                <IconRenderer
                    name="Cross"
                    @click.stop="modalClose"
                />
            </div>
            <div class="filters-modal__container__content">
                <Filter
                    v-for="row in filters"
                    :key="row.id"
                    :resource="row"
                    :reset="filtersMobileReset"
                    :class="`${filterClass(row)}`"
                    @reset="resetMobileFilters"
                    @selected="selectFilter"
                    @clicked="clickedFilter"
                />
            </div>
        </div>
    </div>

    <div
        v-if="filters"
        class="filter-by"
    >
        <LayoutContainer>
            <div class="filter-by__filters">
                <div
                    class="filter-by__filters__click"
                    @click.stop="openModal"
                >
                    <p>{{ filterByTranslation }}<span>:</span></p>

                    <div class="filter-by__filters__chevron">
                        <IconRenderer name="Chevron" />
                    </div>
                </div>

                <Filter
                    v-for="row in filters"
                    :key="row.id"
                    :resource="row"
                    :reset="filtersReset"
                    @reset="resetFilters"
                    @selected="selectFilter"
                    @defaultSelected="selectFilter"
                />
            </div>
        </LayoutContainer>
    </div>
    <div
        v-if="selectedFilters.length"
        class="filter-by filter-by--applied"
    >
        <LayoutContainer>
            <div class="filter-by__filters">
                <p>{{ selectedFilters.length }} selected<span>:</span></p>

                <div
                    class="filter-by__filters__clear"
                    @click="clearAllFilters"
                >
                    <span v-html="clearAllTranslation" />
                    <IconRenderer name="Cross" />
                </div>

                <div
                    v-for="(filter, index) in selectedFilters"
                    :key="index"
                    class="filter-by__filters__selected"
                    @click="clearFilter(index)"
                >
                    <span>{{ filter.filter.title ? filter.filter.title : filter.filter.label }}</span>
                    <IconRenderer name="Cross" />
                </div>
            </div>
        </LayoutContainer>
    </div>
</template>

<script>
import Translation from "@/services/lookup/translation";

export default {
    props: {
        filters: {
            type: Array,
            default: null,
        }
    },

    emits: ['selectedFilters'],

    data(){
        return {
            filtersReset: false,
            filtersMobileReset: false,
            selectedFilters: [],
            modelOpen: false,
            filterClicked: null,
            filterByTranslation: '',
            clearAllTranslation: '',
        }
    },

    computed: {
        modalClasses() {
            const classes = [];

            if (this.modelOpen) {
                classes.push('filters-modal--open');
            }

            return classes;
        },
    },

    methods: {
        clickedFilter(itm){
            if(this.filterClicked){
                if(this.filterClicked.title !== itm.title){
                    this.filterClicked = itm;
                } else {
                    this.filterClicked = null;
                }
            } else {
                this.filterClicked = itm;
            }
        },

        filterClass(itm){
            if(typeof itm !== 'undefined' && this.filterClicked) {
                if (itm.title !== this.filterClicked.title) {
                    return 'filter--not-clicked';
                } else {
                    return '';
                }
            } else {
                return '';
            }
        },

        clearFilter(idx){
            this.selectedFilters.splice(idx,1);
            this.$emit('selectedFilters', this.selectedFilters);
        },

        clearAllFilters(){
            this.selectedFilters = [];
            this.$emit('selectedFilters', this.selectedFilters);
        },

        resetFilters(val){
            this.filtersReset = val;
        },

        selectFilter(itm){
            this.modalClose();
            this.selectedFilters.push(itm);
            this.$emit('selectedFilters', this.selectedFilters);
        },

        resetMobileFilters(val){
            this.filtersMobileReset = val;
        },

        openModal(){
            this.filterClicked = null;
            this.modelOpen = true;
        },

        modalClose() {
            this.filterClicked = null;
            this.modelOpen = false;
        },
    },

    mounted(){
        Translation.many(['filter-by','clear-all']).then(translations => {
            if(translations) {
                this.filterByTranslation = translations['filter-by'];
                this.clearAllTranslation = translations['clear-all'];
            }
        })
    }
}
</script>

<style lang="postcss">
.filter-by {
    @apply border-t-2 border-b border-cotton-seed;

    &--applied {
        @apply border-t-0;
    }

    &__filters {
        @apply flex flex-row justify-between lg:justify-start items-center py-4 text-base lg:py-6;

        &__chevron {
             @apply w-3 h-auto transform -rotate-90 lg:hidden;
        }

        &__clear {
            @apply flex flex-row lg:hidden flex-shrink;
            height: 22px;

            span {
                @apply text-sm mr-2;
            }

            svg {
                @apply w-2 h-auto;
            }
        }

        &__click {
            @apply flex flex-row w-full lg:w-auto lg:inline;
        }

        > p, .filter-by__filters__click > p {
            @apply font-bold m-0 p-0 mr-6 flex-grow lg:flex-grow-0;

            span {
                @apply hidden lg:inline;
            }
        }

        &__selected {
            @apply hidden lg:flex justify-between items-center mr-6 cursor-pointer lg:mr-10;

            span {
                @apply mr-2;
            }

            svg {
                @apply w-2 h-auto;
            }
        }
    }
}

.filters-modal {
    @apply fixed z-50 top-0 left-0 w-screen max-w-full h-screen flex items-center justify-center opacity-0 invisible transition duration-500 lg:hidden;

    &--open {
        @apply opacity-100 visible;

        .filters-modal__container {
            @apply transform-none;
        }
    }

    &__blocker {
        @apply bg-big-stone opacity-50 absolute w-full h-full top-0 left-0;
    }

    &__container {
        @apply relative z-10 w-full m-6 bg-white transform -translate-y-12 transition duration-500 overflow-x-hidden overflow-y-auto;
        max-width: 620px;
        height: 92%;

        &__header {
            @apply flex flex-row justify-between items-center px-4 py-5 border-b border-cotton-seed;

            p {
                @apply flex-grow m-0 p-0;
            }

            svg {
                @apply w-4 h-auto;
            }
        }

        &__content {
            .filter {
                @apply !flex p-4 border-b border-cotton-seed flex-row justify-between items-center m-0;

                span {
                    @apply font-semibold text-sm;
                }

                svg {
                    @apply w-3 h-auto transform -rotate-90 text-dove-gray;
                }

                &--not-clicked {
                    @apply !hidden;
                }

                &--active {
                    @apply justify-start;

                    .back {
                        @apply flex flex-row justify-start items-center lg:hidden border-r border-cotton-seed mr-4 pr-4;

                        span {
                            @apply ml-2 text-sm font-normal;
                        }

                        svg {
                            @apply inline-block w-3 h-auto transform rotate-90;
                        }
                    }

                    svg {
                        @apply hidden;
                    }
                }
            }
        }
    }
}

</style>
