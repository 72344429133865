import {inject} from "vue";
import Kernel from "@oilstone/kernel";
import Cache from "./cache";
import hasEvents from "./has-events";

const cache = new Cache();

const boot = (scope, uuid) => {
    cache.put(
        uuid,
        () => {
            const store = Kernel.resolve('vuexStore');
            const {fire} = hasEvents(uuid);

            return {
                hydrate(item) {
                    if (item) {
                        store.dispatch(scope.path().to('hydrateItem'), {key: uuid, attributes: item});
                        fire('hydrated');

                        return true;
                    }

                    return false;
                },
                load(id) {
                    return store.dispatch(scope.path().to('loadItem'), {key: uuid, id}).then(attributes => {
                        fire('loaded');

                        return attributes;
                    });
                }
            }
        }
    );
};

const resolve = uuid => {
    return cache.resolve(uuid || inject('uuid'));
};

const forget = () => {
    cache.clear();
};

export {boot, resolve, forget};
export default resolve;
