export default {
    type: 'news-item-events',
    aliases: {
        camel: 'newsItemEvents',
        singular: 'News item event',
        plural: 'News item events',
        lowerSingular: 'news item event',
        lowerPlural: 'news item events',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
