export default {
    type: 'further-information',
    name: 'Further Information',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleFurtherInformation',
            loader: () => import('./ModuleFurtherInformation')
        }
    }
};
