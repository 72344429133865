<template>
    <AdminFormGroup
        class="admin-date-range"
        :class="classes"
    >
        <FormLabel
            :text="label"
            class="form-control"
        >
            <div class="admin-date-range__input">
                <Datepicker
                    v-bind="datePickerBindings"
                    @update:model-value="update"
                />
            </div>

            <FormError :text="error"/>
            <FormHelp :text="helpText"/>
        </FormLabel>
    </AdminFormGroup>
</template>

<script>
import formDateComposable from "@/composables/forms/form-date";
import Datepicker from "vue3-date-time-picker";
import {v4 as uuidv4} from "uuid";

export default {
    components: {
        Datepicker
    },

    mixins: [{
        props: formDateComposable.props(),
    }],

    setup(props, {emit}) {
        return {
            ...formDateComposable.composables(props, emit)
        }
    },

    props: {
        startDate: {
            type: Date,
            required: true
        },

        endDate: {
            type: Date,
            required: true
        },
    },

    data() {
        return {
            datePickerConfig: {
                uid: uuidv4(),
                enableTimePicker: false,
                locale: 'en-GB',
                range: true,
                monthPicker: false,
                timePicker: false,
                textInput: true,
                position: 'left',
                weekStart: 1,
                format: 'dd/MM/yyyy',
                monthNameFormat: 'long',
                hideInputIcon: true,
                clearable: false,
                closeOnScroll: true,
                autoApply: true,
                closeOnAutoApply: true,
                inputClassName: 'form-control__input',
            }
        }
    },

    computed: {
        datePickerBindings() {
            const options = this.baseComponentBindings;

            Object.keys(options).forEach(key => options[key] === undefined && delete options[key])

            options.modelValue = this.dateRange;

            return Object.assign(this.datePickerConfig, options);
        },

        dateRange: {
            get() {
                return [this.startDate, this.endDate];
            },

            set(value) {
                this.$emit('update:startDate', value[0]);
                this.$emit('update:endDate', value[1]);
            }
        },
    },

    methods: {
        update(value) {
            this.dateRange = value;
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:startDate', 'update:endDate'],
}
</script>
