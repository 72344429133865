import {inject, computed, reactive, toRefs} from 'vue';

class Factory {
    #props = {
        modelValue: null,
        name: String,
        for: String,
        forIndex: Number,
        label: String,
        helpText: String,
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
    };

    #passthrough = [
        'disabled',
        'for',
        'forIndex',
        'helpText',
        'label',
        'modelValue',
        'name',
        'readonly',
    ];

    #inherit = [
        'update'
    ];

    addProp(key, config) {
        this.#props[key] = config;

        return this;
    }

    addPassthrough(item) {
        this.#passthrough.push(item);

        return this;
    }

    props() {
        return this.#props;
    }

    propsExcept(items) {
        const props = {};

        for (let prop in this.#props) {
            if (items.indexOf(prop) === -1) {
                props[prop] = this.#props[prop];
            }
        }

        return props;
    }

    composables(props, emit) {
        props = toRefs(props);

        const errors = inject('errors', null);
        const ancestors = inject('ancestors', []);

        const error = computed(() => {
            try {
                if (!errors) {
                    return '';
                }

                if (!props.for) {
                    return '';
                }

                let val = '';
                let path = ancestors.concat(props.for.value.split('.'));

                if (props.forIndex && path.length > 1) {
                    const key = path.pop();
                    const arr = errors.pull(...path);
                    let item;

                    if (arr) {
                        item = arr.items.find(item => {
                            return item.index === props.forIndex.value;
                        });
                    }

                    if (item) {
                        val = item.errorMessages[key];
                    }
                } else {
                    val = errors.pull(...path);
                }

                if (!val) {
                    return '';
                }

                if (Array.isArray(val)) {
                    if (val.length) {
                        val = val.join(', ');
                    }
                }

                return val.charAt(0).toUpperCase() + val.slice(1);
            } catch(e){
                return ''
            }
        });

        const baseComponentBindings = {};

        this.#passthrough.forEach(item => {
            baseComponentBindings[item] = props[item];
        });

        return {
            ancestors,
            errors: inject('errors', null),
            error,
            baseComponentBindings: reactive(baseComponentBindings),
            classes: computed(() => {
                const classes = [];

                if (baseComponentBindings.disabled.value) {
                    classes.push('form-control--disabled');
                }

                if (error.value) {
                    classes.push('form-control--error');
                }

                return classes;
            }),
            update(value) {
                console.log('trigger emit func', value)

                if (!baseComponentBindings.disabled.value) {
                    console.log('run it here');
                    emit('update:modelValue', value);
                }
            },
        }
    }

    decorator() {
        const parent = this;

        return {
            props() {
                return parent.props();
            },

            propsExcept(items) {
                return parent.propsExcept(items);
            },

            composables: (props, emit, inherit) => {
                props = toRefs(props);

                const parentComposables = parent.composables(props, emit);
                const composables = {
                    baseBindings: {}
                }

                inherit = new Set(this.#inherit.concat(Array.isArray(inherit) ? inherit : []));

                inherit.forEach(item => {
                    composables[item] = parentComposables[item];
                });

                let parentProps = parent.props();

                for (let prop in parentProps) {
                    composables.baseBindings[prop] = props[prop];
                }

                composables.baseBindings = reactive(composables.baseBindings);

                return composables;
            }
        }
    }
}

export default Factory;
