import BaseRepository from "@/services/publication/repository";
import queryByKeys from "@/services/resources/mixins/query-by-keys";
import queryByProject from "@/services/resources/mixins/query-by-project";
import queryByEvent from "@/services/resources/mixins/query-by-event";
import queryByProgrammeArea from "@/services/resources/mixins/query-by-programme-area";
import queryByPerson from "@/services/resources/mixins/query-by-person";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(queryByKeys).$mix(queryByProject).$mix(queryByEvent).$mix(queryByProgrammeArea).$mix(queryByPerson);
    }

    record(id) {
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('translations')
                    .include('blog-post-people')
                    .include('blog-post-themes')
                    .find(id)
            )
        );
    }

    collection() {
        return this.transformer.many(
            this.try(
                this.baseQuery().sort('publishAt', 'desc').include('collection').include('blog-post-themes').get()
            )
        );
    }

    byCollectionId(id) {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .where('collectionId', id)
                    .include('blog-post-people.people')
                    .include('blog-post-themes')
                    .get()
            )
        );
    }

    forSearch() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .include('blog-post-people.people')
                    .include('blog-post-themes')
                    .where('isPublished',1)
                    .get()
            )
        );
    }
}

export default Repository;
