<template>
    <svg
        class="icon-video fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 65.672 46.176"
    >
        <path
            d="M64.3,7.225A8.252,8.252,0,0,0,58.5,1.381C53.373,0,32.836,0,32.836,0S12.3,0,7.178,1.381A8.252,8.252,0,0,0,1.372,7.225C0,12.38,0,23.135,0,23.135S0,33.89,1.372,39.045A8.128,8.128,0,0,0,7.178,44.8c5.122,1.381,25.658,1.381,25.658,1.381s20.536,0,25.658-1.381A8.129,8.129,0,0,0,64.3,
            39.044c1.372-5.155,1.372-15.91,1.372-15.91S65.673,12.38,64.3,7.225Z"
        />
    </svg>
</template>