import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .hasTitle()
    .hasTimestamps()
    .make();

schema.prop('websiteUrl').setType(String).setValue(null).addMetadata('translatable', true);

export default schema;

