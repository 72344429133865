import BaseRepository from "@/services/resources/repository";
import byProject from "@/services/resources/mixins/query-by-project";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byProject);
    }
}

export default Repository;
