export default {
    type: 'team',
    name: 'Team',
    osOnly: true,
    components: {
        root: {
            name: 'ModuleTeam',
            loader: () => import('./ModuleTeam')
        }
    }
};
