import Kernel from "@oilstone/kernel";
import Cache from "./cache";
import {inject} from "vue";
import hasEvents from "./has-events";

const cache = new Cache();

const boot = (scope, uuid) => {
    cache.put(
        uuid,
        () => {
            const store = Kernel.resolve('vuexStore');
            const {fire} = hasEvents(uuid);

            return  {
                overwrite(item) {
                    return store.dispatch(scope.path().to('overwriteItem'), {
                        key: uuid,
                        item,
                    }).then(attributes => {
                        fire('saved');
                        return attributes;
                    });
                },

                save() {
                    return store.dispatch(scope.path().to('saveItem'), uuid).then(attributes => {
                        fire('saved');
                        return attributes;
                    });
                },

                destroy() {
                    return store.dispatch(scope.path().to('destroyItem'), uuid).then(() => {
                        fire('destroyed');
                        return true;
                    });
                }
            };
        }
    );
};

const resolve = (uuid) => {
    return cache.resolve(uuid || inject('uuid'));
};

const forget = () => {
    cache.clear();
};

export {boot, resolve, forget};
export default resolve;
