export default {
    type: 'unrisd-timeline-entry-themes',
    aliases: {
        camel: 'unrisdTimelineEntryThemes',
        singular: 'UNRISD timeline entry theme',
        plural: 'UNRISD timeline entry themes',
        lowerSingular: 'timeline theme',
        lowerPlural: 'timeline themes',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
