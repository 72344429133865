import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .sluggable()
    .hasSection()
    .hasTitle()
    .hasSummary()
    .hasImage()
    .hasBody()
    .hasTimestamps()
    .publishable()
    .publishableByDate()
    .make();

schema.prop('programmeAreaId').setType(Number).setValue(null).addMetadata('mergeable', true);
schema.prop('projectId').setType(Number).setValue(null).addMetadata('mergeable', true);
schema.prop('eventId').setType(Number).setValue(null).addMetadata('mergeable', true);

export default schema;
