class Registry {
    #items;

    constructor(items = {}) {
        this.#items = items;
    }

    add(resource) {
        this.#items[resource.config.type] = resource;

        return this;
    }

    all() {
        return Object.values(this.#items);
    }

    find(type) {
        return this.#items[type] || null;
    }

    forEach(callback) {
        this.all().forEach(callback);

        return this;
    }

    log() {
        console.log(this.#items);
    }
}

export default Registry;
