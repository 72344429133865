export default {
    type: 'modules',
    aliases: {
        camel: 'modules',
        singular: 'Module',
        plural: 'Modules',
        lowerSingular: 'module',
        lowerPlural: 'modules',
    },
    relations: {
        'blog-posts': {
            adminLabel: 'Blog posts',
            pivot: {
                type: 'blog-post-modules',
                localKey: 'moduleId',
                foreignKey: 'blogPostId'
            }
        },
        'events': {
            adminLabel: 'Events',
            pivot: {
                type: 'event-modules',
                localKey: 'moduleId',
                foreignKey: 'eventId'
            }
        },
        'pages': {
            pivot: {
                type: 'page-modules',
                localKey: 'moduleId',
                foreignKey: 'pageId'
            }
        },
        'people': {
            adminLabel: 'People',
            pivot: {
                type: 'person-modules',
                localKey: 'moduleId',
                foreignKey: 'personId'
            }
        },
        'projects': {
            adminLabel: 'Projects',
            pivot: {
                type: 'project-modules',
                localKey: 'moduleId',
                foreignKey: 'projectId'
            }
        },
        'publications': {
            adminLabel: 'Publications',
            pivot: {
                type: 'publication-modules',
                localKey: 'moduleId',
                foreignKey: 'publicationId'
            }
        },
        'themes': {
            adminLabel: "Themes",
            pivot: {
                type: 'theme-modules',
                localKey: 'moduleId',
                foreignKey: 'themeId'
            }
        },
        'vacancies': {
            pivot: {
                type: 'vacancy-modules',
                localKey: 'moduleId',
                foreignKey: 'vacancyId'
            }
        },
        'news-items': {
            adminLabel: 'News stories',
            pivot: {
                type: 'news-item-modules',
                localKey: 'moduleId',
                foreignKey: 'newsItemId'
            }
        },
        'programme-areas': {
            adminLabel: 'Programme areas',
            pivot: {
                type: 'programme-area-modules',
                localKey: 'moduleId',
                foreignKey: 'programmeAreaId'
            }
        },
        'collections': {
            adminLabel: 'Collections',
            pivot: {
                type: 'collection-modules',
                localKey: 'moduleId',
                foreignKey: 'collectionId'
            }
        },
    },
    publishable: false,
    adminSection: null,
    componentType: 'base',
    components: {}
};
