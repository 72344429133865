export default {
    type: 'event-programme-areas',
    aliases: {
        camel: 'eventProgrammeAreas',
        singular: 'Event programme area',
        plural: 'Event programme areas',
        lowerSingular: 'event programme area',
        lowerPlural: 'event programme areas',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
