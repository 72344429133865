export default {
    type: 'blockquote',
    name: 'Quote',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleBlockquote',
            loader: () => import('./ModuleBlockquote')
        }
    }
};
