import {Schema} from "@oilstone/rest-model-repository";
import Factory from '../factory';

const schema = Factory.schema('highlighted-content');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('title').setType(String).setValue('Panel title');
attributes.prop('summary').setType(String).setValue('This is a panel');
attributes.prop('btnLabel').setType(String).setValue('Explore all');
attributes.prop('btnSrc').setType(String);
attributes.prop('variant').setType(String).setValue('alpha');
attributes.prop('posterVariant').setType(String).setValue('alpha');
attributes.prop('hero').setType(Number).setValue(false);
attributes.prop('size').setType(Number).setValue(4);
attributes.prop('contentType').setType(String).setValue(null);

const autoload = new Schema();
autoload.prop('relation').setType(String).setValue(null);
autoload.prop('keys').setType(Array);

const items = new Schema();
items.prop('promoted').setType(Array);
items.prop('autoload').setType(Schema).setValue(autoload);

attributes.prop('items').setType(Schema).setValue(items);

export default schema;
