export default {
    type: 'content-pullout',
    name: 'Content Pullout',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleContentPullout',
            loader: () => import('./ModuleContentPullout')
        }
    }
};
