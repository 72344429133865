const state = () => ({
    asset: null,
    assets: {},
    currentPath: '',
    downloading: false,
    fetching: false,
    basePath: process.env.VUE_APP_CDN_URL + "/assets/",
});

const getters = {
    asset(state) {
        return state.asset;
    },

    assets(state) {
        return state.assets;
    },

    currentPath(state) {
        return state.currentPath;
    },

    downloading(state) {
        return state.downloading;
    },

    fetching(state) {
        return state.fetching;
    },

    basePath(state) {
        return state.basePath;
    }
}

const mutations = {
    fillAssets(state, value) {
        state.assets = [];
        state.assets = value;
    },

    setAsset(state, value) {
        state.asset = value;
    },

    setCurrentPath(state, value) {
        state.currentPath = value;
    },

    setDownloading(state, value) {
        state.downloading = value;
    },

    setFetching(state, value) {
        state.fetching = value;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}
