<template>
    <PageCopy :page="content"/>
    <DisplayModules v-if="hasModules"/>
</template>

<script>
import displayRecord from './../display-record';

const component = displayRecord();

export default {
    mixins: [component],

    setup(props, context) {
        if (component.setup) {
            return component.setup(props, context);
        }

        return {}
    }
}
</script>
