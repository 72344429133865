<template>
    <svg
        class="icon-mastercard"
        viewBox="0 0 48 32"
        xmlns="http://www.w3.org/2000/svg"
    ><g><rect
        fill="#f2f0ec"
        height="32"
        rx="4"
        width="48"
    /><g><rect
        fill="#f26122"
        height="16.68"
        width="10.21"
        x="18.9"
        y="7.66"
    /><path
        d="M996.19,999.94a10.59,10.59,0,0,1,4-8.34,10.61,10.61,0,1,0,0,16.68A10.62,10.62,0,0,1,996.19,999.94Z"
        fill="#ea1d25"
        transform="translate(-976.19 -983.94)"
    /><path
        d="M1016.63,1006.51h-.07v-.41h.1l.13.29.12-.29h.1v.4h-.07v-.3l-.11.27h-.08l-.12-.27v.31Zm-.3,0h-.06v-.34h-.14v-.07h.35v.07h-.15v.33Z"
        fill="#f69e1e"
        transform="translate(-976.19 -983.94)"
    /><path
        d="M1017.35,999.93a10.61,10.61,0,0,1-17.16,8.35,10.61,10.61,0,0,0,1.78-14.89,9.73,9.73,0,0,0-1.78-1.79,10.6,10.6,0,0,1,17.16,8.33Z"
        fill="#f69e1e"
        transform="translate(-976.19 -983.94)"
    /></g></g></svg>
</template>