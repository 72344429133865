import Kernel from "@oilstone/kernel";
import Context from "../context";

class Navigation {
    static language = 'en';
    static masterRecord = null;

    static push(to) {
        const router = Kernel.resolve('router');

        if (Kernel.resolve('context').app === 'web') {
            return router.push(Navigation.prefixLanguage(to));
        }

        return router.push(to);
    }

    /**
     * @param {string} to
     */
    static append(to) {
        return Kernel.resolve('router').push({
            path: to,
        });
    }

    static currentRoute() {
        return Kernel.resolve('router').currentRoute;
    }

    static switchLanguage(language) {
        Context.setLanguage(language);

        return Navigation.push({
            name: 'resource',
            params: Object.assign({}, Navigation.currentRoute().value.params, {
                language,
            })
        });
    }

    static setLanguage(language) {
        Context.setLanguage(language);

        return Navigation;
    }

    static getLanguage() {
        return Context.language;
    }

    static prefixLanguage(route) {
        if (typeof route === 'string' || route instanceof String) {
            let langFound = false;

            Kernel.resolve('languages').forEach(language => {
                if (route.indexOf('/' + language.code) === 0) {
                    langFound = true;
                }
            });

            if (langFound) {
                return route;
            }

            return '/' + Navigation.getLanguage() + (route.indexOf('/') === 0 ? '' : '/') + route;
        }

        if (!route.params || !route.params.language) {
            return Object.assign({}, route, {
                params: {
                    language: Navigation.getLanguage(),
                },
            });
        }

        return route;
    }
}

export default Navigation;
