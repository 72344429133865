<template>
    <svg
        viewBox="0 0 25 25"
        xmlns="http://www.w3.org/2000/svg"
    ><g><circle
        cx="12.5"
        cy="12.5"
        r="12.5"
    /><path
        d="M10.79,15.94l-4-4.35L5.39,13l5.4,5.8L22.34,6.42,21,5Z"
        fill="#fff"
    /></g></svg>
</template>