<template>
    <svg
        class="icon-nap-offer"
        viewBox="0 0 16.02 16.066"
        xmlns="http://www.w3.org/2000/svg"
    ><g id="checker-2"><path
        id="A_copy"
        d="M24.164,25.878V24.75h3.478A8.12,8.12,0,0,0,24.07,13.94a7.871,7.871,0,0,0-10.628,3.305c-.03.058-.06.115-.088.173a8.008,8.008,0,0,0,13.536,8.46Zm-1.6-1.222-.655-2.068h-3.1l-.658,2.068H16.926l2.82-8.178h1.222l2.82,8.178Zm-3.384-3.2h2.258L20.4,18.076Z"
        data-name="A copy"
        fill-rule="evenodd"
        transform="translate(-12.459 -13.035)"
    /></g></svg>
</template>
