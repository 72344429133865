import Factory from "@/schemas/factory";
import {Schema} from "@oilstone/rest-model-repository";

const schema = new Factory()
    .hasId()
    .translatable()
    .hasSection()
    .sluggable()
    .sortable()
    .selfReferential()
    .hasTitle()
	.hasLede()
	.hasSummary()
	.hasImage()
    .hasBody()
    .hasMetadata()
    .publishable()
    .publishableByDate()
	.hasLotusNotesId()
    .hasTimestamps()
    .make();

schema.prop('navTitle').setType(String).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('programmeAreaId').setType(Number).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('projectId').setType(Number).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('eventId').setType(Number).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('newsItemId').setType(Number).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('publicationId').setType(Number).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('useMinimalFooter').setType(Boolean).setValue(false).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('hideInNav').setType(Boolean).setValue(false).addMetadata('mergeable', true).addMetadata('draftable', true);

const attributesSchema = new Schema();
attributesSchema.prop('subNavigationOption').setType(String);
attributesSchema.prop('homepageOf').setType(Array);
attributesSchema.prop('faqCategoryId').setType(Number);

schema.prop('attributes').setType(Schema).setValue(attributesSchema).addMetadata('mergeable', true).addMetadata('draftable', true);

export default schema;
