import BaseRepository from "@/services/resources/repository";
import Validator from "@/services/resources/validator";
import dayjs from "dayjs";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema, Validator.make(model));
    }

    collection() {
        return this.transformer.many(
            this.try(
                this.baseQuery().include('vacancies').sort('createdAt', 'desc').get()
            )
        );
    }

    getForExport(id) {
        const record = this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('vacancy-application-languages')
                    .include('vacancy-application-education')
                    .include('vacancy-application-employment')
                    .include('vacancy-application-references')
                    .find(id)
            )
        ).then(record => {
            if (!record) {
                return record;
            }

            return this.buildExportCsv([
                this.sortVacancyApplicationRelations(record)
            ]);
        });
    }

    getForExportByVacancy(vacancyId) {
        return this.transformer.many(
            this.try(
                this.baseQuery().where('vacancyId', vacancyId)
                    .include('vacancy-application-languages')
                    .include('vacancy-application-education')
                    .include('vacancy-application-employment')
                    .include('vacancy-application-references')
                    .sort('createdAt', 'desc')
                    .get()
            )
        ).then(collection => {
            return this.buildExportCsv(
                collection.map(record => {
                    return this.sortVacancyApplicationRelations(record);
                })
            );
        });
    }

    sortVacancyApplicationRelations(record) {
        [
            'vacancyApplicationLanguages',
            'vacancyApplicationEducation',
            'vacancyApplicationEmployment',
            'vacancyApplicationEducation'
        ].forEach(sortableKey => {
            record[sortableKey].sort((a, b) => {
                if (a.sort < b.sort) {
                    return -1;
                }

                if (a.sort > b.sort) {
                    return 1;
                }

                return 0;
            });
        });

        return record;
    }

    buildExportCsv(collection) {
        const headers = [
            'First name',
            'Family name',
            'Place of birth',
            'Nationality 1',
            'Nationality 2',
            'Nationality 3',
            'Gender identity',
            'Specified gender (if any)',
            'Date of birth',
            'Address Line',
            'City',
            'State',
            'Country',
            'Telephone number',
            'Email address',
            'Mother tongue',
            'Other languages',
            'Education - Institution',
            'Education - from',
            'Education - to',
            'Education - degrees and academic distinctions',
            'Education - degree Level',
            'Education - main course of study',
            'Other education',
            'Activities',
            'Publications',
            'Employment - current',
            'Employment - from',
            'Employment - to',
            'Employment - exact title of post',
            'Employment - name of employer',
            'Employment - responsibilities and duties',
            'Other employment',
            'Notice period',
            'Cover letter',
            'Reference 1 name',
            'Reference 1 job title',
            'Reference 1 organisation',
            'Reference 1 email address',
            'Reference 2 name',
            'Reference 2 job title',
            'Reference 2 organisation',
            'Reference 2 email address',
            'Reference 3 name',
            'Reference 3 job title',
            'Reference 3 organisation',
            'Reference 3 email address',
        ];

        const rows = collection.map(record => {
            const row = [
                record.firstName || '',
                record.lastName || '',
                record.placeOfBirth || '',
                record.nationality1 || '',
                record.nationality2 || '',
                record.nationality3 || '',
                record.genderIdentity || '',
                record.otherGender || '',
                record.dateOfBirth || '',
                record.address1 || '',
                record.address2 || '',
                record.address3 || '',
                record.address4 || '',
                record.telephoneNumber || '',
                record.emailAddress || '',
                record.motherTongue || '',
            ];

            if (record.vacancyApplicationLanguages.length) {
                row.push('Y');
            } else {
                row.push('N');
            }

            if (record.vacancyApplicationEducation.length) {
                const primaryEducationRow = record.vacancyApplicationEducation[0];

                row.push(primaryEducationRow.institution || '');
                row.push(primaryEducationRow.fromDate || '');
                row.push(primaryEducationRow.toDate || '');
                row.push(primaryEducationRow.qualifications || '');
                row.push(primaryEducationRow.qualificationLevel || '');
                row.push(primaryEducationRow.course || '');

                if (record.vacancyApplicationEducation.length > 1) {
                    row.push('Y')
                } else {
                    row.push('N')
                }
            } else {
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('N');
            }

            row.push(record.activities ? 'Y' : 'N');
            row.push(record.publications ? 'Y' : 'N');

            if (record.vacancyApplicationEmployment.length) {
                const primaryEmploymentRow = record.vacancyApplicationEmployment[0];

                row.push(primaryEmploymentRow.isCurrent ? 'Y' : 'N');
                row.push(primaryEmploymentRow.fromDate || '');
                row.push(primaryEmploymentRow.toDate || '');
                row.push(primaryEmploymentRow.position || '');
                row.push(primaryEmploymentRow.employer || '');
                row.push(primaryEmploymentRow.jobDescription || '');

                if (record.vacancyApplicationEmployment.length > 1) {
                    row.push('Y')
                } else {
                    row.push('N')
                }
            } else {
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('');
                row.push('N');
            }

            row.push(record.noticePeriod || '');
            row.push(record.coverLetter ? 'Y' : 'N');

            record.vacancyApplicationReferences.forEach(reference => {
                row.push(reference.name || '');
                row.push(reference.jobTitle || '');
                row.push(reference.organisation || '');
                row.push(reference.emailAddress || '');
            });

            return row;
        });

        rows.unshift(headers);

        return rows;
    }
}

export default Repository;
