export default {
    type: 'publications',
    aliases: {
        camel: 'publications',
        singular: 'Publication',
        plural: 'Publications',
        lowerSingular: 'publication',
        lowerPlural: 'publications',
    },
    relations: {
        'events': {
            adminLabel: 'Events',
            pivot: {
                type: 'publication-events',
                localKey: 'publicationId',
                foreignKey: 'eventId'
            }
        },
        'keywords': {
            adminLabel: 'Keywords',
            pivot: {
                type: 'publication-keywords',
                localKey: 'publicationId',
                foreignKey: 'keywordId'
            }
        },
        'modules': {
            pivot: {
                type: 'publication-modules',
                localKey: 'publicationId',
                foreignKey: 'moduleId'
            }
        },
        'people': {
            adminLabel: 'UNRISD authors',
            pivot: {
                type: 'publication-people',
                localKey: 'publicationId',
                foreignKey: 'personId'
            }
        },
        'programme-areas': {
            adminLabel: 'Programme areas',
            pivot: {
                type: 'publication-programme-areas',
                localKey: 'publicationId',
                foreignKey: 'programmeAreaId'
            }
        },
        'projects': {
            adminLabel: 'Projects',
            pivot: {
                type: 'publication-projects',
                localKey: 'publicationId',
                foreignKey: 'projectId'
            }
        },
        'publishers': {
            adminLabel: 'Publishers',
            pivot: {
                type: 'publication-publishers',
                localKey: 'publicationId',
                foreignKey: 'publisherId'
            }
        },
        'themes': {
            adminLabel: "Themes",
            pivot: {
                type: 'publication-themes',
                localKey: 'publicationId',
                foreignKey: 'themeId'
            }
        },
        'countries': {
            adminLabel: 'Countries',
            pivot: {
                type: 'publication-countries',
                localKey: 'publicationId',
                foreignKey: 'country'
            }
        },
    },
    publishable: true,
    adminSection: 'library',
    componentType: 'base',
    filterOn: {
        'decades': {
            foreignKey: 'decadeId',
        },
        'programme-areas': {
            foreignKey: 'programmeAreaId',
            in: 'publicationProgrammeAreas',
        },
        'themes': {
            foreignKey: 'themeId',
            in: 'publicationThemes',
        },
        'publishers': {
            foreignKey: 'publisherId',
            in: 'publicationPublishers',
        },
        'projects': {
            foreignKey: 'projectId',
            in: 'publicationProjects',
        },
        'events': {
            foreignKey: 'eventId',
            in: 'publicationEvents',
        },
        'publication-types': {
            foreignKey: 'publicationTypeId',
        },
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerPublicationRecord',
            loader: () => import('./AdministerPublicationRecord')
        },
        adminTranslation: {
            name: 'AdministerPublicationTranslation',
            loader: () => import('./AdministerPublicationTranslation')
        },
        webRecord: {
            name: 'DisplayPublicationRecord',
            loader: () => import('./DisplayPublicationRecord')
        }
    },
    cms: {
        paginationAmount: () => {
            return 100
        },
    }
};
