<template>
    <FormFields>
        <ResourceInput
            label="Start year"
            attribute="startYear"
            type="tel"
            :maxLength="4"
            @keypress="checkYear"
        />

        <ResourceInput
            label="End year"
            attribute="endYear"
            type="tel"
            :maxLength="4"
            @keypress="checkYear"
        />
    </FormFields>
</template>

<!--suppress JSDeprecatedSymbols -->
<script>
export default {
    methods: {
        checkYear(event) {
            event = (event) ? event : window.event;

            const charCode = event.which || event.keyCode;

            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                event.preventDefault();
            }
        },
    }
}
</script>
