import BaseRepository from "@/services/resources/repository";
import byEvent from "@/services/resources/mixins/query-by-event";
import byCountry from "@/services/resources/mixins/query-by-country";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byEvent).$mix(byCountry);
    }
}

export default Repository;
