import BaseRepository from "@/services/resources/repository";
import translatable from "@/services/resources/mixins/translatable-record";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(translatable);
    }
}

export default Repository;
