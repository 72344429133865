<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.84 46.88">
		<g>
			<g>
				<path d="M28,44.59H2.43A2.43,2.43,0,0,1,0,42.16V2.43A2.43,2.43,0,0,1,2.43,0H21.76L30.4,8.58V42.16A2.43,2.43,0,0,1,28,44.59Z" style="fill: #e0e0e0"/>
				<path d="M24.2,8.58h6.2L21.76,0V6.15A2.43,2.43,0,0,0,24.2,8.58Z" style="fill: #eff2f4"/>
				<rect x="15.91" y="34.19" width="22.93" height="12.69" rx="1.46" style="fill: #5097ff"/>
			</g>
			<g>
				<path d="M22.41,37.9a1.87,1.87,0,0,1,1,.74,2.74,2.74,0,0,1,.42.94,4.79,4.79,0,0,1,.11,1,3.92,3.92,0,0,1-.47,2,2.11,2.11,0,0,1-2,1.08H19V37.77H21.5A2.92,2.92,0,0,1,22.41,37.9Zm-2.24.88v3.81H21.3a1.21,1.21,0,0,0,1.2-.85,2.87,2.87,0,0,0,.19-1.11,2.69,2.69,0,0,0-.28-1.37,1.18,1.18,0,0,0-1.11-.48Z" style="fill: #fff"/>
				<path d="M29.33,43.09a2.54,2.54,0,0,1-1.91.68,2.56,2.56,0,0,1-1.91-.68,3.16,3.16,0,0,1-.89-2.41,3.1,3.1,0,0,1,.89-2.41,2.56,2.56,0,0,1,1.91-.68,2.54,2.54,0,0,1,1.91.68,3.1,3.1,0,0,1,.89,2.41A3.16,3.16,0,0,1,29.33,43.09Zm-.76-.89a2.9,2.9,0,0,0,0-3,1.49,1.49,0,0,0-2.3,0,2.9,2.9,0,0,0,0,3,1.51,1.51,0,0,0,2.3,0Z" style="fill: #fff"/>
				<path d="M31.71,38.38a2.39,2.39,0,0,1,1.79-.72,2.42,2.42,0,0,1,2.13,1,2.15,2.15,0,0,1,.4,1.09H34.81a1.68,1.68,0,0,0-.3-.63,1.21,1.21,0,0,0-1-.38,1.18,1.18,0,0,0-1,.54,2.59,2.59,0,0,0-.38,1.51,2.24,2.24,0,0,0,.4,1.47,1.23,1.23,0,0,0,1,.49,1.13,1.13,0,0,0,1-.42,1.82,1.82,0,0,0,.31-.67H36a2.67,2.67,0,0,1-.8,1.55,2.33,2.33,0,0,1-1.65.6,2.47,2.47,0,0,1-2-.81,3.23,3.23,0,0,1-.72-2.23A3.19,3.19,0,0,1,31.71,38.38Z" style="fill: #fff"/>
			</g>
		</g>
	</svg>
</template>