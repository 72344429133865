<template>
    <div
        class="titled-container"
        :class="`titled-container--${variant}`"
    >
        <LayoutContainer>
            <PartialTitle
                :title="title"
                :summary="summary"
            />

            <slot></slot>
        </LayoutContainer>
    </div>
</template>

<script>
export default {
    props: {
        variant: {
            type: String,
            default: 'alpha',
        },
        title: {
            type: String,
            default: null,
        },
        summary: {
            type: String,
            default: null,
        },
    }
}
</script>

<style lang="postcss">
.titled-container {
    @apply pt-4 lg:pt-0 pb-6;

    &--alpha {
         @apply bg-white;
    }

    &--beta {
        @apply bg-cararra;
    }
}
</style>
