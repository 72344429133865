<template>
    <div
        class="record"
        :class="classes"
    >
        <component :is="component"/>
    </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import {provide} from "vue";
import Kernel from "@oilstone/kernel";
import {boot, mutatesData, hydratesData} from "@/services/data-layer/composers";
import Factory from "../factory";
import component from "@/services/resources/component";

export default {
    props: {
        type: String,
        resource: Object
    },

    setup(props) {
        const uuid = uuidv4();
        const resource = Kernel.resolve('resourceRegistry').find(props.type);
        const app = Kernel.resolve('context').app;

        boot(
            resource.schema,
            Factory.scope(resource.config),
            uuid
        );

        provide('type', props.type);
        provide('uuid', uuid);

        if (props.resource) {
            const {hydrate} = hydratesData(uuid);
            hydrate(props.resource);
        } else {
            const {make} = mutatesData(uuid);
            make();
        }

        return {
            component: component(props.type, app === 'cms' ? 'adminRecord' : 'webRecord'),
        }
    },

    computed: {
        classes() {
            return [`record-${this.type}`];
        },
    }
}
</script>
