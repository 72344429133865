class Publication {
    static #useDraftData = false;

    static set useDraftData(useDraftData) {
        Publication.setUseDraftData(useDraftData);
    }

    static get useDraftData() {
        return Publication.getUseDraftData();
    }

    static setUseDraftData(useDraftData) {
        Publication.#useDraftData = useDraftData;
    }

    static getUseDraftData() {
        return Publication.#useDraftData;
    }
}

export default Publication;
