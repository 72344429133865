import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .hasTitle()
    .publishable()
    .publishableByDate()
    .hasTimestamps()
    .make();

schema.prop('answer').setType(String).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('faqCategoryId').setType(Number).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);

export default schema;
