<template>
    <AdminFormGroup
        class="admin-select"
        :class="classes"
    >
        <FormLabel
            :text="label"
            class="form-control"
        >
            <div class="admin-select__input">
                <BaseSelect
                    v-bind="baseComponentBindings"
                    class="form-control__input cursor-pointer"
                    @update:model-value="update"
                />
            </div>

            <FormError :text="error" />
            <FormHelp :text="helpText" />
        </FormLabel>
    </AdminFormGroup>
</template>

<script>
import formSelectComposable from "@/composables/forms/form-select";

export default {
    mixins: [
        {
            props: formSelectComposable.props(),
        },
    ],

    setup(props, { emit }) {
        return {
            ...formSelectComposable.composables(props, emit),
        };
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ["update:modelValue"],
};
</script>
