<template>
    <svg
        viewBox="0 0 21.379 20.629"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g transform="translate(0.75 0.75)">
            <path
                d="M9,9.957V3H20.927V9.957"
                fill="none"
                stroke="#fff"
                stroke-linecap="square"
                stroke-width="1.5"
                transform="translate(-5.024 -3)"
            />
            <path
                d="M6.976,22.445H4.988A1.988,1.988,0,0,1,3,20.457v-4.97A1.988,1.988,0,0,1,4.988,13.5h15.9a1.988,1.988,0,0,1,1.988,1.988v4.97a1.988,1.988,0,0,1-1.988,1.988H18.9"
                fill="none"
                stroke="#fff"
                stroke-linecap="square"
                stroke-linejoin="bevel"
                stroke-width="1.5"
                transform="translate(-3 -6.543)"
            />
            <g
                fill="none"
                stroke-linecap="square"
                transform="translate(-5.024 -9.073)"
            >
                <path
                    d="M9,21H20.927v7.951H9Z"
                    stroke="none"
                />
                <path
                    d="M 10.5 22.4999942779541 L 10.5 27.45141410827637 L 19.42712020874023 27.45141410827637 L 19.42712020874023 22.4999942779541 L 10.5 22.4999942779541 M 9 20.9999942779541 L 20.92712020874023 20.9999942779541 L 20.92712020874023 28.95141410827637 L 9 28.95141410827637 L 9 20.9999942779541 Z"
                    fill="#fff"
                    stroke="none"
                />
            </g>
        </g>
    </svg>
</template>