export default {
    type: 'publication-countries',
    aliases: {
        camel: 'publicationCountries',
        singular: 'Publication country',
        plural: 'Publication countries',
        lowerSingular: 'publication country',
        lowerPlural: 'publication countries',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
