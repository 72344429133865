import BaseRepository from "@/services/publication/repository";
import queryByKeys from "@/services/resources/mixins/query-by-keys";
import translatable from "@/services/resources/mixins/translatable-record";
import dayjs from "dayjs";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(queryByKeys).$mix(translatable);
    }

    current() {
        return this.transformer.many(
            this.try(
                this.baseQuery().where('isPublished', 1).where('closingAt', '>=', dayjs().startOf('day').format('YYYY-MM-DD')).sort('closingAt').get()
            )
        );
    }
}

export default Repository;
