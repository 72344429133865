import PivotRepository from "@/services/resources/pivot-repository";
import byEvent from "@/services/resources/mixins/query-by-event";
import byProgrammeArea from "@/services/resources/mixins/query-by-programme-area";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byEvent).$mix(byProgrammeArea);
    }

    eventsByProgrammeArea(id) {
        return this.relation('events', 'programmeAreaId', id);
    }

    programmeAreasByEvent(id) {
        return this.relation('programme-areas', 'eventId', id, null, [], 'programmeAreas');
    }

    byProgrammeAreas(ids) {
        return this.transformer.many(
            this.try(
                this.baseQuery().where('programmeAreaId', 'in', ids).get()
            )
        )
    }
}

export default Repository;
