class Binding {
    #scope;
    #ob;

    constructor(scope) {
        this.#scope = scope;
    }

    get ob() {
        return this.getOb();
    }

    getOb() {
        return this.#ob;
    }

    for(ob) {
        let prop = ob;
        const ancestor = this.#scope.getAncestor();
        const key = this.#scope.getKey();

        if (ancestor) {
            prop = ancestor.for(prop).getOb();
        }

        if (!key) {
            this.#ob = prop;
            return this;
        }

        this.#ob = prop[key]

        return this;
    }

    prop(prop) {
        if (!this.#ob) {
            return null;
        }

        return this.#ob[prop];
    }

    set(prop, value) {
        if (!this.#ob) {
            this.#ob = {};
        }

        this.#ob[prop] = value;

        return this;
    }
}

export default Binding;
