import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .make();

schema.prop('email').setType(String).setValue(null);
schema.prop('firstName').setType(String).setValue(null);
schema.prop('lastName').setType(String).setValue(null);
schema.prop('country').setType(String).setValue(null);
schema.prop('institution').setType(String).setValue(null);
schema.prop('enquiry').setType(String).setValue(null);

export default schema;
