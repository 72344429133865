import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .sluggable()
    .hasDecade()
    .hasLocation()
    .hasRegion()
    .hasTitle()
    .hasSummary()
    .hasBulletSummary()
    .hasImage()
    .hasBody()
    .hasMetadata()
    .publishable()
    .publishableByDate()
    .hasLotusNotesId()
    .hasTimestamps()
    .make();

schema.prop('publicationTypeId').setType(Number).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('outreach').setType(Boolean).setValue(false).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('pdf').setType(String).setValue(null).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('pdfButtonLabel').setType(String).setValue(null).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('externalAuthors').setType(String).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('location').setType(String).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('numberOfPages').setType(String).setValue(null).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('seriesNumber').setType(String).setValue(null).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('code').setType(String).setValue(null).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('issn').setType(String).setValue(null).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('isbn').setType(String).setValue(null).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('referenceList').setType(String).addMetadata('translatable', true).addMetadata('draftable', true);

export default schema;
