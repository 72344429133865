<template>
    <grid-container class="admin-navbar">
        <grid-column class="admin-navbar__brand">
            <a
                href="/admin"
                class="admin-navbar__brand__logo"
            >
                <LogoOs />
            </a>
        </grid-column>
        <grid-column class="admin-navbar__tools">
            <div class="admin-navbar__tools__title">UNRISD website content management system</div>

            <div class="admin-navbar__tools__buttons">
                <a
                    href="/"
                    target="_blank"
                >
                    <IconRenderer name="External" />
                    <span>Live website</span>
                </a>
                <a href="#" @click.prevent="triggerLogout">
                    <IconRenderer name="Account" />
                    <span>Log out</span>
                </a>
            </div>
        </grid-column>
    </grid-container>
</template>

<script>
import LogoOs from "../../../shared/assets/logos/LogoOs";
import {useStore} from "vuex";
import Navigation from "@/services/navigation";

export default {
    setup() {
        const store = useStore();

        return {
            logout: () => store.dispatch('auth/logout'),
        }
    },

    name: "AdminNavbar",

    components: {LogoOs},

    methods: {
        triggerLogout() {
            this.logout().then(() => {
                Navigation.push({name: 'admin-login'});
            });
        },
    }
}
</script>

<style lang="postcss">
.admin-navbar {
    @apply fixed z-10 w-full bg-gradient-to-r from-purple-1 to-meteorite text-white;
    max-height: 44px;

    &__brand {
        @apply col-span-2 py-2 px-4;

        &__logo {
            svg {
                @apply w-7 h-auto text-white fill-current;
            }
        }
    }

    &__tools {
        @apply flex col-span-10 py-2 pr-4 flex-row justify-end lg:justify-between flex-nowrap;

        &__title {
            @apply hidden lg:block lg:pl-4;
        }

        &__buttons {
            @apply flex flex-row items-center justify-between;

            a {
                @apply text-xs md:text-sm ml-8 flex flex-row items-center justify-start;

                svg {
                    @apply w-3 mr-2;
                }
            }
        }
    }

}
</style>
