<template>
    <div class="multi-stage-progress-bar relative">
        <div class="flex justify-between relative z-30">
            <div
                v-for="stage in stages"
                :key="stage.id"
                :ref="setStageElement"
                class="multi-stage-progress-bar__stage flex lg:flex"
            >
                <div class="relative whitespace-nowrap text-sm flex-shrink-0 pt-7">
                    <span>
                        {{ stage.text }}
                    </span>
                    <div
                        v-if="isActive(stage)"
                        class="fill-current w-6 h-6 rounded-full bg-cod-gray absolute -top-2 left-1/2 transform -translate-x-1/2"
                    ></div>
                    <IconRenderer
                        v-if="isComplete(stage)"
                        name="TickCircle"
                        class="fill-current w-6 h-6 absolute -top-2 left-1/2 transform -translate-x-1/2"
                    />
                </div>
            </div>
        </div>
        <div class="absolute w-full top-0 h-1.5 z-10 bg-gurkha" />
        <div
            class="absolute top-0 z-20 h-1.5 bg-cod-gray"
            :style="completedBarStyles"
        />
    </div>
</template>

<script>
export default {
    props: {
        currentIndex: {
            type: Number,
            required: true
        },
        stages: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            stageElements: [],
            currentStageBox: {
                offsetLeft: 0,
                width: 0,
            },
        }
    },

    watch: {
        currentIndex(val) {
            this.setCurrentStageBox(
                val > (this.stages.length - 1) ? null : this.stageElements[val]
            )
        }
    },

    computed: {
        current() {
            return this.stages[this.currentIndex];
        },

        active() {
            return this.stages.slice(0, this.currentIndex + 1);
        },

        completedBarStyles() {
            if (!this.currentStageBox) {
                return {
                    width: '100%'
                }
            }

            return {
                width: `${this.currentStageBox.offsetLeft + (this.currentStageBox.width / 2)}px`
            }
        },
    },

    methods: {
        isActive(stage) {
            return this.active.indexOf(stage) !== -1;
        },

        isComplete(stage) {
            const index = this.stages.indexOf(stage);

            if (index === -1) {
                return false;
            }

            return this.currentIndex > index;
        },

        setStageElement(el) {
            if (el) {
                this.stageElements.push(el)
            }
        },

        setCurrentStageBox(element) {
            if (!element) {
                this.currentStageBox = null;
                return;
            }

            this.currentStageBox.offsetLeft = element.offsetLeft;
            this.currentStageBox.width = element.offsetWidth;
        }
    },

    mounted() {
        this.stageElements = [...document.querySelectorAll('.multi-stage-progress-bar__stage')];
        this.setCurrentStageBox(this.stageElements[this.currentIndex]);

        window.addEventListener('resize', () => {
            this.setCurrentStageBox(this.stageElements[this.currentIndex]);
        });
    }
}
</script>
