<template>
    <div class="admin-header">
        <h2 class="admin-header__title">
            <slot></slot>
        </h2>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="postcss">
.admin-header {
    @apply font-bold text-spaced mb-4;

    &__title {
         @apply font-sofia-pro text-lg p-0 m-0;
    }
}
</style>
