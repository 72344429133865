<template>
    <AdminFormGroup
        class="admin-input"
        :class="classes"
    >
        <FormLabel
            :text="label"
            class="form-control"
        >
            <div class="admin-input__input">
                <BaseInput
                    v-bind="baseComponentBindings"
                    :type="resolvedType"
                    @update:model-value="update"
                    ref="input"
                    class="form-control__input"
                />
                <div
                    v-if="isPassword"
                    @click="toggleVisibility"
                    class="admin-input__input__visibility-toggle"
                >
                    <IconRenderer
                        v-if="forceType === 'text'"
                        name="EyeCancel"
                        class="fill-current"
                    />
                    <IconRenderer
                        v-else
                        name="Eye"
                        class="fill-current"
                    />
                </div>
            </div>
            <FormError :text="error"/>
            <FormHelp :text="helpText"/>
        </FormLabel>
    </AdminFormGroup>
</template>

<script>
import formInputComposable from "@/composables/forms/form-input";

export default {
    mixins: [{
        props: formInputComposable.props()
    }],

    setup(props, {emit}) {
        return {
            ...formInputComposable.composables(props, emit)
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],

    props: {
        type: {
            type: String,
            default: 'text'
        }
    },

    data() {
        return {
            forceType: null
        }
    },

    computed: {
        resolvedType() {
            return this.forceType || this.type
        },

        isPassword() {
            return this.type === 'password';
        },
    },

    methods: {
        toggleVisibility() {
            if (this.forceType) {
                this.forceType = null;

                return;
            }

            this.forceType = 'text';
        },
    }
}
</script>

<style lang="postcss">
.admin-input {
    &__input {
        @apply relative block;

        &__visibility-toggle {
            @apply absolute cursor-pointer w-6.5 right-4 bottom-1/2 transform translate-y-1/2 text-silver-chalice;
        }
    }
}
</style>
