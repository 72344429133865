class Currency {
    static format(value, stripZeroPence = true, convertZeroToFree = true) {
        if (isNaN(value)) {
            return value;
        } else if (convertZeroToFree && parseFloat(value) === 0) {
            return 'FREE';
        } else {
            const formatted = new Intl.NumberFormat("en-GB", {
                style: "currency",
                currency: "GBP"
            }).format(value);

            return stripZeroPence ? formatted.replace(/\D00(?=\D*$)/, '') : formatted;
        }
    }
}

export default Currency;
