<template>
    <svg
        class="icon-more"
        viewBox="0 0 4 16"
        xmlns="http://www.w3.org/2000/svg"
    ><circle
        cx="2"
        cy="14"
        r="2"
    /><circle
        cx="2"
        cy="8"
        r="2"
    /><circle
        cx="2"
        cy="2"
        r="2"
    /></svg>
</template>
