export default {
    type: 'publishers',
    aliases: {
        camel: 'publishers',
        singular: 'Publisher',
        plural: 'Publishers',
        lowerSingular: 'publisher',
        lowerPlural: 'publishers',
    },
    publishable: false,
    adminSection: 'library',
    componentType: 'base',
    components: {
        adminRecord: {
            name: 'AdministerPublisherRecord',
            loader: () => import('./AdministerPublisherRecord')
        },
        adminTranslation: {
            name: 'AdministerPublisherTranslation',
            loader: () => import('./AdministerPublisherTranslation')
        },
    }
};
