import {Schema} from "@oilstone/rest-model-repository";

class Factory {
    #schema;

    constructor() {
        this.#schema = new Schema()
    }

    make() {
        return this.#schema;
    }

    hasId() {
        this.#schema.prop('id').setType(Number).setValue(null).readOnly().primaryKey();

        return this;
    }

    translatable() {
        this.#schema.prop('language').setType(String).setValue('en');
        this.#schema.prop('translatesId').setType(Number).setValue(null);

        return this;
    }

    selfReferential() {
        this.#schema.prop('parentId').setType(Number).setValue(null);

        return this;
    }

    sortable() {
        this.mergeableFlag(this.#schema.prop('sort').setType(Number).setValue(null));

        return this;
    }

    sluggable() {
        this.draftableFlag(
            ...this.mergeableFlag(this.#schema.prop('slug').setType(String))
        );

        return this;
    }

    hasTheme() {
        this.draftableFlag(
            ...this.mergeableFlag(this.#schema.prop('themeId').setType(Number).setValue(null))
        );

        return this;
    }

    hasDecade() {
        this.draftableFlag(
            ...this.mergeableFlag(this.#schema.prop('decadeId').setType(Number).setValue(null))
        );

        return this;
    }

    hasSection() {
        this.mergeableFlag(this.#schema.prop('section').setType(String));

        return this;
    }

    hasTitle() {
        this.draftableFlag(
            ...this.translatableFlag(this.#schema.prop('title').setType(String))
        );

        return this;
    }

    hasLede() {
        this.draftableFlag(
            ...this.translatableFlag(this.#schema.prop('lede').setType(String))
        );

        return this;
    }

    hasSummary() {
        this.draftableFlag(
            ...this.translatableFlag(this.#schema.prop('summary').setType(String))
        );

        return this;
    }

    hasBulletSummary() {
        this.draftableFlag(
            ...this.translatableFlag(
                this.#schema.prop('summaryBulletsTitle').setType(String),
                this.#schema.prop('summaryBullets').setType(String),
                this.#schema.prop('summaryBulletsOverride').setType(String)
            )
        );

        return this;
    }

    hasImage() {
        this.draftableFlag(
            ...this.mergeableFlag(
                this.#schema.prop('image').setType(String).setValue(null)
            ),
            ...this.translatableFlag(
                this.#schema.prop('imageCredit').setType(String).setValue(null)
            ),
            ...this.translatableFlag(
                this.#schema.prop('imageAlt').setType(String).setValue(null)
            )
        );

        return this;
    }

    hasLocation() {
        this.draftableFlag(
            ...this.mergeableFlag(
                this.#schema.prop('country').setType(String).setValue(null),
                this.#schema.prop('regionId').setType(Number).setValue(null)
            )
        );

        return this;
    }

    hasRegion() {
        this.draftableFlag(
            ...this.mergeableFlag(
                this.#schema.prop('regionId').setType(Number).setValue(null)
            )
        );

        return this;
    }

    hasBody() {
        this.draftableFlag(
            ...this.translatableFlag(this.#schema.prop('body').setType(String))
        );

        return this;
    }

    hasMetadata() {
        this.draftableFlag(
            ...this.translatableFlag(
                this.#schema.prop('windowTitle').setType(String).setValue(null),
                this.#schema.prop('metaDescription').setType(String).setValue(null),
                this.#schema.prop('ogData').setType(String).setValue(null)
            ),
            ...this.mergeableFlag(this.#schema.prop('noIndex').setType(Boolean).setValue(false))
        );

        return this;
    }

    hasYearRange() {
        this.draftableFlag(
            ...this.mergeableFlag(
                this.#schema.prop('startYear').setType(Number).setValue(null),
                this.#schema.prop('endYear').setType(Number).setValue(null)
            )
        );

        return this;
    }

    hasDateRange() {
        this.draftableFlag(
            ...this.mergeableFlag(
                this.#schema.prop('startDate').setType(String).setValue(null),
                this.#schema.prop('endDate').setType(String).setValue(null)
            )
        );

        return this;
    }

    publishable() {
        this.mergeableFlag(this.#schema.prop('isPublished').setType(Boolean).setValue(false));
        this.mergeableFlag(this.#schema.prop('hasUnpublishedChanges').setType(Boolean).setValue(false));

        this.#schema.prop('draft').setType(Object).setValue(null);

        return this;
    }

    publishableByDate() {
        this.draftableFlag(
            ...this.mergeableFlag(this.#schema.prop('publishAt').setType(String).setValue(null))
        );

        return this;
    }

    hasLotusNotesId() {
        this.mergeableFlag(this.#schema.prop('lotusNotesId').setType(String));

        return this;
    }

    hasTimestamps() {
        this.#schema.prop('createdAt').setType(String).setValue(null).readOnly();
        this.#schema.prop('updatedAt').setType(String).setValue(null).readOnly();

        return this;
    }

    mergeableFlag(...props) {
        props.forEach(prop => {
            prop.addMetadata('mergeable', true);
        });

        return props;
    }

    translatableFlag(...props) {
        props.forEach(prop => {
            prop.addMetadata('translatable', true);
        });

        return props;
    }

    draftableFlag(...props) {
        props.forEach(prop => {
            prop.addMetadata('draftable', true);
        });

        return props;
    }
}

export default Factory;
