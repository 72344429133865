import BaseRepository from "@/services/publication/repository";
import queryByKeys from "@/services/resources/mixins/query-by-keys";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(queryByKeys);
    }

    record(id) {
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('translations')
                    .include('project-keywords')
                    .include('project-programme-areas')
                    .include('project-themes')
                    .include('project-countries')
                    .include('project-people')
                    .find(id)
            )
        );
    }

    list(){
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .select('id','title','start_year')
                    .get()
            )
        );
    }

    collection() {
        return this.transformer.many(
            this.try(
                this.baseQuery().sort('publishAt', 'desc').include('project-themes').include('project-programme-areas').get()
            )
        );
    }

    forSearch() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .include('project-programme-areas.programme-areas')
                    .include('project-themes')
                    .select('id','title','slug','summary','image','image_alt','publish_at','decade_id',
                        'project-themes.sort','project-themes.id','themes.id',
                        'project-programme-areas.sort','project-programme-areas.id','programme-areas.id','programme-areas.title'
                    )
                    .where('isPublished',1)
                    .get()
            )
        );
    }

    current() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .where('decadeId', process.env.VUE_APP_CURRENT_DECADE_ID)
                    .get()
            )
        );
    }
}

export default Repository;
