import PivotRepository from "@/services/resources/pivot-repository";
import byProgrammeArea from "@/services/resources/mixins/query-by-programme-area";
import byPerson from "@/services/resources/mixins/query-by-person";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byProgrammeArea).$mix(byPerson);
    }

    peopleByProgrammeArea(id) {
        return this.relation('people', 'programmeAreaId', id, 'person', ['association']).then(collection => {
            return collection.filter(person => person.association !== 'other');
        });
    }

    byProgrammeAreaWithPeople(id) {
        return this.transformer.many(
            this.try(
                this.baseQuery().where('programmeAreaId', id).include('people').get()
            )
        );
    }
}

export default Repository;
