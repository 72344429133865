import Kernel from "@oilstone/kernel";

export default {
    type: 'collections',
    aliases: {
        camel: 'collections',
        singular: 'Series',
        plural: 'Series',
        lowerSingular: 'series',
        lowerPlural: 'series',
    },
    relations: {
        'modules': {
            pivot: {
                type: 'collection-modules',
                localKey: 'collectionId',
                foreignKey: 'moduleId'
            }
        },
        'themes': {
            adminLabel: "Themes",
            pivot: {
                type: 'collection-themes',
                localKey: 'collectionId',
                foreignKey: 'themeId'
            }
        },
    },
    publishable: true,
    adminSection: null,
    componentType: 'base',
    filterOn: {
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminRecord: {
            name: 'AdministerCollectionRecord',
            loader: () => import('./AdministerCollectionRecord')
        },
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminTranslation: {
            name: 'AdministerCollectionTranslation',
            loader: () => import('./AdministerCollectionTranslation')
        },
        webRecord: {
            name: 'DisplayCollectionRecord',
            loader: () => import('./DisplayCollectionRecord')
        },
    },
    cms: {
        collectionTitle: () => {
            const queryParams = Kernel.resolve('router').currentRoute.value.query;

            if (queryParams.section === 'blog-posts') {
                return 'Blog series';
            }

            if (queryParams.section === 'podcasts') {
                return 'Podcast series';
            }
        }
    }
};
