<template>
    <button
        class="base-btn"
        @click="click"
    >
        <slot />
    </button>
</template>

<script>
import Navigation from "@/services/navigation";

export default {
    props: {
        route: Object
    },

    methods: {
        click() {
            if (this.route) {
                Navigation.push(this.route);
            }
        }
    }
}
</script>

<style lang="postcss">
.base-btn {
    @apply uppercase block bg-big-stone text-white w-full h-13 px-7 font-sans-medium text-sm tracking-wide transition duration-300 focus:outline-none;
}
</style>
