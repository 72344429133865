import Factory from '../factory';

const schema = Factory.schema('notice');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('title').setType(String).setValue('Title');
attributes.prop('summary').setType(String).setValue('Summary');
attributes.prop('btnLabel').setType(String).setValue('Button');
attributes.prop('btnSrc').setType(String).setValue('Button link');
attributes.prop('imgSrc').setType(String).setValue('image-placeholder-square.svg');
attributes.prop('imgAlt').setType(String);
attributes.prop('imgCredit').setType(String);

export default schema;
