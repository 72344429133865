<template>
    <component :is="resolvedName" />
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: null,
        }
    },

    computed: {
        resolvedName() {
            return `Icon${this.name}`;
        }
    }
};
</script>