export default {
    type: 'hero-carousel',
    name: 'Hero Carousel',
    osOnly: true,
    components: {
        root: {
            name: 'ModuleHeroCarousel',
            loader: () => import('./ModuleHeroCarousel')
        }
    }
};
