export default [
    {
        path: '/admin/login',
        name: 'admin-login',
        component: () => import('@/apps/cms/templates/AdminLogin'),
        meta: {
            noAuth: true,
        }
    },

    {
        path: '/admin',
        component: () => import('@/apps/cms/templates/AdminTemplate'),
        children: [
            {
                path: '',
                name: 'admin-dashboard',
                component: () => import('@/apps/cms/views/AdminDashboard'),
            },
            {
                path: 'categories',
                name: 'admin-categories',
                component: () => import('@/apps/cms/views/AdminCategorised'),
            },
            {
                path: 'paginated',
                name: 'admin-paginated',
                component: () => import('@/apps/cms/views/AdminPaginated'),
            },
            {
                path: ':type/:id?',
                name: 'admin-resource',
                component: () => import('@/apps/cms/views/AdminResource'),
                props: true
            },
        ]
    },
];
