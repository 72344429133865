export default {
    type: 'rich-text',
    name: 'Rich Text',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleRichText',
            loader: () => import('./ModuleRichText')
        }
    }
};
