<template>
    <div
        class="carousel"
        ref="carousel"
    >
        <div class="carousel__inner">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import EmblaCarousel from 'embla-carousel';

const breakpoints = ['Sm', 'Md', 'Lg', 'Xl'];

const defaults = {
    show: 1,
    scroll: 1,
    gutter: '0px',
    align: 'start',
    loop: false,
    stubWidth: '0px',
}

const propFactory = (breakpoints) => {
    let items = [...breakpoints];
    items.unshift('');

    const props = [];

    items.forEach(breakpoint => {
        for (let key in defaults) {
            props.push(`${key}${breakpoint}`);
        }
    });

    return props;
};

export default {
    props: propFactory(breakpoints),

    data() {
        return {
            carousel: null,
            selected: null,
        }
    },

    methods: {
        option(key, breakpoint) {
            breakpoint = breakpoint || '';

            let value = this[`${key}${breakpoint}`];

            if (typeof value !== 'undefined') {
                return value;
            }

            if (!breakpoint) {
                return defaults[key];
            }

            let reversed = [...breakpoints].reverse();
            let breakpointIndex = reversed.indexOf(breakpoint);
            let ancestors = reversed.slice(breakpointIndex + 1);

            for (let ancestor in ancestors) {
                let value = this[`${key}${ancestors[ancestor]}`];

                if (value) {
                    return value;
                }
            }

            return this[key] || defaults[key];
        },

        init() {
            this.bindCssVars();

            this.carousel = EmblaCarousel(
                this.$refs.carousel,
                {
                    slidesToScroll: this.option('scroll'),
                    align: this.option('align'),
                    loop: this.option('loop'),
                    containScroll: 'keepSnaps'
                }
            );

            this.carousel.on('select', () => {
                this.selected = this.carousel.selectedScrollSnap();
                this.$emit('updated', this.selected);
            });

            this.carousel.on('scroll', () => {
                this.$emit('scrolling');
            });

            this.carousel.on('settle', () => {
                this.$emit('settled');
            })
        },

        bindCssVars() {
            const buildKey = (key, breakpoint) => {
                if (!breakpoint) {
                    return key;
                }

                return `${key}-${breakpoint.toLowerCase()}`;
            };

            const setItemCount = (breakpoint) => {
                this.$refs.carousel.style.setProperty(buildKey('--carousel-item-count', breakpoint), this.option('show', breakpoint));
            };

            const setGutter = (breakpoint) => {
                this.$refs.carousel.style.setProperty(buildKey('--carousel-item-gutter', breakpoint), this.option('gutter', breakpoint));
            };

            const setStubVars = (breakpoint) => {
                let stubWidth = this.option('stubWidth', breakpoint);
                let stubGutter = stubWidth === '0px' ? '0px' : this.option('gutter', breakpoint);

                this.$refs.carousel.style.setProperty(buildKey('--carousel-stub-width', breakpoint), stubWidth);
                this.$refs.carousel.style.setProperty(buildKey('--carousel-stub-gutter', breakpoint), stubGutter);
            };

            const setOptions = (breakpoint) => {
                this.$refs.carousel.style.setProperty(buildKey('--carousel-options', breakpoint), `'${JSON.stringify({
                    slidesToScroll: this.option('scroll', breakpoint),
                    align: this.option('align', breakpoint),
                    loop: this.option('loop', breakpoint),
                })}'`);
            }

            setItemCount();
            setGutter();
            setStubVars();

            breakpoints.forEach(breakpoint => {
                setItemCount(breakpoint);
                setGutter(breakpoint);
                setStubVars(breakpoint);
                setOptions(breakpoint);
            });
        },

        scrollTo(pos){
            return this.carousel.scrollTo(pos);
        },

        scrollNext(){
            return this.carousel.scrollNext();
        },

        scrollPrevious(){
            return this.carousel.scrollPrev();
        },

        canScrollNext(){
            return this.carousel.canScrollNext();
        },

        canScrollPrevious(){
            return this.carousel.canScrollPrev();
        },

        scrollProgress(){
            return this.carousel.scrollProgress();
        },

        selectedScrollSnap(){
            return this.carousel.selectedScrollSnap();
        },

        slidesInView(bool = false){
            return this.carousel.slidesInView(bool);
        },

        scrollSnapList(){
            return this.carousel.scrollSnapList();
        },

        reInit(){
            return this.carousel.reInit();
        },
    },

    mounted() {
        this.init();
    },
}
</script>

<style lang="postcss">
.carousel {
    overflow: hidden;

    &:before {
        @screen sm {
            display: none;
            content: var(--carousel-options-sm);
        }

        @screen md {
            display: none;
            content: var(--carousel-options-md);
        }

        @screen lg {
            content: var(--carousel-options-lg);
        }

        @screen xl {
            content: var(--carousel-options-xl);
        }
    }

    &__inner {
        display: flex;
    }

    &__item {
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        width: calc(((100% - var(--carousel-stub-width) - var(--carousel-stub-gutter)) / var(--carousel-item-count)) - (((var(--carousel-item-count) - 1) * var(--carousel-item-gutter)) / var(--carousel-item-count)));
        margin-right: var(--carousel-item-gutter);

        @screen sm {
            width: calc(((100% - var(--carousel-stub-width-sm) - var(--carousel-stub-gutter-sm)) / var(--carousel-item-count-sm)) - (((var(--carousel-item-count-sm) - 1) * var(--carousel-item-gutter-sm)) / var(--carousel-item-count-sm)));
            margin-right: var(--carousel-item-gutter-sm);
        }

        @screen md {
            width: calc(((100% - var(--carousel-stub-width-md) - var(--carousel-stub-gutter-md)) / var(--carousel-item-count-md)) - (((var(--carousel-item-count-md) - 1) * var(--carousel-item-gutter-md)) / var(--carousel-item-count-md)));
            margin-right: var(--carousel-item-gutter-md);
        }

        @screen lg {
            width: calc(((100% - var(--carousel-stub-width-lg) - var(--carousel-stub-gutter-lg)) / var(--carousel-item-count-lg)) - (((var(--carousel-item-count-lg) - 1) * var(--carousel-item-gutter-lg)) / var(--carousel-item-count-lg)));
            margin-right: var(--carousel-item-gutter-lg);
        }

        @screen xl {
            width: calc(((100% - var(--carousel-stub-width-xl) - var(--carousel-stub-gutter-xl)) / var(--carousel-item-count-xl)) - (((var(--carousel-item-count-xl) - 1) * var(--carousel-item-gutter-xl)) / var(--carousel-item-count-xl)));
            margin-right: var(--carousel-item-gutter-xl);
        }
    }
}
</style>
