<template>
    <AdminFormGroup class="admin-picker">
        <FormLabel
            :text="label"
            class="admin-picker form-control"
        >

            <div class="admin-picker__filter">
                <div
                    class="admin-picker__filter__label"
                    @click="toggleFilter"
                >
                    Filter
                </div>
                <div
                    class="admin-picker__filter__input"
                    v-if="showFilter"
                >
                    <BaseInput
                        v-model="searchString"
                        placeholder="Type here to search..."
                    />
                </div>
            </div>

            <div class="admin-picker__pickable">
                <ul class="admin-picker__list admin-picker__list--available">
                    <li
                        v-for="item in available"
                        :key="item.value"
                        @click="select(item)"
                        class="admin-picker__list__item"
                        :class="(selected.value === item.value ? 'admin-picker__list__item--selected' : '') + ' ' + (item.published ? 'admin-picker__list__item--published' : 'admin-picker__list__item--draft')"
                    >
                        <template v-if="item.published">
                            <IconRenderer
                                name="CmsTick"
                                :title="(item.published ? 'Published' : 'Draft')"
                            />
                        </template>
                        {{ item.text }}
                    </li>
                </ul>
                <div class="admin-picker__actions">
                    <div
                        @click="add"
                        class="admin-picker__actions__item"
                    >
                        <IconRenderer
                            name="Chevron"
                            class="transform -rotate-90"
                        />
                    </div>
                    <div
                        @click="remove"
                        class="admin-picker__actions__item"
                    >
                        <IconRenderer
                            name="Chevron"
                            class="transform rotate-90"
                        />
                    </div>
                </div>
                <ul class="admin-picker__list admin-picker__list--picked">
                    <li
                        v-for="item in picked"
                        :key="item.value"
                        @click="select(item)"
                        class="admin-picker__list__item"
                        :class="(selected.value === item.value ? 'admin-picker__list__item--selected' : '') + ' ' + (item.published ? 'admin-picker__list__item--published' : 'admin-picker__list__item--draft')"
                    >
                        <template v-if="item.published">
                            <IconRenderer
                                name="CmsTick"
                                :title="(item.published ? 'Published' : 'Draft')"
                            />
                        </template>
                        {{ item.text }}
                    </li>
                </ul>
                <div class="admin-picker__actions">
                    <div
                        @click="moveUp"
                        class="admin-picker__actions__item"
                    >
                        <IconRenderer
                            name="Chevron"
                            class="transform rotate-180"
                        />
                    </div>
                    <div
                        @click="moveDown"
                        class="admin-picker__actions__item"
                    >
                        <IconRenderer name="Chevron"/>
                    </div>
                </div>
            </div>
        </FormLabel>
    </AdminFormGroup>
</template>

<script>
import filterItems from "@/composables/collection-filter";
import {toRefs} from "vue";

export default {
    setup(props) {
        props = toRefs(props);

        const {searchString, filtered} = filterItems(props.options, ['value', 'text'], {});

        return {
            searchString,
            filtered,
        }
    },

    props: {
        label: String,
        options: Array,
        modelValue: Array,
        debug: {
            type: Boolean,
            default: false
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],

    data() {
        return {
            selected: {},
            showFilter: false,
        }
    },

    computed: {
        available() {
            return this.filtered.filter(option => {
                if(this.modelValue) {
                    return this.modelValue.indexOf(option.value) === -1;
                } else {
                    return option.value;
                }
            });
        },

        picked() {
            if(this.modelValue) {
                if (Object.keys(this.modelValue).length === 0) {
                    return [];
                }

                return this.filtered.filter(option => {
                    return this.modelValue.indexOf(option.value) !== -1;
                }).sort((a, b) => {
                    return this.modelValue.indexOf(a.value) > this.modelValue.indexOf(b.value) ? 1 : -1;
                });
            } else {
                return [];
            }
        },
    },

    methods: {
        select(item) {
            this.selected = item
        },

        add() {
            if (this.selected && Array.isArray(this.modelValue)) {
                const picked = [...this.modelValue];

                if (picked.indexOf(this.selected.value) === -1) {
                    const index = this.available.findIndex(option => {
                        return this.selected.value === option.value;
                    });

                    picked.push(this.selected.value);

                    this.$emit('update:modelValue', picked);

                    this.$nextTick(() => {
                        if (this.available.length) {
                            this.selected = this.available[this.available.length > index ? index : 0];
                        }
                    });
                }
            }
        },

        remove() {
            if (this.selected) {
                const picked = [...this.modelValue];
                const index = picked.indexOf(this.selected.value);

                if (index !== -1) {
                    const pickedIndex = this.modelValue.indexOf(this.selected.value);

                    picked.splice(index, 1);

                    this.$emit('update:modelValue', picked);

                    this.$nextTick(() => {
                        if (this.picked.length) {
                            this.selected = this.picked[this.picked.length > pickedIndex ? pickedIndex : 0];
                        }
                    });
                }
            }
        },

        moveUp() {
            if (this.selected) {
                const picked = [...this.modelValue];
                const index = picked.indexOf(this.selected.value);

                if (index !== -1 && index > 0) {
                    picked.splice(index, 1);
                    picked.splice(index - 1, 0, this.selected.value);
                }

                this.$emit('update:modelValue', picked);
            }
        },

        moveDown() {
            if (this.selected) {
                const picked = [...this.modelValue];
                const index = picked.indexOf(this.selected.value);

                if (index !== -1 && index < this.modelValue.length - 1) {
                    picked.splice(index, 1);
                    picked.splice(index + 1, 0, this.selected.value);
                }

                this.$emit('update:modelValue', picked);
            }
        },

        toggleFilter() {
            this.showFilter = !this.showFilter;
        },
    }
}
</script>

<style lang="postcss">
.admin-picker {
    &__pickable {
        @apply flex space-x-5;
    }

    &__list {
        @apply border border-swiss-coffee w-1/2 max-h-50 overflow-x-auto;

        &__item {
            @apply flex flex-row cursor-pointer py-1 px-2 m-0 border-b border-gallery items-start justify-start;

            &:last-child {
                @apply border-b-0;
            }

            &--selected {
                @apply bg-orange-4;
            }

            svg {
                @apply text-dusty-gray mr-1 mt-1.5 flex-shrink-0;
                width: 14px;
                height: 14px;
            }

            &--published {
                svg {
                    @apply text-purple-1;
                }
            }
        }
    }

    &__actions {
        @apply flex flex-col;

        &__item {
            @apply border border-swiss-coffee cursor-pointer flex-shrink-0 w-10 h-10 p-3;

            + .admin-picker__actions__item {
                @apply border-t-0;
            }
        }
    }

    &__filter {
        @apply my-2;

        &__label {
            @apply mb-2 cursor-pointer text-grey-1 italic text-xs;
        }
    }
}
</style>
