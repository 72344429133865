export default {
    type: 'aux-pages',
    name: 'Aux pages',
    components: {
        root: {
            name: 'ModuleAuxPages',
            loader: () => import('./ModuleAuxPages')
        }
    }
};
