export default {
    type: 'event-modules',
    aliases: {
        camel: 'eventModules',
        singular: 'Event module',
        plural: 'Event modules',
        lowerSingular: 'event module',
        lowerPlural: 'event modules',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
