<template>
    <div
        class="modal"
        :class="modalClasses"
    >
        <div
            class="modal__blocker"
            @click.stop="modalClose"
        />

        <div class="modal__container">
            <div class="modal__container__header">
                <p>
                    <strong
                        v-if="title"
                        v-html="title"
                    />
                </p>
                <IconRenderer
                    name="Cross"
                    @click.stop="modalClose"
                />
            </div>
            <div class="modal__container__content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null,
        },
        modalOpen: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        modalClasses() {
            const classes = [];

            if (this.modalOpen) {
                classes.push('modal--open');
            }

            return classes;
        },
    },

    methods: {
        modalClose() {
            this.$emit('status',false);
        },
    }
}
</script>

<style scoped>
.modal {
    @apply fixed z-50 top-0 left-0 w-screen max-w-full h-screen flex items-center justify-center opacity-0 invisible transition duration-500;

    &--open {
        @apply opacity-100 visible;

        .modal__container {
            @apply transform-none;
        }
    }

    &__blocker {
        @apply bg-big-stone opacity-50 absolute w-full h-full top-0 left-0;
    }

    &__container {
        @apply relative z-10 w-full m-6 bg-white transform -translate-y-12 transition duration-500 overflow-x-hidden overflow-y-auto;
        max-width: 960px;
        max-height: 92%;

        &__header {
            @apply flex flex-row justify-between items-center px-4 py-5;

            p {
                @apply flex-grow m-0 p-0;
            }

            svg {
                @apply w-4 h-auto;
            }
        }

        &__content {
            @apply w-full;
        }
    }
}
</style>