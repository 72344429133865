import BaseRepository from "@/services/publication/repository";
import queryByKeys from "@/services/resources/mixins/query-by-keys";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(queryByKeys);
    }

    record(id) {
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('translations')
                    .include('event-people')
                    .include('event-themes')
                    .include('event-countries')
                    .include('event-programme-areas')
                    .include('event-projects')
                    .include('event-keywords')
                    .find(id)
            )
        );
    }

    collection() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .sort('publishAt', 'desc')
                    .include('event-programme-areas')
                    .include('event-themes')
                    .include('event-projects')
                    .get()
            )
        );
    }

    forSearch() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .include('event-type')
                    .include('event-projects')
                    .include('event-countries')
                    .include('event-themes')
                    .include('event-programme-areas.programme-areas')
                    .include('event-keywords.keywords')
                    .select('id','title','slug','image','location','startDate','publishAt','decade_id','event_type_id'
                        ,'event-type.title','event-type.id'
                        ,'event-projects.sort','event-projects.id','event-projects.projectId','projects.id'
                        ,'event-countries.sort','event-countries.id','event-countries.country','countries.id'
                        ,'event-themes.sort','event-themes.id','event-themes.themeId','themes.id'
                        ,'event-programme-areas.sort','event-programme-areas.id','event-programme-areas.programmeAreaId','programme-areas.id','programme-areas.title'
                        ,'event-keywords.sort','event-keywords.id','event-keywords.keywordId','keywords.id','keywords.title'
                    )
                    .where('isPublished',1)
                    .get()
            )
        );
    }
}

export default Repository;
