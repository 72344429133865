import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .hasTitle()
    .make();

schema.prop('type').setType(String).addMetadata('mergeable', true);
schema.prop('category').setType(String).addMetadata('mergeable', true);
schema.prop('value').setType(String).setValue(null).addMetadata('translatable', true);

export default schema;
