<template>
    <div class="form-file-upload">
        <FormInput
            type="file"
            placeholder="Select file to upload"
            @change="change"
        />
        <FormBtn
            variant="zeta"
            @click="upload"
        >
            Upload
        </FormBtn>
    </div>
</template>

<script>

import Kernel from "@oilstone/kernel/dist/kernel";

export default {
    props: {
        dir: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            name: null,
            contents: null,
            mimeType: null
        }
    },

    emits: ['uploaded'],

    methods: {
        change(e) {
            let files = e.target.files || e.dataTransfer.files;

            if (files.length) {
                const file = files[0];
                this.name = file['name'];

                this.getBase64(file).then(file => {
                    this.contents = file.contents;
                    this.mimeType = file.mimeType;
                });
            }
        },

        getBase64(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            return new Promise(resolve => {
                reader.onload = () => {
                    const pieces = reader.result.slice(5).split(';');

                    resolve({
                        mimeType: pieces[0],
                        contents: pieces[1].split(',')[1]
                    });
                };
            })
        },

        upload() {
            if (this.name && this.contents) {
                Kernel.resolve('resourceRegistry').find('file-uploads').repository.save({
                    path: `${this.dir}/`,
                    name: this.name,
                    contents: this.contents,
                    mimeType: this.mimeType,
                }).then(resource => {
                    this.$emit('uploaded', resource);
                });
            }
        }
    }
}
</script>

<style>
.form-file-upload {
    .form-btn {
        @apply mt-5;
        width: fit-content;
    }
}
</style>
