export default {
    type: 'impact-stories',
    aliases: {
        camel: 'impactStories',
        singular: 'Impact story',
        plural: 'Impact stories',
        lowerSingular: 'impact story',
        lowerPlural: 'impact stories',
    },
    relations: {
        'modules': {
            pivot: {
                type: 'impact-story-modules',
                localKey: 'impactStoryId',
                foreignKey: 'moduleId'
            }
        },
    },
    publishable: true,
    adminSection: 'about',
    componentType: 'base',
    filterOn: {
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerImpactStoryRecord',
            loader: () => import('./AdministerImpactStoryRecord')
        },
        adminTranslation: {
            name: 'AdministerImpactStoryTranslations',
            loader: () => import('./AdministerImpactStoryTranslations')
        },
        webRecord: {
            name: 'DisplayImpactStoryRecord',
            loader: () => import('./DisplayImpactStoryRecord')
        },
    }
};
