<template>
    <div
        class="admin-sidebar-section"
        :class="active ? 'admin-sidebar-section--active' : ''"
    >
        <div
            class="admin-sidebar-section__button"
            :class="classes"
            @click="handleClick"
        >
            <IconRenderer
                v-if="showIcon"
                :name="section.icon"
            />
            <span>{{ section.title }}</span>
            <IconRenderer
                v-if="showChevron"
                class="w-3"
                name="ChevronDown"
            />
        </div>
        <template v-if="active && sortedNavigation">
            <div class="admin-sidebar-section__items">
                <div
                    v-for="(item, index) in sortedNavigation"
                    :key="index"
                    class="admin-sidebar-section__items__item"
                >
                    <navigation-link :to="item.link">
                        {{ item.title }}
                    </navigation-link>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import Kernel from "@oilstone/kernel";
import Navigation from "@/services/navigation";

export default {
    props: {
        section: {
            type: Object,
            required: true
        },
    },

    data() {
        return {
            active: false,
            resources: [],
        }
    },

    computed: {
        classes() {
            return {
                'admin-sidebar-section__button--icon': this.showIcon,
                'admin-sidebar-section__button--administration': !!this.section.admin,
            }
        },

        showIcon() {
            return !!this.section.icon;
        },

        showChevron() {
            return this.sectionItems && !this.section.admin;
        },

        defaultItems(){
            let items = [];

            if (this.section.hasSectionHomepage) {
                items.push({
                    title: 'Section homepage',
                    link: {
                        name: 'admin-resource',
                        params: {
                            type: 'pages',
                        },
                        query: {
                            section: this.section.key,
                            sectionHomepage: 'true',
                        },
                    },
                });
            }

            if (this.section.hasSectionPages) {
                items.push({
                    title: 'Section pages',
                    link: {
                        name: 'admin-resource',
                        params: {
                            type: 'pages',
                        },
                        query: {
                            section: this.section.key,
                        },
                    },
                });
            }

            return items;
        },

        sectionItems() {
            const baseResources = [];
            const lookupResources = [];

            Kernel.resolve('resourceRegistry').forEach(resource => {
                if (resource.config.adminSection !== this.section.key) {
                    return;
                }

                const menuItem = {
                    title: resource.config.aliases.plural,
                    link: {
                        name: 'admin-resource',
                        params: {
                            type: resource.config.type,
                        },
                    },
                }

                if (resource.config.componentType === 'base') {
                    baseResources.push(menuItem);
                }

                if (resource.config.componentType === 'lookup') {
                    lookupResources.push(menuItem);
                }
            });

            let items = this.section.items || [];

            return items.concat(baseResources).concat(lookupResources);
        },

        sortedNavigation(){
            let items = this.sectionItems;
            let sorted = items.sort((a, b) => (a.title > b.title) ? 1 : -1)
            return this.defaultItems.concat(sorted);
        },
    },

    methods: {
        handleClick() {
            if (this.section.link) {
                return Navigation.push(this.section.link);
            }

            this.active = !this.active;
        },
    },
}
</script>

<style lang="postcss">
.admin-sidebar-section {
    @apply border-b border-grey-3 py-2 px-2 font-sofia-pro;

    &--active {
        .admin-sidebar-section__button {
            @apply !text-purple-1;

            svg {
                @apply !text-purple-1;
            }
        }
    }

    &__button {
        @apply cursor-pointer flex justify-between items-center font-sofia-pro;

        svg {
            @apply text-sm text-grey-2;
        }

        &--icon {
            @apply justify-start;

            svg {
                @apply w-4 mr-2;
            }
        }

        &--administration {
            @apply text-grey-2;
        }

        &:hover {
            @apply text-purple-1;

            svg {
                @apply text-purple-1;
            }
        }
    }

    &__items {
        @apply py-2 px-4 mt-2;

        &__item {
            @apply text-sm mb-2 cursor-pointer hover:text-purple-1;

            .router-link-exact-active {
                @apply text-purple-1;
            }
        }
    }
}
</style>
