import BaseRepository from "@/services/resources/repository";
import byVacancy from "@/services/resources/mixins/query-by-vacancy";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byVacancy);
    }
}

export default Repository;
