<template>
    <span className="lookup lookup--translation">{{ resolved }}</span>
</template>

<script>
import {resolveTranslation} from "@/services/lookup/composers";

export default {
    setup(props) {
        return {
            resolved: resolveTranslation(props.lookup),
        }
    },

    props: {
        lookup: {
            type: String,
            required: true,
        }
    },
}
</script>
