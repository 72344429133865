export default {
    type: 'translations',
    aliases: {
        camel: 'translations',
        singular: 'Translation',
        plural: 'Translations',
        lowerSingular: 'translation',
        lowerPlural: 'translations',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'base',
    searchOn: ['lookup', 'value'],
    components: {
        adminCollection: {
            name: 'AdministerTranslationCollection',
            loader: () => import('./AdministerTranslationCollection')
        },
        adminRecord: {
            name: 'AdministerTranslationRecord',
            loader: () => import('./AdministerTranslationRecord')
        },
        adminTranslation: {
            name: 'AdministerTranslationTranslation',
            loader: () => import('./AdministerTranslationTranslation')
        },
    }
};
