import BaseRepository from "@/services/resources/repository";
import byPerson from "@/services/resources/mixins/query-by-person";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byPerson);
    }

    byAffiliation(id) {
        return this.try(
            this.baseQuery().where('affiliationId', id).get()
        );
    }
}

export default Repository;
