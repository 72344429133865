<template>
	<div class="admin-breadcrumbs">
		Current location: <span @click="updateCurrentPath('')">root</span>
        <span
			v-for="(path,i) in paths"
			v-bind:key="i+'loc'"
        >
            <span
                class="admin-breadcrumbs__crumb"
                v-if="path && path !== ''"
                v-html="path"
                @click="updateCurrentPath(path)"
            />
        </span>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
	computed: {
        ...mapGetters('asset', {
            currentPath: 'currentPath',
        }),

		paths(){
            if(this.currentPath) {
                return this.currentPath.split('/')
            } else {
                return [];
            }
		}
	},

	methods: {
        ...mapMutations('asset', {
            setCurrentPath: 'setCurrentPath',
        }),

		updateCurrentPath(path){
			let np = "";
			let cp = this.currentPath;

			if(path !== "") {
				np = cp.substring(0, cp.indexOf(path)) + path + "/";
			}

			this.setCurrentPath(np);
		}
	},
}
</script>

<style lang="postcss">

.admin-breadcrumbs {
    @apply border-b border-silver py-2 px-4 mb-4 font-heading text-cod-gray text-sm;

	&__crumb {
        @apply mr-0 text-dusty-gray cursor-pointer;

		&:hover {
            @apply text-st-tropaz;
		}

		&:before {
			content: ' / '
		}
	}

	&:last-child {
		&__crumb {
			&:before {
				content: ''
			}
		}
	}
}

</style>
