<template>
    <TitledContainer
        class="web-form"
        :variant="variant"
    >
        <GridContainer>
            <GridColumn class="web-form__form">
                <div class="trim-content">
                    <slot name="form" />
                </div>
            </GridColumn>
            <GridColumn class="web-form__right">
                <slot name="right" />
            </GridColumn>
        </GridContainer>
    </TitledContainer>
</template>

<script>
export default {
    props: {
        variant: {
            type: String,
            default:  'alpha',
        }
    }
}
</script>

<style lang="postcss">
.web-form {
    .grid-container {
        @apply grid-cols-12 gap-8 my-8;

        .grid-column {
            @apply col-span-12;

            &.web-form__form {
                @apply lg:col-span-7;
            }

            &.web-form__right {
                @apply hidden lg:block lg:col-span-4 lg:col-start-9;
            }

            .form-label__text {
                @apply font-semibold font-libre-franklin text-base;
            }

            .form-checkbox__inner {
                @apply text-base;
            }
        }
    }
}
</style>
