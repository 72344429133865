import BaseRepository from "@/services/publication/repository";
import queryByKeys from "@/services/resources/mixins/query-by-keys";
import queryByProject from "@/services/resources/mixins/query-by-project";
import queryByEvent from "@/services/resources/mixins/query-by-event";
import queryByProgrammeArea from "@/services/resources/mixins/query-by-programme-area";
import queryByCollection from "@/services/resources/mixins/query-by-collection";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(queryByKeys).$mix(queryByProject).$mix(queryByEvent).$mix(queryByProgrammeArea).$mix(queryByCollection);
    }

    record(id) {
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('translations')
                    .include('podcast-themes')
                    .find(id)
            )
        );
    }

    collection() {
        return this.transformer.many(
            this.try(
                this.baseQuery().sort('publishAt', 'desc').include('collection').include('podcast-themes').get()
            )
        );
    }

    forSearch(){
        return this.transformer.many(
            this.try (
                this.baseQuery()
                    .include('podcast-themes')
                    .where('isPublished',1)
                    .get()
            )
        );
    }

    episodes(id){
        return this.transformer.many(
            this.try (
                this.baseQuery()
                    .where('collection_id', id)
                    .get()
            )
        );
    }

    byCollectionWithProgrammeArea(id) {
        return this.transformer.many(
            this.try(
                this.baseQuery().where('collectionId', id).include('programme-area').get()
            )
        )
    }
}

export default Repository;
