<template>
    <div class="admin-section mt-10">
        <template v-if="title">
            <AdminHeader>
                {{ title }}
            </AdminHeader>
            <hr/>
        </template>

        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: null
        }
    }
}
</script>
