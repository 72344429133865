import BaseRepository from "@/services/publication/repository";
import queryByKeys from "@/services/resources/mixins/query-by-keys";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(queryByKeys);
    }

    record(id) {
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('translations')
                    .include('news-item-events')
                    .include('news-item-keywords')
                    .include('news-item-people')
                    .include('news-item-programme-areas')
                    .include('news-item-projects')
                    .include('news-item-themes')
                    .find(id)
            )
        );
    }

    collection() {
        return this.transformer.many(
            this.try(
                this.baseQuery().sort('publishAt', 'desc').include('news-item-programme-areas').include('news-item-themes').include('news-item-projects').include('news-item-events').get()
            )
        );
    }

    forSearch() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .include('news-item-themes')
                    .select('id','title','slug','image','image_alt','publish_at','country','news-item-themes.sort','news-item-themes.id','themes.id')
                    .where('isPublished',1)
                    .get()
            )
        );
    }
}

export default Repository;
