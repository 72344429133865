import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .make();

schema.prop('newsItemId').setType(Number);
schema.prop('personId').setType(Number);

export default schema;
