import Factory from './factory';

export default new Factory()
    .addProp('placeholder', String)
    .addProp('minDate', String)
    .addProp('maxDate', String)
    .addProp('minTime', String)
    .addProp('maxTime', String)
    .addProp('startDate', String)
    .addProp('startTime', String)
    .addProp('format', String)
    .addProp('clearable', Boolean)
    .addProp('disabledDates', Array)
    .addPassthrough('placeholder')
    .addPassthrough('minDate')
    .addPassthrough('maxDate')
    .addPassthrough('minTime')
    .addPassthrough('maxTime')
    .addPassthrough('startDate')
    .addPassthrough('startTime')
    .addPassthrough('format')
    .addPassthrough('clearable')
    .addPassthrough('disabledDates');
