import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .make();

schema.prop('vacancyApplicationId').setType(Number);
schema.prop('sort').setType(Number);
schema.prop('institution').setType(String);
schema.prop('fromDate').setType(String);
schema.prop('toDate').setType(String);
schema.prop('qualifications').setType(String);
schema.prop('qualificationLevel').setType(String);
schema.prop('course').setType(String);

export default schema;
