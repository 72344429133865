export default {
    type: 'vacancy-application-employment',
    aliases: {
        camel: 'vacancyApplicationEmployment',
        singular: 'vacancyApplicationEmployment',
        plural: 'Vacancy Application employment',
        lowerSingular: 'vacancy application employment',
        lowerPlural: 'vacancy application employment',
    },
    relations: {
    },
    adminSection: null,
    componentType: 'base',
    filterOn: {
    },
    components: {
    }
};
