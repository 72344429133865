<template>
    <svg
        class="icon-star"
        viewBox="0 0 25.52 24"
        xmlns="http://www.w3.org/2000/svg"
    ><g><path
        d="M18.94,14.84l6.58-4.74-.85-2.47h-8.1c-1-2.9-2.48-7.58-2.5-7.63H11.45S9.92,4.66,9,7.63H.85L0,10.1l6.59,4.74L4.12,22.36s0,.08,0,.16L6.21,24l6.55-4.72L19.32,24l2.13-1.5c0-.05,0-.09,0-.12Z"
        fill="none"
        transform="translate(0 0)"
    /></g><path
        d="M18.94,14.84l6.58-4.74-.85-2.47h-8.1c-1-2.9-2.48-7.58-2.5-7.63H11.45S9.92,4.66,9,7.63H.85L0,10.1l6.58,4.74L4.12,22.36a.76.76,0,0,0,0,.16L6.21,24l6.55-4.72L19.32,24l2.13-1.5c0-.06,0-.1,0-.12Zm-5,2.82-1.17-.85-1.17.85-5,3.61,1.9-5.81.46-1.39-1.19-.86,0,0L2.78,9.63H10.4l.45-1.38c.59-1.79,1.38-4.2,1.91-5.83.54,1.63,1.33,4.05,1.91,5.83l.46,1.38h7.62L18.2,12.91l-.43.3-1.19.86L17,15.46l1.9,5.81Z"
        transform="translate(0 0)"
    /></svg>
</template>
