export default {
    type: 'programme-area-keywords',
    aliases: {
        camel: 'programmeAreaKeywords',
        singular: 'Programme area keyword',
        plural: 'Programme area keywords',
        lowerSingular: 'programme area keyword',
        lowerPlural: 'programme area keywords',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
