import BaseRepository from "@/services/resources/repository";
import byEvent from "@/services/resources/mixins/query-by-event";
import byNewsItem from "@/services/resources/mixins/query-by-news-item";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byEvent).$mix(byNewsItem);
    }
}

export default Repository;
