export default {
    type: 'ebulletins',
    aliases: {
        camel: 'ebulletins',
        singular: 'E-bulletin',
        plural: 'E-bulletins',
        lowerSingular: 'e-bulletin',
        lowerPlural: 'e-bulletins',
    },
    publishable: true,
    adminSection: 'activities',
    componentType: 'base',
    filterOn: {
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerEbulletinRecord',
            loader: () => import('./AdministerEbulletinRecord')
        },
        adminTranslation: {
            name: 'AdministerEbulletinTranslation',
            loader: () => import('./AdministerEbulletinTranslation')
        },
    }
};
