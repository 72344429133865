<template>
    <AdminFormGroup
        class="admin-asset"
        :class="classes"
    >
        <FormLabel
            :text="label"
            class="form-control"
        >
            <div class="admin-asset__input">
                <BaseInput
                    v-bind="baseComponentBindings"
                    ref="input"
                    class="form-control__input"
                    @update:model-value="update"
                />
                <div
                    class="admin-asset__input__button"
                    title="Add file"
                    @click="addAsset"
                >
                    <IconRenderer
                        v-if="filesIcon"
                        name="FileExport"
                    />

                    <IconRenderer
                        v-else
                        name="Plus"
                    />
                </div>
                <div
                    v-if="showView"
                    class="admin-asset__input__button"
                    title="Preview file"
                    @click="viewAsset"
                >
                    <IconRenderer name="Magnify" />
                </div>
                <div
                    v-if="showDelete"
                    class="admin-asset__input__button"
                    title="Clear file path"
                    @click="deleteAsset"
                >
                    <IconRenderer name="Cross" />
                </div>
            </div>

            <FormError :text="error" />
            <FormHelp :text="helpText" />
        </FormLabel>
        <teleport to="body">
            <AssetLibrary
                v-if="pickAsset"
                @selected="updateAsset"
                @close="closeAssetLibrary"
            />
        </teleport>
    </AdminFormGroup>
</template>

<script>
import formAssetComposable from "@/composables/forms/form-asset";
import AssetLibrary from '../asset-library/AssetLibrary';

export default {
    mixins: [{
        props: formAssetComposable.props(),
    }],

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue', 'assetUpdate'],

    setup(props, {emit}) {
        return {
            ...formAssetComposable.composables(props, emit)
        }
    },

    components: {
        AssetLibrary,
    },

    data(){
        return {
            value: null,
            pickAsset: false,
        }
    },

    props: {
        showView: {
            type: Boolean,
            default: true,
        },
        showDelete: {
            type: Boolean,
            default: true,
        },
        filesIcon: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        updateAsset(val){
            this.updateModel(val);
        },

        updateModel(value) {
            this.$emit('assetUpdate', value);
            this.$emit('update:modelValue', value);
        },

        addAsset(){
            this.pickAsset = true;
        },

        viewAsset(){
            if(this.modelValue) {
                window.open(this.modelValue);
            }
        },

        deleteAsset(){
            this.updateModel(null);
        },

        closeAssetLibrary(){
            this.pickAsset = false;
        }
    },
}
</script>

<style lang="postcss">
.admin-asset__input {
    @apply flex flex-row justify-between items-center;

    .base-input {
        @apply flex-grow;
    }

    &__button {
        @apply flex justify-center items-center border border-swiss-coffee cursor-pointer border-l-0 hover:bg-orange-4;
        width: 52px;
        height: 52px !important;
        min-width: 52px;

        svg {
            @apply w-1/3;
        }
    }
}
</style>
