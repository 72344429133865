<template>
    <div
        class="notice"
        :class="classes"
    >
        <LayoutContainer>
            <GridContainer class="notice__container">
                <GridColumn
                    v-if="src"
                    class="notice__img-column"
                >
                    <ImgSquare
                        :src="src"
                        :credit="credit"
                        :alt="alt"
                        :resolve="resolveSrc"
                    />
                </GridColumn>
                <GridColumn class="notice__text-column">
                    <div class="notice__text-column__content">
                        <slot></slot>
                    </div>
                </GridColumn>
            </GridContainer>
        </LayoutContainer>
    </div>
</template>

<script>
export default {
    props: {
        src: String,
        credit: String,
        alt: String,
        resolveSrc: {
            type: Boolean,
            default: false
        },
        variant: {
            type: String,
            default: 'alpha'
        },
        flip: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        classes() {
            const classes = [`notice--${this.variant}`];

            if (this.flip) {
                classes.push('notice--flip');
            }

            if (this.src) {
                classes.push('notice--has-img');
            }

            return classes;
        },
    }
}
</script>

<style lang="postcss">
.notice {
    @apply relative lg:flex py-6 pb-10 mb-6;

    @screen lg {
        @apply p-10;
    }

    &--flip {
        &.notice--has-img {
            .notice__text-column {
                @screen lg {
                    @apply order-1;
                }
            }

            .notice__img-column {
                @screen lg {
                    @apply order-2 col-start-auto;
                }
            }
        }
    }

    &--has-img {
        @screen lg {
            @apply p-0 py-20;
        }

        .notice__text-column {
            @apply pt-6;

            @screen lg {
                @apply pt-0;
            }
        }
    }

    &--alpha {
        @apply bg-st-tropaz;
    }

    &__container {
        @apply w-full;
    }

    &__text-column {
        @apply col-span-12;

        @screen lg {
            @apply col-span-6;
        }

        &__content {
            @apply text-white h-full;
        }
    }

    &__img-column {
        @apply col-span-12;

        @screen lg {
            @apply col-span-6;
        }

        .img-square {
            @apply object-cover;
            max-height: 250px;
            padding-bottom: 250px;

            @screen sm {
                max-height: 400px;
                padding-bottom: 400px;
            }

            @screen lg {
                max-height: 515px;
                padding-bottom: 515px;
            }

            &__image {
                 @apply absolute top-0 left-0 h-full w-full object-cover;
             }

        }
    }
}
</style>
