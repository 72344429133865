export default {
    type: 'project-keywords',
    aliases: {
        camel: 'projectKeywords',
        singular: 'Project keyword',
        plural: 'Project keywords',
        lowerSingular: 'project keyword',
        lowerPlural: 'project keywords',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
