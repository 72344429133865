<template>
    <span
        class="base-checkbox"
        :class="classes"
    >
        <iconRenderer
            name="Tick"
            class="base-checkbox__icon"
        />
        <input :checked="checked"
               :value="modelValue"
               @input="update"
               type="checkbox"
               class="base-checkbox__input"
        />
    </span>
</template>

<script>
    export default {
        props: {
            trueValue: {
                type: null,
                default: true
            },
            falseValue: {
                type: null,
                default: false
            },
            modelValue: null,
        },

        computed: {
            checked() {
                return this.modelValue === this.trueValue;
            },

            classes() {
                return this.checked ? ['base-checkbox--checked'] : [];
            }
        },

        methods: {
            update() {
                this.$emit('update:modelValue', this.modelValue === this.trueValue ? this.falseValue : this.trueValue);
            }
        }
    }
</script>

<style lang="postcss">
.base-checkbox {
    @apply block relative flex items-center justify-center w-6 h-6 bg-white border border-silver-chalice rounded-sm transition-colors duration-300;

    &--checked {
        @apply bg-big-stone;
    }

    &__icon {
        @apply fill-current text-white w-4;
    }

    &__input {
        @apply absolute cursor-pointer opacity-0 w-full h-full;
    }
}
</style>
