<template>
    <div
        v-if="pills"
        class="pills"
        :class="moduleClass"
    >
        <div class="pills__gradient" />
        <div class="pills__container">
            <div
                v-for="(pill, index) in pills"
                :key="index"
                class="pills__container__pill"
                :class="pill.key === selected ? 'pills__container__pill--selected' : ''"
            >
                {{ pill.title }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pills: {
            type: Array,
            default: null
        },
        selected: String,
        variant: {
            type: String,
            default: 'alpha'
        }
    },

    computed: {
        moduleClass() {
            switch(this.variant){
                case 'beta':
                    return 'pills--beta';

                case 'gamma':
                    return 'pills--gamma';

                default:
                    return '';
            }
        }
    }

}
</script>

<style lang="postcss">
.pills {
    @apply mb-6 relative;

    &__gradient {
        @apply h-full w-1/5 absolute right-0 bg-gradient-to-r from-transparent to-white lg:hidden;
    }

    &__container {
        @apply flex flex-row w-full overflow-scroll pr-8 lg:pr-0;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        &__pill {
            @apply border-silver border py-2.5 px-6 text-salt-box cursor-pointer text-xs mr-2 rounded-r-full rounded-l-full transition-colors duration-300 whitespace-nowrap;

            &:hover, &--selected {
                @apply bg-pampas border-pampas text-big-stone;
            }
        }
    }

    &--beta {
        .pills__container__pill {
            &:hover, &--selected {
                @apply bg-navajo-white border-navajo-white;
            }
        }
    }

    &--gamma {
        .pills__container__pill {
            &:hover, &--selected {
                @apply bg-anakiwa border-anakiwa;
            }
        }
    }
}
</style>
