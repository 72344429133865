<template>
    <span class="lookup lookup--key-value">{{ resolved }}</span>
</template>

<script>
import {resolveKeyValue} from "@/services/lookup/composers";

export default {
    setup(props) {
        return {
            resolved: resolveKeyValue(props.category, props.title),
        }
    },

    props: {
        category: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        }
    },
}
</script>
