import Factory from "@/schemas/factory";

const schema = new Factory()
    .make();

schema.prop('id').setType(String).primaryKey();
schema.prop('language').setType(String).setValue(null);
schema.prop('comment').setType(String).setValue(null);
schema.prop('link').setType(String).setValue(null);

export default schema;
