<template>
    <FormBtn class="new-button">
        <slot />
    </FormBtn>
</template>

<script>

</script>

<style lang="postcss">
.new-button {
    @apply w-max mt-4;

    .base-btn {
        @apply bg-purple-1 text-white px-12 font-sofia-pro;
    }
}
</style>
