export default {
    type: 'network-relationships',
    aliases: {
        camel: 'networkRelationships',
        singular: 'Network relationship',
        plural: 'Network relationships',
        lowerSingular: 'network relationship',
        lowerPlural: 'network relationships',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'lookup',
    components: {},
};
