import {inject, reactive} from "vue";
import Kernel from "@oilstone/kernel";
import {Schema, Collection} from "@oilstone/rest-model-repository";
import Scope from "@/services/data-layer/store/scope";
import Cache from "./cache";
import hasEvents from "@/services/data-layer/composers/has-events";

const cache = new Cache();

const boot = (schema, scope, uuid) => {
    cache.put(
        uuid,
        () => {
            const store = Kernel.resolve('vuexStore');
            const {fire} = hasEvents(uuid);

            const dirty = reactive({
                updates: {},
                replacements: {}
            });

            const make = () => {
                store.dispatch(scope.path().to('makeItem'), uuid).then(() => {
                    fire('made');
                });
            }

            const merge = attributes => {
                return store.dispatch(scope.path().to('mergeItem'), {key: uuid, attributes});
            }

            const prepareUpdate = (path, value) => {
                dirty.updates[path] = value;
            }

            const prepareReplacement = (path, index, attribute, value) => {
                if (typeof dirty.replacements[path] === 'undefined') {
                    dirty.replacements[path] = [];
                }

                if (!(index in dirty.replacements[path])) {
                    dirty.replacements[path][index] = {...store.getters[scope.path().to(path)](uuid)[index]};
                }

                dirty.replacements[path][index][attribute] = value;
            }

            const dispatchUpdates = () => {
                for (let path in dirty.updates) {
                    const payload = {key: uuid};
                    const prop = schema.pullProp(path);

                    if (prop) {
                        payload[path.split('.').pop()] = dirty.updates[path] === null ? null : prop.cast(dirty.updates[path]);
                        store.dispatch(scope.path().to(path), payload);
                    }
                }

                dirty.updates = {};
            }

            const dispatchReplacements = () => {
                for (let path in dirty.replacements) {
                    for (let index in dirty.replacements[path]) {
                        const payload = {
                            key: uuid,
                            index,
                            item: dirty.replacements[path][index]
                        };

                        store.dispatch(`${scope.path().to(path)}.putItem`, payload);
                    }
                }

                dirty.replacements = {};
            }

            const dispatchChanges = () => {
                dispatchUpdates();
                dispatchReplacements();
            }

            const mapProps = (schema, scope) => {
                const mutators = {};
                const props = schema.getProps()

                for (let name in props) {
                    let value = props[name].getValue();

                    if (value instanceof Schema) {
                        mutators[name] = mapProps(value, new Scope().inherit(scope).key(name));
                        continue;
                    }

                    mutators[`set${name.charAt(0).toUpperCase() + name.slice(1)}`] = value => {
                        const payload = {key: uuid};
                        payload[name] = value;

                        store.dispatch(scope.path().to(name), payload);
                    }

                    if (value instanceof Collection) {
                        mutators[name] = {
                            make: () => {
                                store.dispatch(`${scope.path().to(name)}.makeItem`, uuid);
                            },
                            put: (index, attributes) => {
                                store.dispatch(`${scope.path().to(name)}.putItem`, {
                                    key: uuid,
                                    index,
                                    item: attributes
                                });
                            },
                            drop: index => {
                                store.dispatch(`${scope.path().to(name)}.dropItem`, {
                                    key: uuid,
                                    index
                                });
                            }
                        }
                    }
                }

                return mutators;
            };

            return {
                dirty,
                make,
                merge,
                prepareUpdate,
                prepareReplacement,
                dispatchUpdates,
                dispatchReplacements,
                dispatchChanges,
                ...mapProps(schema, scope)
            };
        }
    );
};

const resolve = uuid => {
    return cache.resolve(uuid || inject('uuid'));
};

const forget = () => {
    cache.clear();
};

export {boot, resolve, forget};
export default resolve;
