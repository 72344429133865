import AuthProvider from '@/providers/auth';
import CookieProvider from '@/providers/cookie';
import EventProvider from '@/providers/event';
import HttpProvider from '@/providers/http';
import LanguageProvider from '@/providers/language';
import LocalStorageProvider from '@/providers/local-storage';
import ModelProvider from '@/providers/model';
import ModuleProvider from '@/providers/module';
import NavigationProvider from '@/providers/navigation';
import ResourceProvider from "@/providers/resource";
import RouterProvider from '@/providers/router';
import StoreProvider from '@/providers/store';
import ValidationProvider from '@/providers/validation';
import VueProvider from '@/providers/vue';

export default {
    providers: [
        LanguageProvider,
        NavigationProvider,
        LocalStorageProvider,
        CookieProvider,
        AuthProvider,
        EventProvider,
        HttpProvider,
        ModelProvider,
        ValidationProvider,
        ModuleProvider,
        RouterProvider,
        ResourceProvider,
        StoreProvider,
        VueProvider,
    ]
};
