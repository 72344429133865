<template>
    <div v-if="options.length">
        <AdminPicker
            v-for="(foreignKeys, pivotIndex) in foreignKeysArray"
            :key="'admin-picker-' + pivotIndex"
            :label="resolvedLabel(pivotIndex)"
            :options="options"
            :model-value="foreignKeys"
            @update:modelValue="update($event, pivotIndex)"
        />
    </div>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import {inject} from "vue";
import Kernel from "@oilstone/kernel";
import {accessesData, boot, hydratesData} from "@/services/data-layer/composers";
import Factory from "@/services/resources/factory";

const registry = Kernel.resolve('resourceRegistry');

export default {
    props: {
        modelValue: Array,
        relation: String,
        label: {
            type: String,
            required: false,
        },
    },

    setup(props) {
        const type = inject('type');
        const relation = registry.find(props.relation);

        const getOptions = () => {
            return relation.repository.all().then(collection => {
                return collection.map(record => {
                    return {
                        text: record.lastName ? record.lastName + ', ' + record.firstName : record.title,
                        value: isNaN(parseInt(record.id)) ? record.id : parseInt(record.id),
                        published: typeof record.isPublished === 'undefined' ? null : record.isPublished,
                    }
                })
            });
        }

        return {
            type,
            getOptions
        };
    },

    data() {
        return {
            options: [],
        }
    },

    computed: {
        foreignKeysArray() {
            const pivotConfigs = this.getPivotConfigs();
            const foreignKeysArray = [];

            pivotConfigs.forEach(pivotConfig => {
                let pivotData = (this.modelValue || []).map(uuid => {
                    const {item} = accessesData(uuid);

                    return item(uuid).value;
                });

                if (pivotConfig.pivot.filters) {
                    pivotConfig.pivot.filters.forEach(filter => {
                        pivotData = filter(pivotData);
                    });
                }

                foreignKeysArray.push(pivotData.map(item => {
                    const foreignId = item[pivotConfig.pivot.foreignKey];
                    return isNaN(parseInt(foreignId)) ? foreignId : parseInt(foreignId);
                }));
            });

            return foreignKeysArray;
        },
    },

    methods: {
        resolvedLabel(pivotIndex) {
            if (this.label) {
                return this.label;
            }

            return this.getPivotConfigs()[pivotIndex].adminLabel;
        },

        getPivotConfigs() {
            let config = registry.find(this.type).config.relations[this.relation];

            if (!Array.isArray(config)) {
                config = [config];
            }

            return config;
        },

        update(updatedIds, pivotIndex) {
            const foreignKeyIds = this.foreignKeysArray;

            foreignKeyIds[pivotIndex] = updatedIds;

            let uuids = [];

            this.getPivotConfigs().forEach((pivotConfig, index) => {
                const pivot = registry.find(pivotConfig.pivot.type);

                const items = foreignKeyIds[index].map(itemId => {
                    itemId = isNaN(parseInt(itemId)) ? itemId : parseInt(itemId);

                    const uuid = uuidv4();

                    boot(
                        pivot.schema,
                        Factory.scope(pivot.config),
                        uuid
                    );

                    const {hydrate} = hydratesData(uuid);
                    const record = pivot.schema.blueprint();

                    record[pivotConfig.pivot.foreignKey] = itemId;

                    if (pivotConfig.pivot.attributes) {
                        Object.assign(record, pivotConfig.pivot.attributes);
                    }

                    hydrate(record);

                    return uuid;
                });

                uuids = uuids.concat(items);
            });

            this.$emit('update:modelValue', uuids);
        }
    },

    mounted() {
        this.getOptions().then(options => {
            this.options = options;
        });
    }
}
</script>
