<template>
    <div class="form-tip">
        <div class="form-tip__title">
            {{ title }}
        </div>
        <div
            class="form-tip__text"
            v-html="text"
        ></div>
    </div>
</template>

<script>
import Translation from "@/services/lookup/translation";

export default {
    props: {
        text: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            title: '',
        }
    },

    mounted() {
        Translation.get('tip').then(value => {
            this.title = value;
        });
    }
}
</script>

<style>
.form-tip {
    @apply bg-catalina-blue text-white p-7;

    &__title {
        @apply font-bold text-xl mb-2;
    }

    &__text {
        @apply font-goudy;
    }
}
</style>
