export default {
    type: 'video',
    name: 'Video',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleVideo',
            loader: () => import('./ModuleVideo')
        }
    }
};
