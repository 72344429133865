import {ref, inject} from 'vue';
import {hasEvents} from "@/services/data-layer/composers";
import Cache from "@/services/data-layer/composers/cache";

const cache = new Cache();

window.addEventListener('click', () => {
    cache.forEach(item => {
        item.disableFocus();
    })
});

const boot = (scope, uuid) => {
    cache.put(
        uuid,
        () => {
            const {listen, fire} = hasEvents(uuid)

            const focussed = ref(false);
            const editing = ref(false);

            const enableFocus = (e) => {
                focussed.value = true;
                fire('focusenabled');

                e.stopPropagation();
            };

            const disableFocus = () => {
                focussed.value = false;
                fire('focusdisabled');
            };

            const enableEditing = () => {
                editing.value = true;
                fire('editingenabled');
            };

            const disableEditing = () => {
                editing.value = false;
                fire('editingdisabled');
            }

            return {
                fire,
                listen,
                focussed,
                editing,
                enableFocus,
                disableFocus,
                enableEditing,
                disableEditing
            };
        }
    );
};

const resolve = (uuid) => {
    return cache.resolve(uuid || inject('uuid'));
};

export {boot, resolve};
export default resolve;
