export default {
    type: 'project-people',
    aliases: {
        camel: 'projectPeople',
        singular: 'Project person',
        plural: 'Project people',
        lowerSingular: 'project person',
        lowerPlural: 'project people',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
