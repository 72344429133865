import BaseRepository from "@/services/resources/repository";
import translatable from "@/services/resources/mixins/translatable-record";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(translatable);
    }


    byIds(ids) {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .where('id','in', ids)
                    .get()
            )
        )
    }
}

export default Repository;
