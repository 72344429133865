export default {
    type: 'audio',
    name: 'Audio',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleAudio',
            loader: () => import('./ModuleAudio')
        }
    }
};
