<template>
    <div
        class="square-cta"
        :class="paddingClass"
    >
        <GridContainer class="square-cta__grid">
            <GridColumn
                v-if="imgSrc"
                class="square-cta__grid__image-column"
                v-bind="imgColProps"
            >
                <div
                    v-if="textTitle || textSubTitle"
                    class="square-cta__grid__image-column__overlay"
                >
                    <div
                        v-if="textTitle"
                        v-html="textTitle"
                        class="square-cta__grid__image-column__overlay__title"
                        :class="textTitleClass"
                    />
                    <div
                        v-if="textSubTitle"
                        v-html="textSubTitle"
                        class="square-cta__grid__image-column__overlay__sub-title"
                    />
                </div>
                <ImgSquare
                    :src="imgSrc"
                    :resolve="resolveImgSrc"
                    :credit="imgCredit"
                    class="square-cta__grid__image-column__image"
                />
            </GridColumn>
            <GridColumn
                v-bind="textColProps"
                class="square-cta__grid__text-column"
                :class="textLeftClass ? 'square-cta__grid__text-column--left' : ''"
            >
                <div
                    class="square-cta__grid__text-column__content"
                    :class="contentClasses"
                >
                    <div
                        class="square-cta__grid__text-column__content__inner"
                        :class="innerClasses"
                    >
                        <slot></slot>
                    </div>
                </div>
            </GridColumn>
        </GridContainer>
    </div>
</template>

<script>
export default {
    props: {
        imgSrc: String,
        imgCredit: String,
        resolveImgSrc: {
            type: Boolean,
            default: false
        },
        variant: {
            type: String,
            default: 'alpha'
        },
        flip: {
            type: Boolean,
            default: false
        },
        padding: {
            type: Boolean,
            default: true
        },
        textTitle: String,
        textSubTitle: String,
        textBackground: String,
        textLeft: {
            type: Boolean,
            default: false
        },
        alignTop: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        contentClasses(){
            switch (this.variant){
                case 'beta':
                    return 'square-cta__grid__text-column__content--beta';

                case 'gamma':
                    return 'square-cta__grid__text-column__content--gamma';

                default:
                    return '';
            }
        },

        innerClasses(){
            let cls = [];

            if(this.textLeft){
                cls.push('square-cta__grid__text-column__content__inner--left');
            }
            if(this.alignTop){
                cls.push('square-cta__grid__text-column__content__inner--top');
            }
            return cls;
        },

        textLeftClass(){
            return this.textLeft ? '--left' : '';
        },

        alignTopClass(){
            return this.alignTop ? '--top' : '';
        },

        paddingClass(){
            return this.padding ? '' : 'square-cta--no-padding'
        },

        imgColProps() {
            if (this.flip) {
                return {
                    order: 'lg:!order-2'
                }
            }

            return {};
        },

        textColProps() {
            if (this.flip) {
                return {
                    order: 'lg:!order-1'
                }
            }

            return {};
        },

        textTitleClass(){
            if(!this.textSubTitle){
                return 'square-cta__grid__image-column__overlay__title--large'
            }

            return '';
        }
    }
}
</script>

<style lang="postcss">
.square-cta {
    @apply bg-pampas p-4 lg:p-20;

    &--no-padding {
         @apply bg-transparent !p-0;
    }

    &__grid {
        @apply w-full gap-x-0;

        &__image-column {
            @apply col-span-12 lg:col-span-6 order-2 lg:order-1 w-full h-full relative;

            &__overlay {
                @apply absolute bg-white w-2/3 h-2/3 z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center p-4 lg:p-6 flex flex-col align-middle justify-center;

                &__title {
                     @apply text-big-stone font-heading text-2xl lg:text-4xl;

                    &--large {
                        @apply lg:text-5xl;
                    }
                }

                &__sub-title {
                    @apply text-salt-box text-base mt-4;
                }
            }

            &__image {
                 @apply min-h-full min-w-full object-cover;
            }
        }

        &__text-column {
            @apply col-span-12 lg:col-span-6 order-1 lg:order-2 w-full h-full text-center;

            &--left {
                @apply text-left;
            }

            &__content {
                @apply bg-white text-big-stone w-full h-full p-4 lg:p-8 xl:px-12 flex;

                &--beta {
                    @apply !bg-navajo-white;
                }

                &--gamma {
                    @apply !bg-midnight-blue;
                }

                &__inner {
                    @apply flex flex-col my-auto items-center text-center;

                    &--top {
                        @apply lg:my-4 items-start;
                    }

                    &--left {
                        @apply text-left;
                    }
                }
            }
        }
    }
}
</style>
