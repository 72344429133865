// noinspection JSUnresolvedFunction

const state = () => ({
    content: {},
});

const getters = {
    getContent: state => {
        return state.content;
    },
};

const mutations = {
    SET_CONTENT(state, content) {
        state.content = content;
    },
};

const actions = {
    setContent({ commit }, val) {
        commit('SET_CONTENT', val || {});
    },

    trackView({ state, dispatch }) {
        if(window.gtag){
            window.gtag('event', 'page_view', {
                page_title: state.content.lastName ? state.content.firstName + ' ' + state.content.lastName : state.content.title,
                page_location: location.href + '/' + state.content.slug,
                page_path: location.pathname,
                send_page_view: true,
            })
        }

        dispatch('clearContent');
    },

    clearContent({ commit }){
        commit('SET_CONTENT', {});
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
