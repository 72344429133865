export default {
    type: 'project-modules',
    aliases: {
        camel: 'projectModules',
        singular: 'Project module',
        plural: 'Project modules',
        lowerSingular: 'project module',
        lowerPlural: 'project modules',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
