<template>
    <div v-if="item">
        <navigation-link
            :to="{
                type: 'collections',
                resource: item
            }"
            class="poster__img-container"
        >
            <PosterImg
                :content-type="contentType"
                :label="contentType"
                :item="item"
            />
        </navigation-link>

        <Card class="poster__card">
            <div
                v-if="programmeArea"
                class="poster__card__category"
                v-html="programmeArea.title"
            />

            <div class="flex-grow">
                <navigation-link
                    :to="{
                        type: 'collections',
                        resource: item
                    }"
                >
                    <h2 class="poster__card__title">
                        <span v-html="item.title" />
                    </h2>
                </navigation-link>

                <p
                    v-if="episodes"
                    class="text-sm m-0 p-0 font-semibold"
                >
                    {{ episodes }} Episodes
                </p>
            </div>

            <navigation-link
                :to="{
                    type: 'collections',
                    resource: item
                }"
                class="poster__card__time"
            >
                <IconRenderer name="PodcastSeries" />
                <span
                    class="listen-now"
                    v-html="allEpisodes"
                />
            </navigation-link>

            <slot name="share" />
        </Card>
    </div>
</template>

<script>
import Kernel from "@oilstone/kernel";
import Translation from "@/services/lookup/translation";

export default {
    props: {
        contentType: {
            type: String,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        href: {
            type: String,
            default: null,
        }
    },

    data(){
        return {
            programmeArea: null,
            episodes: null,
            allEpisodes: 'All episodes',
        }
    },

    methods: {
        getDetails(){
            this.programmeArea = null;
            this.episodes = null;

            Kernel.resolve('resourceRegistry').find('podcasts').repository.episodes(this.item.id).then(response => {
                if(response){
                    this.episodes = response.length;
                }
            })

            if(this.item.programmeAreaId){
                Kernel.resolve('resourceRegistry').find('programme-areas').repository.find(this.item.programmeAreaId).then(response => {
                    this.programmeArea = response;
                })
            }
        }
    },

    mounted(){
        this.getDetails();

        Translation.many(['all-episodes']).then(translations => {
            if(translations){
                this.allEpisodes = translations['all-episodes'];
            }
        });
    },

    watch: {
        item(){
            this.getDetails();
        }
    }
}
</script>
