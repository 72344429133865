<template>
    <svg
        class="icon-paypal"
        viewBox="0 0 48 32"
        xmlns="http://www.w3.org/2000/svg"
    ><g><rect
        fill="#fff"
        height="32"
        rx="4"
        width="48"
    /><path
        d="M994.62,983.59h-40a4,4,0,0,1-4-4v-24a4,4,0,0,1,4-4h40a4,4,0,0,1,4,4v24A4,4,0,0,1,994.62,983.59Zm-40-30a2,2,0,0,0-2,2v24a2,2,0,0,0,2,2h40a2,2,0,0,0,2-2v-24a2,2,0,0,0-2-2Z"
        fill="#cccccb"
        transform="translate(-950.63 -951.59)"
    /><g><path
        d="M981.43,962.67l-.07.37c-.81,4.2-3.63,5.66-7.21,5.66h-1.81a.88.88,0,0,0-.88.75l-.93,5.92-.28,1.68a.48.48,0,0,0,.4.54H974a.77.77,0,0,0,.77-.66v-.16l.62-3.87v-.21a.77.77,0,0,1,.76-.66h.52c3.14,0,5.6-1.27,6.31-5a4.25,4.25,0,0,0-.65-3.73A3.29,3.29,0,0,0,981.43,962.67Z"
        fill="#189bd7"
        transform="translate(-950.63 -951.59)"
    /><path
        d="M980.57,962.33l-.39-.1-.42-.08a10.58,10.58,0,0,0-1.6-.11h-4.87a.72.72,0,0,0-.33.07.74.74,0,0,0-.43.58l-1,6.57v.19a.88.88,0,0,1,.88-.75h1.82c3.58,0,6.39-1.46,7.2-5.66l.07-.37a5.58,5.58,0,0,0-.67-.28Z"
        fill="#242e65"
        transform="translate(-950.63 -951.59)"
    /><path
        d="M972.52,962.69a.76.76,0,0,1,.76-.65h4.88a10.8,10.8,0,0,1,1.61.11l.42.08.38.1.18.06a5.11,5.11,0,0,1,.68.28,4,4,0,0,0-.81-3.57c-1-1.06-2.63-1.51-4.77-1.51h-6.22a.9.9,0,0,0-.88.75l-2.6,16.41a.55.55,0,0,0,.46.62h3.91l1-6.11Z"
        fill="#253d80"
        transform="translate(-950.63 -951.59)"
    /></g></g></svg>
</template>