import BaseRepository from "@/services/resources/repository";
import queryByKeys from "@/services/resources/mixins/query-by-keys";
import translatable from "@/services/resources/mixins/translatable-record";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(queryByKeys).$mix(translatable);
    }

    byDateDescending() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .select('id','title','slug','start_year','translates_id')
                    .sort('title','desc')
                    .get()
            )
        );
    }
}

export default Repository;
