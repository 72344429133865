export default {
    type: 'alumni-network',
    name: 'Alumni Network',
    osOnly: true,
    components: {
        root: {
            name: 'ModuleAlumniNetwork',
            loader: () => import('./ModuleAlumniNetwork')
        }
    }
};
