export default {
    type: 'content-accordion',
    name: 'Content Accordion',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleContentAccordion',
            loader: () => import('./ModuleContentAccordion')
        }
    }
};
