export default {
    type: 'themes',
    name: 'Themes',
    osOnly: true,
    components: {
        root: {
            name: 'ModuleThemes',
            loader: () => import('./ModuleThemes')
        }
    }
};
