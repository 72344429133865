export default {
    type: 'publication-people',
    aliases: {
        camel: 'publicationPeople',
        singular: 'Publication person',
        plural: 'Publication people',
        lowerSingular: 'publication person',
        lowerPlural: 'publication people',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
