<template>
    <div class="categorised-list">
        <div
            v-for="(category, index) in items"
            v-bind:key="index"
            class="category border-2 border-grey-3 p-4 rounded-xl mb-4 text-blue-ribbon"
            :class="isOpen[category.id] ? 'bg-wild-sand !text-black font-bold' : ''"
        >
            <div
                class="flex items-center justify-between cursor-pointer"
                @click="toggleIsOpen(category.id)"
            >
                <div class="text-base">
                    {{ category.title }}

                    <div
                        class="subtitle"
                        v-if="category.type"
                    >
                        Type: {{ category.type }}
                    </div>
                </div>
                <IconRenderer
                    class="w-4 transform -rotate-90"
                    :class="isOpen[category.id] ? '!rotate-0' : ''"
                    name="Chevron"
                />
            </div>

            <div
                v-if="category.items && isOpen[category.id]"
                class="section-sub-pages clear flex flex-col w-full mt-6"
            >
                <AdministerCollectionItem
                    v-for="sub in category.items"
                    v-bind:key="sub.id"
                    class="category flex items-center justify-between border-2 font-normal border-grey-3 p-4 rounded-xl mt-4 cursor-pointer bg-white"
                    :item="sub"
                    :cta-text="itemCtaText"
                >
                    {{ sub.title }}
                </AdministerCollectionItem>
                <div
                    v-if="actions && actions.length"
                    class="flex justify-end space-x-5 mt-5"
                >
                    <FormBtn
                        v-for="(btn, index) in actions"
                        v-bind="btn.attributes || {}"
                        @click="$emit(btn.event, category)"
                        :key="index"
                    >
                        {{ btn.text }}
                    </FormBtn>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        items: Array,
        actions: Array,
        itemCtaText: {
            type: String,
            default: 'Edit',
        }
    },

    data(){
        return {
            isOpen: [],
        }
    },

    methods: {
        toggleIsOpen(id){
            if(!this.isOpen[id]){
                this.items.forEach((row) => {
                    this.isOpen[row.id] = false;
                });
            }
            this.isOpen[id] = !this.isOpen[id];
        }
    },

    mounted(){
        this.items.forEach((row) => {
            this.isOpen[row.id] = false;
        });
    }
}
</script>

<style lang="postcss">
.categorised-list {
    .subtitle {
        @apply block text-sm text-salt-box mt-0;
    }
}
</style>
