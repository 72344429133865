<template>
    <FormLabel
        :text="strLabel"
        class="form-input form-control"
        :class="classes.concat(errorClass)"
    >
        <span class="form-input__input">
            <BaseInput
                v-bind="baseComponentBindings"
                :type="resolvedType"
                @update:model-value="update"
                class="form-control__input"
                @focus="clearError"
            />
            <div
                v-if="isPassword"
                @click="toggleVisibility"
                class="form-input__input__visibility-toggle"
            >
                <IconRenderer
                    v-if="forceType === 'text'"
                    name="EyeCancel"
                    class="fill-current"
                />
                <IconRenderer
                    v-else
                    name="Eye"
                    class="fill-current"
                />
            </div>
        </span>
        <FormError :text="error"/>
        <FormHelp :text="helpText"/>
    </FormLabel>
</template>

<script>
import formInputComposable from "@/composables/forms/form-input";

export default {
    mixins: [{
        props: formInputComposable.props()
    }],

    setup(props, {emit}) {
        return {
            ...formInputComposable.composables(props, emit)
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],

    props: {
        type: {
            type: String,
            default: 'text'
        },
        hasError: Number,
    },

    data() {
        return {
            forceType: null,
            errorClass: [],
        }
    },

    computed: {
        resolvedType() {
            return this.forceType || this.type
        },

        isPassword() {
            return this.type === 'password';
        },

        strLabel(){
            return this.required && this.label ? `${this.label} *` : this.label;
        },
    },

    methods: {
        toggleVisibility() {
            if (this.forceType) {
                this.forceType = null;

                return;
            }

            this.forceType = 'text';
        },

        clearError(){
            this.errorClass = [];
        }
    },

    watch: {
        hasError(val){
            if(val){
                this.errorClass.push('form-control--error');
            } else {
                this.errorClass = [];
            }
        }
    }
}
</script>

<style lang="postcss">
.form-input {
    &__input {
        @apply relative block;

        &__visibility-toggle {
            @apply absolute cursor-pointer w-6.5 right-4 bottom-1/2 transform translate-y-1/2 text-silver-chalice;
        }
    }
}
</style>
