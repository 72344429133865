export default {
    type: 'affiliations',
    aliases: {
        camel: 'affiliations',
        singular: 'UNRISD affiliation',
        plural: 'UNRISD affiliations',
        lowerSingular: 'affiliation',
        lowerPlural: 'affiliations',
    },
    relations: {
        'people': {
            adminLabel: 'People',
            pivot: {
                type: 'person-affiliations',
                localKey: 'affiliationId',
                foreignKey: 'personId',
            }
        },
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'lookup',
    components: {},
    cms: {
        paginationAmount: () => {
            return 100
        },
    }
};
