<template>
    <textarea
        class="base-textarea"
        :class="containerClasses"
        :value="modelValue"
        :disabled="disabled"
        :name="name"
        @input="update($event.target.value)"
        :rows="rows"
        :maxlength="maxLength"
    />
</template>

<script>
export default {
    props: {
        modelValue: null,
        name: String,
        disabled: {
            type: Boolean,
            default: false
        },
        rows: {
            type: Number,
            default: 7,
        },
        maxLength: Number,
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],

    computed: {
        containerClasses(){
            const classes = [];

            this.disabled ?? classes.push('base-text-area--disabled');

            return classes;
        }
    },

    methods: {
        update(value) {
            this.$emit('update:modelValue', value);
        }
    },
}
</script>

<style lang="postcss">
.base-textarea {
    @apply block w-full outline-none px-4 py-3.5 text-big-stone border border-swiss-coffee resize-none;

    &--disabled {
        @apply text-dusty-gray bg-alto;
    }

    &--error {
        @apply border-red;
    }
}
</style>
