import {Schema, Collection} from "@oilstone/rest-model-repository";
import Factory from '../factory';

const schema = Factory.schema('content-cards');
const attributes = schema.getProp('attributes').getValue();
const item = new Schema();

item.prop('title').setType(String);
item.prop('summary').setType(String);

attributes.prop('title').setType(String).setValue('Title');
attributes.prop('summary').setType(String).setValue('Summary');
attributes.prop('items').setType(Collection).setValue(new Collection(item));

export default schema;
