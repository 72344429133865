<template>
    <Toggle
        :model-value="modelValue"
        :true-value="trueValue"
        :false-value="falseValue"
        :classes="{
            container: 'inline-block',
            toggle: 'flex w-10 h-4 rounded-full relative cursor-pointer transition items-center box-content border-4',
            toggleOn: toggleOnClasses,
            toggleOff: toggleOffClasses,
            handle: 'inline-block bg-white w-4 h-4 top-0 rounded-full absolute transition-all',
            handleOn: 'left-full transform -translate-x-full',
            handleOff: 'left-0',
        }"
        @update:model-value="update"
    />
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    components: {
        Toggle: defineAsyncComponent(() => import('@vueform/toggle')),
    },

    props: {
        modelValue: null,
        trueValue: {
            default: true
        },
        falseValue: {
            default: false
        },
        variant: {
            type: String,
            default: 'alpha'
        }
    },

    computed: {
        toggleOffClasses() {
            const classes = ['justify-end'];

            switch (this.variant) {
            case 'alpha':
                classes.push('bg-midnight-blue');
                classes.push('border-midnight-blue');
                break;
            }

            return classes;
        },

        toggleOnClasses() {
            const classes = ['justify-start'];

            switch (this.variant) {
            case 'alpha':
                classes.push('bg-anakiwa');
                classes.push('border-anakiwa');
                break;
            }

            return classes;
        }
    },

    methods: {
        update(value) {
            this.$emit('update:modelValue', value);
        }
    }
}
</script>
