<template>
    <svg
        class="icon-cms-tick fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
    >
        <g transform="translate(-439 -555)">
            <circle
                cx="10"
                cy="10"
                r="10"
                transform="translate(439 555)"
            />
            <path
                d="M4.924,75.7.213,70.985a.725.725,0,0,1,0-1.025l1.025-1.025a.725.725,0,0,1,1.025,0l3.174,3.174,6.8-6.8a.725.725,0,0,1,1.025,0l1.025,1.025a.725.725,0,0,1,0,1.025L5.949,75.7a.725.725,0,0,1-1.025,0Z"
                transform="translate(442.751 494.902)"
                fill="#fff"
            />
        </g>
    </svg>
</template>
