import { computed, inject, reactive, ref } from 'vue';
import Kernel from "@oilstone/kernel";
import dayjs from "dayjs";

export default (items, searchOn = null, filterOn = null) => {
    const type = inject('type');
    const settings = type ? Kernel.resolve('resourceRegistry').find(type) : null;
    const searchString = ref(null);
    let filters = {};

    if (searchOn === null) {
        searchOn = settings.config.searchOn || ['title'];
    }

    if (filterOn === null) {
        filterOn = settings.config.filterOn || {};
    }

    for (const filterKey in filterOn) {
        if (filterKey === 'other') {
            filterOn['other'].forEach(otherFilterType => {
                filters[otherFilterType] = '';
            });

            continue;
        }

        filters[filterOn[filterKey].foreignKey] = '';
    }

    filters = reactive(filters);

    const filtered = computed(() => {
        let filtered = items.value;

        for (const filterKey in filterOn) {
            if (filterKey === 'other') {
                filterOn['other'].forEach(otherFilterType => {
                    if (filters[otherFilterType] !== '' && filters[otherFilterType] !== null) {  // Some filters can be falsey so be explicit about what constitutes an "unset" filter
                        switch (otherFilterType) {
                            case 'publishYear':
                                filtered = filtered.filter(item => parseInt(dayjs(item['publishAt']).format('YYYY')) === parseInt(filters['publishYear']));
                                break;

                            case 'unpublishedChanges':
                                filtered = filtered.filter(item => item['hasUnpublishedChanges'] === (filters['unpublishedChanges'] === '1'));
                                break;

                            case 'isPublished':
                                filtered = filtered.filter(item => item['isPublished'] === (filters['isPublished'] === '1'));
                                break;
                        }
                    }
                });

                continue;
            }

            const foreignKey = filterOn[filterKey].foreignKey;

            if (filters[foreignKey]) {
                if (filterOn[filterKey].in) {
                    filtered = filtered.filter(item => !!item[filterOn[filterKey].in].find(relation => relation[foreignKey] == filters[foreignKey]));
                } else {
                    filtered = filtered.filter(item => item[foreignKey] == filters[foreignKey]);
                }
            }
        }

        if (searchString.value && searchOn.length) {
            const searchStringNormalized = searchString.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

            filtered = filtered.filter(item => {
                const rowSearch = [];

                searchOn.forEach(property => {
                    if (item[property]) {
                        rowSearch.push(item[property]);
                    }
                });

                return rowSearch.join(' ').normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(searchStringNormalized);
            });
        }

        return filtered;
    });

    return {
        searchString,
        filters,
        filtered,
    }
}
