import ls from "local-storage";

class LocalStorage {
    #localStorage;

    constructor(localStorage) {
        this.#localStorage = localStorage;
    }

    static make() {
        return new LocalStorage(ls);
    }

    get(key) {
        return this.#localStorage.get(key);
    }

    set(key, value) {
        this.#localStorage.set(key, value);
    }

    remove(key) {
        this.#localStorage.remove(key);
    }
}

export default LocalStorage;
