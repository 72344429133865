export default {
    type: 'video-themes',
    aliases: {
        camel: 'videoThemes',
        singular: 'Video theme',
        plural: 'Video themes',
        lowerSingular: 'video theme',
        lowerPlural: 'video themes',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
