import BaseRepository from "@/services/resources/repository";
import PublicationTransformer from "./transformer";

class Repository extends BaseRepository {
    constructor(model, schema, validator) {
        super(model, schema, validator);

        this.addTransformer(new PublicationTransformer);
    }

    collection() {
        return this.transformer.many(
            this.try(
                this.baseQuery().sort('publishAt', 'desc').get()
            )
        );
    }
}

export default Repository;
