export default {
    type: 'file-uploads',
    aliases: {
        camel: 'fileUploads',
        singular: 'Upload',
        plural: 'Uploads',
        lowerSingular: 'upload',
        lowerPlural: 'uploads',
    },
    publishable: false,
    adminSection: null,
    componentType: 'base',
    components: {}
};
