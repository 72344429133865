import Factory from '../factory';

const schema = Factory.schema('themes');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('title').setType(String);
attributes.prop('inline').setType(Boolean).setValue(false);
attributes.prop('items').setType(Array);

export default schema;
