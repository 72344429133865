export default {
    type: 'countries',
    aliases: {
        camel: 'countries',
        singular: 'Country',
        plural: 'Countries',
        lowerSingular: 'country',
        lowerPlural: 'countries',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'lookup',
    components: {
        adminCollectionItem: {
            name: 'AdministerCountryCollectionItem',
            loader: () => import('./AdministerCountryCollectionItem')
        },
    },
    cms: {
        paginationAmount: () => {
            return 50
        },
    }
};
