export default {
    type: 'subscribers',
    aliases: {
        camel: 'subscribers',
        singular: 'Subscriber',
        plural: 'Subscribers',
        lowerSingular: 'subscriber',
        lowerPlural: 'subscribers',
    },
    publishable: false,
    adminSection: null,
    componentType: 'base',
    components: {},
};
