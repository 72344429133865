export default {
    type: 'publication-types',
    aliases: {
        camel: 'publicationType',
        singular: 'Publication type',
        plural: 'Publication types',
        lowerSingular: 'publication type',
        lowerPlural: 'publication types',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'lookup',
    components: {
        adminRecord: {
            name: 'AdministerPublicationTypeRecord',
            loader: () => import('./AdministerPublicationTypeRecord')
        },
    },
};
