export default {
    type: 'projects',
    aliases: {
        camel: 'projects',
        singular: 'Project',
        plural: 'Projects',
        lowerSingular: 'project',
        lowerPlural: 'projects',
    },
    relations: {
        'keywords': {
            adminLabel: 'Keywords',
            pivot: {
                type: 'project-keywords',
                localKey: 'projectId',
                foreignKey: 'keywordId'
            }
        },
        'modules': {
            pivot: {
                type: 'project-modules',
                localKey: 'projectId',
                foreignKey: 'moduleId'
            }
        },
        'programme-areas': {
            adminLabel: 'Programme areas',
            pivot: {
                type: 'project-programme-areas',
                localKey: 'projectId',
                foreignKey: 'programmeAreaId'
            }
        },
        'people': [
            {
                adminLabel: 'Research coordinators',
                pivot: {
                    type: 'project-people',
                    localKey: 'projectId',
                    foreignKey: 'personId',
                    filters: [
                        collection => {
                            return collection.filter(person => {
                                return person.association === 'research-coordinator';
                            })
                        },
                    ],
                    attributes: {
                        association: 'research-coordinator'
                    }
                }
            },
            {
                adminLabel: 'Research assistants',
                pivot: {
                    type: 'project-people',
                    localKey: 'projectId',
                    foreignKey: 'personId',
                    filters: [
                        collection => {
                            return collection.filter(person => {
                                return person.association === 'research-assistant';
                            })
                        },
                    ],
                    attributes: {
                        association: 'research-assistant'
                    }
                }
            },
            {
                adminLabel: 'Researchers',
                pivot: {
                    type: 'project-people',
                    localKey: 'projectId',
                    foreignKey: 'personId',
                    filters: [
                        collection => {
                            return collection.filter(person => {
                                return person.association === 'researcher';
                            })
                        },
                    ],
                    attributes: {
                        association: 'researcher'
                    }
                }
            },
            {
                adminLabel: 'Other Participants',
                pivot: {
                    type: 'project-people',
                    localKey: 'projectId',
                    foreignKey: 'personId',
                    filters: [
                        collection => {
                            return collection.filter(person => {
                                return person.association === 'other';
                            })
                        },
                    ],
                    attributes: {
                        association: 'other'
                    }
                }
            },
        ],
        'events': {
            adminLabel: 'Events',
            pivot: {
                type: 'event-projects',
                localKey: 'projectId',
                foreignKey: 'eventId'
            }
        },
        'news-items': {
            adminLabel: 'News stories',
            pivot: {
                type: 'news-item-projects',
                localKey: 'projectId',
                foreignKey: 'newsItemId'
            }
        },
        'publications': {
            adminLabel: 'Publications',
            pivot: {
                type: 'publication-projects',
                localKey: 'projectId',
                foreignKey: 'publicationId'
            }
        },
        'themes': {
            adminLabel: "Themes",
            pivot: {
                type: 'project-themes',
                localKey: 'projectId',
                foreignKey: 'themeId'
            }
        },
        'countries': {
            adminLabel: 'Countries',
            pivot: {
                type: 'project-countries',
                localKey: 'projectId',
                foreignKey: 'country'
            }
        },
    },
    publishable: true,
    adminSection: 'research',
    componentType: 'base',
    filterOn: {
        'decades': {
            foreignKey: 'decadeId',
        },
        'themes': {
            foreignKey: 'themeId',
            in: 'projectThemes',
        },
        'programme-areas': {
            foreignKey: 'programmeAreaId',
            in: 'projectProgrammeAreas',
        },
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerProjectRecord',
            loader: () => import('./AdministerProjectRecord')
        },
        adminTranslation: {
            name: 'AdministerProjectTranslation',
            loader: () => import('./AdministerProjectTranslation')
        },
        webRecord: {
            name: 'DisplayProjectRecord',
            loader: () => import('./DisplayProjectRecord')
        }
    },
};
