<template>
    <component
        :is="component"
        v-if="visible"
        :label="label"
        :model-value="value"
        :for="attribute"
        class="resource-input"
        @update:modelValue="prepareUpdate(attribute, $event)"
    />
</template>

<script>
import { computed } from "vue";
import { accessesData, mutatesData } from "@/services/data-layer/composers";

export default {
    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },
        attribute: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: "text",
        },
        toggleOn: {
            type: String,
            required: false,
            default: null,
        },
        toggleValue: {
            type: null,
            required: false,
            default: true,
        },
    },

    setup(props) {
        const { resolve } = accessesData();
        const { prepareUpdate, dirty } = mutatesData();

        const value = computed(() => {
            return typeof dirty.updates[props.attribute] !== "undefined" ? dirty.updates[props.attribute] : resolve(props.attribute).value;
        });

        const visible = computed(() => {
            if (props.toggleOn) {
                const togglerValue = typeof dirty.updates[props.toggleOn] !== "undefined" ? dirty.updates[props.toggleOn] : resolve(props.toggleOn).value;
                return togglerValue === props.toggleValue;
            }

            return true;
        });

        return {
            value,
            visible,
            prepareUpdate,
        };
    },

    computed: {
        component() {
            switch (this.type) {
            case "textarea":
                return "AdminTextarea";

            case "richtext":
                return "AdminRichText";

            case "asset":
                return "AdminAsset";

            case "colour":
                return "AdminColour";

            case "date":
                return "AdminDate";

            case "time":
                return "AdminTime";

            case "datetime":
                return "AdminDateTime";

            case "boolean":
                return "AdminBoolean";

            case "radio":
                return "AdminRadioGroup";

            case "checkbox":
                return "AdminCheckbox";

            case "select":
                return "AdminSelect";

            case "picker":
                return "AdminPicker";
            }

            return "AdminInput";
        },
    },
};
</script>
