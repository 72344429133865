class Formatter {
    static clip(input, maxLength) {
        if (typeof input !== 'string' || input.length <= maxLength) {
            return input;
        }

        return `${input.slice(0, maxLength).trim()}&hellip;`;
    }

    static convertRichText(input) {
        if (typeof input !== 'string' || input.length <= 0) {
            return input;
        }

        return input
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/{VIDEO}/g, '')
            .replace(/{IMAGE1}/g, '')
            .replace(/{IMAGE2}/g, '')
            .replace(/{IMAGE3}/g, '')
            .replace(/{IMAGE4}/g, '')
            .replace(/{IMAGE5}/g, '')
            .replace(/{ACCORDION}/g, '')
            .replace(/{GALLERY}/g, '')
            .replace(/&nbsp;/g, ' ')
            .replace(/&ndash;/g, '-')
            .replace(/&rsquo;/g, '\'')
            .replace(/<p><\/p>/g, '');
    }

    static nl2br (str) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }

        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
    }
}

export default Formatter;
