<template>
    <div v-if="relationList.length">
        <RelationPicker
            v-for="(relationType, index) in relationList"
            :key="'relation-picker-' + index"
            :relation="relationType"
            v-model="relations[relationType].values"
        />
    </div>
</template>

<script>
import {accessesData, boot, hasEvents, hydratesData, mutatesData, persistsData} from "@/services/data-layer/composers";
import {inject} from "vue";
import Kernel from "@oilstone/kernel";
import {v4 as uuidv4} from "uuid";
import Factory from "@/services/resources/factory";

export default {
    setup() {
        const type = inject('type');
        const uuid = inject('uuid');
        const {item} = accessesData();
        const {listen} = hasEvents();
        const settings = Kernel.resolve('resourceRegistry').find(type);

        return {
            type,
            item: item(uuid),
            listen,
            config: settings.config,
        }
    },

    data() {
        return {
            relations: {},
            originalRelations: {},
        }
    },

    computed: {
        relationList() {
            return Object.keys(this.relations);
        },
    },

    methods: {
        bootRelation(type) {
            const relation = this.config.relations[type];

            this.originalRelations[type] = [];
            this.relations[type] = Object.assign({values: []}, relation);
        },

        addRelation(relationType, uuid) {
            this.originalRelations[relationType].push(uuid);
            this.relations[relationType].values.push(uuid);
        },

        save() {
            const deletePromises = [];
            const savePromises = [];
            const unchanged = [];

            for (let relation in this.relations) {
                this.relations[relation].values.forEach(uuid => {
                    if (this.originalRelations[relation].includes(uuid)) {
                        unchanged.push(uuid);
                    }
                });
            }

            for (let relation in this.relations) {
                if (this.originalRelations[relation]) {
                    this.originalRelations[relation].forEach(uuid => {
                        if (unchanged.includes(uuid)) {
                            return;
                        }

                        const {destroy} = persistsData(uuid);

                        deletePromises.push(destroy());
                    });
                }
            }

            Promise.all(deletePromises).then(() => {
                for (let relation in this.relations) {
                    this.relations[relation].values.forEach((uuid, index) => {
                        const {merge} = mutatesData(uuid);
                        const {save} = persistsData(uuid);
                        const attributes = {
                            sort: index + 1
                        };

                        const localKey = Array.isArray(this.config.relations[relation]) ? this.config.relations[relation][0].pivot.localKey : this.config.relations[relation].pivot.localKey;

                        attributes[localKey] = parseInt(this.item.id);
                        merge(attributes);
                        savePromises.push(save());
                    });
                }
            });

            return Promise.all(savePromises).then(() => {
                this.originalRelations = {};

                for (const type in this.relations) {
                    this.originalRelations[type] = this.relations[type].values;
                }
            });
        }
    },

    mounted() {
        if (this.config.relations) {
            for (const type in this.config.relations) {
                const relation = Array.isArray(this.config.relations[type]) ? this.config.relations[type][0] : this.config.relations[type];
                const settings = Kernel.resolve('resourceRegistry').find(relation.pivot.type);

                if (relation.adminLabel) {
                    this.bootRelation(type);

                    if (this.item[settings.config.aliases.camel]) {
                        this.item[settings.config.aliases.camel].forEach(relation => {
                            const uuid = uuidv4();

                            this.addRelation(type, uuid);

                            boot(settings.repository.schema, Factory.scope(settings.config), uuid);

                            const {hydrate} = hydratesData(uuid);

                            hydrate(relation);
                        });
                    }
                }
            }
        }

        this.listen({
            saved: () => {
                this.save();
            }
        });
    }
}
</script>
