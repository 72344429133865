export default {
    type: 'podcast-themes',
    aliases: {
        camel: 'podcastThemes',
        singular: 'Podcast theme',
        plural: 'Podcast themes',
        lowerSingular: 'podcast theme',
        lowerPlural: 'podcast themes',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
