export default {
    type: 'file-assets',
    aliases: {
        camel: 'fileAssets',
        singular: 'Asset',
        plural: 'Assets',
        lowerSingular: 'asset',
        lowerPlural: 'assets',
    },
    publishable: false,
    adminSection: null,
    componentType: 'base',
    components: {}
};
