<template>
    <div class="admin-tabs">
        <div
            v-if="tabs.length > 1"
            class="admin-tabs__tab-list"
        >
            <div
                v-for="tab in tabs"
                :key="'tab-' + tab.tabId"
                class="admin-tabs__tab-list__tab"
                :class="{'admin-tabs__tab-list__tab--active': tab.tabId === selectedTabId}"
                @click="selectTab(tab.tabId)"
            >
                {{ tab.tabTitle }}
            </div>
        </div>
        <div class="admin-tabs__tab-content">
            <div
                v-for="tab in tabs"
                :key="'tab-content-' + tab.tabId"
                class="admin-tabs__tab-content__tab"
                :class="{'admin-tabs__tab-content__tab--active': tab.tabId === selectedTabId}"
            >
                <slot :name="tab.tabId"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup(props, {slots}) {
        const tabs = [];
        const tabTitle = tabId => {
            switch (tabId) {
                case 'basic':
                    return 'Page details';

                case 'related':
                    return 'Related data';

                case 'seo':
                    return 'SEO options';

                case 'admin':
                    return 'Admin options';

                default:
                    return tabId;
            }
        };

        for (const tab in slots) {
            tabs.push({
                tabTitle: tabTitle(tab),
                tabId: tab,
            });
        }

        return {
            tabs,
        }
    },

    data() {
        return {
            selectedTabId: this.tabs[0].tabId,
        }
    },

    methods: {
        selectTab(tabId) {
            this.selectedTabId = tabId;
        }
    },
}
</script>

<style lang="postcss">
.admin-tabs {
    &__tab-list {
        @apply border border-r-0 border-l-0 border-alto sm:flex sm:flex-row mb-10;

        &__tab {
            @apply relative z-0 whitespace-nowrap bg-white border border-r-0 border-l-0 border-alto -my-px cursor-pointer hover:text-orange hover:border-orange py-4 px-7;

            &--active {
                @apply z-10 text-orange border border-r-0 border-l-0 border-orange;
            }
        }
    }

    &__tab-content {
        &__tab {
            display: none;

            &--active {
                display: block;
            }
        }
    }
}
</style>
