import BaseRepository from "@/services/resources/repository";
import byEvent from "@/services/resources/mixins/query-by-event";
import byKeyword from "@/services/resources/mixins/query-by-keyword";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byEvent).$mix(byKeyword);
    }
}

export default Repository;
