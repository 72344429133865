import BaseRepository from "@/services/publication/repository";
import queryByKeys from "@/services/resources/mixins/query-by-keys";
import queryByProject from "@/services/resources/mixins/query-by-project";
import queryByEvent from "@/services/resources/mixins/query-by-event";
import queryByProgrammeArea from "@/services/resources/mixins/query-by-programme-area";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(queryByKeys).$mix(queryByProject).$mix(queryByEvent).$mix(queryByProgrammeArea);
    }

    record(id) {
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('translations')
                    .include('video-themes')
                    .include('video-keywords')
                    .find(id)
            )
        );
    }

    collection() {
        return this.transformer.many(
            this.try(
                this.baseQuery().sort('publishAt', 'desc').include('video-themes').get()
            )
        );
    }

    forSearch() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .include('video-themes')
                    .include('video-keywords.keywords')
                    .where('isPublished',1)
                    .get()
            )
        );
    }

}

export default Repository;
