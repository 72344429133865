<template>
    <div @keyup.enter="enter"
         class="form-fields"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        for: String
    },

    provide() {
        return {
            ancestors: this.for ? [...this.for.split('.')] : []
        }
    },

    methods: {
        enter() {
            this.$emit('enter');
        }
    }
}
</script>
