<template>
    <div class="administer-collection">
        <PageTitle>
            {{ pageTitle }}
        </PageTitle>

        <div class="section-group">
            <FilterCollection
                v-model:searchString="searchString"
                v-model:filters="filters"
            />

            <component
                :is="collectionItemComponent"
                :item="row"
                v-for="(row, index) in paginatedItems"
                :key="'item-' + index"
            >
                {{ row.title }}
            </component>

            <div
                v-if="filtered.length > perPage"
                class="administer-collection__pagination"
            >
                <div class="administer-collection__pagination__total">
                    Showing {{ showing }} to {{ pageNo * perPage > totalItems ? totalItems : pageNo * perPage }} of {{ totalItems }} entries
                </div>
                <div class="administer-collection__pagination__pages">
                    <div
                        v-if="pageNo > 1"
                        class="administer-collection__pagination__pages__page"
                    >
                        <span @click="decrementPage">Previous</span>
                    </div>
                    <div
                        v-for="(num) in pages"
                        v-bind:key="num"
                        class="administer-collection__pagination__pages__page"
                    >
                        <span
                            @click="setPage(num)"
                            :class="pageNo === num ? 'active' : ''"
                        >
                            {{ num }}
                        </span>
                    </div>
                    <div
                        class="administer-collection__pagination__pages__page"
                        v-if="pageNo < pages"
                    >
                        <span @click="incrementPage">Next</span>
                    </div>
                </div>
            </div>

            <div
                v-else-if="filtered.length > 0"
                class="administer-collection__pagination"
            >
                <div class="administer-collection__pagination__total">
                    Showing {{ totalItems }} {{ totalItems === 1 ? 'entry' : 'entries' }}
                </div>
            </div>

            <div
                v-else
                class="section-group__empty"
            >
                No data
            </div>
        </div>
        <FormBtn
            @click="addNew"
            class="w-min"
            variant="cms"
        >
            Add New
        </FormBtn>
    </div>
</template>

<script>
import administerCollection from './../administer-collection';

const component = administerCollection();

export default {
    mixins: [component],

    setup(props, context) {
        if (component.setup) {
            return component.setup(props, context);
        }

        return {}
    },

    data() {
        return {
            pageNo: 1,
            perPage: 10,
        }
    },

    computed: {
        showing() {
            return ((this.pageNo * this.perPage) - this.perPage) + 1;
        },

        totalItems() {
            if (this.filtered) {
                return this.filtered.length;
            } else {
                return 0;
            }
        },

        pages() {
            return Math.ceil(this.totalItems / this.perPage);
        },

        paginatedItems() {
            if (this.filtered) {
                if (this.totalItems > this.perPage) {
                    let itms = this.filtered;
                    return itms.slice(this.showing - 1, (this.perPage * this.pageNo));
                } else {
                    return this.filtered;
                }
            } else {
                return [];
            }
        }
    },

    methods: {
        incrementPage() {
            this.pageNo++;

            if (this.pageNo >= this.pages) {
                this.pageNo = this.pages;
            }
        },

        decrementPage() {
            this.pageNo--;

            if (this.pageNo < 1) {
                this.pageNo = 1;
            }
        },

        setPage(n) {
            this.pageNo = n;
        }
    },

    mounted() {
        if (this.config.cms && this.config.cms.paginationAmount) {
            this.perPage = this.config.cms.paginationAmount();
        }
    }
}
</script>

<style lang="postcss">
.administer-collection {

    .section-group {
        @apply border-b border-alto mb-8;

        &__empty {
            @apply mb-8 !border-b-0;
        }
    }

    &__pagination {
        @apply flex flex-row items-center justify-between font-sofia-pro text-sm mb-4 mt-2;

        &__pages {
            @apply flex flex-row;

            &__page {
                @apply ml-4 cursor-pointer hover:text-orange;

                .active {
                    @apply text-orange;
                }
            }
        }
    }
}
</style>
