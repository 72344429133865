import Factory from "@/schemas/factory";

const schema = new Factory()
    .make();

schema.prop('id').setType(String).primaryKey();
schema.prop('contents').setType(String);
schema.prop('mimeType').setType(String);

export default schema;
