import PivotRepository from "@/services/resources/pivot-repository";
import byBlogPost from "@/services/resources/mixins/query-by-blog-post";
import byPerson from "@/services/resources/mixins/query-by-person";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byBlogPost).$mix(byPerson);
    }

    blogPostsByPerson(id) {
        return this.relation('blog-posts', 'personId', id, null, [], 'blogPosts');
    }

    peopleByBlogPost(id){
        return this.relation('people', 'blogPostId', id, null, [], 'people');
    }
}

export default Repository;
