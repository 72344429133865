export default {
    type: 'theme-modules',
    aliases: {
        camel: 'themeModules',
        singular: 'Theme module',
        plural: 'Theme modules',
        lowerSingular: 'theme module',
        lowerPlural: 'theme modules',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
