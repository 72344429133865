<template>
    <svg
        class="icon-plus fill-current"
        viewBox="0 0 15 15"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g transform="translate(12394 17046)">
            <rect
                height="3"
                transform="translate(-12394 -17040)"
                width="15"
            />
            <rect
                height="3"
                transform="translate(-12385 -17046) rotate(90)"
                width="15"
            />
        </g>
    </svg>
</template>
