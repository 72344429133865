export default {
    type: 'cookie-items',
    aliases: {
        camel: 'cookieItems',
        singular: 'Cookie Item',
        plural: 'Cookie Items',
        lowerSingular: 'cookie item',
        lowerPlural: 'cookie items',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'base',
    components: {
        adminCollection: {
            name: 'AdministerCookieItemCollection',
            loader: () => import('./AdministerCookieItemCollection')
        },
        adminRecord: {
            name: 'AdministerCookieItemRecord',
            loader: () => import('./AdministerCookieItemRecord')
        },
        adminTranslation: {
            name: 'AdministerCookieItemTranslation',
            loader: () => import('./AdministerCookieItemTranslation')
        },
    },
    filterOn: {
        'cookies': {
            foreignKey: 'cookieId',
        },
    }
};
