<template>
    <div
        v-if="item"
        class="partial-card"
        @click="openPath"
    >
        <div
            :class="`partial-card__container--${variant}`"
            class="partial-card__container"
        >
            <p
                v-if="item.category"
                class="partial-card__container__category"
                v-html="item.category"
            />
            <h3
                v-if="item.title"
                :class="item.summary ? '' : 'partial-card__container__title--no-summary'"
                class="partial-card__container__title"
            >
                <span v-html="item.title" />
            </h3>
            <p
                v-if="item.summary"
                class="partial-card__container__summary"
                v-html="item.summary"
            />
            <template v-if="item.src">
                <a
                    @click="openPath"
                >
                    {{ item.label ? item.label : discoverStr }}
                </a>
            </template>
            <template v-else>
                <span>{{ item.label ? item.label : discoverStr }}</span>
            </template>
        </div>
    </div>
</template>

<script>
import Navigation from "@/services/navigation";
import Translation from "@/services/lookup/translation";

export default {
    props: {
        item: {
            type: Object,
            default: null,
        },
        variant: {
            type: String,
            default: 'beta',
        }
    },

    data(){
        return {
            discoverStr: 'Discover',
        }
    },

    methods: {
        openPath(){
            if(this.item.src){
                if(this.item.src.indexOf('http') > -1){
                    window.open(this.item.src)
                } else {
                    Navigation.push(this.item.src)
                }
            }
        }
    },

    mounted(){
        Translation.many(['discover']).then(translations => {
            if(translations){
                this.discoverStr = translations['discover'];
            }
        });
    }
}
</script>

<style lang="postcss">
.partial-card {
    @apply flex flex-col h-full flex-grow text-cod-gray cursor-pointer;

    &__container {
        @apply flex flex-col p-6 bg-white lg:h-full w-full mb-8;

        &--beta {
            @apply bg-cararra;
        }

        &__category {
            @apply text-spaced-small text-xs mb-4 text-royal-purple font-semibold;
        }

        &__title {
            @apply text-base cursor-pointer mb-2;

            span {
                @apply title-underline;
            }

            &--no-summary {
                @apply mb-8 lg:flex-grow lg:mb-12;
            }
        }

        &__summary {
            @apply font-goudy leading-relaxed mb-8 lg:flex-grow lg:mb-12;
        }

        > a {
            @apply text-spaced-small text-royal-purple hover:text-cod-gray underline cursor-pointer w-max font-semibold;
        }

        > span {
            @apply text-spaced-small text-royal-purple hover:text-cod-gray underline w-max font-semibold;
        }
    }
}
</style>
