export default {
    type: 'impact-story-modules',
    aliases: {
        camel: 'impactStoryModules',
        singular: 'Impact story module',
        plural: 'Impact story modules',
        lowerSingular: 'impact story module',
        lowerPlural: 'impact story modules',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
