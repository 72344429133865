<template>
    <div class="form-couple">
        <BaseInput
            :type="type"
            :placeholder="placeholder"
            @update:model-value="update"
        />
        <FormBtn
            variant="delta"
            @click="click"
        >
            {{ buttonLabel }}
        </FormBtn>
    </div>
</template>

<script>
export default {
    props: {
        buttonLabel: {
            type: String,
            default: 'Submit'
        },
        placeholder: {
            type: String,
            default: 'Add'
        },
        type: {
            type: String,
            default: 'text'
        }
    },

    emits: ['update:modelValue', 'buttonClicked'],

    methods: {
        click() {
            this.$emit('buttonClicked')
        },

        update(value) {
            this.$emit('update:modelValue', value);
        }
    }
}
</script>

<style lang="postcss">
.form-couple {
    @apply border-b-4 border-salt-box mb-4 flex justify-between align-baseline;

    .base-input {
        @apply placeholder-ship-gray flex-grow bg-transparent border-0 pb-1 px-0 lg:text-lg;
    }

    .form-btn {
        @apply h-auto flex align-baseline mt-3;

        .form-btn__slot__inner {
            @apply text-cod-gray font-libre-franklin lg:text-base;
        }
    }
}
</style>
