export default {
    type: 'vacancies',
    aliases: {
        camel: 'vacancies',
        singular: 'Vacancy',
        plural: 'Vacancies',
        lowerSingular: 'vacancy',
        lowerPlural: 'vacancies',
    },
    relations: {
        'modules': {
            pivot: {
                type: 'vacancy-modules',
                localKey: 'vacancyId',
                foreignKey: 'moduleId'
            }
        },
    },
    publishable: true,
    adminSection: 'about',
    componentType: 'base',
    filterOn: {
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminRecord: {
            name: 'AdministerVacancyRecord',
            loader: () => import('./AdministerVacancyRecord')
        },
        adminTranslation: {
            name: 'AdministerVacancyTranslation',
            loader: () => import('./AdministerVacancyTranslation')
        },
        webRecord: {
            name: 'DisplayVacancyRecord',
            loader: () => import('./DisplayVacancyRecord')
        }
    }
};
