import Kernel from "@oilstone/kernel";
import defaults from "./config";

export default (resourceType, componentType) => {
    const settings = Kernel.resolve('resourceRegistry').find(resourceType);

    const component = settings.config.components[componentType] || defaults.components[componentType];

    return component.name;
}
