import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .make();

schema.prop('vacancyApplicationId').setType(Number);
schema.prop('sort').setType(Number);
schema.prop('name').setType(String);
schema.prop('readEasily').setType(Boolean);
schema.prop('writeEasily').setType(Boolean);
schema.prop('speakEasily').setType(Boolean);
schema.prop('understandEasily').setType(Boolean);

export default schema;
