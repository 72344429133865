export default {
    type: 'person-keywords',
    aliases: {
        camel: 'personKeywords',
        singular: 'Person keyword',
        plural: 'Person keywords',
        lowerSingular: 'person keyword',
        lowerPlural: 'person keywords',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
