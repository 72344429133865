<template>
    <svg
        class="icon-visa"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 32"
    >
        <rect
            width="48"
            height="32"
            rx="4"
            fill="#2a2a6c"
        />
        <path
            d="M26.17,20.75a7.23,7.23,0,0,1-2.6-.49l.43-2a5.06,5.06,0,0,0,2.38.6h.06c.72,0,1.43-.3,1.43-.91,0-.39-.32-.7-1.24-1.15s-2.09-1.18-2.09-2.51c0-1.8,1.65-3.06,4-3.06a6.31,6.31,0,0,1,2.24.41l-.42,2a4.53,4.53,0,0,0-1.92-.43,3.31,3.31,0,0,0-.44,0c-.69.09-1,.45-1,.77s.5.65,1.13,1c1,.52,2.23,
            1.17,2.22,2.7l0-.08c0,1.92-1.64,3.16-4.17,3.16Zm6.63-.08H30.28l3.59-8.57a1.07,1.07,0,0,1,1-.68h2l1.94,9.25H36.59l-.29-1.38h-3l-.49,1.38Zm2.38-6.75-1.26,3.47h2ZM21.86,20.64h-2.4l2-9.25h2.4l-2,9.25Zm-5.25,0H14l-1.91-7.39a1,1,0,0,0-.57-.81,9.76,9.76,0,0,0-2.36-.78l.06-.27h4.07a1.13,1.13,
            0,0,1,1.11.94l1,5.35,2.49-6.29h2.6l-3.88,9.25Z"
            fill="#fff"
        />
    </svg>
</template>