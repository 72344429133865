import PageCopy from "@/apps/web/layout/PageCopy";
import {accessesData} from "@/services/data-layer/composers";
import {inject} from "vue";
import Kernel from "@oilstone/kernel";

export default () => {
    return {
        components: {
            PageCopy
        },

        setup() {
            const type = inject('type');
            const uuid = inject('uuid');
            const {id, title, lede, body, summary, summaryBullets, summaryBulletsTitle, summaryBulletsOverride, slug, translatesId, item} = accessesData();
            const settings = Kernel.resolve('resourceRegistry').find(type);
            const hasModules = settings.config.relations && settings.config.relations.modules;

            console.log('title', summaryBulletsTitle);
            console.log('summaryBullets', summaryBullets);

            return {
                uuid,
                getRecord: item,
                id,
                translatesId,
                slug,
                title,
                lede,
                body,
                summary,
                summaryBullets,
                summaryBulletsTitle,
                summaryBulletsOverride,
                hasModules,
            }
        },

        emits: ['loaded'],

        computed: {
            content() {
                const fields = {
                    title: this.title,
                    lede: this.lede,
                    body: this.body,
                    summary: this.summary,
                    summaryBullets: this.summaryBullets,
                    summaryBulletsTitle: this.summaryBulletsTitle,
                    summaryBulletsOverride: this.summaryBulletsOverride,
                };

                let record = null;

                for (const field in fields) {
                    if (!fields[field] && this.translatesId) {
                        if (!record) {
                            record = this.getRecord(this.uuid);
                        }

                        fields[field] = record.value.translated ? record.value.translated[field] : null;
                    }
                }

                return fields;
            }
        },

        mounted() {
            this.$emit('loaded');
        }
    }
}
