export default {
    type: 'programme-area-people',
    aliases: {
        camel: 'programmeAreaPeople',
        singular: 'Programme area person',
        plural: 'Programme area people',
        lowerSingular: 'programme area person',
        lowerPlural: 'programme area people',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
