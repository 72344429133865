import Factory from "@/schemas/factory";

const schema = new Factory()
    .make();

schema.prop('id').setType(String).primaryKey();
schema.prop('email').setType(String).setValue(null);
schema.prop('firstName').setType(String).setValue(null);
schema.prop('lastName').setType(String).setValue(null);

export default schema;
