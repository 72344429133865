import config from './config';
import schema from "./schema"
import model from "@/services/resources/model";
import Repository from "./repository";

export default () => {
    return {
        config,
        schema,
        repository: Repository.make(model(config), schema)
    }
};
