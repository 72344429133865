// noinspection JSCheckFunctionSignatures,ES6CheckImport

import {Provider} from "@oilstone/container";
import {createRouter, createWebHistory} from 'vue-router';
import webRoutes from "@/apps/web/routes";
import cmsRoutes from "@/apps/cms/routes";
import Publication from "@/services/publication";
import Navigation from "@/services/navigation";
import Context from "@/services/context";
import { trackRouter } from "vue-gtag-next";
// import {forget as forgetDataLayer} from "@/services/data-layer/composers";

export default new Provider().register(container => {
    container.singleton('router', () => {
        let isLoggedIn = false;

        const router = createRouter({
            history: createWebHistory(process.env.BASE_URL),
            routes: webRoutes.concat(cmsRoutes)
        });

        const context = {
            app: null,
            preview: false,
        };

        const loggedIn = async () => {
            try {
                await container.resolve('vuexStore').dispatch('auth/loggedIn');

                return true;
            } catch (e) {
                //
            }

            return false;
        }

        container.add('context', context);

        router.beforeEach(async to => {
            // forgetDataLayer();
            document.body.classList.remove('modal-open');

            // The use of a timeout here is an far from desirable "fix" for mobiles that will ignore the scrollTo call otherwise
            setTimeout(() => {
                window.scrollTo({top: 0, behavior: 'smooth'});
            }, 0);

            if (to.path.startsWith('/admin')) {
                context.app = 'cms';

                Publication.useDraftData = true;

                if (to.meta.noAuth) {
                    return;
                }

                isLoggedIn = await loggedIn();

                if (!isLoggedIn) {
                    return '/admin/login'
                }
            } else {
                context.app = 'web';

                Publication.useDraftData = false;

                if (typeof to.query.preview !== 'undefined') {
                    isLoggedIn = await loggedIn();

                    if (isLoggedIn) {
                        context.preview = true;
                        Publication.useDraftData = true;
                    }
                }

                if (typeof to.params.language !== 'undefined') {
                    Navigation.setLanguage(to.params.language);
                }

                Context.clearRelatedMasterRecord();
            }
        });

        if(window.location.href.toLowerCase().indexOf('/admin') < 0){
            trackRouter(router);
        }

        return router;
    });
});
