export default {
    type: 'event-people',
    aliases: {
        camel: 'eventPeople',
        singular: 'Event person',
        plural: 'Event people',
        lowerSingular: 'event person',
        lowerPlural: 'event people',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
