<template>
    <div
        class="img-square"
        :class="containerClass"
    >
        <BaseImg
            :src="src"
            :resolve="resolve"
            class="img-square__image"
            :class="imageClass"
        />
        <Credit :credit="credit" />
    </div>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            default: null,
        },
        resolve: {
            type: Boolean,
            default: false
        },
        credit: {
            type: String,
            default: null,
        },
        disabledFrom: {
            type: Boolean,
            default: null,
        },
    },

    computed: {
        containerClass() {
            if (this.disabledFrom) {
                return 'img-square--disabled-from-' + this.disabledFrom;
            }

            return '';
        },

        imageClass(){
            if (this.disabledFrom) {
                return 'img-square__image--disabled-from-' + this.disabledFrom;
            }

            return '';
        }
    },
}
</script>

<style lang="postcss">
.img-square {
    @apply relative;
    padding-bottom: 100%;

    &--disabled-from-sm {
        @screen sm {
            padding-bottom: 0;
        }
    }

    &--disabled-from-md {
        @screen md {
            padding-bottom: 0;
        }
    }

    &--disabled-from-lg {
        @screen lg {
            padding-bottom: 0;
        }
    }

    &--disabled-from-xl {
        @screen xl {
            padding-bottom: 0;
        }
    }

    &__image {
        @apply absolute top-0 left-0 h-full w-full object-cover;

        &--disabled-from-sm {
            @apply sm:relative;
        }

        &--disabled-from-md {
            @apply md:relative;
        }

        &--disabled-from-lg {
            @apply lg:relative;
        }

        &--disabled-from-xl {
            @apply xl:relative;
        }
    }
}
</style>
