<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.65 47.85">
		<g>
			<path d="M28.55,45.51H2.48A2.48,2.48,0,0,1,0,43V2.48A2.48,2.48,0,0,1,2.48,0H22.22L31,8.76V43A2.48,2.48,0,0,1,28.55,45.51Z" style="fill: #e0e0e0"/>
			<path d="M24.7,8.76H31L22.22,0V6.28A2.48,2.48,0,0,0,24.7,8.76Z" style="fill: #eff2f4"/>
			<rect x="16.24" y="34.9" width="23.41" height="12.95" rx="1.49" style="fill: #ed4343"/>
			<g>
				<path d="M24.3,41.93a2.28,2.28,0,0,1-1.49.44H21.57v2.14H20.33v-6h2.56A2.1,2.1,0,0,1,24.3,39a1.82,1.82,0,0,1,.52,1.43A1.84,1.84,0,0,1,24.3,41.93Zm-.95-2.15a1,1,0,0,0-.66-.19H21.57v1.75h1.12a1,1,0,0,0,.66-.21.88.88,0,0,0,.24-.68A.84.84,0,0,0,23.35,39.78Z" style="fill: #fff"/>
				<path d="M29.31,38.68a1.92,1.92,0,0,1,1,.76,2.6,2.6,0,0,1,.43,1,4.92,4.92,0,0,1,.11,1,4,4,0,0,1-.47,2,2.17,2.17,0,0,1-2,1.11H25.82v-6h2.57A3.33,3.33,0,0,1,29.31,38.68ZM27,39.59v3.88h1.15a1.24,1.24,0,0,0,1.23-.86,3.24,3.24,0,0,0,.19-1.14,2.81,2.81,0,0,0-.29-1.4,1.21,1.21,0,0,0-1.13-.48Z" style="fill: #fff"/>
				<path d="M31.8,38.56H36v1H33V41h2.64v1H33v2.5H31.8Z" style="fill: #fff"/>
			</g>
		</g>
	</svg>
</template>