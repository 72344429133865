import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .sortable()
    .hasDecade()
    .hasTheme()
    .hasTitle()
    .hasSummary()
    .publishable()
    .publishableByDate()
    .hasTimestamps()
    .make();

export default schema;
