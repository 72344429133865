import Factory from '../factory';

const schema = Factory.schema('network-and-partners');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('networkText').setType(String).setValue('<p>&nbsp;</p>');
attributes.prop('partnersText').setType(String).setValue('<p>&nbsp;</p>');
attributes.prop('networkList').setType(Array)

export default schema;
