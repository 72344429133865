<template>
    <div class="roll-up">
        <ModuleCard>
            <div class="roll-up__trigger">
                <div
                    v-if="title"
                    class="roll-up__trigger__title"
                >
                    {{ title}}
                </div>
                <div v-if="showChange">
                    <div
                        class="roll-up__trigger__btn"
                        @click="change"
                    >
                        CHANGE
                    </div>
                </div>
            </div>
            <div class="roll-up__content">
                <div class="roll-up__content__left">
                    <slot name="left"></slot>
                </div>
                <div class="roll-up__content__right">
                    <slot name="right"></slot>
                </div>
            </div>
        </ModuleCard>
    </div>
</template>


<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        showChange: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        change(){
            this.$emit('change');
        }
    }
}
</script>

<style lang="postcss">
.roll-up {
    &__trigger {
        @apply flex w-full text-salt-box;

        &__title {
            @apply font-sans-medium text-big-stone flex-grow;
        }

        &__btn {
            @apply text-action;
        }
    }

    &__content {
        @apply flex justify-between items-end;

        &__left {
            @apply text-salt-box flex-grow;
        }

        &__right {
            @apply font-sans-medium text-big-stone flex-shrink-0;
        }
    }

    .card {
        @apply block;
    }
}
</style>
