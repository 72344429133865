export default {
    type: 'search-content',
    name: 'Search Content',
    osOnly: true,
    components: {
        root: {
            name: 'ModuleSearchContent',
            loader: () => import('./ModuleSearchContent')
        }
    }
};
