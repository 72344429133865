<template>
    <router-view
        :key="$route.fullPath"
        v-slot="{ Component }"
    >
        <transition
            name="fade"
            mode="out-in"
            :duration="{enter: 800, leave: 50}"
        >
            <component :is="Component"/>
        </transition>
    </router-view>

    <teleport to="head">
        <title>{{ pageTitle }}</title>

        <meta
            v-if="metaDescription"
            name="description"
            :content="metaDescription"
        >
        <meta
            v-if="metaKeywords"
            name="keywords"
            :content="metaKeywords"
        >
        <meta
            v-if="metaAuthor"
            name="author"
            :content="metaAuthor"
        >

        <meta
            v-if="pageTitle"
            property="og:title"
            name="og:title"
            :content="pageTitle"
        >
        <meta
            v-if="ogType"
            property="og:type"
            name="og:type"
            :content="ogType"
        >
        <meta
            v-if="ogImage"
            property="og:image"
            name="og:image"
            :content="ogImage"
        >
        <meta
            v-if="ogData"
            property="og:description"
            name="og:description"
            :content="ogData"
        >
    </teleport>
</template>

<!--suppress JSAnnotator -->
<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('meta', {
            pageTitle: 'pageTitle',
            metaDescription: 'metaDescription',
            metaKeywords: 'metaKeywords',
            metaAuthor: 'metaAuthor',
            ogType: 'ogType',
            ogImage: 'ogImage',
            ogData: 'ogData',
        })
    }
}
</script>
