<template>
    <span
        v-if="text"
        class="form-error"
    >
            {{ text }}
    </span>
</template>

<script>
export default {
    props: {
        text: String
    }
}
</script>

<style lang="postcss">
.form-error {
    @apply block text-xs text-red font-sans-medium mt-2;
}
</style>
