<template>
    <div class="layout-container">
        <slot></slot>
    </div>
</template>

<style lang="postcss">
.layout-container {
    @apply container mx-auto px-6;
}
</style>
