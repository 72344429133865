import Factory from '../factory';

const schema = Factory.schema('office');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('title').setType(String).setValue('Office name');
attributes.prop('department').setType(String).setValue('United Nations Research Institute of Social Development (UNRISD)');
attributes.prop('address').setType(String).setValue('Address');
attributes.prop('email').setType(String).setValue('Email address');
attributes.prop('tel').setType(String).setValue('Telephone');
attributes.prop('fax').setType(String).setValue('Fax');
attributes.prop('btnLabel').setType(String);
attributes.prop('btnSrc').setType(String);
attributes.prop('imgSrc').setType(String).setValue('https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg');
attributes.prop('imgAlt').setType(String);
attributes.prop('imgCredit').setType(String);
attributes.prop('imgLink').setType(String);

export default schema;
