import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .sluggable()
    .hasDecade()
    .hasTitle()
    .hasSummary()
    .hasBulletSummary()
    .hasImage()
    .hasDateRange()
    .hasBody()
    .hasMetadata()
    .publishable()
    .publishableByDate()
    .hasLotusNotesId()
    .hasTimestamps()
    .make();

schema.prop('eventTypeId').setType(Number).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('locationTypeId').setType(Number).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('startTime').setType(String).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('location').setType(String).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('counterparts').setType(String).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('donors').setType(String).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('bookable').setType(Boolean).setValue(false).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('bookingUrl').setType(String).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('pdf').setType(String).setValue(null).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('pdfButtonLabel').setType(String).setValue(null).addMetadata('translatable', true).addMetadata('draftable', true);

export default schema;
