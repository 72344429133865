<template>
    <div v-if="item">
        <div
            class="poster__img-container"
            @click.stop="click"
        >
            <PosterImg
                :content-type="contentType"
                :label="contentType"
                :item="item"
            />
        </div>

        <Card class="poster__card">
            <div
                v-if="item.label"
                class="poster__card__category"
                v-html="item.label"
            />
            <div class="flex-grow">
                <h2
                    class="poster__card__title"
                    @click.stop="click"
                >
                    <span v-html="item.title" />
                </h2>
                <p
                    class="poster__card__date"
                    v-html="dateLocation"
                />
            </div>
            <div
                class="poster__card__time"
                @click.stop="click"
            >
                <span
                    class="listen-now"
                    v-html="listenNow"
                />
                <span
                    class="time"
                    v-html="item.duration"
                />
            </div>

            <slot name="share" />
        </Card>
        <div class="poster__outline" />
    </div>
</template>

<script>
import {inject} from "vue";
import Translation from "@/services/lookup/translation";

export default {
    props: {
        contentType: {
            type: String,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        href: {
            type: String,
            default: null,
        }
    },

    setup() {
        const launchModal = inject('launchModal', true);

        return {
            launchModal
        }
    },

    data(){
        return {
            listenNow: 'Listen now',
        }
    },

    computed: {
        dateLocation() {
            return (this.item.publishAt ? '<span>' + this.$filters.date.format(this.item.publishAt) + '</span>' : '');
        },
    },

    methods: {
        click() {
            if (this.launchModal) {
                this.$store.dispatch('modal/podcast', this.item);

                return;
            }

            this.$emit('navigate', this.item);
        }
    },

    mounted(){
        Translation.many(['listen-now']).then(translations => {
            if(translations){
                this.listenNow = translations['listen-now'];
            }
        })
    }
}
</script>
