export default {
    type: 'office',
    name: 'Office',
    osOnly: true,
    components: {
        root: {
            name: 'ModuleOffice',
            loader: () => import('./ModuleOffice')
        }
    }
};
