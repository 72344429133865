import PivotRepository from "@/services/resources/pivot-repository";
import byProject from "@/services/resources/mixins/query-by-project";
import byPerson from "@/services/resources/mixins/query-by-person";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byProject).$mix(byPerson);
    }

    projectsByPerson(id) {
        return this.relation('projects', 'personId', id, 'project');
    }

    peopleByProject(id) {
        return this.relation('people', 'projectId', id, 'person', ['association'], 'person').then(collection => collection.filter(person => person && person.association && person.association !== 'other'));
    }
}

export default Repository;
