<template>
    <AdminRadioGroup
        v-bind="baseComponentBindings"
        @update:model-value="update"
        :options="booleanOptions"
    />
</template>

<script>
import formBooleanComposable from "@/composables/forms/form-boolean";

export default {
    mixins: [{
        props: formBooleanComposable.props()
    }],

    setup(props, {emit}) {
        return {
            ...formBooleanComposable.composables(props, emit)
        }
    },

    data() {
        return {
            booleanOptions: [
                {
                    label: 'Yes',
                    value: true,
                },
                {
                    label: 'No',
                    value: false,
                },
            ],
        };
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],
}
</script>
