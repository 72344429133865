import Kernel from "@oilstone/kernel";

class Country {
    static show(id) {
        return Kernel.resolve('resourceRegistry').find('countries').repository.collection().then(countries => {
            const country = countries.find(country => country.id === id);

            if (!country) {
                return null;
            }

            return country.title;
        });
    }

    static get(id) {
        return Country.show(id).then(value => value);
    }
}

export default Country;
