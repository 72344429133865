import {ErrorBag, RestModelError} from "@oilstone/rest-model-repository";
import { v4 as uuidv4 } from "uuid";
import { reactive, inject, provide } from "vue";
import Kernel from "@oilstone/kernel";
import { accessesData, boot, hasEvents, hydratesData, mutatesData, persistsData } from "@/services/data-layer/composers";
import { bootPublication, publishableData } from "@/services/publication/composer";
import Factory from "@/services/resources/factory";
import Navigation from "@/services/navigation";
import { useRoute } from "vue-router";

export default () => {
    return {
        setup() {
            const type = inject("type");
            const uuid = inject("uuid");
            const settings = Kernel.resolve("resourceRegistry").find(type);
            const { dispatchChanges, prepareUpdate, dirty } = mutatesData();
            const { item } = accessesData();
            const { listen, fire } = hasEvents();
            const { overwrite, destroy } = persistsData();
            const id = useRoute().params.id;
            const mode = id === "new" ? "create" : "update";
            const errors = reactive(new ErrorBag());

            provide("mode", mode);
            provide('errors', errors);
            bootPublication(settings.repository.schema, Factory.scope(settings.config), uuid, dirty);

            const { dispatchPublishableChanges } = publishableData();

            return {
                type,
                uuid,
                listen,
                fire,
                dispatchChanges,
                dispatchPublishableChanges,
                prepareUpdate,
                errors,
                saveRecord: overwrite,
                destroyRecord: destroy,
                getRecord: item,
                config: settings.config,
                schema: settings.repository.schema,
                mode,
            };
        },

        emits: ["loaded"],

        data() {
            return {
                translations: [],
            };
        },

        computed: {
            translationTabs() {
                return ["en"].concat(
                    this.translations.map(translation => {
                        return translation.code;
                    })
                );
            },

            pageTitle() {
                return (this.mode === "create" ? "Create a new " : "Update ") + this.config.aliases.lowerSingular;
            },
        },

        methods: {
            close() {
                Navigation.push({
                    name: "admin-resource",
                    params: { type: this.type },
                    query: this.$route.query,
                });
            },

            publish() {
                this.fire("publishing");

                this.persist({
                    isPublished: true,
                    hasUnpublishedChanges: false,
                    draft: null,
                }).then(() => {
                    this.fire("masterPublished");
                    this.close();
                }, error => {
                    this.fire('invalidResource');
                });
            },

            unpublish() {
                this.fire("unpublishing");

                return this.persist({
                    isPublished: false,
                    hasUnpublishedChanges: false,
                }).then(() => {
                    this.fire("masterSaved");
                    this.close();
                }, error => {
                    this.fire('invalidResource');
                });
            },

            saveClose() {
                this.fire("savedAndClosing");

                return this.persist({
                    hasUnpublishedChanges: true,
                }).then(() => {
                    this.fire("masterSaved");
                    this.close();
                }, error => {
                    this.fire('invalidResource');
                });
            },

            save() {
                this.fire("saving");

                return this.persist({
                    hasUnpublishedChanges: true,
                }).then(() => {
                    this.fire("masterSaved");
                }, error => {
                    this.fire('invalidResource');
                });
            },

            preview() {
                console.log('previewing')
                this.fire('previewing');

                return this.persist({
                    hasUnpublishedChanges: true,
                }).then(() => {
                    let path = "";
                    const record = this.getRecord(this.uuid).value;

                    switch (this.type){
                        case 'blog-posts':
                            path = `/library/blog-posts/${record.slug}`;
                            break;

                        case 'collections':
                            if(record.section === 'blog-posts') {
                                path = `/library/blog-series/${record.slug}`;
                            } else if(record.section === 'podcasts'){
                                path = `/library/podcast-series/${record.slug}`;
                            }
                            break;

                        case 'events':
                            path = `/activities/events/${record.slug}`;
                            break;

                        case 'impact-stories':
                            path = `/about/impact-stories/${record.slug}`;
                            break;

                        case 'pages':
                            if(record.section === 'general' || record.section === 'footer'){
                                path = `/${record.slug}`;
                            } else if(record.section === 'home') {
                                path = "/";
                            } else if(record.section === 'aux-research'){
                                if (record.programmeAreaId) {
                                    Kernel.resolve('resourceRegistry').find('programme-areas').repository.find(record.programmeAreaId).then(item => {
                                        if(item){
                                            path = `/research/programme-areas/${item.slug}/${record.slug}`;
                                            window.open(`/en${path}?preview=true`);
                                        }
                                    });
                                } else if (record.projectId) {
                                    Kernel.resolve('resourceRegistry').find('projects').repository.find(this.projectId).then(item => {
                                        if (item) {
                                            path = `/research/projects/${item.slug}/${record.slug}`;
                                            window.open(`/en${path}?preview=true`);
                                        }
                                    });
                                }
                            } else {
                                if(record.section === record.slug && !record.parentId) {
                                    path = `/${record.slug}`;
                                } else {
                                    path = `/${record.section}/${record.slug}`;
                                }
                            }
                            break;

                        case 'people':
                            if(record.showPersonPage) {
                                path = `/about/people/${record.slug}`;
                            }
                            break;

                        case 'podcasts':
                            path = `/library/podcasts/${record.slug}`;
                            break;

                        case 'programme-areas':
                            path = `/research/programme-areas/${record.slug}`;
                            break;

                        case 'projects':
                            path = `/research/projects/${record.slug}`;
                            break;

                        case 'publications':
                            path = `/library/publications/${record.slug}`;
                            break;

                        case 'news-items':
                            path = `/activities/news-items/${record.slug}`;
                            break;

                        case 'themes':
                            path = `/research/themes/${record.slug}`;
                            break;

                        case 'vacancies':
                            path = `/about/vacancies/${record.slug}`;
                            break;

                        case 'videos':
                            path = `/library/videos/${record.slug}`;
                            break;
                    }

                    if(path !== ''){
                        window.open(`/en${path}?preview=true`);
                    }

                    this.fire("masterSaved");
                }, error => {
                    console.log('previewing error')

                    this.fire('invalidResource');
                });
            },

            persist(append = {}) {
                return new Promise((resolve, reject) => {
                    if (this.config.publishable && !append.isPublished && this.mode !== "create") {
                        this.dispatchPublishableChanges();
                    } else {
                        this.dispatchChanges();
                    }

                    const data = Object.assign({}, this.getRecord(this.uuid).value, append);

                    this.saveRecord(data).then(resource => {
                        if (!this.translations.length) {
                            const languages = Kernel.resolve("languages")
                                .map(language => {
                                    return language.code === "en" ? null : language.code;
                                })
                                .filter(language => language);

                            languages.forEach(code => {
                                const uuid = uuidv4();

                                boot(this.schema, Factory.scope(this.config), uuid);

                                const { make, setLanguage, setTranslatesId, dirty } = mutatesData(uuid);

                                bootPublication(this.schema, Factory.scope(this.config), uuid, dirty);

                                make();

                                if (setLanguage) {
                                    setLanguage(code);
                                }

                                if (setTranslatesId) {
                                    setTranslatesId(resource.id);
                                }

                                this.translations.push({ code, uuid });
                            });
                        }

                        resolve(resource);
                    }, error => {
                        if (error instanceof RestModelError) {
                            this.errors.replace(error.getBag());
                            this.fire('invalidResource');
                        }

                        reject(error);
                    });
                });
            },

            destroy() {
                this.fire("destroying");

                this.destroyRecord().then(() => {
                    Navigation.push({
                        name: "admin-resource",
                        params: { type: this.type },
                        query: this.$route.query,
                    });
                });
            },
        },

        mounted() {
            // Apply any filters to the new resource
            if (this.mode === "create") {
                for (const paramType in this.$route.query) {
                    this.prepareUpdate(paramType, this.$route.query[paramType] || null);
                }
            }

            const record = this.getRecord(this.uuid).value;

            if (record.translations) {
                record.translations.forEach(translation => {
                    const uuid = uuidv4();

                    boot(this.schema, Factory.scope(this.config), uuid);

                    const { hydrate } = hydratesData(uuid);

                    hydrate(translation);

                    this.translations.push({
                        uuid,
                        code: translation.language,
                    });
                });
            }
        },
    };
};
