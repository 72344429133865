<template>
    <div
        class="page-poster"
        @click="openLink"
    >
        <div class="page-poster__container">
            <div
                v-if="resource.image"
                class="page-poster__container__img-container"
            >
                <ImgCredited
                    :alt="resource.imageAlt"
                    :credit="resource.imageCredit"
                    :src="resource.image"
                    class="page-poster__container__img"
                />
            </div>
            <div class="page-poster__container__details">
                <h2
                    v-if="resource.title"
                    class="page-poster__container__details__title"
                    @click="openLink"
                >
                    <span v-html="resource.title" />
                </h2>
                <p
                    v-if="resource.summary"
                    class="page-poster__container__details__summary"
                    v-html="resource.summary"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Navigation from "@/services/navigation";

export default {
    props: {
        resource: {
            type: Object,
            required: true,
        },
        variant: {
            type: String,
            default: 'alpha'
        },
    },

    methods: {
        openLink(){
            if(this.resource.slug){
                if(this.resource.slug.indexOf('http') > -1){
                    window.open(this.resource.slug);
                } else {
                    Navigation.push(this.resource.slug === 'about' ? this.resource.slug : '/about/' + this.resource.slug);
                }
            }
        }
    }
}
</script>

<style lang="postcss">
.page-poster {
    @apply flex flex-col h-full flex-grow cursor-pointer;

    &__container {
        @apply flex flex-col h-full bg-cararra rounded-xl overflow-hidden mb-8;

        &__img-container {
            @apply relative bg-white flex-shrink-0;
        }

        &__img {
            @apply min-h-full min-w-full object-cover;
            max-height: 220px;
        }

        &__details {
            @apply flex-grow trim-content p-4 lg:p-6;

            &__title {
                @apply text-base my-2 text-cod-gray cursor-pointer;

                span {
                    @apply relative title-underline;
                    background-image: linear-gradient(#111111, #111111);
                }
            }

            &__summary {
                @apply font-goudy;
            }
        }
    }
}
</style>
