export default [
    {
        path: '/',
        redirect: '/en'
    },
    {
        path: '/:language(en|fr|es)',
        component: () => import('@/apps/web/templates/MasterTemplate'),
        children: [
            {
                path: 'page-not-found',
                name: '404',
                component: () => import('@/apps/web/views/404'),
            },
            {
                path: ':keyOne?/:keyTwo?/:keyThree?/:keyFour?',
                name: 'resource',
                component: () => import('@/apps/web/views/Resource'),
                props: true,
            },
        ]
    },
    {
        path: '/:path(.+)',
        component: () => import('@/apps/web/views/Shortcut'),
    }
];
