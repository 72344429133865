<template>
    <div class="admin-sidebar">
        <AdminSidebarSection
            v-for="(section,index) in navigation"
            :key="'sidebar-item-' + index"
            :section="section"
        />


        <div class="admin-sidebar-section">
            <div
                class="admin-sidebar-section__button admin-sidebar-section__button--icon admin-sidebar-section__button--administration"
                @click="assetLibraryClick"
            >
                <IconRenderer name="Clone" />
                <span>Asset library</span>
            </div>
        </div>


        <div class="admin-sidebar__os">
            <p>
                &copy; {{ new Date().getFullYear() }} Online Solutions UK Ltd.<br>
                Registered in England No. 4854663.
            </p>
        </div>

        <teleport to="body">
            <AssetLibrary
                v-if="pickAsset"
                @close="closeAssetLibrary"
            />
        </teleport>
    </div>
</template>

<script>
import AdminSidebarSection from "./partials/AdminSidebarSection";
import AssetLibrary from '../asset-library/AssetLibrary';

export default {
    components: {
        AdminSidebarSection,
        AssetLibrary
    },

    methods: {
        assetLibraryClick() {
            this.pickAsset = true;
        },

        closeAssetLibrary() {
            this.pickAsset = false;
        }
    },

    data() {
        return {
            value: null,
            pickAsset: false,
            navigation: [
                {
                    id: 1,
                    title: 'General Content',
                    key: 'general',
                    hasSectionHomepage: false,
                    hasSectionPages: false,
                    items: [
                        {
                            title: 'Website homepage',
                            link: {
                                name: 'admin-resource',
                                params: {
                                    type: 'pages',
                                },
                                query: {
                                    section: 'home',
                                    sectionHomepage: true,
                                    ignoreParent: true,
                                    sectionTitle: 'Homepage',
                                },
                            },
                        },
                        {
                            title: 'General pages',
                            link: {
                                name: 'admin-resource',
                                params: {
                                    type: 'pages',
                                },
                                query: {
                                    section: 'general',
                                    ignoreParent: true,
                                    sectionTitle: 'General/Landing pages',
                                },
                            },
                        },
                        {
                            title: 'Footer pages',
                            link: {
                                name: 'admin-resource',
                                params: {
                                    type: 'pages',
                                },
                                query: {
                                    section: 'footer',
                                    ignoreParent: true,
                                    sectionTitle: 'Footer pages',
                                },
                            },
                        },
                    ],
                },
                {
                    id: 2,
                    title: 'About',
                    key: 'about',
                    hasSectionHomepage: true,
                    hasSectionPages: true,
                },
                {
                    id: 3,
                    title: 'Research',
                    key: 'research',
                    hasSectionHomepage: true,
                    hasSectionPages: true,
                    items: [
                        {
                            title: 'Aux pages',
                            link: {
                                name: 'admin-resource',
                                params: {
                                    type: 'pages',
                                },
                                query: {
                                    section: 'aux-research',
                                    ignoreParent: true,
                                    sectionTitle: 'Auxiliary research pages',
                                    view: 'auxCategorisedCollection',
                                },
                            },
                        },
                    ],
                },
                {
                    id: 4,
                    title: 'Library',
                    key: 'library',
                    hasSectionHomepage: true,
                    hasSectionPages: true,
                    items: [
                        {
                            title: 'Podcast series',
                            link: {
                                name: 'admin-resource',
                                params: {
                                    type: 'collections',
                                },
                                query: {
                                    section: 'podcasts',
                                },
                            },
                        },
                        {
                            title: 'Blog series',
                            link: {
                                name: 'admin-resource',
                                params: {
                                    type: 'collections',
                                },
                                query: {
                                    section: 'blog-posts',
                                },
                            },
                        },
                        {
                            title: 'Aux pages',
                            link: {
                                name: 'admin-resource',
                                params: {
                                    type: 'pages',
                                },
                                query: {
                                    section: 'aux-library',
                                    ignoreParent: true,
                                    sectionTitle: 'Auxiliary library pages',
                                    view: 'auxCategorisedCollection',
                                },
                            },
                        },
                    ],
                },
                {
                    id: 5,
                    title: 'Activities',
                    key: 'activities',
                    hasSectionHomepage: true,
                    hasSectionPages: true,
                    items: [
                        {
                            title: 'Aux pages',
                            link: {
                                name: 'admin-resource',
                                params: {
                                    type: 'pages',
                                },
                                query: {
                                    section: 'aux-activities',
                                    ignoreParent: true,
                                    sectionTitle: 'Auxiliary activity pages',
                                    view: 'auxCategorisedCollection',
                                },
                            },
                        },
                    ],
                },
                {
                    id: 6,
                    title: 'Administration',
                    key: 'administration',
                    hasSectionHomepage: false,
                    hasSectionPages: false,
                    admin: true,
                    icon: 'Cog',
                    items: [],
                },
            ],
        }
    },
}
</script>

<style lang="postcss">
.admin-sidebar {
    &__os {
        @apply text-sm font-sofia-pro font-normal text-silver mt-8;

        p {
            @apply m-0 p-0 leading-relaxed;
        }
    }
}
</style>
