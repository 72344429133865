<template>
    <div class="page-tabs">
        <LayoutContainer>
            <div class="page-tabs__list">
                <div
                    v-for="(tab, index) in tabs"
                    v-bind:key="index"
                    class="page-tabs__list__tab"
                    :class="active === tab.id ? 'page-tabs__list__tab--active' : ''"
                    v-html="tab.value"
                    @click="update(tab.id)"
                />
            </div>
        </LayoutContainer>
    </div>
</template>

<script>
export default {
    props: {
        active: String,
        tabs: Array,
    },

    methods: {
        update(val){
            this.$emit('updated',val);
        }
    }
}
</script>

<style lang="postcss">
.page-tabs {
    @apply bg-cararra mb-8;

    &__list {
        @apply flex flex-row;

        &__tab {
            @apply pt-4 pb-2 font-libre-franklin font-semibold text-dove-gray text-sm mr-8 cursor-pointer md:text-base md:mr-14;

            &:hover {
                 @apply text-cod-gray border-b-4 border-cod-gray;
            }

            &--active {
                @apply text-cod-gray border-b-4 border-cod-gray;
            }
        }
    }
}
</style>
