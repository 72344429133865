export default {
    type: 'keywords',
    aliases: {
        camel: 'keywords',
        singular: 'Keyword',
        plural: 'Keywords',
        lowerSingular: 'keyword',
        lowerPlural: 'keywords',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'lookup',
    components: {},
};
