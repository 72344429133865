<template>
    <div class="popover">
        <div class="popover__trigger">
            <slot name="trigger"></slot>
            <div class="popover__pointer"/>
        </div>
        <div class="popover__popover">
            <div class="popover__popover__content">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            in: false
        }
    }
}
</script>

<style lang="postcss">
.popover {
    @apply relative cursor-pointer;

    &__trigger {
        @apply relative pb-3;
    }

    &__pointer {
        @apply absolute z-10 -bottom-1 left-1/2 w-2 h-2 bg-big-stone transform rotate-45 -translate-x-1/2 opacity-0;
        will-change: opacity;
    }

    &__popover {
        @apply absolute z-10 bottom-0 -right-2 p-4 bg-big-stone rounded-md text-white text-right text-xs opacity-0 whitespace-nowrap pointer-events-none;
        will-change: opacity, transform;
        transition: opacity 400ms, transform 400ms;
        transform: translateY(calc(100% - 40px));
    }

    &:hover {
        .popover__pointer {
            @apply opacity-100;
            transition: opacity 200ms 150ms;
        }

        .popover__popover {
            @apply opacity-100 pointer-events-auto;
            transform: translateY(100%);
        }
    }
}
</style>
