export default {
    type: 'blog-post-people',
    aliases: {
        camel: 'blogPostPeople',
        singular: 'Blog post person',
        plural: 'Blog post people',
        lowerSingular: 'blog post person',
        lowerPlural: 'blog post people',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
