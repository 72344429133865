import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .make();

schema.prop('collectionId').setType(Number);
schema.prop('moduleId').setType(Number);

export default schema;
