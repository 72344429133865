<template>
    <FormLabel
        :text="strLabel"
        class="form-textarea form-control"
        :class="classes.concat(errorClass)"
    >
        <span class="relative block">
            <BaseTextarea
                v-bind="baseComponentBindings"
                ref="textarea"
                class="form-control__input"
                @update:model-value="update"
                @focus="clearError"
            />
        </span>
        <FormError :text="error" />
        <FormHelp :text="helpText" />
        <FormHelp
            v-if="maxLength"
            class="form-textarea__help"
            :text="maxLengthText"
        />
    </FormLabel>
</template>

<script>
import formTextareaComposable from "@/composables/forms/form-textarea";

export default {
    mixins: [{
        props: formTextareaComposable.props()
    }],

    setup(props, {emit}) {
        return {
            ...formTextareaComposable.composables(props, emit)
        }
    },

    props: {
        maxLength: {
            type: Number,
            required: false,
            default: null,
        },
        hasError: Number,
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],

    data() {
        return {
            errorClass: [],
        }
    },

    computed: {
        maxLengthText() {
            const characters = this.modelValue ? this.modelValue.length : 0;

            return `${(this.maxLength - characters)} characters left`;
        },

        strLabel(){
            return this.required && this.label  ? `${this.label} *` : this.label;
        },

    },

    methods: {
        clearError(){
            this.errorClass = [];
        }
    },

    watch: {
        hasError(val){
            if(val){
                this.errorClass.push('form-control--error');
            } else {
                this.errorClass = [];
            }
        }
    }
}
</script>

<style lang="postcss">
.form-textarea {
    &__help {
        @apply text-right;
    }
}
</style>
