<template>
    <div
        class="login-input"
        :class="classes"
    >
        <FormLabel
            :text="`${label}`"
            :class="labelClass"
        />

        <span class="login-input__input">
            <BaseInput
                v-bind="baseComponentBindings"
                :type="resolvedType"
                @update:model-value="update"
                ref="input"
                class="form-control__input"
                @focus="setLabelClass('selected')"
                @blur="setLabelClass()"
            />
            <div
                v-if="isPassword"
                @click="toggleVisibility"
                class="login-input__input__visibility-toggle"
            >
                <IconRenderer
                    v-if="forceType === 'text'"
                    name="EyeCancel"
                    class="fill-current"
                />
                <IconRenderer
                    v-else
                    name="Eye"
                    class="fill-current"
                />
            </div>
        </span>
        <FormError :text="error"/>
        <FormHelp :text="helpText"/>
    </div>
</template>

<script>
import formInputComposable from "@/composables/forms/form-input";

export default {
    mixins: [{
        props: formInputComposable.props()
    }],

    setup(props, {emit}) {
        return {
            ...formInputComposable.composables(props, emit)
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],

    props: {
        type: {
            type: String,
            default: 'text'
        }
    },

    data() {
        return {
            forceType: null,
            labelClass: null,
        }
    },

    computed: {
        resolvedType() {
            return this.forceType || this.type
        },

        isPassword() {
            return this.type === 'password';
        },
    },

    methods: {
        toggleVisibility() {
            if (this.forceType) {
                this.forceType = null;

                return;
            }

            this.forceType = 'text';
        },

        focus() {
            this.$refs.input.$el.focus();
        },

        setLabelClass(cls) {
            this.labelClass = cls ? cls : null;

            if (this.$refs.input && this.$refs.input.modelValue) {
                this.labelClass = "selected"
            }
        }
    },

    mounted() {
        if (this.$refs.input && this.$refs.input.modelValue) {
            this.labelClass = "selected"
        }
    }
}
</script>

<style lang="postcss">
.login-input {
    @apply mb-4 grid grid-cols-12 relative;

    .form-label {
        @apply absolute z-10 w-auto pointer-events-none;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        transform-origin: left;
        transition: transform .2s;
        text-transform: uppercase;
        letter-spacing: 1px;

        &__text {
            @apply font-normal text-grey-1 text-base m-0 p-0;
            font-family: 'sofia-pro', 'sans-serif' !important;
        }

        &.selected {
            transform: scale(0.75) translateY(-35px);

            .form-label__text {
                @apply text-purple-1;
            }
        }
    }

    &__input {
        @apply col-span-12 relative block;

        input {
            @apply border-white w-full;
            padding: 30px 20px 20px;
        }

        &__visibility-toggle {
            @apply absolute cursor-pointer w-6.5 right-4 bottom-1/2 transform translate-y-1/2 text-silver-chalice;
        }
    }
}
</style>
