import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .hasTitle()
    .hasBody()
    .sortable()
    .hasTimestamps()
    .make();

schema.prop('cookieKey').setType(String).setValue(null).addMetadata('mergeable', true);

export default schema;
