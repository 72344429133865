import BaseRepository from "@/services/resources/repository";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema);
    }

    getByPath(path){
        return this.transformer.many(
            this.try(
                this.baseQuery().where('prefix',path).get()
            )
        );
    }

    deleteAsset(id){
        return this.transformer.one(
            this.try(
                this.destroy(id)
            )
        );
    }

    createDirectory(path){
        return this.transformer.one(
            this.try(
                this.save({
                    'path': path
                })
            )
        );
    }

    uploadAsset(data){
        return this.transformer.one(
            this.try(
                this.save(data)
            )
        );
    }

}

export default Repository;
