import Kernel from '@oilstone/kernel';
import config from './config';
import './styles/index.css';

Kernel.configure(config).registered(() => {
    try {
        return Kernel.resolve('auth').recall();
    } catch (e) {
        console.log(e);

        throw e;
    }
}).run();
