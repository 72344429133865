<template>
    <div
        v-if="!fetching"
        class="asset-library__poster"
        :class="'asset-library__poster--' + fileType"
        @click.stop="selectAsset"
        :title="data.name"
    >
        <div class="asset-library__poster__container">
            <div class="asset-library__poster__container__image">
                <ImgCredited
                    v-if="showThumbnail"
                    :src="imagePath"
                />
                <div
                    v-else
                    class="icon"
                >
                    <Component :is="iconType"/>
                </div>
            </div>
            <div
                v-if="fileType !== 'folder'"
                class="asset-library__poster__container__tools"
            >
                <div
                    title="Download asset"
                    @click.stop="downloadAsset"
                >
                    <IconToolDownload />
                </div>
                <div
                    title="Preview asset"
                    @click.stop="previewAsset"
                    v-if="isImage"
                >
                    <IconToolPreview />
                </div>
                <div
                    title="Use asset"
                    @click.stop="selectAsset"
                >
                    <IconToolUse />
                </div>
                <div
                    title="Delete asset"
                    @click.stop="deleteAsset"
                >
                    <IconToolTrash />
                </div>
            </div>
        </div>

        <div
            v-html="data.name"
            class="asset-library__poster__title"
        />
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import IconDoc from '../../icons/icon-doc';
import IconFile from '../../icons/icon-file';
import IconFolder from '../../icons/icon-folder';
import IconPdf from '../../icons/icon-pdf';
import IconTxt from '../../icons/icon-txt';
import IconXls from '../../icons/icon-xls';
import IconToolDownload from '../../icons/icon-tool-download';
import IconToolPreview from '../../icons/icon-tool-preview';
import IconToolUse from '../../icons/icon-tool-use';
import IconToolTrash from '../../icons/icon-tool-trash';
import ImgCredited from "../../../../../shared/ui/images/ImgCredited";

export default {
    props: ['data', 'lot'],

    components: {
        ImgCredited,
        IconDoc,
        IconFile,
        IconFolder,
        IconPdf,
        IconTxt,
        IconXls,
        IconToolDownload,
        IconToolPreview,
        IconToolUse,
        IconToolTrash,
    },

    data() {
        return {
            currentPath: '',
            imageTypes: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', 'psd'],
            pdfTypes: ['pdf'],
            docTypes: ['doc', 'docx', 'rtf', 'sql', 'log', 'fla', 'xml', 'ade', 'adp', 'mdb', 'accdb', 'ppt', 'pptx', 'odt', 'ots', 'ott', 'odb', 'odg', 'otp', 'otg', 'odf', 'ods', 'odp', 'css', 'ai', 'kmz', 'dwg', 'dxf', 'hpgl', 'plt', 'spl', 'step', 'stp', 'iges', 'igs', 'sat', 'cgm'],
            xlsTypes: ['xls', 'xlsx', 'csv'],
            txtTypes: ['txt']
        }
    },

    computed: {
        ...mapGetters('asset', {
            assets: 'assets',
            basePath: 'basePath',
            fetching: 'fetching',
        }),

        imagePath() {
            if(this.isImage) {
                return 'https://res.cloudinary.com/' + process.env.VUE_APP_CDN_KEY + '/image/fetch/w_146/' + this.basePath + this.data.path;
            } else {
                return '';
            }
        },

        showThumbnail(){
            return this.isImage && this.imagePath && (process.env.VUE_APP_NODE_ENV === 'production')
        },

        isImage() {
            return (this.data.path.toLowerCase().indexOf('.jpg') > -1) || (this.data.path.toLowerCase().indexOf('.jpeg') > -1) || (this.data.path.toLowerCase().indexOf('.png') > -1);
        },

        fileType() {
            if (this.data.path.lastIndexOf('.') > -1) {
                return this.data.path.substring(this.data.path.lastIndexOf('.') + 1);
            } else {
                return 'folder';
            }
        },

        iconType() {
            let ft = this.fileType.toLowerCase();

            if (ft === 'folder') {
                return IconFolder;
            } else if (this.pdfTypes.indexOf(ft) > -1) {
                return IconPdf;
            } else if (this.docTypes.indexOf(ft) > -1) {
                return IconDoc;
            } else if (this.xlsTypes.indexOf(ft) > -1) {
                return IconXls;
            } else if (this.txtTypes.indexOf(ft) > -1) {
                return IconTxt;
            } else if (this.isImage && this.currentPath === '') {
                return IconFile;
            } else {
                return IconFile;
            }
        }
    },

    methods: {
        ...mapMutations('asset', {
            setCurrentPath: 'setCurrentPath',
            setAsset: 'setAsset',
        }),

        downloadAsset() {
            window.open(this.basePath + this.data.path, 'asset-download');
        },

        previewAsset() {
            this.setAsset(this.basePath + this.data.path);
        },

        selectAsset() {
            if (this.fileType === 'folder') {
                this.setCurrentPath(this.data.path + '/');
            } else {
                this.$emit('selected',this.basePath + this.data.path);
            }
        },

        deleteAsset() {
            if (confirm('Are you sure you would like to delete this asset?')) {
                this.$emit('deleted',this.data.id);
            }
        }
    },

    emits: ['deleted', 'selected'],
}
</script>

<style lang="postcss">
.asset-library__poster {
    @apply m-4 mt-0 p-2 cursor-pointer border border-grey-3 bg-white rounded-md hover:border-purple-2 flex-grow-0;
    width: 150px;
    height: 135px;

    &--back {
        @apply bg-grey-3;
    }

    &--folder {
        .asset-library__poster__container {
            &__image {
                background-image: none;

                .icon {
                    @apply w-auto;
                    height: 40px;
                }
            }
        }
    }

    &:hover {
        .asset-library__poster__container__tools {
            @apply bottom-0;
        }
    }

    &__container {
        @apply relative overflow-hidden;
        width: 130px;
        height: 90px;

        &__image {
            @apply flex flex-row items-center justify-center w-full text-center overflow-hidden;
            width: 130px;
            height: 90px;
            background-image: linear-gradient(45deg, #eee 25%, transparent 25%), linear-gradient(-45deg, #eee 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #eee 75%), linear-gradient(-45deg, transparent 75%, #eee 75%);
            background-size: 20px 20px;
            background-position: 0 0, 0 10px, 10px -10px, -10px 0px;

            .icon {
                @apply w-auto;
                height: 70px;
            }
        }

        &__tools {
            @apply absolute flex flex-row items-center justify-between w-full z-10 transition-all duration-500 ease-in-out border border-dusty-gray text-dusty-gray bg-wild-sand py-0.5 px-2;
            height: 28px;
            bottom: -28px;

            svg {
                @apply w-auto;
                height: 14px;

                &:hover {
                    @apply opacity-70;
                }
            }
        }
    }

    &__title {
        @apply text-xs m-0 mt-2 p-0 overflow-hidden whitespace-nowrap overflow-ellipsis;
    }
}
</style>
