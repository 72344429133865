export default {
    type: 'publication-keywords',
    aliases: {
        camel: 'publicationKeywords',
        singular: 'Publication keyword',
        plural: 'Publication keywords',
        lowerSingular: 'publication keyword',
        lowerPlural: 'publication keywords',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
