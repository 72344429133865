export default {
    type: 'theme-timeline-entries',
    aliases: {
        camel: 'themeTimelineEntries',
        singular: 'Theme timeline entry',
        plural: 'Theme timeline entries',
        lowerSingular: 'theme timeline entry',
        lowerPlural: 'theme timeline entries',
    },
    publishable: true,
    adminSection: 'research',
    componentType: 'base',
    filterOn: {
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerThemeTimelineEntryRecord',
            loader: () => import('./AdministerThemeTimelineEntryRecord')
        },
        adminTranslation: {
            name: 'AdministerThemeTimelineEntryTranslation',
            loader: () => import('./AdministerThemeTimelineEntryTranslation')
        },
    }
};
