import BaseRepository from "@/services/publication/repository";
import queryByKeys from "@/services/resources/mixins/query-by-keys";
import translatable from "@/services/resources/mixins/translatable-record";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(queryByKeys).$mix(translatable);
    }

    getEbulletins() {
        return this.transformer.many(
            this.try(
                this.baseQuery().sort('publishAt', 'desc').get()
            )
        );
    }
}

export default Repository;
