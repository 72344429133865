<template>
    <AdminSection
        v-if="!language || language === 'en'"
        title="Image"
    >
        <ResourceInput
            label="Image"
            attribute="image"
            type="asset"
            :help-text="dimensions"
        />

        <ResourceInput
            label="Image alt tag"
            attribute="imageAlt"
            type="text"
        />
    </AdminSection>
</template>

<script>
import {inject} from "vue";

export default {
    setup() {
        const language = inject('language');

        return {
            language,
        }
    },

    props: {
        dimensions: {
            type: String,
            required: false,
            default: '(960px x 510px)'
        }
    },
}
</script>
