<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.23 30.05">
		<g>
			<path d="M30.82,29.71H0l6-18a.76.76,0,0,1,.73-.53h29.2a.77.77,0,0,1,.73,1Z" style="fill: #eff2f4"/>
			<path d="M6.72,11.21h24.1V5.82a.76.76,0,0,0-.77-.77H15.15a.74.74,0,0,1-.54-.23l-3.4-3.4a.76.76,0,0,0-.55-.23H.77A.78.78,0,0,0,0,2V29.71l6-18A.76.76,0,0,1,6.72,11.21Z" style="fill: #eff2f4"/>
		</g>
		<g>
			<path d="M31.61,30.05H.78a.73.73,0,0,1-.62-.32A.75.75,0,0,1,.05,29l6-18A1.54,1.54,0,0,1,7.5,10H36.7a1.54,1.54,0,0,1,1.46,2L32.34,29.53A.78.78,0,0,1,31.61,30.05ZM1.85,28.51h29.2l5.65-17H7.5Z" style="fill: #9b9b9b"/>
			<path d="M.78,30.05H.66A.76.76,0,0,1,0,29.28V1.54A1.54,1.54,0,0,1,1.55,0h9.89a1.54,1.54,0,0,1,1.09.45l3.4,3.4H30.84a1.54,1.54,0,0,1,1.54,1.54v5.4a.78.78,0,0,1-.77.77H7.5v0l-6,18A.75.75,0,0,1,.78,30.05ZM11.44,1.54H1.55v23L6,11.07A1.54,1.54,0,0,1,7.5,10H30.84V5.39H15.93a1.51,1.51,0,0,1-1.08-.45Z" style="fill: #9b9b9b"/>
		</g>
	</svg>
</template>