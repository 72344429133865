export default {
    type: 'organisational-structure',
    name: 'Organisational Structure',
    osOnly: true,
    components: {
        root: {
            name: 'ModuleOrganisationalStructure',
            loader: () => import('./ModuleOrganisationalStructure')
        }
    }
};
