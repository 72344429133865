<template>
    <div
        class="asset-library__poster asset-library__poster--back"
        title="Back"
        @click="goBack"
    >
        <div class="asset-library__poster__image">
            <ImgCredited
                src="icon-back.png"
                :resolve="true"
            />
            <span>Back</span>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    props: ['data', 'lot'],

    computed: {
        ...mapGetters('asset', {
            currentPath: 'currentPath',
        }),
    },

    methods: {
        ...mapMutations('asset', {
            setCurrentPath: 'setCurrentPath',
        }),

        goBack() {
            let np = "";
            let cp = this.currentPath;
            cp = cp.endsWith('/') ? cp.slice(0, -1) : cp;
            cp = cp.split('/');
            cp.pop();

            if (cp.length > 0) {
                cp.forEach(row => {
                    np += row + "/";
                });

                if (np === "/" || np === "//") {
                    np = "";
                }
                this.setCurrentPath(np);
            } else {
                this.setCurrentPath('');
            }
        }
    }
}
</script>

<style lang="postcss">
.asset-library__poster {
    &--back {
        .asset-library__poster__image {
            @apply flex flex-col w-full h-full items-center justify-center text-center overflow-hidden text-xs text-cod-gray;

            img {
                @apply mb-2;
                width: 47px;
                height: 19px;
            }
        }
    }
}
</style>
