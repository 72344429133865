<template>
    <div class="form-address">
        <template v-if="fieldsVisible">
            <FormGroup>
                <FormInput :model-value="address1"
                           label="Address*"
                           for="address1"
                           @update:model-value="update('address1', $event)"
                />
            </FormGroup>
            <FormGroup>
                <FormInput :model-value="address2"
                           @update:model-value="update('address2', $event)"
                />
            </FormGroup>
            <FormGroup>
                <FormInput :model-value="address3"
                           @update:model-value="update('address3', $event)"
                />
            </FormGroup>
            <FormGroup>
                <FormInput :model-value="town"
                           label="Town/City*"
                           for="town"
                           @update:model-value="update('town', $event)"
                />
            </FormGroup>
            <FormGroup>
                <FormInput :model-value="county"
                           label="County"
                           for="county"
                           @update:model-value="update('county', $event)"
                />
            </FormGroup>
        </template>
        <FormGroup class="form-address__lookup">
            <FormInput :model-value="postcode"
                       for="postcode"
                       :label="postcodeLabel"
                       @update:model-value="update('postcode', $event)"
            />
            <FormBtn
                @click="find"
                :working="finding"
            >
                Find
            </FormBtn>
        </FormGroup>
        <FormGroup v-if="options.length">
            <BaseSelect :options="options"
                        :model-value="null"
                        @update:model-value="select"
            />
        </FormGroup>
        <FormGroup v-if="fieldsVisible && countries.length">
            <FormSelect :options="countries"
                        :model-value="country"
                        for="country"
                        label="Country*"
                        @update:model-value="update('country', $event)"
            />
        </FormGroup>
        <div v-if="!fieldsVisible"
             class="form-address__fields-toggle"
             @click="showFields"
        >
            Enter address manually
        </div>
    </div>
</template>

<script>
import Kernel from '@oilstone/kernel';

export default {
    props: {
        for: String,
        address1: String,
        address2: String,
        address3: String,
        town: String,
        county: String,
        postcode: String,
        country: String,
    },

    inject: {
        ancestors: {
            default: () => []
        }
    },

    provide() {
        let ancestors = [...this.ancestors];
        ancestors = ancestors.concat(this.for ? [...this.for.split('.')] : [])

        return {
            ancestors: ancestors
        }
    },

    data() {
        return {
            fieldsVisible: false,
            options: [],
            countries: [],
            finding: false,
        }
    },

    computed: {
        postcodeLabel() {
            return this.fieldsVisible ? 'Postcode*' : 'Enter postcode*';
        },
    },

    methods: {
        showFields() {
            this.fieldsVisible = true;

            this.$emit('showFields');
        },

        find() {
            this.finding = true;

            Kernel.resolve('addressRepository').getByPostcode(this.postcode).then(addresses => {
                this.options = [
                    {
                        text: 'Select an address',
                        value: null
                    }
                ].concat(addresses.map(address => {
                    return {
                        text: address.text,
                        value: address.id
                    }
                }));

                this.finding = false;
            }).catch(() => {
                this.finding = false;
            });
        },

        select(id) {
            Kernel.resolve('addressRepository').find(id).then(address => {
                this.update('address1', address.address1);
                this.update('address2', address.address2);
                this.update('address3', address.address3);
                this.update('town', address.town);
                this.update('county', address.county);
                this.update('postcode', address.postcode);
                this.update('country', address.country);

                this.options = [];
                this.showFields();
            });
        },

        update(field, value) {
            this.$emit(`update:${field}`, value);
        },
    },

    mounted() {
        if (this.address1 && this.town && this.postcode) {
            this.showFields();
        }

        Kernel.resolve('countries').then(countries => {
            this.countries = countries.map(country => {
                return {
                    text: country.name,
                    value: country.name
                }
            });
        });
    },
}
</script>

<style lang="postcss">
.form-address {
    @apply w-full;

    &__lookup {
        @apply items-start;

        .form-btn {
            @apply mt-7 w-max;
        }
    }

    &__fields-toggle {
        @apply font-sans-medium underline text-sm cursor-pointer;
    }
}
</style>
