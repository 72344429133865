import BaseRepository from "@/services/resources/repository";
import byPublication from "@/services/resources/mixins/query-by-publication";
import byKeyword from "@/services/resources/mixins/query-by-keyword";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byPublication).$mix(byKeyword);
    }
}

export default Repository;
