<template>
    <logoOs
        class="logo-os-small"
    />
</template>

<style lang="postcss">
.logo-os-small {
    width: 40px;

    @screen lg {
        width: 60px;
    }
}
</style>
