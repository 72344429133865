import Kernel from "@oilstone/kernel";
import {inject, toRefs} from "vue";
import component from "@/services/resources/component";
import filterItems from "@/composables/collection-filter";
import Navigation from "@/services/navigation";

export default () => {
    return {
        setup(props) {
            props = toRefs(props);

            const type = inject('type');
            const settings = Kernel.resolve('resourceRegistry').find(type);
            const {searchString, filters, filtered} = filterItems(props.items);

            return {
                type,
                config: settings.config,
                collectionItemComponent: component(type, 'adminCollectionItem'),
                searchString,
                filtered,
                filters,
            }
        },

        props: {
            items: Array,
            fetching: {
                required: false,
                default: null,
            },
        },

        data() {
            return {
                loaded: false,
            }
        },

        computed: {
            pageTitle() {
                let title;

                if (this.config.cms && this.config.cms.collectionTitle) {
                    title = this.config.cms.collectionTitle();
                }

                return title || this.config.aliases.plural;
            },
        },

        methods: {
            setLoaded() {
                if (this.fetching) {
                    this.fetching.then(() => {
                        this.loaded = true;
                    });
                }
            },

            addNew() {
                Navigation.push({
                    name: 'admin-resource',
                    params: {
                        type: this.type,
                        id: 'new'
                    },
                    query: this.$route.query,
                });
            },
        },

        mounted() {
            this.setLoaded();
        },

        watch: {
            fetching() {
                this.setLoaded();
            }
        },
    }
}
