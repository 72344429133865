<template>
    <div class="admin-translation-tabs">
        <div
            v-for="tab in resolvedTabs"
            :key="tab.code"
            @click="toggleTab(tab.code)"
            :class="tab.classes"
        >
            {{ tab.title }}
        </div>
    </div>
    <div
        v-for="tab in resolvedTabs"
        :key="tab.code"
        v-show="language === tab.code"
    >
        <slot v-if="tab.primary"></slot>
        <slot :name="tab.code"></slot>
    </div>
</template>

<script>
import Kernel from "@oilstone/kernel";

export default {
    data() {
        return {
            language: 'en',
            tabs: Kernel.resolve('languages').map(language => {
                const tab = {
                    code: language.code,
                    title: language.labels.english,
                };

                if (language.code === 'en') {
                    tab.primary = true;
                }

                return tab;
            }),
        }
    },

    props: {
        items: {
            type: Array,
            default() {
                return Kernel.resolve('languages').map(language => {
                    return language.code;
                });
            }
        }
    },

    computed: {
        resolvedTabs() {
            return this.tabs.filter(tab => {
                return this.items.indexOf(tab.code) !== -1;
            }).map(tab => {
                tab.classes = ['admin-translation-tabs__tab'];

                if (this.language === tab.code) {
                    tab.classes.push('admin-translation-tabs__tab--selected');
                }

                return tab;
            })
        },
    },

    methods: {
        toggleTab: function (language) {
            this.language = language
        },
    },
}
</script>

<style lang="postcss">
.admin-translation-tabs {
    @apply inline-flex pt-2 mb-10 w-full border-b;

    &__tab {
        @apply bg-white mr-2 px-4 py-2 rounded-t cursor-pointer;

        &--selected {
            @apply border-t border-r border-l -mb-px;
        }
    }
}
</style>
