<template>
    <svg
        class="icon-podcast-series fill-current"
        viewBox="0 0 23.38 14.265"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M23.38,14.265H9.12V0H23.38V14.264ZM11.635,2.52h0v9.227h9.227V2.52Z" />
        <rect
            height="14.265"
            width="2.5"
            x="4.5"
            y="0"
        />
        <rect
            height="14.265"
            width="2.5"
            y="0"
        />
        <path d="M18.862,7.132l-5.224,3.1v-6.2Z" />
    </svg>
</template>
