export default {
    type: 'event-projects',
    aliases: {
        camel: 'eventProjects',
        singular: 'Event project',
        plural: 'Event projects',
        lowerSingular: 'event project',
        lowerPlural: 'event projects',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
