<template>
    <AdminFormGroup
        class="admin-date"
        :class="classes"
    >
        <FormLabel
            :text="label"
            class="form-control"
        >
            <div class="admin-date__input">
                <Datepicker
                    v-bind="datePickerBindings"
                    @update:model-value="update"
                />
            </div>

            <FormError :text="error"/>
            <FormHelp :text="helpText"/>
        </FormLabel>
    </AdminFormGroup>
</template>

<script>
import formDateComposable from "@/composables/forms/form-date";
import Datepicker from 'vue3-date-time-picker';
import {v4 as uuidv4} from "uuid";

export default {
    components: {
        Datepicker
    },

    mixins: [{
        props: formDateComposable.props(),
    }],

    setup(props, {emit}) {
        return {
            ...formDateComposable.composables(props, emit)
        }
    },

    data() {
        return {
            datePickerConfig: {
                uid: uuidv4(),
                enableTimePicker: false,
                locale: 'en-GB',
                range: false,
                monthPicker: false,
                timePicker: false,
                textInput: true,
                position: 'left',
                weekStart: 1,
                format: 'dd/MM/yyyy',
                monthNameFormat: 'long',
                hideInputIcon: true,
                clearable: false,
                closeOnScroll: true,
                autoApply: true,
                closeOnAutoApply: true,
                inputClassName: 'form-control__input',
            }
        }
    },

    computed: {
        datePickerBindings() {
            const options = this.baseComponentBindings;

            Object.keys(options).forEach(key => options[key] === undefined && delete options[key])

            return Object.assign(this.datePickerConfig, options);
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],
}
</script>

<style lang="postcss">
.admin-date {
    &__input {
        input {
            @apply block w-full outline-none text-big-stone border border-swiss-coffee p-3.5 rounded-none;
        }
    }
}
</style>
