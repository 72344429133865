import BaseRepository from "@/services/resources/repository";
import byBlogPost from "@/services/resources/mixins/query-by-blog-post";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byBlogPost);
    }
}

export default Repository;
