<template>
    <Component
        v-if="item"
        :is="componentName"
        class="poster"
        :class="classes"
        :item="item"
        :content-type="contentType"
        :href="href"
        @navigate="$emit('navigate', $event)"
    >
        <template v-slot:share>
            <div
                class="poster__card__share-tools"
                :class="isShare ? 'poster__card__share-tools--active' : ''"
            >
                <ShareIcons
                    :title="item.title"
                    :href="href"
                    @clicked="toggleShare"
                />
            </div>
            <a
                class="poster__card__share"
                :class="isShare ? 'poster__card__share--active' : ''"
                @click="toggleShare"
            >
                <IconRenderer name="Share"/>
            </a>
        </template>
    </Component>
</template>

<script>
import ShareIcons from "../../apps/web/layout/stubs/ShareIcons";
import Kernel from "@oilstone/kernel";
import PosterImg from "@/shared/ui/PosterImg";
import PosterBlogPosts from '@/apps/web/posters/PosterBlogPosts';
import PosterBlogSeries from '@/apps/web/posters/PosterBlogSeries';
import PosterEBulletins from '@/apps/web/posters/PosterEBulletins';
import PosterEvents from '@/apps/web/posters/PosterEvents';
import PosterNewsItems from '@/apps/web/posters/PosterNewsItems';
import PosterPodcasts from '@/apps/web/posters/PosterPodcasts';
import PosterPodcastSeries from '@/apps/web/posters/PosterPodcastSeries';
import PosterProgrammeAreas from '@/apps/web/posters/PosterProgrammeAreas';
import PosterProjects from '@/apps/web/posters/PosterProjects';
import PosterPublications from '@/apps/web/posters/PosterPublications';
import PosterThemes from '@/apps/web/posters/PosterThemes'
import PosterVideos from '@/apps/web/posters/PosterVideos';

export default {
    components: {
        PosterBlogPosts,
        PosterBlogSeries,
        PosterEBulletins,
        PosterEvents,
        PosterNewsItems,
        PosterPodcasts,
        PosterPodcastSeries,
        PosterProgrammeAreas,
        PosterProjects,
        PosterPublications,
        PosterThemes,
        PosterVideos,
        PosterImg,
        ShareIcons
    },

    props: {
        contentType: {
            type: String,
            default: 'projects',
        },
        resource: {
            type: Object,
            required: true,
        },
        variant: {
            type: String,
            default: 'alpha'
        },
        selected: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isShare: false,
        }
    },

    computed: {
        componentName(){
            let n = this.contentType.replace('-',' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
            return 'Poster' + n.charAt(0).toUpperCase() + n.slice(1).replace(' ', '');
        },

        item() {
            if (this.resource.projects) {
                return this.resource.projects;
            } else {
                return this.resource;
            }
        },

        classes() {
            let classes = [`poster--${this.variant}`];

            classes.push(`poster--${this.contentType.toLowerCase()}`);

            if (this.selected) {
                classes.push('poster--selected');
            }

            return classes;
        },

        href() {
            const resource = Kernel.resolve('resourceRegistry').find(this.contentType);

            if (resource && resource.config.adminSection) {
                return "/" + resource.config.adminSection + "/" + this.contentType + "/" + this.item.slug;
            }

            return "/" + this.contentType + "/" + this.item.slug;
        },

        dateLocation() {
            let date = this.item.publishAt;

            if (this.contentType === 'events') {
                date = this.item.startDate;
            }

            let str = (date ? '<span>' + this.$filters.date.format(date) + '</span>' : '');
            return str + (this.item.location && str ? ', ' : '') + (this.item.location ? this.item.location : '');
        },
    },

    methods: {
        toggleShare() {
            this.isShare = !this.isShare;
        },
    },
}

</script>

<style lang="postcss">
.poster {
    @apply relative flex flex-col mb-7;

    .card {
        @apply relative flex flex-col flex-grow p-4 filter drop-shadow bg-white;
    }

    &--beta {
        .card {
            @apply bg-cararra drop-shadow-none;
        }
    }

    &--selected {
        .poster__outline {
            @apply opacity-100;
        }
    }

    &__outline {
        outline: solid 4px #111111;
        outline-offset: -4px;
        @apply absolute top-0 left-0 w-full h-full pointer-events-none opacity-0;
        transition: opacity 400ms;
    }

    &--podcasts {
        .poster__img {
            @apply border-b-4 border-royal-purple;
        }

        .card {
            @apply bg-cararra drop-shadow-none;
        }
    }

    &--podcast-series {
        .card {
            @apply bg-black text-white;
        }

        .poster__img {
            @apply border-bermuda;
        }

        .poster__card {
            &__category {
                @apply text-bermuda;
            }

            &__date {
                span {
                    @apply font-bold;
                }
            }

            &__title {
                span {
                    background-image: linear-gradient(#ffffff, #ffffff);
                }
            }

            &__time {
                .listen-now {
                    @apply text-bermuda hover:text-white;
                }

                svg {
                    @apply text-bermuda w-5;
                }

                &:hover {
                    @apply text-white;

                    svg {
                        @apply text-white;
                    }
                }
            }

            &__share {
                @apply bg-bermuda;

                &:hover, &--active {
                    @apply bg-white;
                }

                svg {
                    @apply text-cod-gray;
                }
            }
        }
    }

    &__img-container {
        @apply relative bg-white flex-shrink-0 cursor-pointer;
    }

    &__img {
        height: 210px;

        @screen sm {
            height: 360px;
        }

        @screen lg {
            height: 170px;
        }

        &--default {
            @apply min-h-full min-w-full object-cover;
        }
    }

    &__img-overlay {
        @apply absolute top-0 left-4 z-10 w-max h-auto py-2 px-4 text-white text-spaced-small bg-surfie-green text-xs font-semibold;
    }

    &__img-video {
        @apply absolute top-0 left-0 z-10 w-full h-full flex items-center justify-center;

        &__bg {
            @apply w-16 h-auto;
        }

        &__play {
            @apply absolute z-20 text-white w-4 h-auto;
        }
    }

    &__card {
        @apply relative flex flex-col h-full justify-between;

        &__category {
            @apply text-spaced-small font-semibold text-royal-purple mb-2 transform;
        }

        &__title {
            @apply text-base mb-3 font-semibold tracking-normal cursor-pointer;

            span {
                @apply title-underline pb-0.5;
            }
        }

        &__date {
            @apply text-sm mb-2;

            span {
                @apply font-medium;
            }
        }

        &__discover {
            @apply text-spaced-small font-semibold text-royal-purple text-xs mt-4 underline cursor-pointer w-max;

            &:hover {
                @apply text-black;
            }
        }

        &__time {
            @apply text-spaced-small font-semibold text-royal-purple text-xs mt-4 flex flex-row items-center w-max cursor-pointer;

            .listen-now {
                @apply underline cursor-pointer w-max mr-4;
            }

            .time {
                @apply text-cod-gray;
            }

            svg {
                @apply text-royal-purple mr-2 w-2 h-auto -mt-0.5;
            }

            &:hover {
                @apply text-black;

                svg {
                    @apply text-black;
                }
            }
        }

        &__share-tools {
            @apply opacity-0 transition-all filter duration-300 absolute bg-royal-purple w-6 h-0 right-4 bottom-12 rounded-full flex flex-col items-center justify-between py-3;

            &--active {
                @apply opacity-100 h-24;
            }

            .share-icon {
                @apply relative text-white bg-transparent rounded-none m-0 p-0 opacity-80;
                width: auto;
                height: 11px;

                &:hover {
                    @apply opacity-100;
                }

                svg {
                    width: auto;
                    height: 11px;
                }

                &--email {
                    height: 10px;

                    svg {
                        height: 10px;
                    }
                }

                &--facebook {
                    height: 14px;

                    svg {
                        height: 14px;
                    }
                }

                &:nth-child(1) {
                    @apply order-4;
                }

                &:nth-child(2) {
                    @apply order-3;
                }

                &:nth-child(3) {
                    @apply order-2;
                }

                &:nth-child(4) {
                    @apply order-1;
                }
            }
        }

        &__share {
            @apply absolute right-4 bottom-4 rounded-full w-6 h-6 bg-royal-purple flex items-center justify-center cursor-pointer;

            &:hover, &--active {
                @apply bg-cod-gray;
            }

            svg {
                @apply w-1/2 h-auto text-white -ml-0.5;
            }
        }
    }
}
</style>
