export default {
    type: 'people',
    aliases: {
        camel: 'people',
        singular: 'Person',
        plural: 'People',
        lowerSingular: 'person',
        lowerPlural: 'people',
    },
    relations: {
        'affiliations': {
            adminLabel: 'Affiliations',
            pivot: {
                type: 'person-affiliations',
                localKey: 'personId',
                foreignKey: 'affiliationId',
            }
        },
        'keywords': {
            adminLabel: 'Keywords',
            pivot: {
                type: 'person-keywords',
                localKey: 'personId',
                foreignKey: 'keywordId',
            }
        },
        'modules': {
            pivot: {
                type: 'person-modules',
                localKey: 'personId',
                foreignKey: 'moduleId',
            }
        },
        'sectors': {
            adminLabel: 'Sectors',
            pivot: {
                type: 'person-sectors',
                localKey: 'personId',
                foreignKey: 'sectorId',
            }
        },
        'blog-posts': {
            adminLabel: 'Blog posts',
            pivot: {
                type: 'blog-post-people',
                localKey: 'personId',
                foreignKey: 'blogPostId',
            }
        },
        'news-items': {
            adminLabel: 'News stories',
            pivot: {
                type: 'news-item-people',
                localKey: 'personId',
                foreignKey: 'newsItemId',
            }
        },
        'programme-areas': {
            adminLabel: 'Programme areas',
            pivot: {
                type: 'programme-area-people',
                localKey: 'personId',
                foreignKey: 'programmeAreaId',
            }
        },
        'publications': {
            adminLabel: 'Publications',
            pivot: {
                type: 'publication-people',
                localKey: 'personId',
                foreignKey: 'publicationId',
            }
        },
        'projects': {
            adminLabel: 'Projects',
            pivot: {
                type: 'project-people',
                localKey: 'personId',
                foreignKey: 'projectId',
            }
        },
        'specialisations': {
            adminLabel: 'Specialisations',
            pivot: {
                type: 'person-specialisations',
                localKey: 'personId',
                foreignKey: 'specialisationId',
            }
        },
    },
    publishable: true,
    adminSection: 'about',
    componentType: 'base',
    searchOn: ['firstName', 'lastName'],
    filterOn: {
        'job-titles': {
            foreignKey: 'jobTitleId',
        },
        'countries': {
            foreignKey: 'country',
        },
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminRecord: {
            name: 'AdministerPersonRecord',
            loader: () => import('./AdministerPersonRecord')
        },
        adminCollection: {
            name: 'AdministerPersonCollection',
            loader: () => import('./AdministerPersonCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminTranslation: {
            name: 'AdministerPersonTranslation',
            loader: () => import('./AdministerPersonTranslation')
        },
        webRecord: {
            name: 'DisplayPersonRecord',
            loader: () => import('./DisplayPersonRecord')
        }
    },
    cms: {
        paginationAmount: () => {
            return 100
        },
    }
};
