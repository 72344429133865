<template>
    <video
        :src="resolvedSrc"
        :loop="loop"
        muted
        class="base-video"
        ref="element"
        @ended="$emit('ended')"
    >
        <source
            v-for="(item, index) in resolvedSrc"
            :key="index"
            :src="item"
            type="video/mp4"
        >
    </video>
</template>

<script>
export default {
    props: {
        src: [String, Array],
        resolve: {
            type: Boolean,
            default: false
        },
        loop: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        resolvedSrc() {
            if (!this.src) {
                return [];
            }

            const src = Array.isArray(this.src) ? this.src : [this.src];

            if (!this.resolve) {
                return src;
            }

            return src.map(item => {
                return require(`../../assets/videos/${item}`);
            });
        }
    },

    methods: {
        play() {
            this.$refs.element.play();
        }
    }
}
</script>

<style lang="postcss">
.base-video {
    @apply block w-full;
}
</style>
