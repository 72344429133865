import Publication from "./index";
import _ from 'lodash';

import {Transformer as BaseTransformer} from "@oilstone/rest-model-repository";

class Transformer extends BaseTransformer {
    many(collection) {
        return collection.map(record => {
            return this.one(record);
        });
    }

    one(record) {
        if (!record) {
            return record;
        }

        if (Publication.useDraftData && record.draft && record.draft !== 'null') {
            record = _.merge({}, record, record.draft);
        }

        return record;
    }
}

export default Transformer;
