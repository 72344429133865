<template>
    <svg
        class="icon-envelope fill-current"
        viewBox="0 0 23.746 17.81"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M23.3,69.881a.279.279,0,0,1,.45.218v9.485A2.227,2.227,0,0,1,21.52,81.81H2.226A2.227,2.227,0,0,1,0,79.583V70.1a.277.277,0,0,1,.45-.218c1.039.807,2.416,1.832,7.147,5.269.979.714,2.63,2.217,4.276,2.208,1.656.014,3.339-1.521,4.281-2.208C20.884,71.717,22.257,70.688,23.3,69.881ZM11.873,75.873c1.076.019,2.625-1.354,3.4-1.92,6.154-4.466,6.623-4.856,8.042-5.969a1.11,1.11,0,0,0,.427-.877v-.881A2.227,2.227,0,0,0,21.52,64H2.226A2.227,2.227,0,0,0,0,66.226v.881a1.117,1.117,0,0,0,.427.877c1.419,1.108,1.888,1.5,8.042,5.969C9.248,74.519,10.8,75.892,11.873,75.873Z"
            transform="translate(0 -64)"
        />
    </svg>
</template>
