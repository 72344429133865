<template>
    <div v-if="item">
        <navigation-link
            :to="href"
            class="poster__img-container"
        >
            <PosterImg
                :content-type="contentType"
                :label="label"
                :item="item"
            />
        </navigation-link>

        <Card class="poster__card">
            <div class="flex-grow">
                <navigation-link :to="href">
                    <h2 class="poster__card__title">
                        <span v-html="item.title" />
                    </h2>
                </navigation-link>
                <p
                    class="poster__card__date"
                    v-html="dateLocation"
                />
            </div>

            <navigation-link
                :to="href"
                class="poster__card__discover"
            >
                {{ discoverStr }}
            </navigation-link>

            <slot name="share" />
        </Card>
    </div>
</template>

<script>
import Translation from "@/services/lookup/translation";

export default {
    props: {
        contentType: {
            type: String,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        href: {
            type: String,
            default: null,
        }
    },

    data(){
        return {
            discoverStr: 'Discover',
        }
    },

    computed: {
        label(){
            return 'News';
        },

        dateLocation() {
            let date = this.item.publishAt;

            let str = (date ? '<span>' + this.$filters.date.format(date) + '</span>' : '');
            return str + (this.item.location && str ? ', ' : '') + (this.item.location ? this.item.location : '');
        },
    },

    mounted(){
        Translation.many(['discover']).then(translations => {
            if(translations){
                this.discoverStr = translations['discover'];
            }
        });
    }
}
</script>
