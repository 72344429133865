import BaseRepository from "@/services/resources/repository";
import byCollection from "@/services/resources/mixins/query-by-collection";
import byTheme from "@/services/resources/mixins/query-by-theme";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byCollection).$mix(byTheme);
    }
}

export default Repository;
