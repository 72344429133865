const state = () => ({
    baseTitle: '',
    title: null,
    description: null,
    keywords: [],
    author: null,
    ogType: null,
    ogImage: null,
    ogData: null,
});

const getters = {
    pageTitle: state => {
        return [state.baseTitle, state.title].filter(item => item).join('-');
    },

    metaDescription: state => {
        return state.description;
    },

    metaKeywords: state => {
        return state.keywords.join(' ');
    },

    metaAuthor: state => {
        return state.author;
    },

    ogType: state => {
        return state.ogType;
    },

    ogImage: state => {
        return state.ogImage;
    },

    ogData: state => {
        return state.ogData;
    }
}

const mutations = {
    pageTitle(state, title) {
        state.title = title;
    },

    metaDescription(state, description) {
        state.description = description;
    },

    metaKeywords(state, keywords) {
        state.keywords = keywords;
    },

    metaAuthor(state, author) {
        state.author = author;
    },

    ogType(state, ogData) {
        state.ogType = ogData;
    },

    ogImage(state, ogData) {
        state.ogImage = ogData;
    },

    ogData(state, ogData) {
        state.ogData = ogData;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}
