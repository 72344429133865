export default {
    type: 'staff-and-associates',
    name: 'Staff and Associates',
    osOnly: true,
    components: {
        root: {
            name: 'ModuleStaffAndAssociates',
            loader: () => import('./ModuleStaffAndAssociates')
        }
    }
};
