import PivotRepository from "@/services/resources/pivot-repository";
import byPublication from "@/services/resources/mixins/query-by-publication";
import byEvent from "@/services/resources/mixins/query-by-event";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byPublication).$mix(byEvent);
    }

    publicationsByEvent(id, injectedMasterRecord = null) {
        return this.relation('publications', 'eventId', id,null, [], null, injectedMasterRecord);
    }
}

export default Repository;
