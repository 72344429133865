<template>
    <div
        class="base-radio"
        :class="classes"
    >
        <div class="base-radio__inner"></div>
        <input class="base-radio__input"
               :checked="checked"
               :value="value"
               @input="update"
               type="radio"
        />
    </div>
</template>

<script>
    export default {
        props: {
            value: null,
            modelValue: null,
        },

        computed: {
            checked() {
                return this.modelValue === this.value;
            },

            classes() {
                return this.checked ? ['base-radio--checked'] : [''];
            }
        },

        methods: {
            update() {
                this.$emit('update:modelValue', this.value);
            }
        }
    }
</script>

<style lang="postcss">
.base-radio {
    @apply relative flex items-center justify-center w-6 h-6 border border-silver-chalice rounded-full;

    &--checked {
        .base-radio__inner {
            @apply opacity-100;
        }
    }

    &__inner {
        @apply w-2.5 h-2.5 bg-big-stone rounded-full transition-opacity duration-300 opacity-0;
    }

    &__input {
        @apply absolute cursor-pointer opacity-0 w-full h-full;
    }
}
</style>
