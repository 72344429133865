<template>
    <div
        v-if="credit"
        class="credit"
    >
        <div class="credit__gradient" />
        <div class="credit__credit">
            {{ translations.photoBy }} <strong>{{ credit }}</strong>
        </div>
    </div>
</template>

<script>
import Translation from "@/services/lookup/translation";

export default {
    props: {
        credit: {
            type: String,
            default: null
        },
    },

    data(){
        return {
            translations: {
                photoBy: null,
            }
        }
    },

    mounted() {
        Translation.many(['photo-credit']).then(translations => {
            if(translations){
                this.translations.photoBy = translations['photo-credit'];
            }
        })
    },
}
</script>

<style lang="postcss">
.credit {
    &__gradient {
        @apply absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-black to-transparent opacity-50;
    }

    &__credit {
        @apply flex items-center absolute bottom-0 left-0 p-4 text-cotton-seed text-xs lg:p-6 font-medium;

        strong {
            @apply text-white ml-1 font-medium;
        }
    }
}
</style>
