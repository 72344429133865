<template>
    <div class="feedback">
        <p
            class="feedback__title"
            v-html="text"
        />

        <div class="feedback__thumbs">
            <IconRenderer
                name="Thumb"
                class="feedback__thumbs__thumb feedback__thumbs__thumb--up"
            />

            <IconRenderer
                name="Thumb"
                class="feedback__thumbs__thumb feedback__thumbs__thumb--down"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: null
        }
    }
}
</script>

<style lang="postcss">
.feedback {
    @apply bg-midnight-blue text-white p-4 trim-content flex flex-col justify-center lg:flex-row lg:justify-between lg:items-center;

    &__title {
        @apply text-spaced w-auto text-center mb-4 lg:m-0 lg:text-sm lg:mx-2;
    }

    &__thumbs {
        @apply flex justify-center;

        &__thumb {
            @apply w-10 h-10 text-white fill-current bg-white bg-opacity-40 rounded-full mx-2;

            &:hover {
                @apply bg-opacity-60;
            }

            &--down {
                @apply transform rotate-180;
            }
        }
    }
}
</style>
