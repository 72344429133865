export default {
    type: 'programme-area-theme-timeline-entries',
    aliases: {
        camel: 'programmeAreaThemeTimelineEntries',
        singular: 'Programme area theme timeline entry',
        plural: 'Programme area theme timeline entries',
        lowerSingular: 'programme area theme timeline entry',
        lowerPlural: 'programme area theme timeline entries',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
