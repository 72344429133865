import {computed, inject} from "vue";
import Kernel from "@oilstone/kernel";
import {Schema} from "@oilstone/rest-model-repository";
import Scope from "@/services/data-layer/store/scope";
import Cache from "./cache";

const cache = new Cache();

const boot = (schema, scope, uuid) => {
    cache.put(
        uuid,
        () => {
            const store = Kernel.resolve('vuexStore');

            const mapProps = (schema, scope) => {
                const getters = {};
                const props = schema.getProps()

                for (let name in props) {
                    let value = props[name].getValue();

                    if (value instanceof Schema) {
                        getters[name] = mapProps(value, new Scope().inherit(scope).key(name));
                        continue;
                    }

                    getters[name] = computed(() => {
                        return store.getters[scope.path().to(name)](uuid)
                    });
                }

                return getters;
            };

            return {
                resolve: path => {
                    return computed(() => store.getters[scope.path().to(path)](uuid));
                },
                item: uuid => {
                    return computed(() => store.getters[scope.path().to('item')](uuid));
                },
                exists: uuid => {
                    return computed(() => store.getters[scope.path().to('exists')](uuid));
                },
                items: computed(() => store.getters[scope.path().to('items')]),
                ...mapProps(schema, scope)
            };
        }
    );
}

const resolve = (uuid) => {
    return cache.resolve(uuid || inject('uuid'));
};

const forget = () => {
    cache.clear();
};

export {boot, resolve, forget};
export default resolve;
