<template>
    <div class="paginated-list">
        <VueTableLite
            :is-static-mode="true"
            :columns="columns"
            :rows="items"
            :total="items.length"
            :sortable="initialSort"
            :messages="table.messages"
        />
    </div>
</template>

<script>
import VueTableLite from 'vue3-table-lite'

export default {
    components: {
        VueTableLite,
    },

    props: {
        items: {
            type: Array,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        initialSort: {
            type: Object,
            required: false,
            default: () => {
                return {
                    order: null,
                    sort: "asc",
                };
            }
        },
    },

    data() {
        return {
            table: {
                messages: {
                    pagingInfo: "Showing {0}-{1} of {2}",
                    pageSizeChangeLabel: "Showing:",
                    gotoPageLabel: "Go to page:",
                    noDataAvailable: "No data",
                },
            }
        }
    }
}
</script>
