export default {
    type: 'paginated-posters',
    name: 'Paginated Carousel',
    osOnly: false,
    components: {
        root: {
            name: 'ModulePaginatedPosters',
            loader: () => import('./ModulePaginatedPosters')
        }
    }
};
