import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .sluggable()
    .hasTitle()
    .hasSummary()
    .hasBody()
    .hasMetadata()
    .publishable()
    .publishableByDate()
    .hasTimestamps()
    .make();

schema.prop('reference').setType(String).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('showApply').setType(Boolean).setValue(false).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('includeStatement').setType(Boolean).setValue(false).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('closingAt').setType(String).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('useMinimalFooter').setType(Boolean).setValue(true).addMetadata('mergeable', true).addMetadata('draftable', true);

export default schema;
