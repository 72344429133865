export default {
    type: 'partners',
    aliases: {
        camel: 'partners',
        singular: 'Partner',
        plural: 'Partners',
        lowerSingular: 'partner',
        lowerPlural: 'partners',
    },
    publishable: true,
    adminSection: 'administration',
    componentType: 'base',
    filterOn: {
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerPartnerRecord',
            loader: () => import('./AdministerPartnerRecord')
        },
    }
};
