import Factory from '../factory';

const schema = Factory.schema('alumni-network');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('researchers').setType(Array)
attributes.prop('staff').setType(Array)
attributes.prop('board').setType(Array)

export default schema;
