export default {
    type: 'faqs',
    name: 'FAQs',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleFAQs',
            loader: () => import('./ModuleFAQs')
        }
    }
};
