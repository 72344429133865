import {computed, ref} from 'vue';
import Cache from "@/services/data-layer/composers/cache";
import KeyValue from "@/services/lookup/key-value";
import Context from "@/services/context";

const cache = new Cache();
const cacheKey = (category, title) => Context.getLanguage() + '-' + category + '-' + title;

const boot = (category, title) => {
    cache.put(
        cacheKey(category, title),
        () => {
            let keyValue = ref(null);

            KeyValue.show(category, title).then(resolved => {
                keyValue.value = resolved;
            });

            return computed(() => {
                return keyValue.value;
            });
        }
    );
};

const resolve = (category, title) => {
    if (!cache.has(cacheKey(category, title))) {
        boot(category, title);
    }

    return cache.resolve(cacheKey(category, title));
};

export {boot, resolve};
export default resolve;
