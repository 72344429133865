import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .make();

schema.prop('path').setType(String);
schema.prop('redirectTo').setType(String);

export default schema;
