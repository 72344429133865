import dayjs from 'dayjs';

class DateFormatter {
    static format(input) {
        if (!input) {
            return '';
        }

        if (!dayjs.isDayjs(input)) {
            input = dayjs(input);
        }

        return input.format('D MMM YYYY');
    }

    static range(start, end) {
        if (!end) {
            return DateFormatter.format(start);
        }

        start = dayjs(start);
        end = dayjs(end);

        if (start.isSame(end)) {
            return DateFormatter.format(start);
        }

        if (start.isSame(end, 'month')) {
            return `${start.format('D')} - ${DateFormatter.format(end)}`;
        }

        if (start.isSame(end, 'year')) {
            return `${start.format('D MMM')} - ${DateFormatter.format(end)}`;
        }

        return `${DateFormatter.format(start)} - ${DateFormatter.format(end)}`;
    }

    static datetime(input) {
        return dayjs(input).format('HH:mm, D MMM YYYY');
    }

    static year(input) {
        return dayjs(input).format('YYYY');
    }
}

export default DateFormatter;
