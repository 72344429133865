import Kernel from "@oilstone/kernel";
import {RestModel} from "@oilstone/rest-model";
import Events from "@oilstone/events";

class Context {
    static language = 'en';
    static masterRecord = null;
    static relatedMasterRecord = null;
    static #dispatcher;

    static getDispatcher() {
        if (Context.#dispatcher) {
            return Context.#dispatcher;
        }

        return Context.#dispatcher = new Events();
    }

    static listen(listeners) {
        Context.getDispatcher().listen(listeners);
    }

    static setLanguage(language) {
        if (!language) {
            return;
        }

        const whitelist = Kernel.resolve('languages').map(language => {
            return language.code;
        });

        if (whitelist.indexOf(language) !== -1) {
            Context.language = language;
            RestModel.setBaseUrl(`${process.env.VUE_APP_API_URL}/${language}`);
            Context.getDispatcher().fire('contextlanguageset');
        }
    }

    static getLanguage() {
        return Context.language;
    }

    static setMasterRecord(record) {
        Context.masterRecord = record;

        Context.getDispatcher().fire('contextmasterset');

        return Context;
    }

    static getMasterRecord() {
        return Context.masterRecord;
    }

    static setRelatedMasterRecord(record) {
        Context.relatedMasterRecord = record;

        Context.getDispatcher().fire('contextrelatedmasterset');

        return Context;
    }

    static clearRelatedMasterRecord() {
        return Context.setRelatedMasterRecord(null);
    }

    static getRelatedMasterRecord() {
        return Context.relatedMasterRecord;
    }
}

export default Context;
