<template>
    <div
        v-if="message"
        class="alert-banner"
        :class="classes"
    >
        <IconRenderer
            name="Attention"
            class="alert-banner__icon"
        />

        <span v-html="message"></span>
    </div>
</template>

<script>
export default {
    props: {
        message: String,
        variant: {
            type: String,
            default: 'info'
        }
    },

    computed: {
        classes() {
            return [`alert-banner--${this.variant}`]
        },
    }
}
</script>

<style lang="postcss">
.alert-banner {
    @apply fixed top-0 left-0 z-50 w-full flex justify-center items-center px-6 py-4 text-sm;

    &--error {
        @apply bg-azalea;

        .alert-banner__icon {
            @apply text-red;
        }
    }

    &--info {
        @apply bg-big-stone text-white;

        .alert-banner__icon {
            @apply text-blue-ribbon;
        }
    }

    &__icon {
        @apply w-6 mr-2 flex-shrink-0 fill-current;
    }
}
</style>
