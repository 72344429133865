export default {
    type: 'video-keywords',
    aliases: {
        camel: 'videoKeywords',
        singular: 'Video keyword',
        plural: 'Video keywords',
        lowerSingular: 'video keyword',
        lowerPlural: 'video keywords',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
