export default {
    type: 'decades',
    aliases: {
        camel: 'decades',
        singular: 'Decade',
        plural: 'Decades',
        lowerSingular: 'decade',
        lowerPlural: 'decades',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'base',
    components: {
        adminRecord: {
            name: 'AdministerDecadeRecord',
            loader: () => import('./AdministerDecadeRecord')
        },
    }
};
