<template>
    <span class="lookup lookup--country">{{ resolved }}</span>
</template>

<script>
import {resolveCountry} from "@/services/lookup/composers";

export default {
    setup(props) {
        return {
            resolved: resolveCountry(props.id),
        }
    },

    props: {
        id: {
            type: String,
            required: true,
        }
    },
}
</script>
