import Factory from '../factory';

const schema = Factory.schema('image');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('src').setType(String).setValue('image-placeholder.svg');
attributes.prop('alt').setType(String);
attributes.prop('credit').setType(String);
attributes.prop('width').setType(String).setValue('full');
attributes.prop('disableSave').setType(Boolean).setValue(false);

export default schema;
