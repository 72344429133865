<template>
    <AdminFormGroup
        class="admin-checkbox"
        :class="classes"
    >
        <FormLabel
            :text="label"
            class="form-control"
        >
            <div class="admin-checkbox__input">
                <BaseCheckbox
                    v-bind="baseComponentBindings"
                    @update:model-value="update"
                    class="form-control__input"
                />
            </div>
            <FormHelp :text="helpText"/>
        </FormLabel>
        <FormError :text="error"/>
    </AdminFormGroup>
</template>

<script>
import formCheckboxComposable from "@/composables/forms/form-checkbox";

export default {
    mixins: [{
        props: formCheckboxComposable.props()
    }],

    setup(props, {emit}) {
        return {
            ...formCheckboxComposable.composables(props, emit)
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],
}
</script>

<style lang="postcss">
.admin-checkbox {
    &__input {
        @apply order-1 mr-3;
    }

    .form-control {
        @apply flex;
    }

    .form-label__text {
        @apply order-2 mt-1.5;
    }
}
</style>
