import BaseRepository from "@/services/resources/repository";
import byVideo from "@/services/resources/mixins/query-by-video";
import byKeyword from "@/services/resources/mixins/query-by-keyword";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byVideo).$mix(byKeyword);
    }
}

export default Repository;
