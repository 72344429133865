import Factory from './factory';

export default new Factory()
	.addProp('pattern', String)
	.addProp('maxLength', Number)
	.addProp('autofill', String)
	.addProp('autofocus', Boolean)
	.addProp('placeholder', String)
	.addPassthrough('pattern')
	.addPassthrough('maxLength')
	.addPassthrough('autofill')
	.addPassthrough('autofocus')
	.addPassthrough('placeholder')
