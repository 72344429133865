<template>
    <div
        v-show="visible && loaded"
        class="module"
        :class="classes"
        @click="onClick"
        @dblclick="onDblclick"
    >
        <component
            :is="component"
            @loaded="ready"
        />
        <div
            v-if="blockInput"
            class="module__input-blocker"
        />
    </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { inject, provide } from "vue";
import Kernel from "@oilstone/kernel";
import { accessesData, boot, hasEvents, hydratesData, module, mutatesData, persistsData } from "../composers";
import Factory from "../factory";

export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        id: {
            type: [String, Number],
            required: false,
            default: null,
        },
        resource: {
            type: Object,
            required: false,
            default: null,
        },
        sort: {
            type: Number,
            required: false,
            default: null,
        },
        pointerEvents: {
            type: Boolean,
            default: true,
        },
    },

    setup(props) {
        const app = Kernel.resolve("context").app;
        const settings = Kernel.resolve("moduleRegistry").find(props.type);
        const uuid = uuidv4();
        const scope = Factory.scope(settings.config);

        boot(settings.schema, scope, uuid, inject("parentUuid", null));
        provide("uuid", uuid);
        provide("parentUuid", uuid);
        provide("resource", {
            type: inject("type", null),
            uuid: inject("uuid", null),
        });

        const { focussed, enableFocus, disableFocus, enableEditing } = module(uuid);
        const { id: moduleId, parentId, item } = accessesData(uuid);
        const { listen, fire } = hasEvents(uuid);
        const { load, hydrate } = hydratesData(uuid);
        const { make, setSort } = mutatesData(uuid);
        const { overwrite, destroy } = persistsData(uuid);

        return {
            app,
            listen,
            fire,
            uuid,
            item,
            moduleId,
            parentId,
            focussed,
            enableFocus,
            disableFocus,
            enableEditing,
            load,
            make,
            hydrate,
            setSort,
            saveRecord: overwrite,
            destroy,
            component: settings.config.components.root.name,
        };
    },

    emits: ['remove'],

    data() {
        return {
            removed: false,
            loaded: false,
        };
    },

    watch: {
        id() {
            this.load(this.id);
        },

        sort() {
            this.applySort();
        },
    },

    computed: {
        classes() {
            const classes = [`module-${this.type}`];

            if (this.app === "cms") {
                classes.push("module--editable");

                if (this.pointerEvents) {
                    classes.push("module--pointer-events-enabled");
                }
            }

            if (this.focussed) {
                classes.push("module--focussed");
            }

            return classes;
        },

        blockInput() {
            return this.app === "cms";
        },

        isNew() {
            return !this.moduleId;
        },

        visible() {
            return this.isNew || this.type === "container" || this.parentId !== null;
        },
    },

    methods: {
        ready() {
            if (this.resource) {
                this.hydrate(this.resource);

                if (!this.visible) {
                    this.removed = true;
                }

                this.loaded = true;
                return;
            }

            this.make();

            if (this.id) {
                this.load(this.id);

                if (!this.visible) {
                    this.removed = true;
                }

                this.loaded = true;
                return;
            } else {
                this.fire("madenew");
            }

            this.loaded = true;
            this.applySort();
        },

        applySort() {
            this.setSort(this.sort);
        },

        onClick(e) {
            if (this.app === "cms") {
                if (this.pointerEvents) {
                    this.enableFocus(e);
                }
            }
        },

        onDblclick(e) {
            if (this.app === "cms") {
                if (this.pointerEvents) {
                    this.enableEditing(e);
                }
            }
        },

        save(payload, append = {}) {
            const parentId = parseInt(payload.item.id);
            const module = Object.assign({}, this.item(this.uuid).value || {}, {
                parentId,
                draft: {
                    parentId,
                },
            });

            if (this.removed && !this.isNew && append.isPublished) {
                this.destroy();
                return;
            }

            if (this.removed && !this.isNew) {
                module.draft.parentId = null;
            }

            if (!this.isNew && !append.isPublished) {
                module.draft.attributes = Object.assign({}, module.attributes);
                module.draft.sort = module.sort;
                module.draft.position = module.position;

                delete module.attributes;
                delete module.sort;
                delete module.position;
            }

            if (append.isPublished) {
                module.isPublished = true;
                module.draft = null;
            }

            this.saveRecord(module);
        },
    },

    mounted() {
        this.listen({
            parentmoduleSaved: payload => {
                this.save(payload);
            },

            parentmodulePublished: payload => {
                this.save(payload, {
                    isPublished: true,
                });
            },
        });

        window.addEventListener("keyup", e => {
            if (!this.focussed) {
                return false;
            }

            if (e.key === "Delete" || e.key === "Backspace") {
                this.removed = true;
                this.$emit("remove");
            }
        });
    },
};
</script>

<style lang="postcss">
.module {
    @apply relative;
    outline-offset: 5px;

    &--pointer-events-enabled {
        &:hover {
            @apply cursor-pointer;
            outline: dashed 1px cornflowerblue;
        }

        .module__input-blocker {
            @apply absolute top-0 left-0 w-full h-full;
            z-index: 999;
        }
    }

    &--focussed {
        outline: solid 1px cornflowerblue !important;
    }
}
</style>
