<template>
    <svg
        class=" fill-current"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
    ><path
        d="M21,33.958a8,8,0,1,1,8-8A8.009,8.009,0,0,1,21,33.958Zm0-14a6,6,0,1,0,6,6A6.006,6.006,0,0,0,21,19.958Z"
        transform="translate(-12.999 -17.958)"
    /><polygon points="5.879 11.538 4.465 10.124 7.001 7.588 7 4.042 9 4.042 9.001 8.416 5.879 11.538" /></svg>
</template>
