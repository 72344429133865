import Factory from '../factory';

const schema = Factory.schema('newsletter-signup');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('consentCheckboxLabel').setType(String).setValue('I would like to receive informational communications from UNRISD.');
attributes.prop('consentText').setType(String).setValue('By ticking the subscribe box you give consent for UNRISD to send you informational email communications, including newsletters. We will not send marketing ads or anything you have not authorized us to do.');
attributes.prop('buttonText').setType(String).setValue('Subscribe');
attributes.prop('formSubmittedText').setType(String).setValue('Thank you for your submission');

export default schema;
