import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .sluggable()
    .hasSummary()
    .hasLocation()
    .hasImage()
    .hasYearRange()
    .hasLede()
    .hasBody()
    .hasMetadata()
    .publishable()
    .publishableByDate()
    .hasLotusNotesId()
    .hasTimestamps()
    .make();

schema.prop('firstName').setType(String).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('lastName').setType(String).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('jobTitleId').setType(Number).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('jobGroupId').setType(Number).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('networkRelationshipId').setType(Number).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('email').setType(String).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('showPersonPage').setType(Boolean).setValue(false).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('linkedinProfile').setType(String).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('sex').setType(String).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('projectKeywords').setType(String).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);

export default schema;
