export default {
    type: 'publication-modules',
    aliases: {
        camel: 'publicationModules',
        singular: 'Publication module',
        plural: 'Publication modules',
        lowerSingular: 'publication module',
        lowerPlural: 'publication modules',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
