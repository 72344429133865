<template>
    <div
        v-if="(loading || fetching)"
        class="asset-library__loading"
    >
        <span>Fetching assets</span>
        <div class="asset-library__loading__loading-spinner">
            <div class="asset-library__loading__loading-spinner__ring">
                <div class="asset-library__loading__loading-spinner__ring__inner"></div>
            </div>
        </div>
    </div>

    <div
        v-if="assets"
        class="asset-library__results"
        :class="(loading || fetching ? 'asset-library__results--loading' : '')"
    >
        <PosterBack v-if="currentPath !== ''" />

        <template v-if="assets['folders']">
            <Poster
                v-for="(row, k) in assets['folders']"
                v-bind:key="'folder'+k"
                :data="row"
                lot="folder"
            />
        </template>

        <template v-if="assets['files']">
            <Poster
                v-for="(row, k) in assets['files']"
                v-bind:key="'file'+k"
                :data="row"
                lot="file"
                @deleted="deleted"
                @selected="selected"
            />
        </template>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import PosterBack from './partials/stubs/PosterBack';
import Poster from "./partials/stubs/Poster";

export default {
    components: {
        Poster,
        PosterBack,
    },

    props: ['loading'],

    emits: ['deleted', 'selected'],

    computed: {
        ...mapGetters('asset', {
            currentPath: 'currentPath',
            assets: 'assets',
            fetching: 'fetching',
        }),

        watchPath() {
            if (this.currentPath) {
                return this.currentPath;
            } else {
                return '';
            }
        },

    },

    methods: {
        ...mapMutations('asset', {
            setCurrentPath: 'setCurrentPath',
        }),

        deleted(id) {
            this.$emit('deleted', id);
        },

        selected(val){
            this.$emit('selected', val);
        },
    },
}
</script>

<style lang="postcss">
.asset-library__loading {
    @apply fixed flex flex-col justify-center items-center;
    width: 280px;
    height: 100px;
    left: calc(50% - 140px);
    top: calc(50% - 50px);

    span {
        @apply inline-block font-sofia-pro font-bold mb-4 p-0 text-2xl text-purple-1;
    }

    &__loading-spinner {
        @apply relative inline-block;
        width: 110px;
        height: 110px;

        &__ring {
            @apply relative;
            width: 110px !important;
            height: 110px !important;

            &__inner,
            &__inner:after {
                @apply absolute border-4 border-purple-1 rounded-full;
                width: 104px;
                height: 104px;
                border-top-color: transparent;
            }

            &__inner {
                -webkit-animation: spinning 1s linear infinite;
                animation: spinning 1s linear infinite;
                top: 55px;
                left: 55px;

                &:after {
                    @apply transform rotate-90;
                }
            }
        }
    }
}

.asset-library__results {
    @apply relative px-4 m-0 flex flex-row flex-wrap overflow-x-hidden overflow-y-auto w-full h-auto;
    max-height: calc(100% - 125px);

    &--loading {
        @apply opacity-50;
    }
}

@keyframes spinning {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@-webkit-keyframes spinning {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
</style>
