import Factory from "@/schemas/factory";
import {Schema, Collection} from "@oilstone/rest-model-repository";

const navigationItem = () => {
    return new Factory()
        .hasId()
        .hasTitle()
        .hasSection()
        .translatable()
        .sluggable()
        .make()
}

const schema = navigationItem();
const subSchema = navigationItem();
const subNavAttributesSchema = new Schema();
const subNavOptionsSchema = new Factory().hasId().hasTitle().translatable().sluggable().make();

subNavAttributesSchema.prop('subNavigationOption').setType(String);

subSchema.prop('attributes').setType(Schema).setValue(subNavAttributesSchema);

subSchema.prop('subNavigationOptions').setType(Collection).setValue(new Collection(subNavOptionsSchema));

schema.prop('subNavigationItems').setType(Collection).setValue(new Collection(subSchema));

export default schema;
