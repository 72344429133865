class Path {
    #scope;
    #delimiter = '.'
    #pieces = [];

    constructor(scope) {
        this.#scope = scope;
        this.#resolve();
    }

    get pieces() {
        return this.getPieces();
    }

    getPieces() {
        return this.#pieces;
    }

    #resolve() {
        let pieces = [];
        const ancestor = this.#scope.getAncestor();
        const key = this.#scope.getKey();

        if (ancestor) {
            pieces = pieces.concat(ancestor.path().getPieces());
        }

        if (key) {
            pieces.push(key)
        }

        this.#pieces = pieces;

        return this;
    }

    to(prop) {
        return this.#scope.getPrefix() + [].concat(this.#pieces).concat([prop]).join(this.#delimiter);
    }
}

export default Path;
