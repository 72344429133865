import {Provider} from '@oilstone/container';
import {createApp, defineAsyncComponent} from "vue";
import App from '@/App.vue';
import Currency from "@/utilities/number/currency";
import StringFormatter from "@/utilities/string/formatter";
import NumberFormatter from "@/utilities/number/formatter";
import DateFormatter from "@/utilities/date/formatter";
import Country from "@/services/lookup/country";
import Translation from "@/services/lookup/translation";
import KeyValue from "@/services/lookup/key-value";
import VueGtag from "vue-gtag-next";
import { VueReCaptcha } from "vue-recaptcha-v3";
import InstantSearch from 'vue-instantsearch/vue3/es';

export default new Provider().boot(container => {
    const store = container.resolve('vuexStore');
    const router = container.resolve('router');
    const gtm = container.resolve('gtm');
    const resourceRegistry = container.resolve('resourceRegistry');
    const moduleRegistry = container.resolve('moduleRegistry');
    const registered = [];

    const autoload = [
        require.context('../shared/assets/logos', true, /\.vue$/),
        require.context('../shared/base', true, /\.vue$/),
        require.context('../shared/layout', true, /\.vue$/),
        require.context('../shared/ui', true, /\.vue$/),
        require.context('../shared/modules/ui', true, /\.vue$/),
        require.context('../services/resources/ui', true, /\.vue$/),
        require.context('../services/navigation/ui', true, /\.vue$/),
        require.context('../services/lookup/ui', true, /\.vue$/),
        require.context('../apps/cms/forms', true, /\.vue$/),
        require.context('../apps/cms/layout', true, /\.vue$/),
    ];

    const app = createApp(App).use(router).use(store).use(InstantSearch).use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });

    if(window.location.href.toLowerCase().indexOf('/admin') < 0) {
        if (gtm) {
            app.use(gtm);
        }

        app.use(VueGtag, {
            property: {
                isEnabled: false,
                id: process.env.VUE_APP_GTM_ID,
            }
        }, router);
    }

    store.dispatch('cookie/hydrate');

    autoload.forEach(context => {
        context.keys().forEach(filename => {
            const componentConfig = context(filename);
            const componentName = filename.split('/').pop().replace(/\.\w+$/, '');

            if (!registered.includes(componentName)) {
                app.component(componentName, componentConfig.default || componentConfig)
            }

            registered.push(componentName);
        });
    });

    resourceRegistry.all().forEach(resource => {
        for (let component in resource.config.components) {
            const componentConfig = resource.config.components[component];

            if (!registered.includes(componentConfig.name)) {
                app.component(componentConfig.name, defineAsyncComponent(componentConfig.loader));
            }

            registered.push(componentConfig.name);
        }
    });

    moduleRegistry.all().forEach(module => {
        const componentConfig = module.config.components.root;

        if (!registered.includes(componentConfig.name)) {
            app.component(componentConfig.name, defineAsyncComponent(componentConfig.loader));
        }

        registered.push(componentConfig.name);
    });

    app.config.globalProperties.$filters = {
        currency: Currency,
        string: StringFormatter,
        number: NumberFormatter,
        date: DateFormatter,
        country: Country,
        translation: Translation,
        keyValue: KeyValue,
    };

    app.mount('#app');
});
