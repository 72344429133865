export default {
    type: 'job-titles',
    aliases: {
        camel: 'jobTitles',
        singular: 'UNRISD job title',
        plural: 'UNRISD job titles',
        lowerSingular: 'job title',
        lowerPlural: 'job titles',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'lookup',
    components: {},
};
