export default {
    type: 'pages',
    aliases: {
        camel: 'pages',
        singular: 'Page',
        plural: 'Pages',
        lowerSingular: 'page',
        lowerPlural: 'pages',
    },
    relations: {
        'modules': {
            pivot: {
                type: 'page-modules',
                localKey: 'pageId',
                foreignKey: 'moduleId'
            }
        },
    },
    publishable: true,
    adminSection: null,
    componentType: 'base',
    filterOn: {
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPageCollection',
            loader: () => import('./AdministerPageCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerPageRecord',
            loader: () => import('./AdministerPageRecord')
        },
        adminTranslation: {
            name: 'AdministerPageTranslations',
            loader: () => import('./AdministerPageTranslations')
        },
        webRecord: {
            name: 'DisplayPageRecord',
            loader: () => import('./DisplayPageRecord')
        },
        auxCategorisedCollection: {
            name: 'AdministerAuxPageCollection',
            loader: () => import('./AdministerAuxPageCollection')
        },
    }
};
