import Factory from "@/schemas/factory";

const schema = new Factory()
    .make();

schema.prop('id').setType(String).primaryKey();
schema.prop('path').setType(String);
schema.prop('file').setType(String).setValue(null);
schema.prop('fileName').setType(String).setValue(null);

export default schema;
