<template>
    <div
        class="tool-tip"
        :class="position"
    >
        <div class="tool-tip__helper">
            <IconRenderer
                :name="attentionIcon"
                class="tool-tip__helper__icon"
                @click.stop="toggleToolTip"
            />

            <div
                v-if="open"
                class="tool-tip__helper__arrow"
            />
        </div>

        <div
            v-if="open"
            class="tool-tip__popup"
        >
            <div
                class="tool-tip__popup__container"
                :class="toolTipSize"
            >
                <IconRenderer
                    name="Cross"
                    class="tool-tip__popup__container__icon"
                    @click.stop="toggleToolTip"
                />
                <div
                    class="tool-tip__popup__container__text"
                    v-html="text"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: null,
        },

        position: {
            type: String,
            default: 'relative',
        },

        toolTipSize: {
            type: String,
            default: null,
        }
    },

    data() {
        return {
            open: false
        }
    },

    computed: {
        attentionIcon() {
            return this.open ? 'AttentionOver' : 'AttentionOutline'
        }
    },

    methods: {
        onResize() {
            if (window.innerWidth <= 1023) {
                this.$nextTick(() => {
                    let tt = window.document.querySelector('.tooltip');
                    if (tt) {
                        tt.style.left = "-" + (window.document.querySelector('.tool-tip').offsetLeft - 12) + "px";
                    }
                })
            }
        },

        toggleToolTip() {
            this.open = !this.open;
            this.onResize();
        }
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
}
</script>

<style lang="postcss">
.tool-tip {
    @apply inline;

    &__helper {
        @apply flex flex-col justify-items-center ml-2 mt-1;

        &__icon {
            @apply w-4 cursor-pointer h-auto;
        }

        &__arrow {
            @apply bg-big-stone w-2 h-2 transform rotate-45 mt-5 ml-1 z-20 absolute;
        }
    }

    &__popup {
        @apply absolute;

        &__container {
            @apply z-20 mt-2 rounded-md border-big-stone bg-big-stone text-white shadow;

            &__icon {
                @apply w-3 ml-auto fill-current cursor-pointer float-right mt-3 mr-3 h-auto;
            }

            &__text {
                @apply text-xs pl-4 pr-8 py-3 w-full;
            }
        }
    }
}
</style>
