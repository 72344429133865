<template>
    <div class="filter-collection">
        <hr>

        <div class="filter-collection__header">
            <div>
                <p>
                    <strong>{{ filterByTranslation }}:</strong>
                </p>
            </div>
            <FormBtn
                v-if="showClear"
                variant="cms"
                class="filter-collection__header__clear"
                @click="clear"
            >
                {{ showAllTranslation }}
            </FormBtn>
        </div>

        <GridContainer v-if="Object.keys(filterOn).length">
            <GridColumn
                v-for="filter in Object.keys(filterOn).filter(filter => filter !== 'other')"
                :key="filter"
                class="col-span-12 lg:col-span-4"
            >
                <FilterSelect
                    :model-value="innerFilters[filterOn[filter].foreignKey]"
                    :relation="filter"
                    :method="filterOn[filter].method || null"
                    @change="update(filterOn[filter].foreignKey, $event.target.value)"
                />
            </GridColumn>

            <GridColumn
                v-for="otherFilterType in filterOn['other'] || []"
                :key="otherFilterType"
                class="col-span-12 lg:col-span-4"
            >
                <AdminSelect
                    :model-value="innerFilters[otherFilterType]"
                    :options="getOtherFilterOptions(otherFilterType)"
                    class="resource-input"
                    @change="update(otherFilterType, $event.target.value)"
                />
            </GridColumn>
        </GridContainer>

        <div class="filter-collection__search">
            <FormInput
                v-model="innerSearchString"
                placeholder="Search"
                class="mb-10"
                @input="update('searchString', $event.target.value)"
            />
        </div>

        <hr>
    </div>
</template>

<script>
import { inject } from "vue";
import Kernel from "@oilstone/kernel";
import Translation from "@/services/lookup/translation";

export default {
    setup() {
        const type = inject("type");
        const settings = Kernel.resolve("resourceRegistry").find(type);

        return {
            filterOn: settings.config.filterOn || {},
        };
    },

    props: ["searchString", "filters"],

    emits: ['update:searchString', 'update:filters'],

    data() {
        return {
            innerSearchString: this.searchString,
            innerFilters: this.filters,
            filterByTranslation: "",
            showAllTranslation: "",
        };
    },

    computed: {
        showClear() {
            return !!this.innerSearchString || Object.keys(this.innerFilters).some(filter => this.innerFilters[filter]);
        },
    },

    methods: {
        clear() {
            this.innerSearchString = "";

            for (const filterKey in this.innerFilters) {
                this.innerFilters[filterKey] = "";
            }

            this.update();
        },

        update(filterType, value) {
            if(filterType === 'searchString') {
                this.innerSearchString = value;
                this.$emit("update:searchString", this.innerSearchString);
                return;
            }

            this.innerFilters[filterType] = value;

            this.$emit("update:filters", this.innerFilters);
        },

        getOtherFilterOptions(filter) {
            switch (filter) {
                case "publishYear":
                    return [{ label: " - Year", value: "" }].concat(
                        [...Array(2030 - 1950 + 1).keys()]
                            .map(x => x + 1950)
                            .reverse()
                            .map(year => {
                                return {
                                    label: year,
                                    value: year,
                                };
                            })
                    );

                case "unpublishedChanges":
                    return [
                        {
                            label: " - Unpublished changes",
                            value: "",
                        },
                        {
                            label: "Yes",
                            value: "1",
                        },
                        {
                            label: "No",
                            value: "0",
                        },
                    ];

                case "isPublished":
                    return [
                        {
                            label: " - Status",
                            value: "",
                        },
                        {
                            label: "Published",
                            value: "1",
                        },
                        {
                            label: "Draft",
                            value: "0",
                        },
                    ];
            }
        },
    },

    mounted() {
        Translation.many(["filter-by", "show-all"]).then(translations => {
            if (translations) {
                this.filterByTranslation = translations["filter-by"];
                this.showAllTranslation = translations["show-all"];
            }
        });
    },

    watch: {
        searchString() {
            this.innerSearchString = this.searchString;
        },

        filters() {
            this.innerFilters = this.filters;
        },
    },
};
</script>

<style lang="postcss">
.filter-collection {
    &__header {
        @apply flex justify-between mb-4;
    }
}
</style>
