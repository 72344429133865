<template>
    <img
        :src="resolvedSrc"
        :alt="alt"
        class="base-img"
    />
</template>

<script>
export default {
    props: {
        src: String,
        alt: String,
        resolve: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        resolvedSrc() {
            if (!this.src) {
                return '';
            }

            if (!this.resolve) {
                return this.src;
            }

            return require(`../../assets/images/${this.src}`);
        }
    }
}
</script>

<style lang="postcss">
.base-img {
    @apply block w-full;
}
</style>
