<template>
    <div>
        <input
            type="color"
            class="base-colour"
            :list="id"
            :value="modelValue"
            @input="update($event.target.value)"
        >
        <datalist :id="id">
            <option
                v-for="(option, index) in options"
                :key="index"
                :value="option"
            />
        </datalist>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: [String, Number, null],
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ["update:modelValue"],

    computed: {
        id() {
            return `base-colour-${this._.uid}-options`;
        }
    },

    methods: {
        update(value) {
            this.$emit("update:modelValue", value);
        },
    },

    mounted() {
        console.log('mounted colour picker', this);
    },
};
</script>

<style lang="postcss">
.base-select {
    @apply block w-full outline-none text-big-stone border border-swiss-coffee p-3.5 cursor-pointer bg-white;
}
</style>
