export default {
    type: 'site-search',
    name: 'Site Search',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleSiteSearch',
            loader: () => import('./ModuleSiteSearch')
        }
    }
};
