export default {
    type: 'programme-areas',
    aliases: {
        camel: 'programmeAreas',
        singular: 'Programme area',
        plural: 'Programme areas',
        lowerSingular: 'programme area',
        lowerPlural: 'programme areas',
    },
    relations: {
        'events': {
            adminLabel: "Promoted activities",
            pivot: {
                type: 'event-programme-areas',
                localKey: 'programmeAreaId',
                foreignKey: 'eventId'
            }
        },
        'news-items': {
            adminLabel: 'News stories',
            pivot: {
                type: 'news-item-programme-areas',
                localKey: 'programmeAreaId',
                foreignKey: 'newsItemId'
            }
        },
        'keywords': {
            adminLabel: "Keywords",
            pivot: {
                type: 'programme-area-keywords',
                localKey: 'programmeAreaId',
                foreignKey: 'keywordId'
            }
        },
        'modules': {
            pivot: {
                type: 'programme-area-modules',
                localKey: 'programmeAreaId',
                foreignKey: 'moduleId'
            }
        },
        'people': [
            {
                adminLabel: "Research team members",
                pivot: {
                    type: 'programme-area-people',
                    localKey: 'programmeAreaId',
                    foreignKey: 'personId',
                    filters: [
                        collection => {
                            return collection.filter(person => {
                                return person.association !== 'other';
                            })
                        },
                    ],
                    attributes: {
                        association: 'research-team'
                    }
                }
            },
            {
                adminLabel: 'Other Participants',
                pivot: {
                    type: 'programme-area-people',
                    localKey: 'programmeAreaId',
                    foreignKey: 'personId',
                    filters: [
                        collection => {
                            return collection.filter(person => {
                                return person.association === 'other';
                            })
                        },
                    ],
                    attributes: {
                        association: 'other'
                    }
                }
            },
        ],
        'theme-timeline-entries': {
            pivot: {
                type: 'programme-area-theme-timeline-entries',
                localKey: 'programmeAreaId',
                foreignKey: 'themeTimelineEntryId'
            }
        },
        'projects': {
            adminLabel: "Promoted projects",
            pivot: {
                type: 'project-programme-areas',
                localKey: 'programmeAreaId',
                foreignKey: 'projectId'
            }
        },
        'publications': {
            adminLabel: "Promoted publications",
            pivot: {
                type: 'publication-programme-areas',
                localKey: 'programmeAreaId',
                foreignKey: 'publicationId'
            }
        },
        'themes': {
            adminLabel: "Themes",
            pivot: {
                type: 'programme-area-themes',
                localKey: 'programmeAreaId',
                foreignKey: 'themeId'
            }
        },
    },
    publishable: true,
    adminSection: 'research',
    componentType: 'base',
    filterOn: {
        'decades': {
            foreignKey: 'decadeId',
        },
        'themes': {
            foreignKey: 'themeId',
            in: 'programmeAreaThemes',
        },
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerProgrammeAreaRecord',
            loader: () => import('./AdministerProgrammeAreaRecord')
        },
        adminTranslation: {
            name: 'AdministerProgrammeAreaTranslation',
            loader: () => import('./AdministerProgrammeAreaTranslation')
        },
        webRecord: {
            name: 'DisplayProgrammeAreaRecord',
            loader: () => import('./DisplayProgrammeAreaRecord')
        }
    },
};
