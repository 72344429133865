export default {
    type: 'project-programme-areas',
    aliases: {
        camel: 'projectProgrammeAreas',
        singular: 'Project programme area',
        plural: 'Project programme areas',
        lowerSingular: 'project programme area',
        lowerPlural: 'project programme areas',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
