import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .hasTitle()
    .hasTimestamps()
    .make();

schema.prop('showOnWeb').setType(Boolean).addMetadata('mergeable', true);

export default schema;
