<template>
    <div v-if="item">
        <navigation-link
            :to="`/library${href}`"
            class="poster__img-container"
        >
            <PosterImg
                :content-type="contentType"
                :label="posterLabel"
                :item="item"
            />
        </navigation-link>

        <Card class="poster__card">
            <div class="flex-grow">
                <navigation-link :to="`/library${href}`">
                    <h2 class="poster__card__title">
                        <span v-html="item.title" />
                    </h2>
                </navigation-link>
            </div>

            <navigation-link
                :to="`/library${href}`"
                class="poster__card__discover"
            >
                {{ discoverStr }}
            </navigation-link>

            <slot name="share" />
        </Card>
    </div>
</template>

<script>
import Translation from "@/services/lookup/translation";

export default {
    props: {
        contentType: {
            type: String,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        href: {
            type: String,
            default: null,
        }
    },

    data(){
        return {
            discoverStr: 'Discover',
        }
    },

    computed: {
        posterLabel(){
            return 'Blog Series';
        },
    },

    mounted(){
        Translation.many(['discover']).then(translations => {
            if(translations){
                this.discoverStr = translations['discover'];
            }
        });
    }
}
</script>
