export default {
    type: 'news-item-people',
    aliases: {
        camel: 'newsItemPeople',
        singular: 'News item person',
        plural: 'News item people',
        lowerSingular: 'news item person',
        lowerPlural: 'news item people',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
