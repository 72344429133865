<template>
    <div
        class="collection"
        :class="classes"
    >
        <component
            :is="component"
            :items="resource"
        />
    </div>
</template>

<script>
import {provide} from "vue";
import component from "@/services/resources/component";
import {useRoute} from 'vue-router'

export default {
    props: {
        type: String,
        resource: Array
    },

    setup(props) {
        provide('type', props.type);

        const route = useRoute();

        return {
            component: component(props.type, route.query.view || 'adminCollection'),
        }
    },


    computed: {
        classes() {
            return [`collection-${this.type}`];
        },
    }
}
</script>
