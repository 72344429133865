export default {
    type: 'events',
    aliases: {
        camel: 'events',
        singular: 'Event',
        plural: 'Events',
        lowerSingular: 'event',
        lowerPlural: 'events',
    },
    relations: {
        'keywords': {
            adminLabel: 'Keywords',
            pivot: {
                type: 'event-keywords',
                localKey: 'eventId',
                foreignKey: 'keywordId'
            }
        },
        'modules': {
            pivot: {
                type: 'event-modules',
                localKey: 'eventId',
                foreignKey: 'moduleId'
            }
        },
        'programme-areas': {
            adminLabel: 'Programme areas',
            pivot: {
                type: 'event-programme-areas',
                localKey: 'eventId',
                foreignKey: 'programmeAreaId'
            }
        },
        'projects': {
            adminLabel: 'Projects',
            pivot: {
                type: 'event-projects',
                localKey: 'eventId',
                foreignKey: 'projectId'
            }
        },
        'news-items': {
            adminLabel: 'News stories',
            pivot: {
                type: 'news-item-events',
                localKey: 'eventId',
                foreignKey: 'newsItemId'
            }
        },
        'publications': {
            adminLabel: 'Publications',
            pivot: {
                type: 'publication-events',
                localKey: 'eventId',
                foreignKey: 'publicationId'
            }
        },
        'themes': {
            adminLabel: "Themes",
            pivot: {
                type: 'event-themes',
                localKey: 'eventId',
                foreignKey: 'themeId'
            }
        },
        'countries': {
            adminLabel: 'Countries',
            pivot: {
                type: 'event-countries',
                localKey: 'eventId',
                foreignKey: 'country'
            }
        },
        'people': [
            {
                adminLabel: 'Research coordinators',
                pivot: {
                    type: 'event-people',
                    localKey: 'eventId',
                    foreignKey: 'personId',
                    filters: [
                        collection => {
                            return collection.filter(person => {
                                return person.association === 'research-coordinator';
                            })
                        },
                    ],
                    attributes: {
                        association: 'research-coordinator'
                    }
                }
            },
            {
                adminLabel: 'Research assistants',
                pivot: {
                    type: 'event-people',
                    localKey: 'eventId',
                    foreignKey: 'personId',
                    filters: [
                        collection => {
                            return collection.filter(person => {
                                return person.association === 'research-assistant';
                            })
                        },
                    ],
                    attributes: {
                        association: 'research-assistant'
                    }
                }
            },
            {
                adminLabel: 'Researchers',
                pivot: {
                    type: 'event-people',
                    localKey: 'eventId',
                    foreignKey: 'personId',
                    filters: [
                        collection => {
                            return collection.filter(person => {
                                return person.association === 'researcher';
                            })
                        },
                    ],
                    attributes: {
                        association: 'researcher'
                    }
                }
            },
            {
                adminLabel: 'Participants',
                pivot: {
                    type: 'event-people',
                    localKey: 'eventId',
                    foreignKey: 'personId',
                    filters: [
                        collection => {
                            return collection.filter(person => {
                                return person.association === 'participant';
                            })
                        },
                    ],
                    attributes: {
                        association: 'participant'
                    }
                }
            },
            {
                adminLabel: 'Speakers',
                pivot: {
                    type: 'event-people',
                    localKey: 'eventId',
                    foreignKey: 'personId',
                    filters: [
                        collection => {
                            return collection.filter(person => {
                                return person.association === 'speaker';
                            })
                        },
                    ],
                    attributes: {
                        association: 'speaker'
                    }
                }
            },
            {
                adminLabel: 'Chairs',
                pivot: {
                    type: 'event-people',
                    localKey: 'eventId',
                    foreignKey: 'personId',
                    filters: [
                        collection => {
                            return collection.filter(person => {
                                return person.association === 'chair';
                            })
                        },
                    ],
                    attributes: {
                        association: 'chair'
                    }
                }
            },
        ],
    },
    publishable: true,
    adminSection: 'activities',
    componentType: 'base',
    filterOn: {
        'decades': {
            foreignKey: 'decadeId',
        },
        'programme-areas': {
            foreignKey: 'programmeAreaId',
            in: 'eventProgrammeAreas',
        },
        'themes': {
            foreignKey: 'themeId',
            in: 'eventThemes',
        },
        'projects': {
            foreignKey: 'projectId',
            in: 'eventProjects',
        },
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerEventRecord',
            loader: () => import('./AdministerEventRecord')
        },
        adminTranslation: {
            name: 'AdministerEventTranslation',
            loader: () => import('./AdministerEventTranslation')
        },
        webRecord: {
            name: 'DisplayEventRecord',
            loader: () => import('./DisplayEventRecord')
        },
    },
};
