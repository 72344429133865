import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .sluggable()
    .hasTitle()
    .hasSummary()
    .hasImage()
    .hasMetadata()
    .publishable()
    .publishableByDate()
    .hasLotusNotesId()
    .hasTimestamps()
    .make();

schema.prop('programmeAreaId').setType(Number).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('projectId').setType(Number).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('eventId').setType(Number).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('youtubeId').setType(String).setValue(null).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('duration').setType(String).setValue(null).addMetadata('mergeable', true).addMetadata('draftable', true);

export default schema;

