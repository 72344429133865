class AccessToken {
    #httpClient;
    #url;
    #resourceOwnerUrl;

    constructor(httpClient, url, resourceOwnerUrl) {
        this.#httpClient = httpClient;
        this.#url = url;
        this.#resourceOwnerUrl = resourceOwnerUrl;
    }

    fetch(payload) {
        return this.#httpClient.post(this.#url, payload).then(response => {
            return response.extract();
        });
    }

    owner(accessToken) {
        return this.#httpClient.get(this.#resourceOwnerUrl, {
            headers: {
                'Content-Type': 'application/vnd.api+json',
                'Authorization': accessToken.toString(),
            }
        }).then(response => {
            return response.extract();
        });
    }
}

export default AccessToken;
