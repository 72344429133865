export default {
    type: 'news-items',
    aliases: {
        camel: 'newsItems',
        singular: 'News',
        plural: 'News',
        lowerSingular: 'news',
        lowerPlural: 'news',
    },
    relations: {
        'events': {
            adminLabel: 'Events',
            pivot: {
                type: 'news-item-events',
                localKey: 'newsItemId',
                foreignKey: 'eventId',
            }
        },
        'keywords': {
            adminLabel: 'Keywords',
            pivot: {
                type: 'news-item-keywords',
                localKey: 'newsItemId',
                foreignKey: 'keywordId',
            }
        },
        'modules': {
            pivot: {
                type: 'news-item-modules',
                localKey: 'newsItemId',
                foreignKey: 'moduleId',
            }
        },
        'people': {
            adminLabel: 'Authors',
            pivot: {
                type: 'news-item-people',
                localKey: 'newsItemId',
                foreignKey: 'personId',
            }
        },
        'programme-areas': {
            adminLabel: 'Programme areas',
            pivot: {
                type: 'news-item-programme-areas',
                localKey: 'newsItemId',
                foreignKey: 'programmeAreaId',
            }
        },
        'projects': {
            adminLabel: 'Projects',
            pivot: {
                type: 'news-item-projects',
                localKey: 'newsItemId',
                foreignKey: 'projectId',
            }
        },
        'themes': {
            adminLabel: "Themes",
            pivot: {
                type: 'news-item-themes',
                localKey: 'newsItemId',
                foreignKey: 'themeId'
            }
        },
    },
    publishable: true,
    adminSection: 'activities',
    componentType: 'base',
    filterOn: {
        'decades': {
            foreignKey: 'decadeId',
        },
        'programme-areas': {
            foreignKey: 'programmeAreaId',
            in: 'newsItemProgrammeAreas',
        },
        'themes': {
            foreignKey: 'themeId',
            in: 'newsItemThemes',
        },
        'projects': {
            foreignKey: 'projectId',
            in: 'newsItemProjects',
        },
        'events': {
            foreignKey: 'eventId',
            in: 'newsItemEvents',
        },
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerNewsItemRecord',
            loader: () => import('./AdministerNewsItemRecord')
        },
        adminTranslation: {
            name: 'AdministerNewsItemTranslation',
            loader: () => import('./AdministerNewsItemTranslation')
        },
        webRecord: {
            name: 'DisplayNewsItemRecord',
            loader: () => import('./DisplayNewsItemRecord')
        },
    },
};
