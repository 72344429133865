import BaseRepository from "@/services/resources/repository";
import byPerson from "@/services/resources/mixins/query-by-person";
import byKeyword from "@/services/resources/mixins/query-by-keyword";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byPerson).$mix(byKeyword);
    }
}

export default Repository;
