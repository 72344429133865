<template>
    <svg
        class="icon-youtube fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 17"
    >
        <path
            d="M23.5,2.641A3.017,3.017,0,0,0,21.377.505C19.5,0,12,0,12,0S4.5,0,2.624.505A3.016,3.016,0,0,0,.5,2.641,31.568,31.568,0,0,0,0,8.455a31.568,31.568,0,0,0,.5,5.814,3.014,3.014,0,0,0,2.124,2.136c1.871.5,9.376.5,9.376.5s7.505,0,9.377-.5A3.014,3.014,0,0,0,23.5,14.269,31.743,31.743,0,0,0,24,
            8.455,31.656,31.656,0,0,0,23.5,2.641ZM9.546,12.023V4.887l6.273,3.568Z"
            fill-rule="evenodd"
        />
    </svg>
</template>
