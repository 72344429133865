import {Schema} from "@oilstone/rest-model-repository";
import StoreFactory from "@/services/data-layer/store/factory"
import Scope from "@/services/data-layer/store/scope";

class Factory {
    static schema(type) {
        const schema = new Schema();

        schema.prop('id').setType(Number).setValue(null).readOnly().primaryKey();
        schema.prop('parentId').setType(Number).addMetadata('draftable', true);
        schema.prop('type').setType(String).setValue(type);
        schema.prop('sort').setType(Number).addMetadata('draftable', true);
        schema.prop('attributes').setType(Schema).setValue(new Schema()).addMetadata('draftable', true);
        schema.prop('createdAt').setType(String).setValue(null).readOnly();
        schema.prop('updatedAt').setType(String).setValue(null).readOnly();

        return schema;
    }

    static store(registry, repository) {
        const modules = {};

        registry.all().forEach(module => {
            modules[module.config.type] = StoreFactory.make(module.schema, repository);
        });

        return {
            namespaced: true,
            modules
        }
    }

    static scope(config) {
        const scope = new Scope();

        if (config) {
            scope.prefix(`modules/${config.type}/`);
        }

        return scope;
    }
}

export default Factory;
