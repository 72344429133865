export default {
    type: 'feedback',
    aliases: {
        camel: 'feedback',
        singular: 'feedback',
        plural: 'Feedback',
        lowerSingular: 'feedback',
        lowerPlural: 'feedback',
    },
    publishable: false,
    adminSection: null,
    componentType: 'base',
    components: {},
};
