export default {
    type: 'users',
    aliases: {
        camel: 'users',
        singular: 'User',
        plural: 'Users',
        lowerSingular: 'user',
        lowerPlural: 'users',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'base',
    searchOn: ['email'],
    components: {
        adminCollection: {
            name: 'AdministerUserCollection',
            loader: () => import('./AdministerUserCollection')
        },
        adminRecord: {
            name: 'AdministerUserRecord',
            loader: () => import('./AdministerUserRecord')
        },
    }
};
