import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .hasTitle()
    .hasBody()
    .sortable()
    .hasTimestamps()
    .make();

schema.prop('cookieId').setType(Number).setValue(null).addMetadata('mergeable', true);
schema.prop('type').setType(String).setValue(null).addMetadata('translatable', true)
schema.prop('expiry').setType(String).setValue(null).addMetadata('translatable', true)

export default schema;
