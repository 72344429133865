export default {
    type: 'sectors',
    aliases: {
        camel: 'sectors',
        singular: 'UNRISD sector',
        plural: 'UNRISD sectors',
        lowerSingular: 'sector',
        lowerPlural: 'sectors',
    },
    relations: {
        'people': {
            adminLabel: 'People',
            pivot: {
                type: 'person-sectors',
                localKey: 'sectorId',
                foreignKey: 'personId',
            }
        },
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'lookup',
    components: {},
};
