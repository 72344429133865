import Factory from '../factory';

const schema = Factory.schema('enquiry');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('title').setType(String).setValue('Quick Enquiry?');
attributes.prop('consentCheckboxLabel').setType(String).setValue('Please tick to confirm you understand our Privacy Policy around processing your personal information.');
attributes.prop('buttonText').setType(String).setValue('Submit');
attributes.prop('formSubmittedText').setType(String).setValue('Thank you for your submission');
attributes.prop('rightHandPanelText').setType(String).setValue('<h3>We\'ll be in Touch</h3><p>Fill out the form and we\'ll respond as soon as we can.</p>');

export default schema;
