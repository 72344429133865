import Factory from '../factory';

const schema = Factory.schema('staff-and-associates');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('staff').setType(Array);
attributes.prop('board').setType(Array);
attributes.prop('associates').setType(Array);

export default schema;
