import BaseRepository from "@/services/resources/repository";
import byPublication from "@/services/resources/mixins/query-by-publication";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byPublication);
    }
}

export default Repository;
