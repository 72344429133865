<template>
    <FormLabel :text="label">
        <ResourceInput
            type="select"
            label="where"
            :attribute="relationAttributePath"
            :options="relations"
        />
        <RelationEmbed
            label="contains"
            :attribute="keysAttributePath"
            :relation="relation"
        />
    </FormLabel>
</template>

<script>
import {accessesData, mutatesData} from "@/services/data-layer/composers";
import {computed} from "vue";

export default {
    props: {
        modelValue: Array,
        label: String,
        attribute: String,
        relations: Array
    },

    setup(props) {
        const {resolve} = accessesData();
        const {dirty} = mutatesData();

        const relationAttributePath = `${props.attribute}.relation`;
        const keysAttributePath = `${props.attribute}.keys`;

        const relation = computed(() => {
            return typeof dirty.updates[relationAttributePath] !== 'undefined' ? dirty.updates[relationAttributePath] : resolve(relationAttributePath).value;
        });

        return {
            relationAttributePath,
            keysAttributePath,
            relation
        }
    }
}
</script>
