<template>
    <Module
        v-if="moduleContainer"
        type="container"
        :resource="moduleContainer"
        :pointer-events="false"
    />
</template>

<script>
import {inject} from "vue";
import {accessesData, hasEvents} from "@/services/data-layer/composers";
import Kernel from "@oilstone/kernel";
import Publication from "@/services/publication/index";
import _ from 'lodash';

export default {
    setup() {
        const {id, translatesId} = accessesData();
        const {listen} = hasEvents();
        const resource = Kernel.resolve('resourceRegistry').find(inject('type'));
        const pivotConfig = resource.config.relations.modules.pivot;
        const pivot = Kernel.resolve('resourceRegistry').find(pivotConfig.type);

        const findPivot = () => {
            return pivot.repository
                .baseQuery()
                .include('modules.modules')
                .where(pivotConfig.localKey, id.value)
                .where('modules.modules.id', '!=', null)
                .first()
                .then(record => {
                    if (!record && translatesId.value) {
                        return pivot.repository
                            .baseQuery()
                            .include('modules.modules')
                            .where(pivotConfig.localKey, translatesId.value)
                            .where('modules.modules.id', '!=', null)
                            .first();
                    }

                    return record;
                });
        };

        return {
            findPivot,
            listen
        }
    },

    data() {
        return {
            moduleContainer: null,
        }
    },

    mounted() {
        this.findPivot().then(record => {
            if (record) {
                if (Publication.useDraftData && record.modules && record.modules.modules) {
                    record.modules.modules = record.modules.modules.map(module => {
                        if(module.draft && module.draft !== 'null'){
                            return _.merge({}, module, module.draft);
                        }
                    });
                }

                this.moduleContainer = record.modules;
            }
        });
    }
}
</script>
