export default {
    type: 'collection-themes',
    aliases: {
        camel: 'collectionThemes',
        singular: 'Collection theme',
        plural: 'Collection themes',
        lowerSingular: 'collection theme',
        lowerPlural: 'collection themes',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
