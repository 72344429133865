// noinspection JSUnresolvedFunction

import Kernel from "@oilstone/kernel";

const state = {
    authenticating: null,
    error: null,
};

const getters = {
    authenticating: state => state.authenticating,
    error: state => state.error,
}

const mutations = {
    authenticating(state, authenticating) {
        state.authenticating = authenticating;
    },

    error(state, error) {
        state.error = error;
    }
}

const actions = {
    authenticating({commit}, authenticating) {
        commit('authenticating', authenticating);
    },

    error({commit}, error) {
        commit('error', error);

        Kernel.resolve('auth').logout().catch(() => {
            //
        });
    },

    login({dispatch, state}, {username, password}) {
        if (state.authenticating) {
            return state.authenticating;
        }

        dispatch('authenticating', null);
        dispatch('error', null);

        const authenticationPromise = Kernel.resolve('auth').user(username, password).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                return dispatch('error', error.response.data.message);
            }

            return dispatch('error', 'You must enter correct details to login, please try again');
        }).finally(() => {
            dispatch('authenticating', null);
        });

        dispatch('authenticating', authenticationPromise);

        return authenticationPromise;
    },

    logout({dispatch, state}) {
        return (state.authenticating || Promise.resolve()).then(() => {
            dispatch('error', null);
            dispatch('authenticating', null);

            return Kernel.resolve('auth').logout();
        });
    },

    loggedIn({state}) {
        return (state.authenticating || Promise.resolve()).then(() => {
            return Kernel.resolve('auth').isLoggedIn();
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
