export default {
    type: 'news-item-themes',
    aliases: {
        camel: 'newsItemThemes',
        singular: 'News item theme',
        plural: 'News item themes',
        lowerSingular: 'news item theme',
        lowerPlural: 'news item themes',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
