export default {
    type: 'blog-posts',
    aliases: {
        camel: 'blogPosts',
        singular: 'Blog',
        plural: 'Blogs',
        lowerSingular: 'blog',
        lowerPlural: 'blogs',
    },
    relations: {
        'modules': {
            pivot: {
                type: 'blog-post-modules',
                localKey: 'blogPostId',
                foreignKey: 'moduleId'
            }
        },
        'people': {
            adminLabel: 'Authors',
            pivot: {
                type: 'blog-post-people',
                localKey: 'blogPostId',
                foreignKey: 'personId'
            }
        },
        'themes': {
            adminLabel: "Themes",
            pivot: {
                type: 'blog-post-themes',
                localKey: 'blogPostId',
                foreignKey: 'themeId'
            }
        },
    },
    publishable: true,
    adminSection: 'library',
    componentType: 'base',
    filterOn: {
        'collections': {
            foreignKey: 'collectionId',
            method: 'blogCollections',
        },
        'programme-areas': {
            foreignKey: 'programmeAreaId',
        },
        'themes': {
            foreignKey: 'themeId',
            in: 'blogPostThemes',
        },
        'projects': {
            foreignKey: 'projectId',
        },
        'events': {
            foreignKey: 'eventId',
        },
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminRecord: {
            name: 'AdministerBlogPostRecord',
            loader: () => import('./AdministerBlogPostRecord')
        },
        adminCollection: {
            name: 'AdministerBlogPostCollection',
            loader: () => import('./AdministerBlogPostCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminTranslation: {
            name: 'AdministerBlogPostTranslation',
            loader: () => import('./AdministerBlogPostTranslation')
        },
        webRecord: {
            name: 'DisplayBlogPostRecord',
            loader: () => import('./DisplayBlogPostRecord')
        },
    },
};
