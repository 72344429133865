import {Provider} from '@oilstone/container';

export default new Provider().register((container) => {
    container.add('languages', [
        {
            code: 'en',
            labels: {
                english: 'English',
                translation: 'English'
            }
        },
        {
            code: 'fr',
            labels: {
                english: 'French',
                translation: 'Fran&#231;ais'
            }
        },
        {
            code: 'es',
            labels: {
                english: 'Spanish',
                translation: 'Espa&#241;ol'
            }
        }
    ])
});
