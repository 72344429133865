<template>
    <svg
        class="icon-apple-pay fill-current"
        viewBox="0 0 49.98 32"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g><path
            d="M995.14,951.59h-42a7.3,7.3,0,0,0-.94.08,3.4,3.4,0,0,0-.9.3,3.07,3.07,0,0,0-1.31,1.31,3.66,3.66,0,0,0-.3.9,5.86,5.86,0,0,0-.08.94c0,.14,0,.28,0,.43v24.07c0,.15,0,.29,0,.43a5.86,5.86,0,0,0,.08.94,3,3,0,0,0,.3.9,3,3,0,0,0,1.31,1.31,3.16,3.16,0,0,0,.9.29,5.87,5.87,0,0,0,.94.09h42.92a6,6,0,0,0,.94-.09,3.1,3.1,0,0,0,.89-.29,2.94,2.94,0,0,0,1.31-1.32,3.12,3.12,0,0,0,.3-.89,5.86,5.86,0,0,0,.08-.94c0-.14,0-.29,0-.43V955.56c0-.15,0-.29,0-.43a5.86,5.86,0,0,0-.08-.94,3.29,3.29,0,0,0-.3-.9,3,3,0,0,0-1.31-1.31,3.46,3.46,0,0,0-.89-.3,7.45,7.45,0,0,0-.94-.08h-1Z"
            transform="translate(-949.63 -951.59)"
        /><path
            d="M995.14,952.66h.91a5.12,5.12,0,0,1,.78.06,2.49,2.49,0,0,1,.6.2,2,2,0,0,1,.85.85,1.83,1.83,0,0,1,.19.6,4.11,4.11,0,0,1,.07.78c0,.14,0,.27,0,.41v24.06c0,.13,0,.27,0,.4a4.15,4.15,0,0,1-.07.79,2.1,2.1,0,0,1-.19.6,1.92,1.92,0,0,1-.85.84,1.85,1.85,0,0,1-.6.2,6.85,6.85,0,0,1-.78.07H953.19a6.85,6.85,0,0,1-.78-.07,2.23,2.23,0,0,1-.6-.2,1.92,1.92,0,0,1-.84-.85,1.77,1.77,0,0,1-.2-.6,4.9,4.9,0,0,1-.07-.77V955.16a5.16,5.16,0,0,1,.07-.79,2,2,0,0,1,1.05-1.44,2,2,0,0,1,.6-.2,6.65,6.65,0,0,1,.78-.07h41.94"
            fill="#fff"
            transform="translate(-949.63 -951.59)"
        /><g><g><path
            d="M963.28,962.36a2.83,2.83,0,0,0,.64-2,2.71,2.71,0,0,0-1.83.94,2.59,2.59,0,0,0-.66,1.91,2.3,2.3,0,0,0,1.85-.87"
            transform="translate(-949.63 -951.59)"
        /><path
            d="M963.91,963.37c-1-.06-1.89.58-2.37.58s-1.24-.55-2-.54a3,3,0,0,0-2.56,1.56c-1.1,1.89-.29,4.7.78,6.24.52.76,1.14,1.6,2,1.57s1.08-.5,2-.5,1.22.5,2,.48,1.38-.76,1.9-1.52a6.45,6.45,0,0,0,.85-1.75,2.79,2.79,0,0,1-1.66-2.52,2.85,2.85,0,0,1,1.34-2.37,2.92,2.92,0,0,0-2.27-1.23"
            transform="translate(-949.63 -951.59)"
        /></g><g><path
            d="M972.81,961.24a3.58,3.58,0,0,1,3.76,3.4,2.16,2.16,0,0,1,0,.36,3.62,3.62,0,0,1-3.47,3.77h-2.81v3.91h-1.77V961.24Zm-2.52,6h2a2.27,2.27,0,1,0,.29-4.54h-2.33Z"
            transform="translate(-949.63 -951.59)"
        /><path
            d="M977,970.31c0-1.46,1.12-2.35,3.1-2.47l2.29-.13v-.64c0-.93-.63-1.49-1.68-1.49a1.61,1.61,0,0,0-1.76,1.23h-1.61c.1-1.51,1.38-2.62,3.44-2.62s3.31,1.07,3.31,2.74v5.75h-1.64v-1.37h0a3,3,0,0,1-2.63,1.51,2.52,2.52,0,0,1-2.76-2.26C977,970.47,977,970.39,977,970.31Zm5.39-.75v-.66l-2.06.13c-1,.07-1.6.52-1.6,1.23s.6,1.21,1.53,1.21a2,2,0,0,0,2.13-1.86Z"
            transform="translate(-949.63 -951.59)"
        /><path
            d="M985.67,975.75v-1.39a2.63,2.63,0,0,0,.56,0,1.38,1.38,0,0,0,1.48-1.18s.15-.51.15-.52l-3-8.35h1.85l2.11,6.79h0l2.11-6.79h1.81l-3.12,8.78c-.72,2-1.54,2.67-3.27,2.67C986.14,975.79,985.9,975.77,985.67,975.75Z"
            transform="translate(-949.63 -951.59)"
        /></g></g></g></svg>
</template>