import BaseRepository from "@/services/resources/repository";
import byUnrisdTimelineEntry from "@/services/resources/mixins/query-by-unrisd-timeline-entry";
import byTheme from "@/services/resources/mixins/query-by-theme";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byUnrisdTimelineEntry).$mix(byTheme);
    }
}

export default Repository;
