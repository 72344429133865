import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .make();

schema.prop('vacancyApplicationId').setType(Number);
schema.prop('sort').setType(Number);
schema.prop('position').setType(String);
schema.prop('isCurrent').setType(Boolean).setValue(false);
schema.prop('fromDate').setType(String);
schema.prop('toDate').setType(String);
schema.prop('employer').setType(String);
schema.prop('jobDescription').setType(String);

export default schema;
