export default {
    type: 'specialisations',
    aliases: {
        camel: 'specialisations',
        singular: 'Specialization',
        plural: 'Specializations',
        lowerSingular: 'specialization',
        lowerPlural: 'specializations',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'lookup',
    components: {},
};
