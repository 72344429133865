<template>
    <div class="form-rich-text">
        <div
            v-if="editor"
            class="form-rich-text__toolbar"
        >
            <button>D</button>

            <button
                :class="{ 'is-active': editor.isActive('bold') }"
                title="Bold"
                @click="editor.chain().focus().toggleBold().run()"
            >
                <IconRenderer
                    class="small"
                    name="Bold"
                />
            </button>
            <button
                :class="{ 'is-active': editor.isActive('italic') }"
                title="Italic"
                @click="editor.chain().focus().toggleItalic().run()"
            >
                <IconRenderer
                    class="small"
                    name="Italic"
                />
            </button>
            <button
                :class="{ 'is-active': editor.isActive('underline') }"
                title="Underline"
                @click="editor.chain().focus().toggleUnderline().run()"
            >
                <IconRenderer
                    class="small"
                    name="Underline"
                />
            </button>

            <div class="form-rich-text__toolbar__spacer" />

            <button
                :class="{ 'is-active': editor.isActive('bulletList') }"
                title="Bulleted list"
                @click="editor.chain().focus().toggleBulletList().run()"
            >
                <IconRenderer name="List" />
            </button>
            <button
                :class="{ 'is-active': editor.isActive('orderedList') }"
                title="Numbered list"
                @click="editor.chain().focus().toggleOrderedList().run()"
            >
                <IconRenderer name="ListOl" />
            </button>

            <div class="form-rich-text__toolbar__spacer" />

            <button
                :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
                title="Heading 2"
                @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            >
                H2
            </button>
            <button
                :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
                title="Heading 3"
                @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            >
                H3
            </button>
            <button
                :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
                title="Heading 4"
                @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
            >
                H4
            </button>

            <div class="form-rich-text__toolbar__spacer" />

            <button
                :class="{ 'is-active': editor.isActive('link') }"
                title="Add link"
                @click="addLink"
            >
                <IconRenderer name="Link" />
            </button>
            <button
                :class="{ 'is-active': editor.isActive('link') }"
                title="Remove link"
                @click="editor.commands.unsetLink()"
            >
                <IconRenderer name="Unlink" />
            </button>

            <div class="form-rich-text__toolbar__spacer" />

            <button
                title="View source"
                @click="viewSource"
            >
                <IconRenderer name="Code" />
            </button>
        </div>
        <div class="form-rich-text__content">
            <editor-content :editor="editor" />
        </div>

        <teleport to="body">
            <div
                v-if="toggleViewSource || toggleNewLink"
                class="form-rich-text__modal-blocker"
            />
            <div
                v-if="toggleViewSource"
                class="form-rich-text__modal form-rich-text__modal--view-source"
            >
                <div class="form-rich-text__modal__header">
                    <h2>View source</h2>
                    <a
                        class="form-rich-text__modal__header__close"
                        @click="closeViewSource"
                    >
                        <IconRenderer name="Cross" />
                    </a>
                </div>
                <div class="form-rich-text__modal__details">
                    <FormTextarea
                        :model-value="viewSourceStr"
                        @update:modelValue="updateSourceModel"
                    />
                    <FormBtn
                        variant="beta"
                        @click="attachSource"
                    >
                        Update
                    </FormBtn>
                </div>
            </div>

            <div
                v-if="toggleNewLink"
                class="form-rich-text__modal form-rich-text__modal--add-link"
            >
                <div class="form-rich-text__modal__header">
                    <h2>Add link</h2>
                    <a
                        class="form-rich-text__modal__header__close"
                        @click="closeLink"
                    >
                        <IconRenderer name="Cross" />
                    </a>
                </div>
                <div class="form-rich-text__modal__details">
                    <AdminAsset
                        :model-value="newLink"
                        :show-view="false"
                        :show-delete="false"
                        :files-icon="true"
                        class="resource-input"
                        @update:modelValue="updateModel"
                    />
                    <FormBtn
                        variant="beta"
                        @click="attachLink"
                    >
                        Add link
                    </FormBtn>
                </div>
            </div>
        </teleport>
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import FormTextarea from "./FormTextarea";

export default {
    components: {
        FormTextarea,
        EditorContent,
    },

    props: {
        modelValue: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            editor: null,
            newLink: null,
            toggleNewLink: false,
            toggleViewSource: false,
            viewSourceStr: null,
        }
    },

    watch: {
        modelValue(value) {
            const isSame = this.editor.getHTML() === value

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
    },

    methods: {
        updateModel(val){
            this.newLink = val;
        },

        updateSourceModel(val){
            this.viewSourceStr = val;
        },

        addLink() {
            this.newLink = this.editor.getAttributes('link').href;
            this.toggleNewLink = true;
        },

        closeLink() {
            this.toggleNewLink = false;
        },

        attachLink(){
            if(this.newLink.indexOf('http') > -1) {
                this.editor.commands.setLink({href: this.newLink, target: '_blank'});
            } else {
                this.editor.commands.setLink({href: this.newLink, target: '_self', rel: ''});
            }
            this.closeLink();
        },

        viewSource(){
            this.viewSourceStr = this.editor.getHTML();
            this.toggleViewSource = true;
        },

        closeViewSource(){
            this.toggleViewSource = false;
        },

        attachSource(){
            this.editor.commands.setContent(this.viewSourceStr, false)
            this.closeViewSource();
        }
    },

    mounted() {
        this.editor = new Editor({
            content: this.modelValue,
            extensions: [
                StarterKit,
                Link.configure({
                    openOnClick: false,
                }),
                Underline,
            ],
            onUpdate: () => {
                this.$emit('update:modelValue', this.editor.getHTML())
            },
        })
    },

    beforeUnmount() {
        this.editor.destroy()
    },
}
</script>

<style lang="postcss">
.form-rich-text {
    @apply flex flex-col relative border border-alto;

    &__toolbar {
        @apply border-b border-alto bg-white p-2 flex flex-row items-center justify-start font-sofia-pro filter drop-shadow;

        button {
            @apply border border-white text-cod-gray rounded-md flex flex-row items-center justify-center text-sm flex-shrink-0 mr-1 bg-white transition-colors duration-400 ease-in-out;
            width: 30px;
            height: 30px;

            svg {
                @apply w-4;

                &.small {
                    @apply w-3;
                }
            }

            &:first-child {
                @apply hidden;
            }

            &:hover {
                @apply bg-gallery border-gallery;
            }

            &.is-active {
                @apply bg-gallery border-gallery;
            }
        }

        &__spacer {
            @apply mx-2;
        }

    }

    &__content {
        @apply p-4 trim-content cursor-text h-full overflow-x-hidden overflow-y-auto;
        max-height: 50vh;

        .ProseMirror {
            @apply trim-content;

            p, li {
                @apply text-base font-goudy leading-relaxed;

                &:empty {
                    @apply hidden;
                }
            }

            a {
                @apply text-royal-purple underline cursor-pointer;
            }

            p {
                @apply mb-5 leading-relaxed;
            }

            b,strong {
                @apply font-bold;
            }

            ul, ol {
                @apply mb-4;

                li {
                    @apply ml-6 mb-4 ;
                }
            }

            ul {
                li {
                    @apply relative;

                    &:before {
                        @apply absolute text-cotton-seed text-4xl rounded-full bg-cotton-seed -left-5.5 top-3;
                        content: '';
                        width: 8px;
                        height: 8px;
                    }
                }
            }

            ol {
                @apply  -ml-2;
                li {
                    @apply relative list-decimal pl-1;
                }
            }

            h2,h3,h4,h5,h6 {
                @apply text-lg leading-relaxed text-cod-gray normal-case border-0 mb-0 pb-0;
            }

            h2 {
                @apply text-5xl font-extrabold;
            }

            h3 {
                @apply text-xl;
            }

            h4 {
                @apply text-base;
            }
        }
    }

    &__modal-blocker {
        @apply fixed bg-cod-gray bg-opacity-60 w-screen h-screen top-0 left-0;
        z-index: 4000;
    }

    &__modal {
        @apply fixed flex flex-col bg-pampas top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 filter drop-shadow-lg;
        width: 80%;
        height: 80%;
        z-index: 4001;

        @screen md {
            width: 500px;
            height: 200px;
        }

        &--view-source {
            @screen md {
                width: 75%;
                height: 75%;
            }

            .form-textarea {
                @apply flex-grow mb-4;

                span, textarea {
                    @apply h-full;
                }

                textarea {
                    @apply text-black;
                    font-family: "Courier New", sans;
                    font-size: 14px;
                    line-height: 16px;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }

            .form-btn {
                @apply w-max;
            }
        }

        &__header {
            @apply flex flex-row justify-between items-center py-3 px-4 bg-cod-gray text-white;

            h2 {
                @apply text-lg m-0 p-0;
            }

            &__close {
                @apply cursor-pointer;

                svg {
                    @apply w-4;
                }
            }
        }

        &__details {
            @apply p-4 h-full flex flex-col flex-grow justify-between;
        }
    }
}
</style>
