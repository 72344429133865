import Factory from '../factory';

const schema = Factory.schema('aux-pages');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('title').setType(String).setValue('Further reading');
attributes.prop('parentType').setType(String).setValue(null);
attributes.prop('parentId').setType(Number);
attributes.prop('items').setType(Array);

export default schema;
