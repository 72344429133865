import BaseRepository from "@/services/resources/repository";
import byPerson from "@/services/resources/mixins/query-by-person";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byPerson);
    }

    modulesByPerson(id){
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('modules.modules')
                    .where('person_id',id)
                    .first()
            )
        );
    }
}

export default Repository;
