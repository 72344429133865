const state = {
    cookie: null,
    feedback: null,
    person: null,
    podcast: null,
    video: null,
};

const getters = {
    cookie: state => state.cookie,
    feedback: state => state.feedback,
    person: state => state.person,
    podcast: state => state.podcast,
    video: state => state.video,
}

const mutations = {
    cookie(state, record) {
        state.cookie = record;
    },

    feedback(state, record) {
        state.feedback = record;
    },

    person(state, record) {
        state.person = record;
    },

    podcast(state, record) {
        state.podcast = record;
    },

    video(state, record) {
        state.video = record;
    },
}

const actions = {
    cookie({commit}, record) {
        commit('cookie', record);
    },

    feedback({commit}, record) {
        commit('feedback', record);
    },

    person({commit}, record) {
        commit('person', record);
    },

    podcast({commit}, record) {
        commit('podcast', record);
    },

    video({commit}, record) {
        commit('video', record);
    },

    clearCookie({commit}) {
        commit('cookie', null);
    },

    clearFeedback({commit}) {
        commit('feedback', null);
    },

    clearPerson({commit}) {
        commit('person', null);
    },

    clearPodcast({commit}) {
        commit('podcast', null);
    },

    clearVideo({commit}) {
        commit('video', null);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
