export default {
    type: 'page-modules',
    aliases: {
        camel: 'pageModules',
        singular: 'Page module',
        plural: 'Page modules',
        lowerSingular: 'page module',
        lowerPlural: 'page modules',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
