<template>
    <div class="administer-collection-item">
        <span class="administer-collection-item__title">
            <div>
                <slot />

                <span
                    v-if="hasSubtitle"
                    class="subtitle"
                >
                    <slot name="subtitle" />
                </span>
            </div>
        </span>

        <FormBtn
            variant="beta"
            @click="goToEdit"
        >
            {{ ctaText }}
        </FormBtn>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import administerCollectionItem from "./../administer-collection-item";

const component = administerCollectionItem();

export default {
    mixins: [component],

    setup(props, context) {
        const setup = component.setup(props, context);
        const hasSubtitle = ref(false);

        onMounted(() => {
            if (context.slots.subtitle && context.slots.subtitle().length) {
                hasSubtitle.value = true;
            }
        });

        return {
            ...setup,
            hasSubtitle,
        };
    },
};
</script>

<style lang="postcss">
.administer-collection-item {
    @apply flex items-center justify-between border border-alto p-4 rounded-xl cursor-pointer m-0 mb-4 filter drop-shadow bg-white;
    margin-right: 0 !important;

    &.item-draft {
        .administer-collection-item__title {
            @apply text-dusty-gray;
        }
    }

    &.item-published {
        .administer-collection-item__title {
            svg {
                @apply text-blue-ribbon;
            }
        }
    }

    &__title {
        @apply text-base;

        .has-icon {
            @apply flex flex-row items-center justify-start;

            svg {
                @apply w-4 text-dusty-gray mr-3;
            }
        }
    }

    .base-btn {
        @apply hover:bg-cedar-wood-finish hover:border-cedar-wood-finish;
        height: 40px;

        &:hover {
            background: #671c00 !important;
        }
    }

    .subtitle {
        @apply block text-sm text-salt-box mt-0;

        &--warn {
            @apply text-meteor;
        }
    }
}
</style>
