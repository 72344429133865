<template>
    <div class="grid-container grid">
        <slot></slot>
    </div>
</template>

<style lang="postcss">
.grid-container {
    @apply grid-cols-12 gap-x-4 lg:gap-x-8;
}
</style>
