export default {
    type: 'unrisd-timeline-entries',
    aliases: {
        camel: 'unrisdTimelineEntries',
        singular: 'UNRISD timeline entry',
        plural: 'UNRISD timeline entries',
        lowerSingular: 'timeline entry',
        lowerPlural: 'timeline entries',
    },
    relations: {
        'themes': {
            adminLabel: "Themes",
            pivot: {
                type: 'unrisd-timeline-entry-themes',
                localKey: 'unrisdTimelineEntryId',
                foreignKey: 'themeId'
            }
        },
    },
    publishable: true,
    adminSection: 'about',
    componentType: 'base',
    filterOn: {
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerUnrisdTimelineEntryRecord',
            loader: () => import('./AdministerUnrisdTimelineEntryRecord')
        },
        adminTranslation: {
            name: 'AdministerUnrisdTimelineEntryTranslation',
            loader: () => import('./AdministerUnrisdTimelineEntryTranslation')
        },
    }
};
