import PivotRepository from "@/services/resources/pivot-repository";
import byEvent from "@/services/resources/mixins/query-by-event";
import byPerson from "@/services/resources/mixins/query-by-person";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byEvent).$mix(byPerson);
    }

    peopleByEvent(id) {
        return this.relation('people', 'eventId', id, 'person', ['association']);
    }
    eventsByPerson(id) {
        return this.relation('events', 'personId', id, 'event');
    }
}

export default Repository;
