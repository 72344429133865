export default {
    type: 'publication-publishers',
    aliases: {
        camel: 'publicationPublishers',
        singular: 'Publication publisher',
        plural: 'Publication publishers',
        lowerSingular: 'publication publisher',
        lowerPlural: 'publication publishers',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
