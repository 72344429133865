<template>
    <span
        v-if="text"
        class="form-help"
    >
            {{ text }}
        </span>
</template>

<script>
export default {
    props: {
        text: String
    }
}
</script>

<style lang="postcss">
.form-help {
    @apply block text-xs text-salt-box mt-2;
}
</style>
