import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .make();

schema.prop('projectId').setType(Number);
schema.prop('themeId').setType(Number);

export default schema;
