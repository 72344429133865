export default {
    type: 'faqs',
    aliases: {
        camel: 'faqs',
        singular: 'FAQ',
        plural: 'FAQs',
        lowerSingular: 'FAQ',
        lowerPlural: 'FAQs',
    },
    publishable: true,
    adminSection: 'about',
    componentType: 'base',
    filterOn: {
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerFaqRecord',
            loader: () => import('./AdministerFaqRecord')
        },
        adminTranslation: {
            name: 'AdministerFaqTranslation',
            loader: () => import('./AdministerFaqTranslation')
        },
    }
};
