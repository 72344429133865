<template>
    <svg
        class="icon-moty"
        viewBox="0 0 16 15.24"
        xmlns="http://www.w3.org/2000/svg"
    ><path
        d="M16,7.507,15.64,6.4l-2.719-.353,2-1.881-.356-1.11-4.2,1.356V0H9.2L8.021,2.48,6.852,0H5.684V4.439L1.461,3.055,1.1,4.161,3.085,6.048.364,6.391,0,7.5,4.206,8.879,1.6,12.47l.936.686,2.406-1.317-.508,2.7.936.686,2.62-3.589,2.615,3.609.945-.686-.505-2.706,2.406,1.314.945-.682L11.782,8.871ZM12.5,11.55l-1.823-1.007L9.92,11.1l.39,2.044L8.689,10.911l-.694.5L7.3,10.9,5.681,13.13l.39-2.043-.758-.552-1.823,1L5.109,9.312l-.68-.489L4.7,8.01,2.079,7.144l2.065-.253L4.435,6,2.922,4.57l2.616.86.267-.811H6.67V1.872l.884,1.872H8.49l.886-1.872V4.625h.833l.261.8,2.62-.848L11.57,5.99l.289.893,2.064.261-2.62.849.267.829-.683.5Z"
        transform="translate(0 0)"
    /></svg>
</template>
