<template>
    <svg
        class="icon-magnify stroke-current"
        viewBox="0 0 17.688 15.787"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M253.148,252.394a6.021,6.021,0,0,1-4.737,2.251,5.633,5.633,0,1,1,5.921-5.63,5.408,5.408,0,0,1-1.184,3.379l5.335,5.059Z"
            fill="none"
            stroke-width="2"
            transform="translate(-241.482 -242.392)"
        />
    </svg>
</template>
