<template>
    <router-link :to="resolvedTo">
        <template v-if="options.type === 'btn'">
            <FormBtn v-bind="options.attributes">
                <slot></slot>
            </FormBtn>
        </template>
        <template v-else>
            <slot></slot>
        </template>
    </router-link>
</template>

<script>
import Navigation from "@/services/navigation";
import Kernel from "@oilstone/kernel";

const resourceRegistry = Kernel.resolve('resourceRegistry');

export default {
    props: {
        to: {
            required: true,
        },
        options: {
            type: Object,
            default() {
                return {
                    type: 'a'
                }
            }
        }
    },

    computed: {
        resolvedTo() {
            let to = this.to;

            if (typeof to === 'object' && to.type && to.resource) {
                const config = resourceRegistry.find(to.type).config;

                if (to.type === 'collections') {
                    let subtype = '';

                    switch (to.resource.section) {
                        case 'blog-posts':
                            subtype = 'blog-series';
                            break;

                        case 'podcasts':
                            subtype = 'podcast-series';
                            break;
                    }

                    to = `/library/${subtype}/${to.resource.slug}`;
                } else {
                    to = `${config.adminSection}/${config.type}/${to.resource.slug}`;
                }
            }

            if (Kernel.resolve('context').app === 'web') {
                return Navigation.prefixLanguage(to);
            }

            return to;
        }
    }
}
</script>
