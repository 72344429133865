import PivotRepository from "@/services/resources/pivot-repository";
import byPublication from "@/services/resources/mixins/query-by-publication";
import byTheme from "@/services/resources/mixins/query-by-theme";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byPublication).$mix(byTheme);
    }

    themesByPublication(id) {
        return this.relation('themes', 'publicationId', id);
    }
}

export default Repository;
