export default {
    type: 'person-affiliations',
    aliases: {
        camel: 'personAffiliations',
        singular: 'Person affiliation',
        plural: 'Person affiliations',
        lowerSingular: 'person affiliation',
        lowerPlural: 'person affiliations',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
