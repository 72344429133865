export default {
    type: 'legacy-files',
    aliases: {
        camel: 'legacyFiles',
        singular: 'Legacy file',
        plural: 'Legacy files',
        lowerSingular: 'legacy file',
        lowerPlural: 'legacy files',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'base',
    components: {
        adminRecord: {
            name: 'AdministerLegacyFileRecord',
            loader: () => import('./AdministerLegacyFileRecord')
        },
        adminCollection: {
            name: 'AdministerLegacyFileCollection',
            loader: () => import('./AdministerLegacyFileCollection')
        },
    },
    cms: {
        paginationAmount: () => {
            return 50
        },
    }
};
