export default {
    type: 'publication-projects',
    aliases: {
        camel: 'publicationProjects',
        singular: 'Publication project',
        plural: 'Publication projects',
        lowerSingular: 'publication project',
        lowerPlural: 'publication projects',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
