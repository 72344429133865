import { Provider } from "@oilstone/container";
import Registry from "@/services/resources/registry";
import affiliations from "@/shared/resources/affiliations";
import blogPostModules from "@/shared/resources/blog-post-modules";
import blogPostPeople from "@/shared/resources/blog-post-people";
import blogPostThemes from "@/shared/resources/blog-post-themes";
import blogPosts from "@/shared/resources/blog-posts";
import collectionThemes from "@/shared/resources/collection-themes";
import collectionModules from "@/shared/resources/collection-modules";
import collections from "@/shared/resources/collections";
import cookies from "@/shared/resources/cookies";
import cookieItems from "@/shared/resources/cookie-items";
import countries from "@/shared/resources/countries";
import decades from "@/shared/resources/decades";
import ebulletins from "@/shared/resources/ebulletins";
import enquiries from "@/shared/resources/enquiries";
import eventCountries from "@/shared/resources/event-countries";
import eventKeywords from "@/shared/resources/event-keywords";
import eventModules from "@/shared/resources/event-modules";
import eventPeople from "@/shared/resources/event-people";
import eventProgrammeAreas from "@/shared/resources/event-programme-areas";
import eventProjects from "@/shared/resources/event-projects";
import eventThemes from "@/shared/resources/event-themes";
import eventTypes from "@/shared/resources/event-types";
import events from "@/shared/resources/events";
import faqCategories from "@/shared/resources/faq-categories";
import faqs from "@/shared/resources/faqs";
import feedback from "@/shared/resources/feedback";
import fileAssets from "@/shared/resources/file-assets";
import fileUploads from "@/shared/resources/file-uploads";
import impactStories from "@/shared/resources/impact-stories";
import impactStoryModules from "@/shared/resources/impact-story-modules";
import jobGroups from "@/shared/resources/job-groups";
import jobTitles from "@/shared/resources/job-titles";
import keyValues from "@/shared/resources/key-values";
import keywords from "@/shared/resources/keywords";
import legacyFiles from "@/shared/resources/legacy-files";
import locationTypes from "@/shared/resources/location-types";
import modules from "@/shared/modules/resource";
import navigationItems from "@/shared/resources/navigation-items";
import networkRelationships from "@/shared/resources/network-relationships";
import newsItemEvents from "@/shared/resources/news-item-events";
import newsItemKeywords from "@/shared/resources/news-item-keywords";
import newsItemModules from "@/shared/resources/news-item-modules";
import newsItemPeople from "@/shared/resources/news-item-people";
import newsItemProgrammeAreas from "@/shared/resources/news-item-programme-areas";
import newsItemProjects from "@/shared/resources/news-item-projects";
import newsItemThemes from "@/shared/resources/news-item-themes";
import newsItems from "@/shared/resources/news-items";
import pageModules from "@/shared/resources/page-modules";
import pages from "@/shared/resources/pages";
import partners from "@/shared/resources/partners";
import people from "@/shared/resources/people";
import personAffiliations from "@/shared/resources/person-affiliations";
import personKeywords from "@/shared/resources/person-keywords";
import personModules from "@/shared/resources/person-modules";
import personSectors from "@/shared/resources/person-sectors";
import personSpecialisations from "@/shared/resources/person-specialisations";
import podcastThemes from "@/shared/resources/podcast-themes";
import podcasts from "@/shared/resources/podcasts";
import programmeAreaKeywords from "@/shared/resources/programme-area-keywords";
import programmeAreaModules from "@/shared/resources/programme-area-modules";
import programmeAreaPeople from "@/shared/resources/programme-area-people";
import programmeAreaThemeTimelineEntries from "@/shared/resources/programme-area-theme-timeline-entries";
import programmeAreaThemes from "@/shared/resources/programme-area-themes";
import programmeAreas from "@/shared/resources/programme-areas";
import projectCountries from "@/shared/resources/project-countries";
import projectKeywords from "@/shared/resources/project-keywords";
import projectModules from "@/shared/resources/project-modules";
import projectPeople from "@/shared/resources/project-people";
import projectProgrammeAreas from "@/shared/resources/project-programme-areas";
import projectThemes from "@/shared/resources/project-themes";
import projects from "@/shared/resources/projects";
import publicationCountries from "@/shared/resources/publication-countries";
import publicationEvents from "@/shared/resources/publication-events";
import publicationKeywords from "@/shared/resources/publication-keywords";
import publicationModules from "@/shared/resources/publication-modules";
import publicationPeople from "@/shared/resources/publication-people";
import publicationProgrammeAreas from "@/shared/resources/publication-programme-areas";
import publicationProjects from "@/shared/resources/publication-projects";
import publicationPublishers from "@/shared/resources/publication-publishers";
import publicationThemes from "@/shared/resources/publication-themes";
import publicationTypes from "@/shared/resources/publication-types";
import publications from "@/shared/resources/publications";
import publishers from "@/shared/resources/publishers";
import regions from "@/shared/resources/regions";
import sectors from "@/shared/resources/sectors";
import shortcuts from "@/shared/resources/shortcuts";
import specialisations from "@/shared/resources/specialisations";
import subscribers from "@/shared/resources/subscribers";
import themeModules from "@/shared/resources/theme-modules";
import themeTimelineEntries from "@/shared/resources/theme-timeline-entries";
import themes from "@/shared/resources/themes";
import translations from "@/shared/resources/translations";
import unrisdTimelineEntries from "@/shared/resources/unrisd-timeline-entries";
import unrisdTimelineEntryThemes from "@/shared/resources/unrisd-timeline-entry-themes";
import unrisdTimelineEntryTypes from "@/shared/resources/unrisd-timeline-entry-types";
import users from "@/shared/resources/users";
import vacancies from "@/shared/resources/vacancies";
import vacancyApplications from "@/shared/resources/vacancy-applications";
import vacancyApplicationLanguages from "@/shared/resources/vacancy-application-languages";
import vacancyApplicationEducation from "@/shared/resources/vacancy-application-education";
import vacancyApplicationEmployment from "@/shared/resources/vacancy-application-employment";
import vacancyApplicationReferences from "@/shared/resources/vacancy-application-references";
import vacancyModules from "@/shared/resources/vacancy-modules";
import videoKeywords from "@/shared/resources/video-keywords";
import videoThemes from "@/shared/resources/video-themes";
import videos from "@/shared/resources/videos";

export default new Provider().register(container => {
    container.singleton("resourceRegistry", () => {
        const registry = new Registry();

        const resources = {
            affiliations,
            blogPostModules,
            blogPostPeople,
            blogPostThemes,
            blogPosts,
            collectionModules,
            collectionThemes,
            collections,
            cookies,
            cookieItems,
            countries,
            decades,
            ebulletins,
            enquiries,
            eventCountries,
            eventKeywords,
            eventModules,
            eventPeople,
            eventProgrammeAreas,
            eventProjects,
            eventThemes,
            eventTypes,
            events,
            faqCategories,
            faqs,
            feedback,
            fileAssets,
            fileUploads,
            impactStories,
            impactStoryModules,
            jobGroups,
            jobTitles,
            keyValues,
            keywords,
            legacyFiles,
            locationTypes,
            modules,
            navigationItems,
            networkRelationships,
            newsItemEvents,
            newsItemKeywords,
            newsItemModules,
            newsItemPeople,
            newsItemProgrammeAreas,
            newsItemProjects,
            newsItemThemes,
            newsItems,
            pageModules,
            pages,
            partners,
            people,
            personAffiliations,
            personKeywords,
            personModules,
            personSectors,
            personSpecialisations,
            podcastThemes,
            podcasts,
            programmeAreaKeywords,
            programmeAreaModules,
            programmeAreaPeople,
            programmeAreaThemeTimelineEntries,
            programmeAreaThemes,
            programmeAreas,
            projectCountries,
            projectKeywords,
            projectModules,
            projectPeople,
            projectProgrammeAreas,
            projectThemes,
            projects,
            publicationCountries,
            publicationEvents,
            publicationKeywords,
            publicationModules,
            publicationPeople,
            publicationProgrammeAreas,
            publicationProjects,
            publicationPublishers,
            publicationThemes,
            publicationTypes,
            publications,
            publishers,
            regions,
            sectors,
            shortcuts,
            specialisations,
            subscribers,
            themeModules,
            themeTimelineEntries,
            themes,
            translations,
            unrisdTimelineEntries,
            unrisdTimelineEntryThemes,
            unrisdTimelineEntryTypes,
            users,
            vacancies,
            vacancyApplications,
            vacancyApplicationEducation,
            vacancyApplicationEmployment,
            vacancyApplicationLanguages,
            vacancyApplicationReferences,
            vacancyModules,
            videoKeywords,
            videoThemes,
            videos,
        };

        Object.values(resources).forEach(resource => {
            registry.add(resource());
        });

        return registry;
    });
});
