<template>
    <svg
        class="icon-twitter fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 20"
    >
        <path
            d="M23.953,2.324a10.046,10.046,0,0,1-2.825.775A4.961,4.961,0,0,0,23.291.376,10.193,10.193,0,0,1,20.164,1.56,4.92,4.92,0,0,0,11.78,6.042,13.94,13.94,0,0,1,1.64.916,4.825,4.825,0,0,0,.974,3.391a4.922,4.922,0,0,0,2.188,4.1,4.91,4.91,0,0,1-2.228-.62v.06A4.924,4.924,0,0,0,4.88,11.754a5.008,5.008,0,0,1-2.212.085,4.938,4.938,0,0,0,4.6,3.417,9.874,9.874,0,0,1-6.1,2.1A10.6,10.6,0,0,1,0,17.3a14.008,14.008,0,0,0,7.557,2.209,13.9,13.9,0,0,0,14-13.8V5.522c0-.21,0-.42-.015-.63A9.958,9.958,0,0,0,24,2.344Z"
            fill-rule="evenodd"
        />
    </svg>
</template>
