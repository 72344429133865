<template>
    <div v-if="item">
        <navigation-link
            :to="href"
            class="poster__img-container"
        >
            <PosterImg
                :content-type="contentType"
                :label="label"
                :item="item"
            />
        </navigation-link>

        <Card class="poster__card">
            <div class="flex-grow">
                <navigation-link :to="href">
                    <h2 class="poster__card__title">
                        <span v-html="item.title" />
                    </h2>
                </navigation-link>

                <p
                    v-if="item.summary"
                    class="font-goudy leading-relaxed text-base m-0 p-0"
                    v-html="item.summary"
                />
            </div>

            <navigation-link
                :to="href"
                class="poster__card__discover"
            >
                {{ discoverStr }}
            </navigation-link>

            <slot name="share" />
        </Card>
    </div>
</template>

<script>
import Translation from "@/services/lookup/translation";

export default {
    props: {
        contentType: {
            type: String,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        href: {
            type: String,
            default: null,
        }
    },

    data(){
        return {
            discoverStr: 'Discover',
        }
    },

    computed: {
        label() {
            return 'Theme';
        }
    },

    mounted(){
        Translation.many(['discover']).then(translations => {
            if(translations){
                this.discoverStr = translations['discover'];
            }
        });
    }
}
</script>
