import config from './config';
import model from "@/services/resources/model";
import Repository from "./repository";
import Factory from "@/shared/modules/factory";

export default () => {
    return {
        config,
        schema: Factory.schema(null),
        repository: Repository.make(model(config)),
    }
};
