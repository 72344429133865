export default {
    type: 'publication-programme-areas',
    aliases: {
        camel: 'publicationProgrammeAreas',
        singular: 'Publication programme area',
        plural: 'Publication programme areas',
        lowerSingular: 'publication programme area',
        lowerPlural: 'publication programme areas',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
