<template>
    <div
        :class="classes"
        class="icon-top-up bg-big-stone rounded-sm p-1"
    >
        <svg
            class="icon-top-up__wheel"
            viewBox="0 0 68 68"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g class="icon-top-up__progress icon-top-up__progress--group-1">
                <rect
                    fill="#fff"
                    height="9"
                    transform="translate(-1279.498 -502.516) rotate(-22.5)"
                    width="2"
                    x="1007.23"
                    y="1014.754"
                />
                <rect
                    fill="#ffb8c2"
                    height="9"
                    width="2"
                    x="33"
                    y="50.417"
                />
                <rect
                    fill="#ffe100"
                    height="9"
                    transform="translate(-500.618 -1268.032) rotate(22.5)"
                    width="2"
                    x="991.157"
                    y="1014.754"
                />
                <rect
                    fill="#fff"
                    height="9"
                    transform="translate(39.91 -1365.48) rotate(45)"
                    width="2"
                    x="984.345"
                    y="1010.202"
                />
                <rect
                    fill="#fff"
                    height="9"
                    transform="translate(570.434 -1249.883) rotate(67.5)"
                    width="2"
                    x="979.792"
                    y="1003.389"
                />
                <rect
                    fill="#ffe100"
                    height="9"
                    transform="translate(1012.853 -945.277) rotate(90)"
                    width="2"
                    x="978.194"
                    y="995.353"
                />
                <rect
                    fill="#ffb8c2"
                    height="9"
                    transform="translate(-1277.053 552.463) rotate(-67.5)"
                    width="2"
                    x="979.792"
                    y="987.316"
                />
            </g>
            <g class="icon-top-up__progress icon-top-up__progress--group-2">
                <rect
                    fill="#ffe100"
                    height="9"
                    transform="translate(1412.397 18.827) rotate(135)"
                    width="2"
                    x="984.345"
                    y="980.503"
                />
                <rect
                    fill="#ffb8c2"
                    height="9"
                    transform="translate(-1265.872 -511.621) rotate(-22.5)"
                    width="2"
                    x="991.157"
                    y="975.951"
                />
                <rect
                    fill="#fff"
                    height="9"
                    width="2"
                    x="33"
                    y="8.417"
                />
                <rect
                    fill="#ffb8c2"
                    height="9"
                    transform="translate(-514.244 -1277.136) rotate(22.5)"
                    width="2"
                    x="1007.23"
                    y="975.951"
                />
                <rect
                    fill="#ffe100"
                    height="9"
                    transform="translate(27.608 -1395.178) rotate(45)"
                    width="2"
                    x="1014.043"
                    y="980.503"
                />
                <rect
                    fill="#fff"
                    height="9"
                    transform="translate(579.538 -1295.654) rotate(67.5)"
                    width="2"
                    x="1018.595"
                    y="987.316"
                />
                <rect
                    fill="#fff"
                    height="9"
                    transform="translate(1054.853 -987.277) rotate(90)"
                    width="2"
                    x="1020.194"
                    y="995.353"
                />
                <rect
                    fill="#ffe100"
                    height="9"
                    transform="translate(-1267.948 598.234) rotate(-67.5)"
                    width="2"
                    x="1018.595"
                    y="1003.389"
                />
                <rect
                    fill="#ffb8c2"
                    height="9"
                    transform="translate(1484.096 48.525) rotate(135)"
                    width="2"
                    x="1014.043"
                    y="1010.202"
                />
            </g>
            <g class="icon-top-up__status icon-top-up__status--tick">
                <circle
                    cx="34"
                    cy="34"
                    fill="#00b43c"
                    r="10.5"
                />
                <path
                    d="M998.117,1000.08l-3.463-3.721L993.5,997.6l4.617,4.961,9.895-10.631-1.154-1.241Z"
                    fill="#fff"
                    transform="translate(-966.5 -963.688)"
                />
            </g>
            <g class="icon-top-up__status icon-top-up__status--arrow">
                <polygon
                    fill="#fff"
                    points="37.956 31.931 34 28.414 30.044 31.931 31.372 33.426 33.001 31.978 33.001 39.751 35.001 39.751 35.001 31.98 36.628 33.426 37.956 31.931"
                />
                <path
                    d="M1000.194,1012.936a13,13,0,1,1,13-13A13.015,13.015,0,0,1,1000.194,1012.936Zm0-24a11,11,0,1,0,11,11A11.012,11.012,0,0,0,1000.194,988.936Z"
                    fill="#fff"
                    transform="translate(-966.194 -965.935)"
                />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        applied: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        classes() {
            return this.applied ? ['icon-top-up--applied'] : [];
        }
    }
}
</script>

<style lang="postcss">
.icon-top-up {
    &--applied {
        .icon-top-up__wheel {
            animation: top-up-complete 800ms 800ms ease-out;
        }

        .icon-top-up__status--tick {
            opacity: 1;
        }

        .icon-top-up__status--arrow {
            opacity: 0;
        }

        .icon-top-up__progress--group-2 {
            rect {
                opacity: 1;

                &:first-child {
                    transition-delay: 0ms;
                }

                &:nth-child(2) {
                    transition-delay: 75ms;
                }

                &:nth-child(3) {
                    transition-delay: 150ms;
                }

                &:nth-child(4) {
                    transition-delay: 225ms;
                }

                &:nth-child(5) {
                    transition-delay: 300ms;
                }

                &:nth-child(6) {
                    transition-delay: 375ms;
                }

                &:nth-child(7) {
                    transition-delay: 450ms;
                }

                &:nth-child(8) {
                    transition-delay: 525ms;
                }

                &:nth-child(9) {
                    transition-delay: 600ms;
                }
            }
        }
    }

    &__status {
        transition: opacity 600ms 600ms ease-out;

        &--tick {
            opacity: 0;
        }
    }

    &__progress {
        &--group-2 {
            rect {
                opacity: 0;
                transition: opacity 200ms ease-out;

                &:first-child {
                    transition-delay: 600ms;
                }

                &:nth-child(2) {
                    transition-delay: 525ms;
                }

                &:nth-child(3) {
                    transition-delay: 450ms;
                }

                &:nth-child(4) {
                    transition-delay: 375ms;
                }

                &:nth-child(5) {
                    transition-delay: 300ms;
                }

                &:nth-child(6) {
                    transition-delay: 225ms;
                }

                &:nth-child(7) {
                    transition-delay: 150ms;
                }

                &:nth-child(8) {
                    transition-delay: 75ms;
                }

                &:nth-child(9) {
                    transition-delay: 0ms;
                }
            }
        }
    }

    @keyframes top-up-complete {
        50% {
            transform: scale(1.2);
        }
        60% {
            transform: scale(0.85);
        }
        80% {
            transform: scale(1.1)
        }
        100% {
            transform: scale(1)
        }
    }
}
</style>
