<template>
    <div>
        <template v-if="showMore && page.body">
            <div
                class="page-copy page-copy--show-more"
                :class="classes"
            >
                <LayoutContainer>
                    <GridContainer>
                        <GridColumn>
                            <div
                                class="overview trim-content"
                                :class="showingMore ? 'page-copy__show-more__overview--active' : 'page-copy__show-more__overview'"
                                v-html="page.body"
                            />

                            <div
                                class="page-copy__show-more"
                                :class="showingMore ? 'page-copy__show-more--active' : ''"
                            >
                                <div
                                    class="page-copy__show-more__toggle"
                                    @click="showingMoreToggle"
                                >
                                    <span v-html="moreLabel" />
                                    <IconRenderer name="Chevron" />
                                </div>
                            </div>
                        </GridColumn>
                    </GridContainer>
                </LayoutContainer>
            </div>
        </template>
        <template v-else>
            <div
                class="page-copy"
                :class="classes"
            >
                <LayoutContainer>
                    <GridContainer>
                        <GridColumn>
                            <div
                                v-if="page.header"
                                class="page-copy__header"
                                v-html="page.header"
                            />

                            <slot />

                            <h1
                                v-if="page.title"
                                v-html="page.title"
                            />
                            <h2 v-if="page.dateRange">
                                {{ $filters.date.range(page.dateRange.start, page.dateRange.end) }}
                            </h2>
                            <h2 v-else-if="page.date">
                                {{ $filters.date.format(page.date) }}
                            </h2>
                            <div class="flex flex-col lg:flex-row item-start page-copy__lede-text">
                                <div
                                    v-if="programmeAreas.length"
                                    class="space-y-2"
                                >
                                    <navigation-link
                                        v-for="programmeArea in programmeAreas"
                                        :key="programmeArea.id"
                                        :to="{
                                            type: 'programme-areas',
                                            resource: programmeArea
                                        }"
                                        class="block"
                                    >
                                        <h3 v-html="programmeArea.title" />
                                    </navigation-link>
                                </div>
                                <template v-if="blogSeries.length">
                                    <navigation-link
                                        v-for="series in blogSeries"
                                        :key="series.id"
                                        :to="{
                                            type: 'collections',
                                            resource: series
                                        }"
                                    >
                                        <h3 v-html="series.title" />
                                    </navigation-link>
                                </template>
                                <div
                                    v-if="page.time"
                                    class="flex-shrink-0"
                                >
                                    <h3 class="time">
                                        <IconRenderer name="Clock" />
                                        <span
                                            class="time__text"
                                            v-html="page.time.hours ? page.time.hours + ':' + page.time.minutes : page.time"
                                        />
                                    </h3>
                                </div>
                                <div
                                    v-if="page.project"
                                    class="flex-shrink-0"
                                >
                                    <h3 class="project">
                                        <IconRenderer name="Clock" />
                                        <span v-html="page.project" />
                                    </h3>
                                </div>
                            </div>
                            <div
                                v-if="!bordered && page.lede"
                                class="lede"
                                v-html="page.lede"
                            />
                            <div
                                v-if="!bordered && pageBody"
                                class="overview trim-content"
                                v-html="pageBody"
                            />
                        </GridColumn>
                    </GridContainer>
                </LayoutContainer>
            </div>

            <LayoutContainer
                v-if="bordered && (page.lede || pageBody)"
                class="page-copy"
            >
                <GridContainer>
                    <GridColumn>
                        <div
                            v-if="page.lede"
                            class="lede"
                            v-html="page.lede"
                        />
                        <div
                            v-if="pageBody"
                            class="overview trim-content"
                            v-html="pageBody"
                        />
                    </GridColumn>
                </GridContainer>
            </LayoutContainer>
        </template>
    </div>
</template>

<script>
import Translation from "@/services/lookup/translation";

export default {
    props: {
        page: Object,
        bordered: {
            type: Boolean,
            default: false,
        },
        showMore: {
            type: Boolean,
            default: false,
        },
    },

    data(){
        return {
            showingMore: false,
            showMoreTranslation: '',
            showLessTranslation: '',
        }
    },

    computed: {
        classes() {
            const classes = [];

            if (this.bordered) {
                classes.push('page-copy--bordered');
            }

            if (this.bordered && this.page.header) {
                classes.push('page-copy--bordered-header');
            }

            return classes;
        },

        moreLabel(){
            return this.showingMore ? this.showLessTranslation : this.showMoreTranslation;
        },

        programmeAreas() {
            if (this.page.programmeAreas && Array.isArray(this.page.programmeAreas)) {
                return this.page.programmeAreas;
            }

            if (this.page.programmeArea) {
                return [this.page.programmeArea];
            }

            return [];
        },

        blogSeries() {
            if (this.page.blogSeries && Array.isArray(this.page.blogSeries)) {
                return this.page.blogSeries;
            }

            if (this.page.blogSeries) {
                return [this.page.blogSeries];
            }

            return [];
        },

        pageBody(){
            if(this.page.body) {
                if (this.page.body.length > 0 && this.page.body.toLowerCase().substring(0, 3) !== '<p>') {
                    return "<p>" + this.page.body + "</p>";
                } else {
                    return this.page.body;
                }
            } else {
                return null;
            }
        },
    },

    methods: {
        showingMoreToggle(){
            this.showingMore = !this.showingMore;
        },
    },

    mounted(){
        Translation.many(['show-more','show-less']).then(translations => {
            if(translations) {
                this.showMoreTranslation = translations['show-more'];
                this.showLessTranslation = translations['show-less'];
            }
        })
    }
}
</script>

<style lang="postcss">
.page-copy {
    @apply text-cod-gray my-6;

    &--bordered {
        @apply border-t border-b border-alto my-6 pt-4 pb-8;
    }

    &--bordered-header {
        @apply pt-0;
    }

    .grid-container {
        @apply grid-cols-12 gap-8;

        .grid-column {
            @apply col-span-12 lg:col-span-8;

            .page-copy__header {
                @apply text-spaced-small bg-surfie-green text-white w-max py-2 px-4 mb-2;
            }

            h1 {
                @apply mb-4 lg:text-7xl pt-4;
            }

            h2 {
                @apply text-boulder mb-4 lg:text-5xl;
            }

            .page-copy__lede-text {
                @apply lg:space-x-15;

                a {
                    @apply no-underline;

                    &:hover {
                         @apply underline;
                    }
                }

                h3 {
                    @apply text-spaced-tight text-sm lg:text-base font-bold text-royal-purple lg:space-x-8;

                    &.time, &.project {
                        @apply flex flex-row items-center justify-start mt-4 lg:mt-0;

                        svg {
                            @apply w-4 mr-2;
                        }
                    }

                    .time__text, span {
                        @apply ml-0 !important;
                    }
                }
            }

            .lede {
                @apply font-goudy italic text-xl lg:text-2xl trim-content mb-3;

                p {
                    &:empty {
                        @apply hidden;
                    }
                }
            }

            a {
                @apply text-royal-purple underline cursor-pointer;
            }

            p, li {
                @apply font-goudy leading-relaxed;

                &:empty {
                    @apply hidden;
                }
            }

            ul, ol {
                @apply mb-4;

                li {
                    @apply ml-6 mb-4 ;
                }
            }

            ul {
                li {
                    @apply relative;

                    &:before {
                        @apply absolute text-cotton-seed text-4xl rounded-full bg-cotton-seed -left-5.5 top-3;
                        content: '';
                        width: 8px;
                        height: 8px;
                    }
                }
            }

            ol {
                @apply  -ml-2;
                li {
                    @apply relative list-decimal pl-1;
                }
            }

            .overview {
                h2,h3,h4,h5,h6 {
                    @apply text-lg leading-relaxed text-cod-gray normal-case border-0 mb-0 pb-0;
                }

                h2 {
                    @apply text-5xl font-extrabold;
                }

                h3 {
                    @apply text-xl;
                }

                h4 {
                    @apply text-base;
                }
            }
        }
    }

    &__show-more {
        @apply font-libre-franklin text-base text-cod-gray mb-4 w-full text-center;

        &__overview {
            @apply hidden;

            &--active {
                 @apply block;
            }
        }

        &__toggle {
            @apply flex flex-row w-max items-center justify-center font-semibold cursor-pointer mx-auto;

            svg {
                @apply w-3 text-silver-chalice ml-2 mt-0.5 transition-transform transform duration-400;
            }
        }

        &--active {
            .page-copy__show-more__toggle {
                @apply mt-4;

                svg {
                    @apply rotate-180;
                }
            }
        }
    }
}

.project-list {
    @apply mt-4 lg:mt-6;

    a {
        text-decoration-line: none !important;
        color: #2d2d2d !important;

        &:hover {
            text-decoration-line: underline !important;
        }

        &:after {
            content: ', ';
        }

        &:last-child:after
        {
            content: '';
        }
    }
}
</style>
