import PivotRepository from "@/services/resources/pivot-repository";
import byPublication from "@/services/resources/mixins/query-by-publication";
import byProject from "@/services/resources/mixins/query-by-project";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byPublication).$mix(byProject);
    }

    projectsByPublication(id) {
        return this.relation('projects', 'publicationId', id, null, [], 'projects');
    }

    publicationsByProject(id, injectedMasterRecord = null) {
        return this.relation('publications', 'projectId', id, null, [], null, injectedMasterRecord);
    }
}

export default Repository;
