<template>
    <label class="form-label">
        <span
            v-if="text"
            class="form-label__text"
            v-html="text"
        ></span>
        <slot></slot>
    </label>
</template>

<script>
export default {
    props: {
        text: String
    }
}
</script>

<style lang="postcss">
.form-label {
    @apply text-sm block w-full;

    &__text {
        @apply block mb-1.5 font-sans-medium;
    }
}
</style>
