<template>
    <FormLabel
        :text="strLabel"
        class="form-select form-control"
        :class="classes.concat(errorClass)"
    >
        <BaseSelect
            v-bind="baseComponentBindings"
            @update:model-value="update"
            @focus="clearError"
        />
        <FormError :text="error"/>
        <FormHelp :text="helpText"/>
    </FormLabel>
</template>

<script>
import formSelectComposable from "@/composables/forms/form-select";

export default {
    mixins: [{
        props: formSelectComposable.props()
    }],

    setup(props, {emit}) {
        return {
            ...formSelectComposable.composables(props, emit)
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],

    props: {
        hasError: Number,
    },

    data(){
        return {
            errorClass: [],
        }
    },

    computed:{
        strLabel(){
            return this.required && this.label ? `${this.label} *` : this.label;
        },
    },

    methods: {


        clearError(){
            this.errorClass = [];
        }
    },

    watch: {
        hasError(val){
            if(val){
                this.errorClass.push('form-control--error');
            } else {
                this.errorClass = [];
            }
        }
    }
}
</script>

<style lang="postcss">
.form-select {
    &.form-control--error {
        .base-select {
            &__toggle {
                @apply border-red;
            }
        }
    }
}
</style>
