export default {
    type: 'event-keywords',
    aliases: {
        camel: 'eventKeywords',
        singular: 'Event keyword',
        plural: 'Event keywords',
        lowerSingular: 'event keyword',
        lowerPlural: 'event keywords',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
