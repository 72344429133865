<template>
    <div class="postcard">
        <BaseImg
            v-if="imgSrc"
            :src="imgSrc"
            :resolve="resolveImgSrc"
            :alt="alt"
        />
        <div class="postcard__legibility-gradient"></div>
        <div class="postcard__text">
            <div class="postcard__text__title">
                <slot name="title"></slot>
            </div>
            <div class="postcard__text__strapline">
                <slot name="strapline"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        imgSrc: String,
        alt: String,
        resolveImgSrc: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="postcss">
.postcard {
    @apply relative rounded-lg overflow-hidden;

    &__legibility-gradient {
        @apply absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-black to-transparent opacity-80;
    }

    &__text {
        @apply absolute bottom-0 left-0 w-full p-3 sm:p-6 text-white;

        &__title {
            @apply font-heading text-lg;

            @screnn sm {
                @apply text-xl;
            }
        }

        &__strapline {
            @apply text-sm;
        }
    }
}
</style>
