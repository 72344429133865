export default {
    type: 'ebulletins',
    name: 'eBulletins',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleEbulletin',
            loader: () => import('./ModuleEbulletin')
        }
    }
};
