export default {
    type: 'key-values',
    aliases: {
        camel: 'keyValues',
        singular: 'Key value',
        plural: 'Key values',
        lowerSingular: 'key value',
        lowerPlural: 'key values',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'base',
    searchOn: ['category', 'title'],
    components: {
        adminRecord: {
            name: 'AdministerKeyValueRecord',
            loader: () => import('./AdministerKeyValueRecord')
        },
        adminTranslation: {
            name: 'AdministerKeyValueTranslation',
            loader: () => import('./AdministerKeyValueTranslation')
        },
    }
};
