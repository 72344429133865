import Factory from './factory';

export default new Factory()
    .addProp('maxLength', {
        type: Number,
        required: false,
        default: null,
    })
    .addProp('autofocus', Boolean)
    .addProp('placeholder', String)
    .addProp('required', Boolean)
    .addPassthrough('maxLength')
    .addPassthrough('autofocus')
    .addPassthrough('placeholder')
    .addPassthrough('required')
