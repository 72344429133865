export default {
    type: 'cookies',
    aliases: {
        camel: 'cookies',
        singular: 'Cookie Category',
        plural: 'Cookie Categories',
        lowerSingular: 'cookie category',
        lowerPlural: 'cookie categories',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'base',
    components: {
        adminCollection: {
            name: 'AdministerCookieCollection',
            loader: () => import('./AdministerCookieCollection')
        },
        adminRecord: {
            name: 'AdministerCookieRecord',
            loader: () => import('./AdministerCookieRecord')
        },
        adminTranslation: {
            name: 'AdministerCookieTranslation',
            loader: () => import('./AdministerCookieTranslation')
        },
    }
};
