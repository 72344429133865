export default {
    type: 'show-more',
    name: 'Rich Text (Show more)',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleShowMore',
            loader: () => import('./ModuleShowMore')
        }
    }
};
