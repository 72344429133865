<template>
    <FormLabel
        :text="strLabel"
        class="form-control form-checkbox"
        :class="classes.concat(errorClass)"
    >
        <div class="form-checkbox__inner">
            <BaseCheckbox
                v-bind="baseComponentBindings"
                @update:model-value="update"
                @change="clearError"
                ref="input"
                class="form-control__input"
            />
            <slot></slot>
        </div>
        <FormError :text="error"/>
        <FormHelp :text="helpText"/>
    </FormLabel>
</template>

<script>
import formInputComposable from "@/composables/forms/form-input";

export default {
    mixins: [{
        props: formInputComposable.props()
    }],

    setup(props, {emit}) {
        return {
            ...formInputComposable.composables(props, emit)
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],

    props: {
        hasError: Number,
    },

    data() {
        return {
            errorClass: [],
        }
    },

    computed: {
        strLabel(){
            return this.required && this.label ? `${this.label} *` : this.label;
        }
    },

    methods: {
        clearError(){
            this.errorClass = [];
        }
    },

    watch: {
        hasError(val){
            if(val){
                this.errorClass.push('form-control--error');
            } else {
                this.errorClass = [];
            }
        }
    }
}
</script>

<style lang="postcss">
.form-checkbox {
    @apply cursor-pointer relative block;

    &__inner {
        @apply flex items-center text-xs;
    }

    .base-checkbox {
        @apply flex-shrink-0 mr-2;
    }

    p {
        @apply !m-0;

        a {
            @apply text-royal-purple underline cursor-pointer;
        }
    }
}
</style>
