<template>
    <transition
        v-if="asset"
        name="fade"
    >
        <div class="asset-library__preview">
            <div class="col asset-library__preview__image">
                <a
                    class="asset-library__preview__image__close"
                    @click="close"
                >
                    <IconRenderer name="Cross" />
                </a>

                <div class="asset-library__preview__image__image">
                    <img
                        :src="imagePath"
                        alt=""
                    >
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: "PreviewAsset",

    computed: {
        ...mapGetters('asset', {
            asset: 'asset',
            basePath: 'basePath',
        }),

        imagePath(){
            if(this.asset !== ''){
                return this.asset;
            } else {
                return null;
            }
        }
    },

    methods: {
        ...mapMutations('asset', {
            setAsset: 'setAsset',
        }),

        close(){
            this.setAsset(null);
        }
    }
}
</script>

<style lang="postcss">
.asset-library__preview {
    @apply fixed w-full h-full bg-cod-gray bg-opacity-95 top-0 left-0 z-20 m-0;

	&__image {
        @apply relative flex w-full h-full items-center justify-center;

		&__close {
            @apply cursor-pointer text-white absolute z-30 top-4 right-4;

            svg {
                @apply w-8 h-8;
            }
		}

        &__image {
            @apply relative w-full h-full flex items-center justify-center text-center;

			img {
                @apply border-4 border-white w-auto h-auto;
				max-width: 80%;
				max-height: 80%;
			}
		}
	}
}
</style>
