import Factory from '../factory';

const schema = Factory.schema('blockquote');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('quote').setType(String).setValue('Quote');
attributes.prop('author').setType(String).setValue('Author');
attributes.prop('position').setType(String).setValue('Position');
attributes.prop('variant').setType(String).setValue('alpha');

export default schema;
