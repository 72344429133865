import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .hasLotusNotesId()
    .translatable()
    .hasTitle()
    .hasSummary()
    .hasImage()
    .publishable()
    .publishableByDate()
    .hasTimestamps()
    .make();

schema.prop('legacy').setType(Boolean).setValue(false).addMetadata('draftable', true);
schema.prop('pdf').setType(String).setValue(null).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('mailchimpCampaignId').setType(String).setValue(null).addMetadata('translatable', true).addMetadata('draftable', true);

export default schema;
