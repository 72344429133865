<template>
    <div class="social-icons">
        <a
            v-if="translations.youtube"
            :href="translations.youtube"
            target="_blank"
            class="social-icons__icon social-icons__icon--youtube"
        >
            <IconRenderer
                name="YouTube"
            />
        </a>

        <a
            v-if="translations.facebook"
            :href="translations.facebook"
            target="_blank"
            class="social-icons__icon social-icons__icon--facebook"
        >
            <IconRenderer
                name="Facebook"
            />
        </a>

        <a
            v-if="translations.twitter"
            :href="translations.twitter"
            target="_blank"
            class="social-icons__icon social-icons__icon--twitter"
        >
            <IconRenderer
                name="Twitter"
            />
        </a>

        <a
            v-if="translations.linkedin"
            :href="translations.linkedin"
            target="_blank"
            class="social-icons__icon social-icons__icon--linkedin"
        >
            <IconRenderer
                name="LinkedIn"
            />
        </a>
    </div>
</template>

<script>
import KeyValue from "@/services/lookup/key-value";

export default {
    data(){
        return {
            translations: {
                facebook: null,
                twitter: null,
                linkedin: null,
                youtube: null,
            }
        }
    },

    mounted(){
        KeyValue.show('footer', 'follow-link-facebook').then(val => {
            this.translations.facebook = val;
        });
        KeyValue.show('footer', 'follow-link-twitter').then(val => {
            this.translations.twitter = val;
        });
        KeyValue.show('footer', 'follow-link-linkedin').then(val => {
            this.translations.linkedin = val;
        });
        KeyValue.show('footer', 'follow-link-youtube').then(val => {
            this.translations.youtube = val;
        });
    }
}
</script>

<style lang="postcss">
.social-icons {
    @apply w-full flex flex-row;

    &__icon {
        @apply w-14 h-14 rounded-full bg-cararra lg:w-16 lg:h-16 flex justify-center items-center text-st-tropaz mr-4 cursor-pointer;

        &:hover {
            @apply bg-st-tropaz text-white;
        }

        svg {
            @apply w-1/2 h-auto;
        }

        &--facebook {
            svg {
                @apply w-auto h-1/2;
            }
        }

        &--linkedin {
            svg {
                @apply w-auto h-2/5;
            }
        }
    }
}
</style>
