<template>
    <div v-if="item">
        <navigation-link
            :to="href"
            class="poster__img-container"
        >
            <PosterImg
                :content-type="contentType"
                :label="label"
                :item="item"
            />
        </navigation-link>

        <Card class="poster__card">
            <div
                v-if="programmeArea"
                class="poster__card__category"
                v-html="programmeArea.title"
            />

            <div class="flex-grow">
                <navigation-link :to="href">
                    <h2 class="poster__card__title">
                        <span v-html="item.title" />
                    </h2>
                </navigation-link>
                <p
                    class="poster__card__date"
                    v-html="dateLocation"
                />
            </div>

            <navigation-link
                :to="href"
                class="poster__card__discover"
            >
                {{ discoverStr }}
            </navigation-link>

            <slot name="share" />
        </Card>
    </div>
</template>

<script>
import Kernel from "@oilstone/kernel";
import Translation from "@/services/lookup/translation";
import Context from "@/services/context";

export default {
    props: {
        contentType: {
            type: String,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        href: {
            type: String,
            default: null,
        }
    },

    computed: {
        dateLocation() {
            let date = this.item.startDate;

            let str = (date ? '<span>' + this.$filters.date.format(date) + '</span>' : '');
            return str + (this.item.location && str ? ', ' : '') + (this.item.location ? this.item.location : '');
        },
    },

    data(){
        return {
            programmeArea: null,
            label: null,
            discoverStr: 'Discover',
        }
    },

    methods: {
        getDetails(){
            this.programmeArea = null;
            this.label = null;

            if(Context.getLanguage() !== "en") {
                Kernel.resolve("resourceRegistry").find("event-types").repository.getLabels().then(results => {
                    results.forEach(row => {
                        if(row.translatesId === this.item.eventTypeId){
                            this.label = row.title;
                        }
                    })
                });
            } else {
                if (this.item.eventType) {
                    this.label = this.item.eventType.title;
                } else if (this.item.eventTypeId) {
                    Kernel.resolve('resourceRegistry').find('event-types').repository.find(this.item.eventTypeId).then(type => {
                        if (type.title) {
                            this.label = type.title;
                        }
                    });
                }
            }

            if(this.item.eventProgrammeAreas && this.item.eventProgrammeAreas.length > 0){
                this.programmeArea = this.item.eventProgrammeAreas[0].programmeAreas
            }
        }
    },

    mounted(){
        this.getDetails();

        Translation.many(['discover']).then(translations => {
            if(translations){
                this.discoverStr = translations['discover'];
            }
        });
    },

    watch: {
        item(){
            this.getDetails();
        }
    }
}
</script>
