<template>
    <FormFields>
        <ResourceInput
            label="Start date"
            attribute="startDate"
            type="date"
        />

        <ResourceInput
            label="End date"
            attribute="endDate"
            type="date"
        />
    </FormFields>
</template>
