<template>
    <svg
        class="icon-burger stroke-current"
        viewBox="0 0 32 23"
        xmlns="http://www.w3.org/2000/svg"
    >
        <line
            fill="none"
            stroke-width="2"
            x2="32"
            y1="1"
            y2="1"
        />
        <line
            fill="none"
            stroke-width="2"
            x2="32"
            y1="12"
            y2="12"
        />
        <line
            fill="none"
            stroke-width="2"
            x2="32"
            y1="22"
            y2="22"
        />
    </svg>
</template>