<template>
    <Poster
        :resource="resolvedItem.resource"
        :content-type="resolvedItem.contentType"
        :variant="variant"
    />
</template>

<script>
export default {
    props: {
        variant: String,
        contentType: String,
        item: Object
    },

    computed: {
        resolvedItem() {
            if (this.item.contentType) {
                return this.item
            }

            return {
                contentType: this.contentType,
                resource: this.item
            }
        }
    }
}
</script>
