<template>
    <svg
        class="icon-gift"
        viewBox="0 0 19.69 18.09"
        xmlns="http://www.w3.org/2000/svg"
    ><path
        d="M15.24,3.31V2A1.93,1.93,0,0,0,14.73.6,1.68,1.68,0,0,0,13.46,0H11A1.69,1.69,0,0,0,9.74.57,1.69,1.69,0,0,0,8.5,0H6A1.68,1.68,0,0,0,4.75.6,1.89,1.89,0,0,0,4.24,2h0V3.31H0V9.55H1.77v8.54H17.91V9.55h1.78V3.31ZM10.71,2c0-.3.17-.49.27-.49h2.48c.1,0,.28.19.28.49v.88h-3Zm-5,0c0-.3.18-.49.28-.49H8.5c.09,0,.27.19.27.49v.88h-3ZM9,16.59H3.27v-7H9ZM9,8.05H1.5V4.81H9Zm7.45,8.54H10.46v-7h5.95Zm1.78-8.54H10.46V4.81h7.73Z"
        fill="#111"
        transform="translate(0 0)"
    /></svg>
</template>
