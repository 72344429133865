<template>
    <AdminPicker
        v-if="options.length"
        :label="label"
        :options="options"
        :model-value="value"
        @update:modelValue="prepareUpdate(attribute, $event)"
        :debug="debug"
    />
</template>

<script>
import {computed} from "vue";
import Kernel from "@oilstone/kernel";
import {accessesData, mutatesData} from "@/services/data-layer/composers";

const registry = Kernel.resolve('resourceRegistry');

export default {
    props: {
        label: String,
        attribute: String,
        relation: String,
        debug: {
            type: Boolean,
            default: false
        }
    },

    setup(props) {
        const {resolve} = accessesData();
        const {prepareUpdate, dirty} = mutatesData();

        const value = computed(() => {
            return typeof dirty.updates[props.attribute] !== 'undefined' ? dirty.updates[props.attribute] : resolve(props.attribute).value;
        });

        const getOptions = () => {
            let rel = registry.find(props.relation);

            if (!rel) {
                return Promise.resolve([])
            }

            return rel.repository.all().then(collection => {
                const mapped = collection.map(record => {
                    return {
                        text: record.lastName ? record.lastName + ', ' + record.firstName : record.title,
                        value: parseInt(record.id),
                        published: typeof record.isPublished === 'undefined' ? null : record.isPublished,
                    }
                });

                return mapped;
            });
        }

        return {
            value,
            prepareUpdate,
            getOptions
        };
    },

    data() {
        return {
            options: [],
        }
    },

    methods: {
        fetchObjects(){
            try {
                this.getOptions().then(options => {
                    this.options = options.sort((a, b) => (a.text.toUpperCase() > b.text.toUpperCase()) ? 1 : -1);
                });
            } catch(e){
                return [];
            }
        }
    },

    mounted() {
        this.fetchObjects();
    },

    watch: {
        relation() {
            this.fetchObjects();
        }
    },
}
</script>
