export default {
    type: 'vacancy-modules',
    aliases: {
        camel: 'vacancyModules',
        singular: 'Vacancy module',
        plural: 'Vacancy modules',
        lowerSingular: 'vacancy module',
        lowerPlural: 'vacancy modules',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
