import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .sortable()
    .hasDecade()
    .hasTitle()
    .hasSummary()
    .hasImage()
    .hasYearRange()
    .publishable()
    .publishableByDate()
    .hasTimestamps()
    .make();

schema.prop('unrisdTimelineEntryTypeId').setType(Number).addMetadata('mergeable', true).addMetadata('draftable', true);
schema.prop('furtherInfoUrl').setType(String).setValue(null).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('vson').setType(String).addMetadata('translatable', true).addMetadata('draftable', true);

export default schema;
