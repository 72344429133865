import {RestModel} from "@oilstone/rest-model";

export default config => {
    const type = config.modelName || config.type;

    let model = RestModel.resolve(type)

    if (!model) {
        RestModel.register(type, factory => {
            return factory.collectable(type);
        });

        model = RestModel.resolve(type)
    }

    return model;
};
