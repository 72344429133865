export default {
    type: 'page-adverts',
    name: 'Page adverts',
    osOnly: true,
    components: {
        root: {
            name: 'ModulePageAdverts',
            loader: () => import('./ModulePageAdverts')
        }
    }
};
