import Kernel from "@oilstone/kernel/dist/kernel";

class Validator {
    #http;
    #model;
    static #baseUrl;

    constructor(model) {
        this.#http = Kernel.resolve('httpClient');
        this.#model = model;
    }

    static make(http, model) {
        return new Validator(http, model);
    }

    static setBaseUrl(url) {
        Validator.#baseUrl = url;
    }

    run(attributes) {
        return this._send(this._buildPayload(attributes));
    }

    _buildPayload(attributes) {
        return {
            data: {
                attributes: {
                    type: this.#model.getType(),
                    attributes
                }
            }
        }
    }

    _send(payload) {
        return this.#http.post(
            `${Validator.#baseUrl}/validate`,
            payload,
            {headers: {'Content-Type': 'application/vnd.api+json'}}
        ).catch(error => {
            throw error.response.data.errors;
        });
    }
}

export default Validator;
