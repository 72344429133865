export default {
    type: 'newsletter-signup',
    name: 'Newsletter signup',
    osOnly: true,
    components: {
        root: {
            name: 'ModuleNewsletterSignup',
            loader: () => import('./ModuleNewsletterSignup')
        }
    }
};
