export default {
    type: 'regions',
    aliases: {
        camel: 'regions',
        singular: 'Region',
        plural: 'Regions',
        lowerSingular: 'region',
        lowerPlural: 'regions',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'lookup',
    components: {},
};
