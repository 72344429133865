<template>
    <div
        class="ui-blocker"
        :class="classes"
    >
        <IconRenderer
            name="Spinner"
            class="ui-blocker__spinner"
        />
    </div>
</template>

<script>
import Kernel from '@oilstone/kernel';

export default {
    data() {
        return {
            visible: false
        }
    },

    computed: {
        classes() {
            return this.visible ? ['ui-blocker--visible'] : [];
        }
    },

    mounted() {
        let self = this;

        Kernel.resolve('event').listen({
            blockUI() {
                self.visible = true;
            },
            unblockUI() {
                self.visible = false;
            }
        });
    }
}
</script>

<style lang="postcss">
.ui-blocker {
    @apply fixed z-50 flex items-center justify-center w-screen h-screen bg-big-stone transition-opacity duration-700 opacity-0 pointer-events-none;

    &--visible {
        @apply opacity-50 pointer-events-auto;
    }

    &__spinner {
        @apply w-10 mr-4 text-white fill-current animate-spin;
    }
}
</style>
