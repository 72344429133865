import {inject} from "vue";
import Kernel from "@oilstone/kernel";
import Events from "@oilstone/events";
import Cache from "./cache";

const cache = new Cache();
const events = new Events();

const boot = (scope, uuid, parentUuid) => {
    cache.put(
        uuid,
        () => {
            const store = Kernel.resolve('vuexStore');

            const fire = (event) => {
                events.fire(`${uuid}-${event}`, {
                    uuid,
                    item: store.getters[scope.path().to('item')](uuid)
                });
            }

            const listen = (listeners) => {
                const resolvedListeners = {};

                for (let event in listeners) {
                    const resolvedEvent = event.startsWith('parent') ? event.replace('parent', `${parentUuid}-`) : `${uuid}-${event}`;

                    resolvedListeners[resolvedEvent] = listeners[event];
                }

                events.listen(resolvedListeners);
            }

            return {
                listen,
                fire
            }
        }
    );
};

const resolve = (uuid) => {
    return cache.resolve(uuid || inject('uuid'));
};

const forget = () => {
    cache.clear();
};

export {boot, resolve, forget};
export default resolve;
