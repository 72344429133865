import {AccessToken as BaseAccessToken, RestModel} from "@oilstone/rest-model";
import Kernel from "@oilstone/kernel";

class Auth {
    #tokenClient;

    #accessToken;

    #grantType;

    #sessionName = 'unrisdSessionStorageV1';

    constructor(client) {
        this.#tokenClient = client;
    }

    client() {
        return this.fetch({
            grantType: 'client_credentials',
        }).then(token => {
            this.register('client_credentials', token);
        });
    }

    user(username, password) {
        return this.fetch({
            grantType: 'password',
            username,
            password
        }).then(token => {
            return this.register('password', token).remember(token);
        });
    }

    refresh() {
        if (this.#grantType !== 'password') {
            return this.client();
        }

        return this.fetch({
            grantType: 'refresh_token',
            token: this.#accessToken.refresh_token,
        }).then(token => {
            return this.register('password', token).remember(token);
        }).catch(() => {
            return this.logout();
        });
    }

    recall() {
        if (this.#accessToken) {
            return this.#accessToken;
        }

        let token = Kernel.resolve('localStorage').get(this.#sessionName);

        if (!token) {
            return this.client();
        }

        return this.register('password', token).remember(token);
    }

    fetch(payload) {
        return this.#tokenClient.fetch(payload);
    }

    register(type, accessToken) {
        this.#grantType = type;
        this.#accessToken = accessToken;

        RestModel.setAccessToken(
            new BaseAccessToken(accessToken.token_type, accessToken.access_token).onRefresh(token => {
                return this.refresh().then(() => {
                    token.value = this.#accessToken.access_token;
                    return true;
                });
            })
        );

        return this;
    }

    remember(accessToken) {
        Kernel.resolve('localStorage').set(this.#sessionName, accessToken);

        return this;
    }

    /**
     * @returns {Promise}
     */
    isLoggedIn() {
        if (this.#grantType === 'password' && this.#accessToken) {
            if (Date.now() >= this.#accessToken.expires * 1000) {
                return this.refresh().then(() => {
                    return this.isLoggedIn();
                }).catch(() => {
                    return Promise.reject();
                });
            }

            return Promise.resolve();
        }

        return Promise.reject();
    }

    logout() {
        Kernel.resolve('localStorage').remove(this.#sessionName);

        return this.client();
    }

    isOS() {
        return this.owner().then(owner => {
            if (!owner || parseInt(owner.id) !== 1) {  //TODO: Identify OS user much better than this
                return Promise.resolve(false);
            }

            return Promise.resolve(true);
        });
    }

    owner() {
        if (this.#grantType !== 'password') {
            return null;
        }

        return this.#tokenClient.owner(new BaseAccessToken(this.#accessToken.token_type, this.#accessToken.access_token)).then(attributes => {
            return Object.assign({id: attributes.id}, attributes.data);
        }).catch(() => {
            return null;
        });
    }
}

export default Auth;
