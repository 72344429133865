export default {
    type: 'search-content-advanced',
    name: 'Advanced Search Content',
    osOnly: true,
    components: {
        root: {
            name: 'ModuleSearchContentAdvanced',
            loader: () => import('./ModuleSearchContentAdvanced')
        }
    }
};
