import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .sluggable()
    .hasDecade()
    .hasLocation()
    .hasTitle()
    .hasImage()
    .hasBody()
    .hasMetadata()
    .publishable()
    .publishableByDate()
    .hasTimestamps()
    .make();

schema.prop('externalAuthors').setType(String).addMetadata('translatable', true).addMetadata('draftable', true);
schema.prop('outreach').setType(Boolean).setValue(false).addMetadata('translatable', true).addMetadata('draftable', true);

export default schema;
