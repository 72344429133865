import Factory from '../factory';

const schema = Factory.schema('dynamic-accordion');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('title').setType(String).setValue('Publications');
attributes.prop('variant').setType(String).setValue('alpha');
attributes.prop('items').setType(Array);

export default schema;
