<template>
    <div class="admin-translation-form-fields">
        <component
            :is="component"
            :key="'translation-form-fields-' + language"
        />
    </div>
</template>

<script>
import {inject, provide} from 'vue';
import component from "@/services/resources/component";

export default {
    setup(props) {
        provide('language', props.language);

        return {
            component: component(inject('type'), props.componentName),
        }
    },

    props: {
        language: {
            type: String,
            required: false,
            default: 'en',
        },

        componentName: {
            type: String,
            required: false,
            default: 'adminTranslation',
        },
    },
}
</script>
