export default {
    type: 'themes',
    aliases: {
        camel: 'themes',
        singular: 'Theme',
        plural: 'Themes',
        lowerSingular: 'theme',
        lowerPlural: 'themes',
    },
    relations: {
        'modules': {
            pivot: {
                type: 'theme-modules',
                localKey: 'themeId',
                foreignKey: 'moduleId'
            }
        },
        'timeline-entries': {
            pivot: {
                type: 'theme-timeline-entries',
                localKey: 'themeId',
                foreignKey: 'timelineEntryId'
            }
        },
    },
    publishable: true,
    adminSection: 'research',
    componentType: 'base',
    filterOn: {
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerThemeRecord',
            loader: () => import('./AdministerThemeRecord')
        },
        adminTranslation: {
            name: 'AdministerThemeTranslation',
            loader: () => import('./AdministerThemeTranslation')
        },
        webRecord: {
            name: 'DisplayThemeRecord',
            loader: () => import('./DisplayThemeRecord')
        }
    }
};
