<template>
    <div class="partial-title">
        <h2
            v-if="title"
            :class="showSummary ? '' : 'partial-title__title--no-summary'"
            class="partial-title__title"
            v-html="title"
        />
        <div
            v-if="showSummary"
            class="partial-title__summary-grid"
        >
            <div
                class="partial-title__summary"
                v-html="summary"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null,
        },
        summary: {
            type: String,
            default: null,
        },
    },

    computed: {
        showSummary(){
            return !!(this.summary && this.summary.replace(/(<([^>]+)>)/gi, "") !== '');
        }
    }
}
</script>

<style lang="postcss">
.partial-title {
    @apply pt-4;

    &__title {
        @apply mb-2 lg:pt-6;

        &--no-summary {
            @apply mb-8 lg:mb-12;
        }
    }

    &__summary-grid {
        @apply lg:grid lg:grid-cols-12;
    }

    &__summary {
        @apply font-goudy lg:col-span-8 trim-content mb-8 lg:mb-12;

        > p {
            &:only-child {
                br {
                    @apply hidden;
                }
            }

            &:empty {
                @apply hidden;
            }
        }
    }
}
</style>
