<template>
    <BaseImg
        :src="imageSrc"
        :alt="item.imageAlt"
        :resolve="!item.image"
        class="poster__img poster__img--default"
    />
    <div
        v-if="label"
        class="poster__img-overlay"
        v-html="type"
    />
    <div
        v-if="contentType === 'videos'"
        class="poster__img-video"
    >
        <IconRenderer
            class="poster__img-video__bg"
            name="Video"
        />
        <IconRenderer
            class="poster__img-video__play"
            name="Play"
        />
    </div>
</template>

<script>
import Context from "@/services/context";

export default {
    props: {
        item: Object,
        contentType: String,
        label: String
    },

    computed: {
        type() {
            const language = Context.getLanguage();
            let label = '';

            if(this.label === this.contentType) {
                if (this.label.indexOf('-series') > -1) {
                    label = this.label.replace('-', ' ');
                } else {
                    label = this.label.substring(0, this.label.length - 1).replace('-', ' ');
                }
            } else {
                label = this.label;
            }

            if (language !== 'en') {
                switch (language) {
                case 'fr':
                    switch(label.toLowerCase()) {
                    case 'news':
                        return 'Actualités';

                    case 'podcast series':
                        return 'Séries de podcasts';

                    case 'blog series':
                        return 'Séries de blogs';

                    case 'project':
                        return 'Projet';

                    case 'programme area':
                        return 'Programme';
                    }

                    break;

                case 'es':
                    switch(label.toLowerCase()) {
                    case 'news':
                        return 'Noticias';

                    case 'podcast series':
                        return 'Serie podcast';

                    case 'blog series':
                        return 'Serie de blogs';

                    case 'project':
                        return 'Proyecto';

                    case 'programme area':
                        return 'Programa';
                    }

                    break;
                }
            }

            return label;
        },

        imageSrc() {
            switch (this.contentType){
            case 'blog-posts':
                return this.item.image ? this.item.image : 'blog.png';

            case 'blog-series':
                return this.item.image ? this.item.image : 'blog.png';

            case 'ebulletins':
                return this.item.image ? this.item.image : 'ebulletin.png';

            case 'events':
                return this.item.image ? this.item.image : 'event.png';

            case 'news-items':
                return this.item.image ? this.item.image : 'news.png';

            case 'podcasts':
                return this.item.image ? this.item.image : 'podcast-singular.jpg';

            case 'podcast-series':
                return this.item.image ? this.item.image : 'podcast-series.jpg';

            case 'programme-areas':
                return this.item.image ? this.item.image : 'programme-area.png';

            case 'projects':
                return this.item.image ? this.item.image : 'project.png';

            case 'publications':
                return this.item.image ? this.item.image : 'publication.png';

            default:
                return this.item.image ? this.item.image : 'poster-placeholder.png';
            }
        },
    }
}
</script>
