<template>
    <AdminFormGroup
        class="admin-radio-group"
        :class="classes"
    >
        <FormLabel
            :text="label"
            class="form-control"
        />

        <div class="admin-radio-group__input grid grid-cols-3 gap-4 text-sm">
            <FormGroup
                v-for="(option, index) in options"
                :key="index"
            >
                <FormRadio
                    :value="option.value"
                    v-bind="baseComponentBindings"
                    @update:model-value="update"
                >
                    {{ option.label }}
                </FormRadio>
            </FormGroup>
        </div>

        <FormError :text="error"/>
        <FormHelp :text="helpText"/>
    </AdminFormGroup>
</template>

<script>
import formRadioGroupComposable from "@/composables/forms/form-radio-group";

export default {
    mixins: [{
        props: formRadioGroupComposable.props()
    }],

    setup(props, {emit}) {
        return {
            ...formRadioGroupComposable.composables(props, emit)
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],
}
</script>
