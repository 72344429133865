import {Schema, Collection} from "@oilstone/rest-model-repository";
import Factory from '../factory';

const schema = Factory.schema('further-information');
const attributes = schema.getProp('attributes').getValue();
const item = new Schema();

item.prop('category').setType(String).setValue('2021');
item.prop('title').setType(String).setValue('Title');
item.prop('summary').setType(String).setValue('Summary');
item.prop('label').setType(String).setValue('Download PDF');
item.prop('src').setType(String).setValue('');

attributes.prop('variant').setType(String).setValue('alpha');
attributes.prop('title').setType(String).setValue('Title');
attributes.prop('summary').setType(String).setValue('Summary');
attributes.prop('items').setType(Collection).setValue(new Collection(item));

export default schema;
