import {Repository as BaseRepository} from "@oilstone/rest-model-repository";
import Blender from "@oilstone/blender";
import Context from '@/services/context';
import Kernel from "@oilstone/kernel";
import dayjs from "dayjs";

class Repository extends BaseRepository {
    #validator;

    static make(model, schema, validator) {
        return new Repository(model, schema, validator);
    }

    constructor(model, schema, validator) {
        super(model, schema);

        this.#validator = validator;
    }

    $mix(mixins) {
        Blender.on(this).mix(mixins);

        return this;
    }

    baseQuery() {
        const query = super.baseQuery();
        const language = Context.getLanguage();

        if (language && this.schema.getProp('language')) {
            query.where('language', language);
        }

        return query;
    }

    findTranslated(id) {
        const language = Context.getLanguage();

        if (language !== 'en') {
            return this.transformer.one(
                this.try(
                    this.baseQuery().where('translatesId', id).first()
                )
            );
        }

        return super.find(id);
    }

    findManyByMasterAndSort(ids) {
        if (Context.getLanguage() === 'en') {
            return this.findManyAndSort(ids);
        }

        const primaryKeyName = this.schema.primaryKey.name;

        return this.transformerPipeline.many(
            this.try(
                super.baseQuery().where('language', 'en').where(this.schema.primaryKey.name, 'in', ids).include('translations').get()
            )
        ).then(collection => {
            return collection.sort((a, b) => {
                return ids.indexOf(a[primaryKeyName]) - ids.indexOf(b[primaryKeyName]);
            }).map(record => {
                return record.translations.find(translation => {
                    return translation.language === Context.getLanguage();
                })
            }).filter(record => record);
        });
    }

    list() {
        return this.transformer.many(
            this.try(
                this.baseQuery().select('id,title').get()
            )
        );
    }

    output(type, ids) {
        let camel = type;

        if (camel === 'programme-areas') {
            camel = 'programmeAreas';
        }

        if (camel === 'news-items') {
            camel = 'newsItems';
        }

        const methodType = camel.charAt(0).toUpperCase() + camel.slice(1, -1);
        const resourceRegistry = Kernel.resolve('resourceRegistry');

        const getPublications = (id) => {
            const pivotResource = resourceRegistry.find(`publication-${type}`);

            if (!pivotResource) {
                return Promise.resolve([]);
            }

            const method = `publicationsBy${methodType}`

            if (typeof pivotResource.repository[method] === 'undefined') {
                return Promise.resolve([]);
            }

            return pivotResource.repository[method](id, {id});
        }

        const getMedia = (mediaType, id) => {
            const repository = resourceRegistry.find(mediaType).repository;
            const method = `by${methodType}`;

            if (typeof repository[method] === 'undefined') {
                return Promise.resolve([]);
            }

            return repository[method](id);
        }

        const media = ['videos', 'podcasts', 'collections'];

        return Promise.all(
            ids.map(id => {
                return Promise.all([
                    getPublications(id).then(collection => {
                        return collection.map(record => {
                            record.date = dayjs(record.publishAt);

                            return {
                                contentType: 'publications',
                                resource: record
                            }
                        });
                    }),
                    ...media.map(item => {
                        return getMedia(item, id).then(collection => {
                            return collection.map(record => {
                                let contentType = item;

                                if (contentType === 'collections') {
                                    contentType = record.section === 'blog-posts' ? 'blog-series' : 'podcast-series';
                                }

                                record.date = dayjs(record.publishAt || record.createdAt);

                                return {
                                    contentType,
                                    resource: record
                                }
                            });
                        })
                    })
                ]).then(arrays => {
                    return [].concat(...arrays);
                })
            })
        ).then(arrays => {
            return [].concat(...arrays).sort((a, b) => {
                return a.resource.date.isBefore(b.resource.date) ? 1 : -1;
            })
        });
    }

    validate(attributes) {
        return this.try(
            this.#validator.run(attributes)
        );
    }
}

export default Repository;
