<template>
    <div class="admin-relation-form-fields">
        <component :is="relationsComponent"/>
    </div>
</template>

<script>
import {inject} from "vue";
import component from "./../component";

export default {
    setup() {
        return {
            relationsComponent: component(inject('type'), 'adminRelations'),
        }
    },
}
</script>
