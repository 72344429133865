import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .make();

schema.prop('fileUploadId').setType(Number);
schema.prop('vacancyId').setType(Number);
schema.prop('firstName').setType(String).setValue('');
schema.prop('middleName').setType(String).setValue('');
schema.prop('lastName').setType(String).setValue('');
schema.prop('maidenName').setType(String).setValue('');
schema.prop('placeOfBirth').setType(String).setValue('');
schema.prop('nationality1').setType(String).setValue('');
schema.prop('nationality2').setType(String).setValue('');
schema.prop('nationality3').setType(String).setValue('');
schema.prop('address1').setType(String).setValue('');
schema.prop('address2').setType(String).setValue('');
schema.prop('address3').setType(String).setValue('');
schema.prop('address4').setType(String).setValue('');
schema.prop('telephoneNumber').setType(String).setValue('');
schema.prop('emailAddress').setType(String).setValue('');
schema.prop('genderIdentity').setType(String).setValue('');
schema.prop('otherGender').setType(String).setValue('');
schema.prop('otherGender').setType(String).setValue('');
schema.prop('dateOfBirth').setType(String).setValue('');
schema.prop('motherTongue').setType(String).setValue('');
schema.prop('activities').setType(String).setValue('');
schema.prop('publications').setType(String).setValue('');
schema.prop('noticePeriod').setType(String).setValue('');
schema.prop('coverLetter').setType(String).setValue('');
schema.prop('confirmed').setType(Number).setValue(0);

export default schema;
