import BaseRepository from "@/services/resources/repository";
import byProgrammeArea from "@/services/resources/mixins/query-by-programme-area";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byProgrammeArea);
    }
}

export default Repository;
