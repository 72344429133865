export default {
    type: 'person-modules',
    aliases: {
        camel: 'personModules',
        singular: 'Person module',
        plural: 'Person modules',
        lowerSingular: 'person module',
        lowerPlural: 'person modules',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
