<template>
    <AlertBanner
        variant="info"
        :message="message"
        class="alert-notification"
    />
</template>

<script>
import {defineAsyncComponent} from 'vue';

export default {
    components: {
        AlertBanner: defineAsyncComponent(() => import('./AlertBanner')),
    },

    props: {
        message: String
    }
}
</script>
