import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .hasLotusNotesId()
    .make();

schema.prop('file').setType(String);

export default schema;
