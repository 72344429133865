export default {
    type: 'event-themes',
    aliases: {
        camel: 'eventThemes',
        singular: 'Event theme',
        plural: 'Event themes',
        lowerSingular: 'event theme',
        lowerPlural: 'event themes',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
