import BaseRepository from "@/services/publication/repository";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema);
    }

    collection() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .sort('path')
                    .get()
            )
        );
    }
}

export default Repository;
