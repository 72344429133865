import {Schema, Collection} from "@oilstone/rest-model-repository";
import Factory from '../factory';

const schema = Factory.schema('statistics');
const attributes = schema.getProp('attributes').getValue();
const item = new Schema();

item.prop('statistic').setType(String);
item.prop('summary').setType(String);

attributes.prop('title').setType(String).setValue('Title');
attributes.prop('summary').setType(String).setValue('Summary');
attributes.prop('source').setType(String).setValue('Source');
attributes.prop('colour').setType(String).setValue('#2864AA');
attributes.prop('items').setType(Collection).setValue(new Collection(item));

export default schema;
