<template>
    <div class="card">
        <slot></slot>
    </div>
</template>

<style lang="postcss">
.card {
    @apply flex bg-pampas p-3.5;
}
</style>
