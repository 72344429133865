// noinspection JSUnresolvedFunction

import Kernel from '@oilstone/kernel';

const state = () => ({
    accepted: false,
    preferences: {
        analytics: false,
        marketing: false,
    },
});

const getters = {
    getCookieAcceptance: state => {
        return state.accepted;
    },

    getCookiePreferences: state => {
        return state.preferences;
    },

    getFunctionalCookiesPreference: state => {
        return state.preferences.accepted;
    },

    getAnalyticsCookiesPreference: state => {
        return state.preferences.analytics;
    },

    getMarketingCookiesPreference: state => {
        return state.preferences.marketing;
    },
};

const mutations = {
    SET_COOKIE_ACCEPTANCE(state, flag) {
        state.accepted = flag;
    },

    SET_ANALYTICS_COOKIES_PREFERENCE(state, flag) {
        state.preferences.analytics = flag;
    },

    SET_MARKETING_COOKIES_PREFERENCE(state, flag) {
        state.preferences.marketing = flag;
    },
};

const actions = {
    setCookieAcceptance({ commit }, flag) {
        commit('SET_COOKIE_ACCEPTANCE', flag);
    },

    setAnalyticsCookiesPreference({ commit }, flag) {
        commit('SET_ANALYTICS_COOKIES_PREFERENCE', flag);
    },

    setMarketingCookiesPreference({ commit }, flag) {
        commit('SET_MARKETING_COOKIES_PREFERENCE', flag);
    },

    hideDialogue({ commit }) {
        commit('SET_DIALOGUE_VISIBILITY', false);
    },

    setRecommended({ commit }) {
        commit('SET_COOKIE_ACCEPTANCE', true);
        commit('SET_ANALYTICS_COOKIES_PREFERENCE', true);
        commit('SET_MARKETING_COOKIES_PREFERENCE', true);
    },

    persist({ state, dispatch }) {
        Kernel.resolve('cookie').set(
            'cookieconsent_preferences_v1',
            JSON.stringify({
                analytics: state.preferences.analytics,
                marketing: state.preferences.marketing,
            }),
            {
                expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), // 1 year
                path: '/',
                domain: '.' + process.env.VUE_APP_SHARED_SESSION_DOMAIN,
                sameSite: 'Lax',
                secure: process.env.VUE_APP_NODE_ENV !== 'development',
            }
        );

        dispatch('updateGtm');
    },

    hydrate({ commit, state }) {
        let cookies = Kernel.resolve('cookie').get('cookieconsent_preferences_v1');
        const gtag = window.gtag;

        if (cookies) {
            cookies = JSON.parse(cookies);

            commit('SET_COOKIE_ACCEPTANCE', true);
            commit('SET_ANALYTICS_COOKIES_PREFERENCE', cookies.analytics);
            commit('SET_MARKETING_COOKIES_PREFERENCE', cookies.marketing);

            if(window.gtag){
                gtag('consent', 'update', {analytics_storage: state.preferences.analytics ? 'granted' : 'denied'});
                gtag('consent', 'update', {ad_storage: state.preferences.marketing ? 'granted' : 'denied'});
                gtag('consent', 'update', {personalization_storage: state.preferences.marketing ? 'granted' : 'denied'});
                gtag('consent', 'update', {functionality_storage: 'granted'});
                gtag('consent', 'update', {security_storage: 'granted'});

                gtag({
                    event: 'consent-set',
                    analytics_storage: state.preferences.analytics ? 'granted' : 'denied',
                    ad_storage: state.preferences.marketing ? 'granted' : 'denied',
                    personalization_storage: state.preferences.marketing ? 'granted' : 'denied',
                    functionality_storage: 'granted',
                    security_storage: 'granted',
                });
            }
        } else {
            commit('SET_COOKIE_ACCEPTANCE', false);
            commit('SET_ANALYTICS_COOKIES_PREFERENCE', false);
            commit('SET_MARKETING_COOKIES_PREFERENCE', false);

            if(window.gtag){
                gtag('consent', 'default', {analytics_storage: 'denied'});
                gtag('consent', 'default', {ad_storage: 'denied'});
                gtag('consent', 'default', {personalization_storage: 'denied'});
                gtag('consent', 'default', {functionality_storage: 'granted'});
                gtag('consent', 'default', {security_storage: 'granted'});

                gtag({
                    event: 'consent-set',
                    analytics_storage: 'denied',
                    ad_storage: 'denied',
                    personalization_storage: 'denied',
                    functionality_storage: 'granted',
                    security_storage: 'granted',
                });
            }
        }
    },

    updateGtm({ state }) {
        if (window.gtag) {
            const gtag = window.gtag;

            gtag('consent', 'update', {analytics_storage: state.preferences.analytics ? 'granted' : 'denied'});
            gtag('consent', 'update', {ad_storage: state.preferences.marketing ? 'granted' : 'denied'});
            gtag('consent', 'update', {personalization_storage: state.preferences.marketing ? 'granted' : 'denied'});
            gtag('consent', 'update', {functionality_storage: 'granted'});
            gtag('consent', 'update', {security_storage: 'granted'});

            gtag({
                event: 'consent-updated',
                analytics_storage: state.preferences.analytics ? 'granted' : 'denied',
                ad_storage: state.preferences.marketing ? 'granted' : 'denied',
                personalization_storage: state.preferences.marketing ? 'granted' : 'denied',
                functionality_storage: 'granted',
                security_storage: 'granted',
            });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
