<template>
    <a
        :href="facebookHref"
        target="_blank"
        class="share-icon share-icon--facebook"
        @click="emitClick"
    >
        <IconRenderer name="Facebook" />
    </a>
    <a
        :href="twitterHref"
        target="_blank"
        class="share-icon share-icon--twitter"
        @click="emitClick"
    >
        <IconRenderer name="Twitter" />
    </a>
    <a
        :href="linkedinHref"
        target="_blank"
        class="share-icon share-icon--linkedin"
        @click="emitClick"
    >
        <IconRenderer name="LinkedIn" />
    </a>
    <a
        :href="emailHref"
        class="share-icon share-icon--email"
        @click="emitClick"
    >
        <IconRenderer name="Envelope" />
    </a>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    emits: ['clicked'],

    props: {
        title: {
            type: String,
            default: null,
        },
        href: {
            type: String,
            default: null
        }
    },

    computed: {
        ...mapGetters('meta', {
            pageTitle: 'pageTitle',
            ogImage: 'ogImage',
            ogData: 'ogData',
        }),

        shareTitle(){
            return this.title ? this.title : this.pageTitle;
        },

        ogPhoto(){
            return '';
        },

        ogDescription(){
            return '';
        },

        pageHref(){
            let h = this.href ? this.href : window.location.href;

            if(h.indexOf('http') < 0){
                h = process.env.VUE_APP_URL + "/en/" + h;
            }

            return h;
        },

        emailHref(){
            return "mailto:?subject=" + encodeURIComponent(this.shareTitle) + " - UNRISD&body=" + encodeURIComponent(
                'Thanks for your interest in UNRISD! We hope you enjoy ' + this.shareTitle + '.\n' +
                'If you liked this, why not sign up for updates from UNRISD at ' + process.env.VUE_APP_URL + '/en/sign-up-for-email-alerts\n'+
                '\n'+
                'Best wishes,\n' +
                'The UNRISD team'
            );
        },

        twitterHref(){
            let o = 'https://twitter.com/share';
            o += "?text=" + encodeURIComponent(this.shareTitle);
            o += "&url=" + encodeURIComponent(this.pageHref);
            o += "&via=" + encodeURIComponent('UNRISD');
            return o;
        },

        facebookHref(){
            let o = 'https://www.facebook.com/dialog/feed';
            o += "?app_id=631513796878843";
            o += "&link=" + encodeURIComponent(this.pageHref);
            o += "&picture=" + encodeURIComponent(this.ogImage);
            o += "&name=" + encodeURIComponent(this.shareTitle);
            o += "&description=" + encodeURIComponent(this.ogData);
            o += "&display=popup";
            o += "&redirect_uri=" + window.location.href;
            return o;
        },

        linkedinHref(){
            let o = 'http://www.linkedin.com/shareArticle?';
            o += "title=" + encodeURIComponent(this.shareTitle);
            o += "&mini=true";
            o += "&url=" + encodeURIComponent(this.pageHref);
            return o;
        },
    },

    methods: {
        emitClick(){
            this.$emit('clicked', true);
        }
    }
}
</script>

<style lang="postcss">
.share-icon {
    @apply w-13 h-13 rounded-full bg-white flex justify-center items-center text-st-tropaz mr-4 cursor-pointer;

    &:hover {
        @apply bg-st-tropaz text-white;
    }

    svg {
        @apply w-1/2 h-auto;
    }

    &--facebook {
        svg {
            @apply w-auto h-1/2;
        }
    }

    &--linkedin {
        svg {
            @apply w-auto h-2/5;
        }
    }
}
</style>
