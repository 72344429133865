export default {
    type: 'image',
    name: 'Image',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleImage',
            loader: () => import('./ModuleImage')
        }
    }
};
