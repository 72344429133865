export default {
    type: 'collection-modules',
    aliases: {
        camel: 'collectionModules',
        singular: 'Collection module',
        plural: 'Collection modules',
        lowerSingular: 'collection module',
        lowerPlural: 'collection modules',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
