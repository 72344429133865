import PivotRepository from "@/services/resources/pivot-repository";
import byPublication from "@/services/resources/mixins/query-by-publication";
import byPerson from "@/services/resources/mixins/query-by-person";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byPublication).$mix(byPerson);
    }

    publicationsByPerson(id) {
        return this.relation('publications', 'personId', id, null, [], 'publications');
    }

    peopleByPublication(id) {
        return this.relation('people', 'publicationId', id, null, [], 'people');
    }
}

export default Repository;
