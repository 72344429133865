import BaseRepository from "@/services/resources/repository";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema);
    }

    record(id) {
        return super.find(id).then(record => {
            if (record.password) {
                record.password = '';
            }

            return record;
        });
    }

    save(attributes) {
        return super.save(attributes).then(record => {
            if (record.password) {
                record.password = '';
            }

            return record;
        });
    }
}

export default Repository;
