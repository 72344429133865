import BaseRepository from "@/services/resources/repository";
import byProgrammeArea from "@/services/resources/mixins/query-by-programme-area";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byProgrammeArea);
    }

    byThemeTimelineEntry(id) {
        return this.try(
            this.baseQuery().where('themeTimelineEntryId', id).get()
        );
    }
}

export default Repository;
