export default {
    type: 'content-cards',
    name: 'Inline Content Cards',
    osOnly: true,
    components: {
        root: {
            name: 'ModuleContentCards',
            loader: () => import('./ModuleContentCards')
        }
    }
};
