<template>
    <audio class="base-audio">
        <source
            :src="resolvedSrc"
            :type="type"
        />
    </audio>
</template>

<script>
export default {
    props: {
        src: String,
        type: {
            type: String,
            default: 'audio/mpeg'
        },
        resolve: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        resolvedSrc() {
            if (!this.src) {
                return '';
            }

            if (!this.resolve) {
                return this.src;
            }

            return require(`../../assets/audio/${this.src}`);
        }
    }
}
</script>

<style lang="postcss">
.base-audio {
    @apply hidden;
}
</style>
