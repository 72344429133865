<template>
    <svg
        class="icon-paginate fill-current"
        viewBox="0 0 24.588 21.074"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.245,65.948l8.15,8.122a.659.659,0,0,1,0,.933l-8.15,8.122a.659.659,0,0,1-.933,0l-.39-.39a.659.659,0,0,1,0-.933l6.366-6.333H4.171a.661.661,0,0,1-.659-.659v-.549a.661.661,0,0,1,.659-.659H21.288l-6.361-6.333a.659.659,0,0,1,0-.933l.39-.39A.653.653,0,0,1,16.245,65.948ZM.659,85.074H1.1a.661.661,0,0,0,.659-.659V64.659A.661.661,0,0,0,1.1,64H.659A.661.661,0,0,0,0,64.659V84.416A.661.661,0,0,0,.659,85.074Z"
            transform="translate(0 -64)"
        />
    </svg>
</template>
