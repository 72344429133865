import {Schema, Collection} from "@oilstone/rest-model-repository";
import Factory from '../factory';

const schema = Factory.schema('table');
const attributes = schema.getProp('attributes').getValue();
const item = new Schema();

item.prop('column1').setType(String).setValue('&nbsp;');
item.prop('column2').setType(String).setValue('&nbsp;');
item.prop('column3').setType(String).setValue('&nbsp;');
item.prop('column4').setType(String).setValue('&nbsp;');
item.prop('column5').setType(String).setValue('&nbsp;');

attributes.prop('columnNumber').setType(Number).setValue(2);
attributes.prop('header1').setType(String).setValue('&nbsp;');
attributes.prop('header2').setType(String).setValue('&nbsp;');
attributes.prop('header3').setType(String).setValue('&nbsp;');
attributes.prop('header4').setType(String).setValue('&nbsp;');
attributes.prop('header5').setType(String).setValue('&nbsp;');
attributes.prop('items').setType(Collection).setValue(new Collection(item));

export default schema;
