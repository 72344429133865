import {inject} from "vue";
import Navigation from "@/services/navigation";

export default () => {
    return {
        setup() {
            const type = inject('type');

            return {
                type,
            }
        },

        props: {
            item: {
                type: Object,
                required: true,
            },
            ctaText: {
                type: String,
                default: 'Edit',
            }
        },

        methods: {
            goToEdit() {
                Navigation.push({
                    name: 'admin-resource',
                    params: {
                        type: this.type,
                        id: this.item.id,
                    },
                    query: this.$route.query
                });
            }
        }
    }
}
