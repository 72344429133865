export default {
    type: 'blog-post-themes',
    aliases: {
        camel: 'blogPostThemes',
        singular: 'Blog post theme',
        plural: 'Blog post themes',
        lowerSingular: 'blog post theme',
        lowerPlural: 'blog post themes',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
