<template>
    <div
        class="pullout"
        :class="classes"
    >
        <GridContainer class="pullout__img-grid">
            <GridColumn class="pullout__img-column">
                <imgCredited
                    :src="imgSrc"
                    :resolve="resolveImgSrc"
                    :credit="imgCredit"
                />
            </GridColumn>
        </GridContainer>
        <GridContainer class="pullout__text-grid">
            <GridColumn class="pullout__text-column">
                <GridContainer class="pullout__text-column__grid">
                    <gridColumn class="pullout__text-column__square">
                        <GridContainer class="pullout__text-column__content-grid">
                            <GridColumn class="pullout__text-column__content-column">
                                <slot></slot>
                            </GridColumn>
                        </GridContainer>
                    </gridColumn>
                </GridContainer>
            </GridColumn>
        </GridContainer>
    </div>
</template>

<script>
export default {
    props: {
        imgSrc: String,
        imgCredit: String,
        resolveImgSrc: {
            type: Boolean,
            default: false
        },
        variant: {
            type: String,
            default: 'alpha'
        },
        flip: {
            type: Boolean,
            default: false
        },
        target: {
            type: String,
            default: 'img'
        }
    },

    computed: {
        classes() {
            const classes = [`pullout--${this.variant}`, `pullout--${this.target}`];

            if (this.flip) {
                classes.push('pullout--flip');
            }

            return classes;
        },
    }
}
</script>

<style lang="postcss">
.pullout {
    @apply relative -mx-6 mb-7;

    @screen lg {
        @apply mx-0;
    }

    &--alpha {
        .pullout__text-column {
            @apply bg-pampas;
        }
    }

    &--beta {
        .pullout__text-column {
            @apply bg-midnight-blue text-white;
        }
    }

    &--img {
        &.pullout--flip {
            .pullout__text-grid {
                @screen 2xl {
                    left: -116px;
                }
            }

            .pullout__text-column {
                @screen lg {
                    @apply col-start-auto;
                }
            }

            .pullout__img-column {
                @screen lg {
                    @apply col-start-7;
                }
            }

            .pullout__text-column__content-column {
                @screen lg {
                    @apply col-start-3;
                }
            }
        }

        .pullout__img-grid {
            @apply pointer-events-none z-10;

            @screen lg {
                @apply absolute top-1/2 transform -translate-y-1/2;
            }
        }

        .pullout__text-grid {
            @screen 2xl {
                position: relative;
                left: 116px;
            }
        }

        .pullout__text-column {
            &__square {
                @screen lg {
                    @apply col-span-8;
                    padding-top: 100%;
                }
            }

            &__grid {
                @screen lg {
                    @apply grid-cols-10;
                }
            }

            &__content-grid {
                @screen lg {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    @apply grid-cols-8;
                }
            }

            &__content-column {
                @screen lg {
                    @apply text-center flex flex-col items-center col-start-5;
                }
            }
        }

        .pullout__img-column {
            @apply col-span-12;

            @screen lg {
                @apply col-span-6;
            }
        }
    }

    &--text {
        &.pullout--flip {
            .pullout__text-column {
                @screen lg {
                    @apply col-start-6;
                }
            }

            .pullout__img-column {
                @screen lg {
                    @apply col-start-auto;
                }
            }

            .pullout__text-grid {
                @screen 2xl {
                    left: 116px;
                }
            }
        }

        .pullout__text-grid {
            @apply w-full z-10;

            @screen lg {
                @apply absolute top-1/2 transform -translate-y-1/2;
            }

            @screen 2xl {
                left: -116px;
            }
        }

        .pullout__text-column {
            @apply col-span-12;

            @screen lg {
                @apply col-span-7;
                padding: 120px 116px;
            }

            &__content-column {
                @apply col-span-12;
            }
        }

        .pullout__img-column {
            @screen lg {
                @apply col-start-5;
            }
        }
    }

    &__img-grid {
        @apply w-full;
    }

    &__img-column {
        @apply px-6 relative z-10 col-span-12;

        @screen lg {
            @apply px-0 col-span-8;
        }
    }

    &__text-column {
        @apply px-6 pb-6 col-span-12;
        margin-top: calc(-50% + 24px);
        padding-top: 50%;

        @screen lg {
            @apply p-0 m-0 col-span-10 col-start-3;
        }

        &__grid {
            @screen lg {
                @apply grid-cols-7;
            }
        }

        &__content-grid {
            @screen lg {
                @apply grid-cols-12;
            }
        }

        &__content-column {
            @apply col-span-12;

            @screen lg {
                @apply col-span-4;
            }
        }

        &__square {
            @apply relative col-span-12;

            @screen lg {
                @apply col-span-7;
            }
        }
    }
}
</style>
