<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 47.65 47.65"
    >
        <path
            d="M665,399.69l2.52-2.52a20.25,20.25,0,0,1,28.58-28.58l2.52-2.52h0A23.81,23.81,0,0,0,665,399.69Z"
            transform="translate(-658.86 -359.97)"
            fill="#fff"
        />
        <path
            d="M678.35,386.3l2.83-2.84-.06,0a5.74,5.74,0,1,1,7.08-7.08l0,.07,2.84-2.84a9.43,9.43,0,1,0-12.71,12.71Z"
            transform="translate(-658.86 -359.97)"
            fill="#fff"
        />
        <path
            d="M687,381.28l-2.84,2.84.07,0a5.74,5.74,0,1,1-7.09,7.08l0-.06L674.3,394A9.43,9.43,0,1,0,687,381.28Z"
            transform="translate(-658.86 -359.97)"
            fill="#fff"
        />
        <path
            d="M700.4,367.89l-2.52,2.52A20.25,20.25,0,0,1,669.3,399l-2.52,2.52h0a23.81,23.81,0,0,0,33.62-33.63Z"
            transform="translate(-658.86 -359.97)"
        />
    </svg>
</template>