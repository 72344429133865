<template>
    <PageTitle>
        {{ pageTitle }}
    </PageTitle>

    <AdminTabs>
        <template #basic>
            <AdminTranslationTabs :items="translationTabs">
                <template #en>
                    <AdminTranslationFormFields language="en" />

                    <AdminButtons
                        :hide-preview="true"
                        @save="save"
                        @saveClose="saveClose"
                        @cancel="close"
                        @delete="destroy"
                        @publish="publish"
                        @unpublish="unpublish"
                    />
                </template>

                <template #fr>
                    <AdminTranslationFormFields language="fr" />
                </template>

                <template #es>
                    <AdminTranslationFormFields language="es" />
                </template>
            </AdminTranslationTabs>
        </template>
    </AdminTabs>
</template>

<script>
import administerRecord from './../administer-record';

const component = administerRecord();

export default {
    mixins: [component],

    setup(props, context) {
        if (component.setup) {
            return component.setup(props, context);
        }

        return {}
    }
}
</script>
