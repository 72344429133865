import BaseRepository from "@/services/resources/repository";
import byProject from "@/services/resources/mixins/query-by-project";
import byCountry from "@/services/resources/mixins/query-by-country";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byProject).$mix(byCountry);
    }
}

export default Repository;
