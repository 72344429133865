<template>
    <FormFields>
        <ResourceSeo/>
    </FormFields>
</template>

<script>
import {inject, provide} from "vue";
import {accessesData} from "@/services/data-layer/composers";

export default {
    setup() {
        const language = inject('language');
        const translatesUuid = inject('uuid');
        const {id, items} = accessesData();
        let uuid = translatesUuid;

        for (let itemUuid in items.value) {
            const item = items.value[itemUuid];

            if (parseInt(item.translatesId) === parseInt(id.value) && item.language === language) {
                uuid = itemUuid;
                break;
            }
        }

        provide('uuid', uuid);
        provide('translatesUuid', translatesUuid);
    },
}
</script>
