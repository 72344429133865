<template>
    <label
        class="form-radio form-control"
        :class="classes"
    >
        <slot />

        <div class="form-radio__input-container">
            <BaseRadio
                :value="value"
                :model-value="modelValue"
                class="form-control__input"
                @update:model-value="update"
            />
        </div>
    </label>
</template>

<script>
export default {
    props: {
        variant: {
            type: String,
            default: 'alpha',
        },
        value: null,
        modelValue: null,
    },

    computed: {
        checked() {
            return this.modelValue === this.value;
        },

        classes() {
            if(this.variant === 'beta') {
                return this.checked ? ['form-radio--beta border-royal-purple'] : ['form-radio--beta border-swiss-coffee'];
            } else {
                return this.checked ? ['border-big-stone'] : ['border-swiss-coffee'];
            }
        }
    },

    methods: {
        update() {
            this.$emit('update:modelValue', this.value);
        }
    }
}
</script>

<style lang="postcss">
.form-radio {
    @apply cursor-pointer relative block w-full p-4 border rounded-sm transition duration-300;

    &__input-container {
        @apply absolute top-4 right-4;
    }

    &--beta {
        .base-radio__inner {
            @apply bg-royal-purple
        }
    }
}
</style>
