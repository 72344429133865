export default {
    type: 'event-countries',
    aliases: {
        camel: 'eventCountries',
        singular: 'Event country',
        plural: 'Event countries',
        lowerSingular: 'event country',
        lowerPlural: 'event countries',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
