export default {
    type: 'highlighted-content',
    name: 'Highlighted Content',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleHighlightedContent',
            loader: () => import('./ModuleHighlightedContent')
        }
    }
};
