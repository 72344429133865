export default {
    type: 'programme-area-modules',
    aliases: {
        camel: 'programmeAreaModules',
        singular: 'Programme area module',
        plural: 'Programme area modules',
        lowerSingular: 'programme area module',
        lowerPlural: 'programme area modules',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
