<template>
    <div class="form-password-strength">
        <FormInput v-bind="baseComponentBindings"
                   type="password"
                   @update:model-value="validateAndUpdate"
        />
        <p class="form-password-strength__help-text">
            Your password must contain:
        </p>
        <ul class="form-password-strength__rules">
            <li v-for="(rule, index) in rules"
                :key="index"
                class="form-password-strength__rule"
                :class="{
                    'form-password-strength__rule--error': error && !rule.passes
                }"
            >
                <IconRenderer v-if="rule.passes"
                              name="Tick"
                              class="form-password-strength__rule__icon"
                />
                <IconRenderer v-else-if="error && !rule.passes"
                              name="AttentionOutline"
                              class="form-password-strength__rule__icon"
                />
                <span v-else
                      class="form-password-strength__rule__bullet"
                >
                    <span class="form-password-strength__rule__bullet__disc"></span>
                </span>
                {{ rule.text }}
            </li>
        </ul>
    </div>
</template>

<script>
import formInputComposable from "@/composables/forms/form-input";

const formInputDecoratorComposable = formInputComposable.decorator();

export default {
    mixins: [{
        props: formInputDecoratorComposable.props()
    }],

    setup(props, {emit}) {
        return {
            ...formInputDecoratorComposable.composables(props, emit, ['error'])
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],

    data() {
        return {
            rules: [
                {
                    text: '8 characters',
                    passes: false,
                    validate(input) {
                        return input.length >= 8;
                    }
                },
                {
                    text: 'One lowercase letter',
                    passes: false,
                    validate(input) {
                        return input.search(/[a-z]/) !== -1;
                    }
                },
                {
                    text: 'One uppercase letter',
                    passes: false,
                    validate(input) {
                        return input.search(/[A-Z]/) !== -1;
                    }
                },
                {
                    text: 'One number',
                    passes: false,
                    validate(input) {
                        return input.search(/[0-9]/) !== -1;
                    }
                },
                {
                    text: 'One symbol such as *,.!',
                    passes: false,
                    validate(input) {
                        return input.search(/[-’/`~!#*$£@_%+=.,^&(){}[\]|;:"'<>?§±\\]/) !== -1;
                    }
                }
            ]
        }
    },

    methods: {
        validate(value) {
            this.rules.forEach(rule => {
                rule.passes = rule.validate(value);
            });
        },

        passes() {
            for (let rule in this.rules) {
                if (!this.rules[rule].passes) {
                    return false;
                }
            }

            return true;
        },

        validateAndUpdate(value)  {
            this.validate(value);
            this.update(value);
        }
    }
}
</script>

<style lang="postcss">
.form-password-strength {
    @apply w-full;

    .form-input {
        @apply mb-2;
    }

    &__help-text {
        @apply text-xs mb-2;
    }

    &__rule {
        @apply flex items-center text-xs mt-1;

        &--error {
            @apply text-red;
        }

        &__icon {
            @apply w-3 h-3 mr-3;
        }

        &__bullet {
            @apply block w-3 h-3 mr-3 flex items-center justify-center;

            &__disc {
                @apply block w-1 h-1 bg-big-stone rounded-full;
            }
        }
    }
}
</style>
