<template>
    <ResourceInput
        label="Window title"
        attribute="windowTitle"
    />

    <ResourceInput
        label="Meta description"
        attribute="metaDescription"
        type="textarea"
    />

    <ResourceInput
        label="OG Data"
        attribute="ogData"
        type="textarea"
    />

    <ResourceInput
        label="No index"
        attribute="noIndex"
        type="boolean"
        v-if="!language || language === 'en'"
    />
</template>

<script>
import {inject} from "vue";

export default {
    setup() {
        const language = inject('language');

        return {
            language,
        }
    }
}
</script>
