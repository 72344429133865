import BaseRepository from "@/services/resources/repository";
import translatable from "@/services/resources/mixins/translatable-record";
import bySection from "@/services/resources/mixins/query-by-section";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(translatable).$mix(bySection);
    }
}

export default Repository;
