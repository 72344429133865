export default {
    type: 'job-groups',
    aliases: {
        camel: 'jobGroups',
        singular: 'UNRISD job group',
        plural: 'UNRISD job groups',
        lowerSingular: 'job group',
        lowerPlural: 'job groups',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'lookup',
    components: {},
};
