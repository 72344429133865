export default {
    type: 'location-types',
    aliases: {
        camel: 'locationTypes',
        singular: 'Location type',
        plural: 'Location types',
        lowerSingular: 'location type',
        lowerPlural: 'location types',
    },
    publishable: false,
    adminSection: 'administration',
    componentType: 'lookup',
    components: {},
};
