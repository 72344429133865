export default {
    type: 'person-specialisations',
    aliases: {
        camel: 'personSpecialisations',
        singular: 'Person specialisation',
        plural: 'Person specialisations',
        lowerSingular: 'person specialisation',
        lowerPlural: 'person specialisations',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
