<template>
    <svg
        viewBox="0 0 49.9 31.92"
        xmlns="http://www.w3.org/2000/svg"
    ><g><g><g><path
        d="M983.31,956.16h-41.9a6,6,0,0,0-.94.08,3.46,3.46,0,0,0-.89.3,3,3,0,0,0-1.31,1.31,3.46,3.46,0,0,0-.3.89,5.86,5.86,0,0,0-.08.94c0,.14,0,.28,0,.43v24c0,.15,0,.29,0,.44a7.3,7.3,0,0,0,.08.93,3.46,3.46,0,0,0,.3.89,3.06,3.06,0,0,0,2.21,1.61,5.84,5.84,0,0,0,.93.08h42.85a5.84,5.84,0,0,0,.93-.08,2.81,2.81,0,0,0,.9-.3,3,3,0,0,0,1.31-1.31,3.1,3.1,0,0,0,.29-.89,5.86,5.86,0,0,0,.08-.94c0-.14,0-.28,0-.43v-24c0-.15,0-.29,0-.43a5.86,5.86,0,0,0-.08-.94,3.1,3.1,0,0,0-.29-.89,2.94,2.94,0,0,0-1.32-1.31,3.39,3.39,0,0,0-.88-.3,7.45,7.45,0,0,0-.94-.08h-1"
        fill="#3c4043"
        fill-rule="evenodd"
        transform="translate(-937.88 -956.16)"
    /><path
        d="M983.31,957.23h.91a6.46,6.46,0,0,1,.78.07,2,2,0,0,1,.6.2,1.94,1.94,0,0,1,.85.85,2,2,0,0,1,.19.6,4.9,4.9,0,0,1,.07.77v24.82a5.14,5.14,0,0,1-.07.78,2.1,2.1,0,0,1-.19.6,1.92,1.92,0,0,1-.85.84,2.17,2.17,0,0,1-.59.2,5.12,5.12,0,0,1-.78.06H941.44a5.12,5.12,0,0,1-.78-.06,2.49,2.49,0,0,1-.6-.2,1.92,1.92,0,0,1-.84-.85,1.85,1.85,0,0,1-.2-.6,4.9,4.9,0,0,1-.07-.77c0-.14,0-.28,0-.41v-24c0-.14,0-.27,0-.41a5.14,5.14,0,0,1,.07-.78,2.1,2.1,0,0,1,.19-.6,1.92,1.92,0,0,1,.85-.84,2.12,2.12,0,0,1,.6-.2,5.12,5.12,0,0,1,.78-.06h41.87"
        fill="#fffffe"
        fill-rule="evenodd"
        transform="translate(-937.88 -956.16)"
    /></g><g><g><path
        d="M961.91,972.86v4.55h-1.42V966.18h3.77a3.3,3.3,0,0,1,2.43,1,3.26,3.26,0,0,1,.16,4.58l-.16.16a3.34,3.34,0,0,1-2.43,1Zm0-5.3v3.92h2.38a1.81,1.81,0,0,0,1.4-.58,2,2,0,0,0,0-2.78,1.92,1.92,0,0,0-1.36-.56Z"
        fill="#3c4043"
        transform="translate(-937.88 -956.16)"
    /><path
        d="M971,969.48a3.48,3.48,0,0,1,2.49.85,3,3,0,0,1,.91,2.35v4.73H973v-1.06H973a2.7,2.7,0,0,1-2.35,1.31,3,3,0,0,1-2.09-.75,2.39,2.39,0,0,1-.84-1.88,2.34,2.34,0,0,1,.89-1.9,3.76,3.76,0,0,1,2.38-.71,4.29,4.29,0,0,1,2.08.47v-.33a1.71,1.71,0,0,0-.58-1.29,2.08,2.08,0,0,0-1.37-.52,2.15,2.15,0,0,0-1.88,1L968,971A3.4,3.4,0,0,1,971,969.48Zm-1.83,5.58a1.17,1.17,0,0,0,.47.94,1.74,1.74,0,0,0,1.1.37,2.26,2.26,0,0,0,1.6-.67,2.12,2.12,0,0,0,.7-1.59,2.82,2.82,0,0,0-1.85-.52,2.41,2.41,0,0,0-1.45.42A1.23,1.23,0,0,0,969.16,975.06Z"
        fill="#3c4043"
        transform="translate(-937.88 -956.16)"
    /><path
        d="M982.2,969.73l-4.75,11.06H976l1.77-3.87-3.12-7.19h1.55l2.25,5.52h0l2.2-5.52Z"
        fill="#3c4043"
        fill-rule="evenodd"
        transform="translate(-937.88 -956.16)"
    /></g><g><path
        d="M956.16,971.88a7.17,7.17,0,0,0-.11-1.31h-6v2.48h3.44a3,3,0,0,1-1.27,2v1.61h2A6.32,6.32,0,0,0,956.16,971.88Z"
        fill="#4285f4"
        fill-rule="evenodd"
        transform="translate(-937.88 -956.16)"
    /><path
        d="M950.06,978.19a6.05,6.05,0,0,0,4.21-1.56l-2-1.61a3.75,3.75,0,0,1-2.16.61,3.79,3.79,0,0,1-3.57-2.66h-2.11v1.67A6.36,6.36,0,0,0,950.06,978.19Z"
        fill="#34a853"
        fill-rule="evenodd"
        transform="translate(-937.88 -956.16)"
    /><path
        d="M946.49,973a4,4,0,0,1,0-2.47v-1.66h-2.11a6.48,6.48,0,0,0,0,5.79Z"
        fill="#fbbc04"
        fill-rule="evenodd"
        transform="translate(-937.88 -956.16)"
    /><path
        d="M950.06,967.84a3.37,3.37,0,0,1,2.44,1l1.81-1.85a6,6,0,0,0-4.25-1.67,6.34,6.34,0,0,0-5.68,3.55l2.11,1.67A3.81,3.81,0,0,1,950.06,967.84Z"
        fill="#ea4335"
        fill-rule="evenodd"
        transform="translate(-937.88 -956.16)"
    /></g></g></g></g></svg>
</template>