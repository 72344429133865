import {inject, provide} from "vue";
import Kernel from "@oilstone/kernel";
import component from "@/services/resources/component";
import {accessesData, hasEvents, mutatesData, persistsData} from "@/services/data-layer/composers";
import {bootPublication, publishableData} from "@/services/publication/composer";
import Factory from "@/services/resources/factory";

export default () => {
    return {
        setup() {
            const type = inject('type');
            const mode = inject('mode');
            const language = inject('language');
            const translatesUuid = inject('uuid');
            const {id, items} = accessesData();
            const {listen} = hasEvents();
            const settings = Kernel.resolve('resourceRegistry').find(type);

            let uuid = translatesUuid;

            for (let itemUuid in items.value) {
                const item = items.value[itemUuid];

                if (parseInt(item.translatesId) === parseInt(id.value) && item.language === language) {
                    uuid = itemUuid;
                    break;
                }
            }

            provide('uuid', uuid);
            provide('translatesUuid', translatesUuid);

            const {item} = accessesData(uuid);
            const {merge, dispatchChanges, dirty} = mutatesData(uuid);
            const {overwrite} = persistsData(uuid);
            const {fire} = hasEvents(uuid);

            bootPublication(settings.repository.schema, Factory.scope(settings.config), uuid, dirty);

            const {dispatchPublishableChanges} = publishableData(uuid);

            return {
                uuid,
                translatesUuid,
                mode,
                listen,
                fire,
                merge,
                dispatchChanges,
                dispatchPublishableChanges,
                saveRecord: overwrite,
                getRecord: item,
                config: settings.config,
                schema: settings.repository.schema,
                moduleComponent: settings.config.relations && settings.config.relations.modules ? component(type, 'adminModules') : null,
            }
        },

        methods: {
            save(append = {}) {
                const translating = this.getRecord(this.translatesUuid).value;

                if (this.uuid === this.translatesUuid) {
                    return;
                }

                const copy = {translatesId: parseInt(translating.id)};
                const props = this.schema.getProps();

                for (let name in props) {
                    let prop = props[name];

                    if ((this.mode === 'create' && prop.metadata.translatable) || prop.metadata.mergeable) {
                        copy[name] = translating[name];
                    }
                }

                this.merge(copy);

                if (this.config.publishable && !append.isPublished && this.mode !== 'create') {
                    this.dispatchPublishableChanges();
                } else {
                    this.dispatchChanges();
                }

                const data = Object.assign({}, this.getRecord(this.uuid).value, append);

                this.saveRecord(data).then(() => {
                    this.fire(append.isPublished ? 'masterPublished' : 'masterSaved');
                });
            },
        },

        mounted() {
            this.listen({
                masterSaved: () => {
                    this.save();
                },

                masterPublished: () => {
                    this.save({
                        isPublished: true,
                        draft: null,
                    });
                }
            });
        }
    }
}
