export default {
    type: 'publication-events',
    aliases: {
        camel: 'publicationEvents',
        singular: 'Publication event',
        plural: 'Publication events',
        lowerSingular: 'publication event',
        lowerPlural: 'publication events',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
