<template>
    <svg
        class="icon-attention-outline"
        viewBox="0 0 17 17"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <circle
                cx="8.5"
                cy="8.5"
                fill="none"
                r="8.5"
            />
            <path
                d="M8.5,17A8.5,8.5,0,1,1,17,8.5,8.51,8.51,0,0,1,8.5,17Zm0-15.5a7,7,0,1,0,7,7A7,7,0,0,0,8.5,1.5Z"
                fill="#111"
            />
        </g>
        <rect
            fill="#111"
            height="5"
            width="1.5"
            x="7.75"
            y="7.5"
        />
        <rect
            fill="#111"
            height="1.5"
            width="1.5"
            x="7.75"
            y="4.5"
        />
    </svg>
</template>
