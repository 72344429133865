import PivotRepository from "@/services/resources/pivot-repository";
import byNewsItem from "@/services/resources/mixins/query-by-news-item";
import byProgrammeArea from "@/services/resources/mixins/query-by-programme-area";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byNewsItem).$mix(byProgrammeArea);
    }

    programmeAreasByNewsItem(id) {
        return this.relation('programme-areas', 'newsItemId', id, null, [], 'programmeAreas');
    }
}

export default Repository;
