<template>
    <div class="form-group">
        <slot></slot>
    </div>
</template>

<style lang="postcss">
.form-group {
    @apply mb-4;
}
</style>
