import Kernel from "@oilstone/kernel";

class Translation {
    static show(lookup) {
        return Kernel.resolve('resourceRegistry').find('translations').repository.collection().then(translations => {
            const translation = translations.find(translation => translation.lookup === lookup);

            if (!translation) {
                return null;
            }

            let value = translation.value;

            if (value === null && translation.translated) {
                value = translation.translated.value;
            }

            return value;
        });
    }

    static get(lookup) {
        return Translation.show(lookup).then(value => value);
    }

    static many(lookups) {
        return Promise.all(lookups.map(lookup => Translation.show(lookup))).then(translations => {
            const translated = {};

            lookups.forEach((lookup, index) => {
                translated[lookup] = translations[index];
            });

            return translated;
        });
    }
}

export default Translation;
