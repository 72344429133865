<template>
	<div class="asset-library__nav-bar">
        <div class="asset-library__nav-bar__brand">
            <h2 class="asset-library__nav-bar__brand__title">
                Asset Library
            </h2>

            <div class="asset-library__nav-bar__brand__options">
                <FormBtn
                    class="asset-library__nav-bar__brand__options__create"
                    @click="create"
                    title="Create Directory"
                    variant="delta"
                >
                    <span class="title-underline !p-0">Create<span class="desktop-text">&nbsp;Directory</span></span>
                </FormBtn>

                <FormBtn
                    class="asset-library__nav-bar__brand__options__upload"
                    @click="upload"
                    title="Upload new asset"
                    variant="delta"
                >
                    <span class="title-underline !p-0">Upload<span class="desktop-text">&nbsp;new asset</span></span>
                </FormBtn>

                <FormBtn
                    class="asset-library__nav-bar__brand__options__refresh"
                    @click="refresh"
                    title="Refresh Assets"
                    variant="delta"
                >
                    <IconRenderer name="Clockwise" />
                </FormBtn>

                <FormBtn
                    class="asset-library__nav-bar__brand__options__close"
                    @click="close"
                    title="Close"
                    variant="delta"
                >
                    <IconRenderer name="Cross" />
                </FormBtn>
            </div>
        </div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				query: null
			}
		},

        methods: {
            refresh(){
                this.$emit('refresh', true);
            },

            create(){
                this.$emit('create', true);
            },

            upload(){
                this.$emit('upload', true);
            },

            close(){
                this.$emit('close', true);
            }
        },

        emits: ['refresh', 'create', 'upload', 'close'],
    }
</script>

<style lang="postcss">
.asset-library__nav-bar {
    @apply bg-cod-gray;

    &__brand {
        @apply flex flex-col md:flex-row justify-between items-center p-4;

        &__title {
            @apply text-white text-3xl p-0 m-0 mb-2 md:mb-0;
        }

        &__options {
            @apply flex flex-row items-center justify-between;

            .form-btn {
                @apply ml-4 cursor-pointer;

                .desktop-text {
                    @apply hidden md:inline;
                }

                .base-btn {
                    @apply py-2 text-white;
                }
            }

            &__refresh {
                @apply ml-2;

                svg {
                    @apply w-5 h-auto;
                }
            }

            &__close {
                @apply ml-2;

                svg {
                    @apply w-4 h-auto;
                }
            }
        }
    }
}
</style>
