import BaseRepository from "@/services/publication/repository";
import translatable from "@/services/resources/mixins/translatable-record";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(translatable);
    }

    getByCategoryId(id){
        return this.transformer.many(
            this.try(
                this.baseQuery().where('faqCategoryId', id).get()
            )
        )
    }
}

export default Repository;
