import {Schema, Collection} from "@oilstone/rest-model-repository";
import Factory from '../factory';

const schema = Factory.schema('hero-carousel');
const attributes = schema.getProp('attributes').getValue();
const item = new Schema();

item.prop('title').setType(String).setValue('Title');
item.prop('summary').setType(String).setValue('Summary');
item.prop('btnLabel').setType(String).setValue('Button');
item.prop('btnSrc').setType(String);
item.prop('imgSrc').setType(String).setValue('image-placeholder-square.svg');
item.prop('imgAlt').setType(String);
item.prop('imgCredit').setType(String);

attributes.prop('items').setType(Collection).setValue(new Collection(item));

export default schema;
