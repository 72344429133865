import {Provider} from '@oilstone/container';
import Auth from "@/services/auth/auth";
import AccessToken from "@/services/auth/access-token";

export default new Provider().register(container => {
    container.singleton('auth', () => {
        return new Auth(
            new AccessToken(
                container.resolve('httpClient'),
                `${process.env.VUE_APP_URL}/oauth/token`,
                `${process.env.VUE_APP_URL}/oauth/owner`,
            )
        )
    });
});
