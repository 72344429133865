<template>
    <AdminSelect
        v-if="visible"
        :label="label"
        :options="options"
        :model-value="value"
        :show-empty="showEmpty"
        class="resource-input"
        @update:modelValue="update"
    />
</template>

<script>
import { computed, inject } from "vue";
import Kernel from "@oilstone/kernel";
import { accessesData, mutatesData } from "@/services/data-layer/composers";

const registry = Kernel.resolve("resourceRegistry");

export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        attribute: {
            type: String,
            required: true,
        },
        relation: {
            type: String,
            required: true,
        },
        method: {
            type: String,
            required: false,
            default: null,
        },
        toggleOn: {
            type: String,
            required: false,
            default: null,
        },
        toggleValue: {
            type: Boolean,
            required: false,
            default: true,
        },
        showEmpty: {
            type: Boolean,
            required: false,
            default: true,
        },
    },

    setup(props) {
        const type = inject("type");
        const { resolve } = accessesData();
        const { prepareUpdate, dirty } = mutatesData();

        const value = computed(() => {
            return typeof dirty.updates[props.attribute] !== "undefined" ? dirty.updates[props.attribute] : resolve(props.attribute).value;
        });

        const visible = computed(() => {
            if (props.toggleOn) {
                const togglerValue = typeof dirty.updates[props.toggleOn] !== "undefined" ? dirty.updates[props.toggleOn] : resolve(props.toggleOn).value;
                return togglerValue === props.toggleValue;
            }

            return true;
        });

        return {
            type,
            value,
            visible,
            prepareUpdate,
        };
    },

    data() {
        return {
            options: [],
        };
    },

    watch: {
        relation() {
            this.getOptions();
        },
    },

    methods: {
        update(value) {
            const intValue = value === null || value === '' || value === '0' ? null : parseInt(value);

            this.prepareUpdate(this.attribute, isNaN(intValue) || intValue === 0 ? value : intValue);
        },

        getOptions() {
            return registry
                .find(this.relation)
                .repository[this.method || "all"]()
                .then(collection => {
                    return collection.map(record => {
                        const value = parseInt(record.id);

                        return {
                            label: record.title,
                            value: isNaN(value) ? record.id : value,
                        };
                    });
                })
                .then(options => {
                    this.options = options;
                });
        },
    },

    mounted() {
        this.getOptions();
    },
};
</script>
