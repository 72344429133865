import BaseRepository from "@/services/publication/repository";
import queryByKeys from "@/services/resources/mixins/query-by-keys";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(queryByKeys);
    }

    record(id) {
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('translations')
                    .include('programme-area-keywords')
                    .include('programme-area-people')
                    .include('programme-area-themes')
                    .find(id)
            )
        );
    }

    list(){
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .select('id','title','publish_at')
                    .get()
            )
        );
    }

    collection() {
        return this.transformer.many(
            this.try(
                this.baseQuery().sort('publishAt', 'desc').include('programme-area-themes').get()
            )
        );
    }

    forSearch() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .include('programme-area-themes')
                    .select('id','title','slug','image','publishAt','decade_id',
                        'programme-area-themes.sort','programme-area-themes.id','themes.id'
                    )
                    .where('isPublished',1)
                    .get()
            )
        );
    }

    current() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .where('decadeId', process.env.VUE_APP_CURRENT_DECADE_ID)
                    .get()
            )
        );
    }
}

export default Repository;
