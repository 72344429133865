<template>
    <div
        class="network-card"
        :class="summary ? 'network-card--summary' : ''"
        @click="open"
    >
        <div class="network-card__header">
            <div class="network-card__header__container">
                <h3
                    v-if="resource.title"
                    class="network-card__header__title"
                    v-html="resource.title"
                />
                <h3
                    v-if="name"
                    class="network-card__header__title"
                    v-html="name"
                />
                <p
                    v-if="jobTitle && summary"
                    class="network-card__position"
                    v-html="jobTitle.title"
                />
            </div>
            <template v-if="showImg">
                <ImgCredited
                    v-if="resource.image"
                    :src="resource.image"
                    :alt="name"
                    class="network-card__header__image"
                />
                <div
                    v-else
                    class="network-card__header__initials"
                    v-html="getInitials(name)"
                />
            </template>
        </div>
        <div
            v-if="!summary"
            class="network-card__grow"
        />
        <div
            v-if="(jobTitle && !summary) || resource.country"
            class="trim-content mt-6"
        >
            <p
                v-if="jobTitle && !summary"
                class="network-card__position"
                v-html="jobTitle.title"
            />
            <p
                v-if="resource.country"
                class="network-card__country"
            >
                <Country :id="resource.country" />
            </p>
            <p
                v-if="resource.summary && !minimal"
                class="network-card__summary"
                v-html="resource.summary"
            />
        </div>
    </div>
</template>

<script>
import Navigation from "@/services/navigation";
import Kernel from "@oilstone/kernel";

export default {
    props: {
        resource: {
            type: Object,
            required: true,
        },
        showImg: {
            type: Boolean,
            default: true,
        },
        summary: {
            type: Boolean,
            default: false,
        },
        clickable: {
            type: Boolean,
            default: true,
        },
        modal: {
            type: Boolean,
            default: true,
        },
        auxPage: {
            type: Boolean,
            default: false,
        },
        minimal: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            jobTitle: null,
            person: null,
        }
    },

    computed: {
        name() {
            if (this.resource.lastName) {
                return this.resource.firstName + " " + this.resource.lastName;
            } else {
                return null;
            }
        }
    },

    methods: {
        getInitials(str) {
            if (str) {
                let ary = str.split(' ');
                let initials = '';

                ary.forEach(word => {
                    if (word.length >= 3) {
                        initials += word.charAt(0);
                    }
                })

                return initials.toUpperCase();
            }
        },

        open() {
            if (!this.clickable) {
                return;
            }

            if (this.auxPage) {
                window.open(Navigation.currentRoute().value.path + '/' + this.resource.slug);
            } else if (this.resource.showPersonPage) {
                Navigation.push('/about/people/' + this.resource.slug);
            } else {
                this.$store.dispatch('modal/person', this.person);
            }
        }
    },

    mounted() {
        if (this.resource.jobTitleId) {
            Kernel.resolve('resourceRegistry').find('job-titles').repository.findTranslated(this.resource.jobTitleId).then(response => {
                this.jobTitle = response;
                this.person = this.resource;
                this.person.jobTitle = response;
            });
        }
    }
}
</script>

<style lang="postcss">
.network-card {
    @apply bg-white flex flex-col flex-grow p-4 lg:p-5 filter drop-shadow cursor-pointer;

    &__header {
        @apply flex flex-row flex-grow justify-between align-top;

        &__container {
            @apply w-full flex-grow;
        }


        &__title {
            @apply text-lg m-0 p-0;
        }

        &__image {
            @apply w-max rounded-full overflow-hidden flex-grow flex-shrink-0;
            width: 60px !important;
            height: 60px !important;

            img {
                width: 60px !important;
                height: 60px !important;
            }
        }

        &__initials {
            @apply flex rounded-full overflow-hidden text-white bg-surfie-green text-xl justify-center items-center flex-shrink-0;
            width: 60px !important;
            height: 60px !important;
        }
    }

    &__grow {
        @apply flex-grow;
    }

    &__position {
        @apply font-goudy m-0 pb-1 font-bold;
    }

    &__country {
        @apply font-goudy m-0 p-0;
    }

    &__summary {
        @apply font-goudy text-base mt-6 p-0;
    }

    &--summary {
        .network-card__header {
            @apply mb-0 flex-grow-0;

            &__container {
                @apply mr-4;

                p {
                    @apply text-base;
                }
            }
        }

        p {
            @apply font-goudy leading-relaxed;
        }
    }
}
</style>
