import {computed, ref} from 'vue';
import Cache from "@/services/data-layer/composers/cache";
import Translation from "@/services/lookup/translation";
import Context from "@/services/context";

const cache = new Cache();
const cacheKey = lookup => Context.getLanguage() + '-' + lookup;

const boot = lookup => {
    cache.put(
        cacheKey(lookup),
        () => {
            let translation = ref(null);

            Translation.show(lookup).then(resolved => {
                translation.value = resolved;
            });

            return computed(() => {
                return translation.value;
            });
        }
    );
};

const resolve = lookup => {
    if (!cache.has(cacheKey(lookup))) {
        boot(lookup);
    }

    return cache.resolve(cacheKey(lookup));
};

export {boot, resolve};
export default resolve;
