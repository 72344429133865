import BaseRepository from "@/services/publication/repository";
import queryByKeys from "@/services/resources/mixins/query-by-keys";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(queryByKeys);
    }

    record(id) {
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('translations')
                    .include('publication-events')
                    .include('publication-keywords')
                    .include('publication-people')
                    .include('publication-programme-areas')
                    .include('publication-projects')
                    .include('publication-publishers')
                    .include('publication-themes')
                    .include('publication-countries')
                    .find(id)
            )
        );
    }

    collection() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .sort('publishAt', 'desc')
                    .include('publication-programme-areas')
                    .include('publication-themes')
                    .include('publication-projects')
                    .include('publication-publishers')
                    .include('publication-events')
                    .get()
            )
        );
    }

    forSearch() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .include('publication-programme-areas')
                    .include('publication-projects')
                    .include('publication-themes')
                    .include('publication-publishers')
                    .include('publication-type')
                    .include('publication-countries')
                    .include('publication-keywords.keywords')
                    .select('id','title','slug','image','location','externalAuthors','publishAt','decade_id', 'publication_type_id'
                        ,'publication-programme-areas.sort','publication-programme-areas.id','publication-programme-areas.programmeAreaId','programme-areas.id','programme-areas.title'
                        ,'publication-projects.sort','publication-projects.id','publication-projects.projectId','projects.id'
                        ,'publication-themes.sort','publication-themes.id','publication-themes.themeId','themes.id'
                        ,'publication-publishers.sort','publication-publishers.id','publication-publishers.publisherId','publishers.id'
                        ,'publication-type.title','publication-type.id'
                        ,'publication-keywords.sort','publication-keywords.id','keywords.id','keywords.title'
                        ,'publication-countries.sort','publication-countries.id','publication-countries.country','countries.id'
                    )
                    .where('isPublished',1)
                    .get()
            )
        );
    }
}

export default Repository;
