<template>
    <AdminInput
        :label="label"
        :type="type"
        :model-value="value"
        @update:modelValue="prepareReplacement(collection, index, attribute, $event)"
        class="resource-input"
    />
</template>

<script>
import {accessesData, mutatesData} from "@/services/data-layer/composers";

export default {
    props: {
        label: String,
        collection: String,
        attribute: String,
        index: Number,
        type: {
            type: String,
            default: 'input'
        }
    },

    setup(props) {
        const {resolve} =  accessesData();
        const {prepareReplacement} = mutatesData();

        return {
            item: resolve(props.collection).value[props.index],
            prepareReplacement
        };
    },

    computed: {
        value() {
            return this.item[this.attribute];
        }
    }
}
</script>
