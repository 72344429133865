export default {
    type: 'vacancy-applications',
    aliases: {
        camel: 'vacancyApplications',
        singular: 'vacancyApplication',
        plural: 'Vacancy Applications',
        lowerSingular: 'vacancy application',
        lowerPlural: 'vacancy applications',
    },
    relations: {
    },
    adminSection: 'about',
    componentType: 'base',
    filterOn: {
    },
    components: {
        adminCollection: {
            name: 'AdministerVacancyApplicationCollection',
            loader: () => import('./AdministerVacancyApplicationCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerVacancyApplicationRecord',
            loader: () => import('./AdministerVacancyApplicationRecord')
        },
    }
};
