<template>
    <transition name="fade" v-if="display">
        <div class="asset-library__make-dir">
            <div class="asset-library__make-dir__header">
                <h2>Create new directory</h2>
                <a
                    class="asset-library__make-dir__header__close"
                    @click="close"
                >
                    <IconRenderer name="Cross"/>
                </a>
            </div>
            <div class="asset-library__make-dir__details">
                <FormInput
                    placeholder="Enter your directory name"
                    v-model="newDirectory"
                />

                <FormBtn
                    variant="beta"
                    @click="createDirectory"
                >
                    Create directory
                </FormBtn>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: ['makeDir'],

    data() {
        return {
            newDirectory: null,
        }
    },

    computed: {
        ...mapGetters('asset', {
            currentPath: 'currentPath',
        }),

        display() {
            return !!this.makeDir;
        },
    },

    methods: {
        close() {
            this.$emit('close', true);
        },

        createDirectory() {
            if (this.newDirectory !== '') {
                this.$emit('created', this.currentPath + this.newDirectory);
            }
        }
    },

    mounted(){
        this.newDirectory = null;
    },

    emits: ['created', 'close'],
}
</script>

<style lang="postcss">
.asset-library__make-dir {
    @apply fixed flex flex-col bg-pampas top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 filter drop-shadow-lg;
    width: 80%;
    height: 80%;

    @screen md {
        width: 500px;
        height: 200px;
    }

    &__header {
        @apply flex flex-row justify-between items-center py-3 px-4 bg-cod-gray text-white;

        h2 {
            @apply text-lg m-0 p-0;
        }

        &__close {
            @apply cursor-pointer;

            svg {
                @apply w-4;
            }
        }
    }

    &__details {
        @apply p-4 h-full flex flex-col flex-grow justify-between;


    }
}
</style>
