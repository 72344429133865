import {boot as bootDataLayer, hasEvents, accessesData, mutatesData, persistsData, hydratesData} from "@/services/data-layer/composers";
import {boot as bootModule, resolve as module} from "./module";

const boot = (schema, scope, uuid, parentUuid) => {
    bootDataLayer(schema, scope, uuid, parentUuid);
    bootModule(scope, uuid);
}

export {
    boot,
    module,
    hasEvents,
    accessesData,
    mutatesData,
    persistsData,
    hydratesData
};
