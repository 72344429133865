<template>
    <FormFields>
        <ResourceTitle/>
    </FormFields>
</template>

<script>
import administerTranslation from '../administer-translation';

const component = administerTranslation();

export default {
    mixins: [component],

    setup(props, context) {
        if (component.setup) {
            return component.setup(props, context);
        }

        return {}
    }
}
</script>
