export default {
    type: 'faq-categories',
    aliases: {
        camel: 'faqCategories',
        singular: 'FAQ Category',
        plural: 'FAQ Categories',
        lowerSingular: 'category',
        lowerPlural: 'categories',
    },
    publishable: false,
    adminSection: 'about',
    componentType: 'lookup',
    components: {},
};
