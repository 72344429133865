<template>
    <AdminSection title="Modules">
        <Module
            type="container"
            :sort="1"
            :pointer-events="false"
            class="mb-5 last:mb-0"
            ref="moduleContainer"
        />
    </AdminSection>
</template>
