import PivotRepository from "@/services/resources/pivot-repository";
import byPublication from "@/services/resources/mixins/query-by-publication";
import byProgrammeArea from "@/services/resources/mixins/query-by-programme-area";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byPublication).$mix(byProgrammeArea);
    }

    programmeAreasByPublication(id) {
        return this.relation('programme-areas', 'publicationId', id, null, [], 'programmeAreas');
    }

    publicationsByProgrammeArea(id, injectedMasterRecord = null) {
        return this.relation('publications', 'programmeAreaId', id, null, [], null, injectedMasterRecord);
    }
}

export default Repository;
