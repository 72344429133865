<template>
    <input
        class="base-input"
        :type="type"
        :value="modelValue"
        :disabled="disabled"
        :pattern="pattern"
        :name="name"
        :autofill="autofill"
        :autofocus="autofocus"
        :maxlength="maxLength"
        @input="update($event.target.value)"
    />
</template>

<script>
export default {
    props: {
        modelValue: null,
        type: {
            type: String,
            default: 'text'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        pattern: String,
        maxLength: Number,
        name: String,
        autofill: String,
        autofocus: Boolean,
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],

    methods: {
        update(value) {
            this.$emit('update:modelValue', value);
        }
    },
}
</script>

<style lang="postcss">
.base-input {
    @apply block w-full outline-none text-big-stone border border-swiss-coffee p-3.5;
}
</style>
