import PivotRepository from "@/services/resources/pivot-repository";
import byProject from "@/services/resources/mixins/query-by-project";
import byTheme from "@/services/resources/mixins/query-by-theme";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byProject).$mix(byTheme);
    }

    projectsByTheme(id) {
        return this.relation('projects', 'themeId', id);
    }

    themesByProject(id) {
        return this.relation('themes', 'projectId', id);
    }
}

export default Repository;
