<template>
    <div class="admin-buttons mt-10 flex">
        <div class="admin-buttons_button m-max">
            <FormBtn
                variant="cms"
                :disabled="saveAndCloseDisabled"
                :working="saveAndCloseWorking"
                @click="saveCloseClicked"
            >
                Save & Close
            </FormBtn>
        </div>

        <div
            v-if="showSave"
            class="admin-buttons_button m-max ml-4"
        >
            <FormBtn
                variant="cms"
                :disabled="saveDisabled"
                :working="saveWorking"
                @click="saveClicked"
            >
                Save
            </FormBtn>
        </div>

        <div
            v-if="showPreview"
            class="admin-buttons_button m-max ml-4"
        >
            <FormBtn
                variant="cms-outline"
                :disabled="previewDisabled"
                :working="previewWorking"
                @click="previewClicked"
            >
                Preview
            </FormBtn>
        </div>


        <div
            v-if="showPublish"
            class="admin-buttons_button m-max ml-4"
        >
            <FormBtn
                variant="cms-outline"
                :disabled="publishDisabled"
                :working="publishWorking"
                @click="publishClicked"
            >
                Publish
            </FormBtn>
        </div>

        <div
            v-if="showUnpublish"
            class="admin-buttons_button m-max ml-4"
        >
            <FormBtn
                variant="cms-outline"
                :disabled="unpublishDisabled"
                :working="unpublishWorking"
                @click="unpublishClicked"
            >
                Unpublish
            </FormBtn>
        </div>

        <div
            v-if="showCancel"
            class="admin-buttons_button m-max ml-4"
        >
            <FormBtn
                variant="cms-outline"
                :disabled="cancelDisabled"
                :working="cancelWorking"
                @click="cancelClicked"
            >
                Close
            </FormBtn>
        </div>

        <div
            v-if="showDelete"
            class="admin-buttons_button m-max ml-4"
        >
            <FormBtn
                variant="cms-outline-full"
                :disabled="deleteDisabled"
                :working="deleteWorking"
                title="Delete"
                @click="deleteClicked"
            >
                <IconRenderer name="Cross" />
            </FormBtn>
        </div>
    </div>
</template>

<script>

import {hasEvents, accessesData} from "@/services/data-layer/composers";

export default {
    setup() {
        const {listen} = hasEvents();
        const {id, isPublished} = accessesData();

        return {
            listen,
            id,
            isPublished,
        }
    },

    props: {
        hideSave: {
            type: Boolean,
            default: false
        },

        hidePreview: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            working: null,
        }
    },

    computed: {
        showSave() {
            return !this.hideSave;
        },

        showCancel() {
            return true;
        },

        showDelete() {
            return true;
        },

        showPublish() {
            return this.id && typeof this.isPublished !== 'undefined';
        },

        showUnpublish() {
            return this.isPublished;
        },

        showPreview(){
            return !this.hidePreview;
        },

        saveDisabled() {
            return this.working && this.working !== 'save';
        },

        saveWorking() {
            return this.working && this.working === 'save';
        },

        saveAndCloseDisabled() {
            return this.working && this.working !== 'saveClose';
        },

        saveAndCloseWorking() {
            return this.working && this.working === 'saveClose';
        },

        publishDisabled() {
            return this.working && this.working !== 'publish';
        },

        publishWorking() {
            return this.working && this.working === 'publish';
        },

        previewWorking() {
            return this.working && this.working === 'preview';
        },

        previewDisabled() {
            return this.working && this.working !== 'preview';
        },

        unpublishDisabled() {
            return this.working && this.working !== 'unpublish';
        },

        unpublishWorking() {
            return this.working && this.working === 'unpublish';
        },

        cancelDisabled() {
            return this.working && this.working !== 'cancel';
        },

        cancelWorking() {
            return this.working && this.working === 'cancel';
        },

        deleteDisabled() {
            return this.working && this.working !== 'delete';
        },

        deleteWorking() {
            return this.working && this.working === 'delete';
        },
    },

    emits: ['saveClose', 'save', 'publish', 'unpublish', 'cancel', 'delete', 'preview'],

    methods: {
        saveCloseClicked() {
            this.$emit('saveClose');
        },

        saveClicked() {
            this.$emit('save');
        },

        publishClicked() {
            this.$emit('publish');
        },

        unpublishClicked() {
            this.$emit('unpublish');
        },

        cancelClicked() {
            this.$emit('cancel');
        },

        deleteClicked() {
            if (confirm('Are you sure you would like to delete this record?')) {
                this.$emit('delete');
            }
        },

        previewClicked() {
            this.$emit('preview');
        },
    },

    mounted() {
        this.listen({
            invalidResource: () => {
                this.working = null;
            },

            saving: () => {
                this.working = 'save';
            },

            saved: () => {
                this.working = null;
            },

            savedAndClosing: () => {
                this.working = 'saveClose';
            },

            savedAndClosed: () => {
                this.working = null;
            },

            publishing: () => {
                this.working = 'publish';
            },

            published: () => {
                this.working = null;
            },

            unpublishing: () => {
                this.working = 'unpublish';
            },

            unpublished: () => {
                this.working = null;
            },

            destroying: () => {
                this.working = 'delete';
            },

            destroyed: () => {
                this.working = null;
            },

            previewing: () => {
                this.working = 'preview';
            },

            previewed: () => {
                this.working = null;
            }
        });
    }
}
</script>
