import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .sluggable()
    .hasDecade()
    .hasTitle()
    .hasSummary()
    .hasBulletSummary()
    .hasImage()
    .hasYearRange()
    .hasBody()
    .hasMetadata()
    .publishable()
    .publishableByDate()
    .hasLotusNotesId()
    .hasTimestamps()
    .make();

export default schema;
