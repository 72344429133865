import {Provider} from '@oilstone/container';
import Registry from '@/services/resources/registry';
import alumniNetworkModule from '@/shared/modules/alumni-network';
import audioModule from '@/shared/modules/audio';
import auxPagesModule from '@/shared/modules/aux-pages';
import blockquoteModule from "@/shared/modules/blockquote";
import buttonModule from "@/shared/modules/button";
import containerModule from '@/shared/modules/container';
import contentAccordionModule from '@/shared/modules/content-accordion';
import contentCardsModule from '@/shared/modules/content-cards';
import contentPulloutModule from '@/shared/modules/content-pullout';
import cookieListModule from '@/shared/modules/cookie-list';
import dynamicAccordionModule from '@/shared/modules/dynamic-accordion';
import dynamicPaginationModule from '@/shared/modules/dynamic-pagination';
import eBulletins from '@/shared/modules/ebulletins';
import faqsModule from '@/shared/modules/faqs';
import furtherInformationModule from '@/shared/modules/further-information';
import heroCarouselModule from '@/shared/modules/hero-carousel';
import highlightedContentModule from '@/shared/modules/highlighted-content';
import imageModule from '@/shared/modules/image';
import networkAndPartnersModule from '@/shared/modules/network-and-partners';
import newsletterSignupModule from '@/shared/modules/newsletter-signup';
import noticeModule from '@/shared/modules/notice';
import officeModule from '@/shared/modules/office';
import organisationalStructure from '@/shared/modules/organisational-structure'
import pageAdvertsModule from '@/shared/modules/page-adverts';
import paginatedCarouselModule from '@/shared/modules/paginated-posters';
import programmesModule from '@/shared/modules/programmes';
import quickEnquiry from '@/shared/modules/enquiry';
import richTextModule from '@/shared/modules/richtext';
import searchContentAdvancedModule from '@/shared/modules/search-content-advanced';
import searchContentModule from '@/shared/modules/search-content';
import showMoreModule from '@/shared/modules/show-more';
import siteSearchModule from '@/shared/modules/site-search';
import staffAndAssociatesModule from '@/shared/modules/staff-and-associates';
import statisticModule from '@/shared/modules/statistics';
import tableModule from '@/shared/modules/table';
import teamModule from '@/shared/modules/team';
import themesModule from '@/shared/modules/themes';
import VacancyModule from '@/shared/modules/vacancies';
import videoModule from '@/shared/modules/video';

export default new Provider().register(container => {
    container.singleton('moduleRegistry', () => {
        const registry = new Registry();

        registry
            .add(alumniNetworkModule)
            .add(audioModule)
            .add(auxPagesModule)
            .add(blockquoteModule)
            .add(buttonModule)
            .add(containerModule)
            .add(contentAccordionModule)
            .add(contentCardsModule)
            .add(contentPulloutModule)
            .add(cookieListModule)
            .add(dynamicAccordionModule)
            .add(dynamicPaginationModule)
            .add(eBulletins)
            .add(faqsModule)
            .add(furtherInformationModule)
            .add(heroCarouselModule)
            .add(highlightedContentModule)
            .add(imageModule)
            .add(networkAndPartnersModule)
            .add(newsletterSignupModule)
            .add(noticeModule)
            .add(officeModule)
            .add(organisationalStructure)
            .add(pageAdvertsModule)
            .add(paginatedCarouselModule)
            .add(programmesModule)
            .add(quickEnquiry)
            .add(richTextModule)
            .add(searchContentAdvancedModule)
            .add(searchContentModule)
            .add(showMoreModule)
            .add(siteSearchModule)
            .add(staffAndAssociatesModule)
            .add(statisticModule)
            .add(tableModule)
            .add(teamModule)
            .add(themesModule)
            .add(VacancyModule)
            .add(videoModule);

        return registry;
    });
});
