<template>
    <transition name="fade" v-if="display">
        <div class="asset-library__upload-asset">
            <div class="asset-library__upload-asset__header">
                <h2>Upload new asset</h2>
                <a
                    class="asset-library__upload-asset__header__close"
                    @click="close"
                >
                    <IconRenderer name="Cross" />
                </a>
            </div>
            <div class="asset-library__upload-asset__details">
                <FormInput
                    type="file"
                    placeholder="Select file to upload"
                    @change="onFileChange"
                />
                <FormBtn
                    variant="beta"
                    @click="uploadFile"
                >
                    Upload new asset
                </FormBtn>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: ['uploading'],

    data() {
        return {
            newAsset: null,
            fileName: null
        }
    },

    computed: {
        ...mapGetters('asset', {
            currentPath: 'currentPath',
        }),

        display() {
            return !!this.uploading;
        },
    },

    methods: {
        close() {
            this.$emit('close', true);
        },

        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (files.length) {
                this.fileName = files[0]['name'];
                this.getBase64(files[0]);
            }
        },

        getBase64(file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.newAsset = reader.result;
            };
        },

        uploadFile() {
            if (this.newAsset) {
                this.$emit('uploaded', {
                    'path': this.currentPath ? this.currentPath : '',
                    'file': this.newAsset,
                    'fileName': this.fileName,
                });
            }
        }
    },

    mounted(){
        this.newAsset = null;
        this.fileName = null;
    },

    emits: ['uploaded', 'close'],
}
</script>

<style lang="postcss">
.asset-library__upload-asset {
    @apply fixed flex flex-col bg-pampas top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 filter drop-shadow-lg;
    width: 80%;
    height: 80%;

    @screen md {
        width: 500px;
        height: 200px;
    }

    &__header {
        @apply flex flex-row justify-between items-center py-3 px-4 bg-cod-gray text-white;

        h2 {
            @apply text-lg m-0 p-0;
        }

        &__close {
            @apply cursor-pointer;

            svg {
                @apply w-4;
            }
        }
    }

    &__details {
        @apply p-4 h-full flex flex-col flex-grow justify-between;

        .form-input {
            @apply bg-white;
        }
    }
}
</style>
