<template>
    <div class="img-credited">
        <BaseImg
            :src="src"
            :alt="alt"
            :resolve="resolve"
            class="img-credited__image"
        />

        <Credit :credit="credit" />
    </div>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            default: null,
        },
        alt: {
            type: String,
            default: null,
        },
        credit: {
            type: String,
            default: null,
        },
        resolve: {
            type: Boolean,
            default: false
        },
    }
}
</script>

<style lang="postcss">
.img-credited {
    @apply relative;
}
</style>
