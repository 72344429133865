import {boot as bootEvents, resolve as hasEvents, forget as forgetEvents} from "./has-events";
import {boot as bootAccessors, resolve as accessesData, forget as forgetAccessors} from "./accesses-data";
import {boot as bootMutators, resolve as mutatesData, forget as forgetMutators} from "./mutates-data";
import {boot as bootPersistors, resolve as persistsData, forget as forgetPersistors} from "./persists-data";
import {boot as bootHydrators, resolve as hydratesData, forget as forgetHydrators} from "./hydrates-data";

const boot = (schema, scope, uuid, parentUuid) => {
    bootEvents(scope, uuid, parentUuid);
    bootAccessors(schema, scope, uuid);
    bootMutators(schema, scope, uuid);
    bootPersistors(scope, uuid);
    bootHydrators(scope, uuid);
}

const forget = () => {
    forgetEvents();
    forgetAccessors();
    forgetMutators();
    forgetPersistors();
    forgetHydrators();
}

export {
    boot,
    forget,
    hasEvents,
    accessesData,
    mutatesData,
    persistsData,
    hydratesData
};
