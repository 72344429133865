<template>
    <svg
        class="icon-danger"
        viewBox="0 0 25.015 23"
        xmlns="http://www.w3.org/2000/svg"
    ><path
        d="M309.005,425.7l-10.421-18.689a2.052,2.052,0,0,0-3.611,0L284.552,425.7a2.159,2.159,0,0,0,1.806,3.234H307.2A2.159,2.159,0,0,0,309.005,425.7Zm-13.6-11.86h2.753a.531.531,0,0,1,.52.568l-.322,6.11a.529.529,0,0,1-.52.51h-2.108a.53.53,0,0,1-.521-.51l-.322-6.11A.531.531,0,0,1,295.4,413.842Zm1.377,12.129a2.068,2.068,0,0,1,0-4.133,1.98,1.98,0,0,1,1.656.911,2.1,2.1,0,0,1,.341,1.156A2.032,2.032,0,0,1,296.779,425.971Z"
        transform="translate(-284.271 -405.936)"
    /></svg>
</template>
