export default {
    type: null,
    aliases: {
        singular: null,
        plural: null,
    },
    publishable: false,
    adminSection: null,
    componentType: null,
    components: {
        adminRecord: {
            name: 'AdministerRecord',
            loader: () => import('./ui/AdministerRecord')
        },
        adminCollection: {
            name: 'AdministerCollection',
            loader: () => import('./ui/AdministerCollection')
        },
        adminCollectionItem: {
            name: 'AdministerCollectionItem',
            loader: () => import('./ui/AdministerCollectionItem')
        },
        adminTranslation: {
            name: 'AdministerTranslation',
            loader: () => import('./ui/AdministerTranslation')
        },
        adminTranslationSeo: {
            name: 'AdministerTranslationSeo',
            loader: () => import('./ui/AdministerTranslationSeo')
        },
        adminRelations: {
            name: 'AdministerRelations',
            loader: () => import('./ui/AdministerRelations')
        },
        adminModules: {
            name: 'AdministerModules',
            loader: () => import('./ui/AdministerModules')
        },
        webRecord: {
            name: 'DisplayRecord',
            loader: () => import('./ui/DisplayRecord')
        },
    }
};
