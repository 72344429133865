<template>
    <div v-if="item">
        <navigation-link
            :to="href"
            class="poster__img-container"
        >
            <PosterImg
                :content-type="contentType"
                :item="item"
            />
        </navigation-link>

        <Card class="poster__card">
            <div class="flex-grow">
                <navigation-link :to="href">
                    <h2 class="poster__card__title">
                        <span v-html="item.title" />
                    </h2>
                </navigation-link>
                <p
                    class="poster__card__date"
                    v-html="dateLocation"
                />

                <p
                    v-if="people"
                    class="font-libre-franklin text-boulder text-sm m-0 p-0"
                    v-html="people"
                />
            </div>

            <navigation-link
                :to="href"
                class="poster__card__discover"
            >
                {{ discoverStr }}
            </navigation-link>

            <slot name="share" />
        </Card>
    </div>
</template>

<script>
import Kernel from "@oilstone/kernel";
import Translation from "@/services/lookup/translation";

export default {
    props: {
        contentType: {
            type: String,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        href: {
            type: String,
            default: null,
        }
    },

    data(){
        return {
            people: null,
            discoverStr: 'Discover',
        }
    },

    computed: {
        dateLocation() {
            let date = this.item.publishAt;

            let str = (date ? '<span>' + this.$filters.date.format(date) + '</span>' : '');
            return str + (this.item.location && str ? ', ' : '') + (this.item.location ? this.item.location : '');
        },
    },

    methods: {
        getPeople() {
            if (Array.isArray(this.item.blogPostPeople)) {
                return Promise.resolve(this.item.blogPostPeople.map(item => {
                    return item.people;
                }));
            }

            return Kernel.resolve('resourceRegistry').find('blog-post-people').repository.baseQuery()
                .include('people').where('blogPostId', this.item.id)
                .get()
                .then(collection => {
                    return collection.map(item => {
                        return item.people;
                    });
                });
        },

        getDetails() {
            this.people = '';

            this.getPeople().then(people => {
                if(Array.isArray(people) && people.length) {
                    people.forEach(person => {
                        this.people += person.firstName + " " + person.lastName + ", ";
                    })

                    this.people = people.map(person => {
                        return `${person.firstName} ${person.lastName}`;
                    }).join(', ');
                }

                if(this.item.externalAuthors && this.item.externalAuthors !== ""){
                    if(this.people !== "") {
                        this.people = this.people + ", " + this.item.externalAuthors.replaceAll(';', ', ');
                    } else {
                        this.people = this.item.externalAuthors.replaceAll(';', ', ');
                    }
                }
            })
        }
    },

    mounted(){
        this.getDetails();

        Translation.many(['discover']).then(translations => {
            if(translations){
                this.discoverStr = translations['discover'];
            }
        });
    },

    watch: {
        item(){
            this.getDetails();
        }
    }
}
</script>
