import Cookies from 'js-cookie';

class Cookie {
    #baseCookie;

    /**
     * @param {Cookies} baseCookie
     * @returns {Cookie}
     */
    constructor(baseCookie) {
        this.#baseCookie = baseCookie;

        return new Proxy(
            this,
            {
                get: (target, property) => {
                    if (Reflect.has(this.#baseCookie, property)) {
                        return (...params) => {
                            return Reflect.get(this.#baseCookie, property)(...params);
                        }
                    }

                    return Reflect.get(this, property);
                }
            }
        );
    }

    static make() {
        return new Cookie(Cookies.withAttributes({path: '/'}));
    }
}

export default Cookie;
