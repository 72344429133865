import BaseRepository from "@/services/publication/repository";
import queryByKeys from "@/services/resources/mixins/query-by-keys";
import translatable from "@/services/resources/mixins/translatable-record";
import Kernel from "@oilstone/kernel";
import Context from '@/services/context';

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(queryByKeys).$mix(translatable);
    }

    collection() {
        const queryParams = Kernel.resolve('router').currentRoute.value.query;
        const section = queryParams.section;
        const sectionHomepage = queryParams.sectionHomepage;
        const ignoreParent = queryParams.ignoreParent;
        const query = this.baseQuery();

        if (typeof section !== 'undefined') {
            query.where('section', section);
        }

        if (!ignoreParent) {
            if (sectionHomepage === 'true') {
                query.where('parentId', null);
            } else {
                query.where('parentId', '!=', null);
            }
        }

        return this.transformer.many(
            this.try(
                query
                    .include('programme-area')
                    .include('project')
                    .include('event')
                    .include('news-item')
                    .include('publication')
                    .get()
            )
        );
    }

    homepage() {
        return this.transformer.one(
            this.try(
                this.baseQuery().include('translated').where('section', 'home').where('parentId', null).first()
            )
        );
    }

    sectionHomepage(section) {
        return this.transformer.one(
            this.try(
                this.baseQuery().where('parentId', null).where('section', section).first()
            )
        )
    }

    navigation() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .where('section', 'in', Kernel.resolve('navigationSections'))
                    .where('parentId', null)
                    .include('pages')
                    .get()
            )
        );
    }

    findManyTranslatableAndSort(ids){
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .include('translated')
                    .where('language', Context.getLanguage())
                    .where('id', 'in', ids)
                    .orWhere('translatesId', 'in', ids)
                    .get()
            )
        );
    }

    programmeAreaAuxPages() {
        return this.auxPages('programmeAreaId', 'aux-research');
    }

    projectAuxPages() {
        return this.auxPages('projectId', 'aux-research');
    }

    eventAuxPages() {
        return this.auxPages('eventId', 'aux-activities');
    }

    newsItemAuxPages() {
        return this.auxPages('newsItemId', 'aux-activities');
    }

    publicationAuxPages() {
        return this.auxPages('publicationId', 'aux-library');
    }

    auxPages(foreignKeyName, section = 'aux-research') {
        const masterRecord = Context.getRelatedMasterRecord() || Context.getMasterRecord();

        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .where('section', section)
                    .where(foreignKeyName, masterRecord.id)
                    .sort('publishAt', 'desc')
                    .get()
            )
        );
    }

    auxPageBySlug(slug, foreignKeyName, foreignKeyId) {
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('translated')
                    .where(foreignKeyName, foreignKeyId)
                    .where('slug', slug)
                    .first()
            )
        );
    }

    footerNavigation() {
        return this.transformer.many(
            this.try(
                this.baseQuery()
                    .where('section', 'footer')
                    .sort('sort', 'asc')
                    .get()
            )
        );
    }

    updateSort(items, element){
        if(items && items.length > 0){
            items.forEach((row, pos) => {
                if(pos <= element.newIndex && row.id !== element.element.id){
                    this.save({
                        id: row.id,
                        sort: pos+1,
                    });
                }
            })
        }

        this.save({
            id: element.element.id,
            sort: element.newIndex+1,
        });

        return true;
    }
}

export default Repository;
