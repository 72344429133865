import BaseRepository from "@/services/resources/repository";
import byPodcast from "@/services/resources/mixins/query-by-podcast";
import byTheme from "@/services/resources/mixins/query-by-theme";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byPodcast).$mix(byTheme);
    }
}

export default Repository;
