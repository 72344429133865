<template>
    <div v-if="item">

        <div
            @click="launchModal"
            class="poster__img-container"
        >
            <PosterImg
                :content-type="contentType"
                :item="item"
            />
        </div>

        <Card class="poster__card">
            <div
                v-if="item.label"
                class="poster__card__category"
                v-html="item.label"
            />
            <div class="flex-grow">
                <h2
                    @click="launchModal"
                    class="poster__card__title"
                >
                    <span v-html="item.title"/>
                </h2>
                <p
                    class="poster__card__date"
                    v-html="dateLocation"
                />
            </div>
            <div
                v-if="item.duration"
                @click="launchModal"
                class="poster__card__time"
            >
                <IconRenderer name="Play"/>
                <span
                    class="time"
                    v-html="item.duration"
                />
            </div>

            <slot name="share"></slot>
        </Card>
    </div>
</template>

<script>
export default {
    props: {
        contentType: {
            type: String,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        href: {
            type: String,
            default: null,
        }
    },

    computed: {
        dateLocation() {
            return (this.item.publishAt ? '<span>' + this.$filters.date.format(this.item.publishAt) + '</span>' : '');
        },
    },

    methods: {
        launchModal() {
            this.$store.dispatch('modal/video', this.item);
        }
    },
}
</script>
