export default {
    type: 'vacancy-application-references',
    aliases: {
        camel: 'vacancyApplicationReferences',
        singular: 'vacancyApplicationReference',
        plural: 'Vacancy Application references',
        lowerSingular: 'vacancy application reference',
        lowerPlural: 'vacancy application references',
    },
    relations: {
    },
    adminSection: null,
    componentType: 'base',
    filterOn: {
    },
    components: {
    }
};
