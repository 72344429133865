import { inject } from "vue";
import Kernel from "@oilstone/kernel";
import Cache from "./../data-layer/composers/cache";
import _ from "lodash";

const store = Kernel.resolve("vuexStore");
const cache = new Cache();

const bootPublication = (schema, scope, uuid, dirty) => {
    cache.put(uuid, () => {
        const dispatchPublishableChanges = () => {
            let draft = _.merge({}, store.getters[scope.path().to('draft')](uuid) || {});

            for (let path in dirty.updates) {
                const payload = { key: uuid };
                const propName = path.split(".").pop();
                const propValue = dirty.updates[path];

                let prop = null;

                if (path.indexOf("attributes.") === 0) {
                    const attributeProp = schema.pullProp("attributes");

                    prop = attributeProp.getValue().pullProp(propName);

                    if (prop && attributeProp.metadata && attributeProp.metadata.draftable) {
                        draft["attributes"] = draft["attributes"] || {}

                        draft["attributes"][propName] = prop.cast(propValue);
                        continue;
                    }
                } else {
                    prop = schema.pullProp(propName);

                    if (prop && prop.metadata && prop.metadata.draftable) {
                        draft[propName] = prop.cast(propValue);
                        continue;
                    }
                }

                if (prop) {
                    payload[propName] = prop.cast(propValue);

                    store.dispatch(scope.path().to(path), payload);
                }
            }

            for (let path in dirty.replacements) {
                const propName = path.split(".").pop();
                const prop = schema.getProp(propName);

                if (prop && prop.metadata && prop.metadata.draftable) {
                    draft[propName] = dirty.replacements[path];
                    continue;
                }

                for (let index in dirty.replacements[path]) {
                    const payload = {
                        key: uuid,
                        index,
                        item: dirty.replacements[path][index],
                    };

                    store.dispatch(`${scope.path().to(path)}.putItem`, payload);
                }
            }

            if (Object.keys(draft).length === 0) {
                draft = null;
            }

            store.dispatch(scope.path().to("draft"), {
                key: uuid,
                draft,
            });

            dirty.updates = {};
            dirty.replacements = {};
        };

        return {
            dispatchPublishableChanges,
        };
    });
};

const publishableData = uuid => {
    return cache.resolve(uuid || inject("uuid"));
};

export { bootPublication, publishableData };
export default publishableData;
