export default {
    bySlug(slug) {
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('translated')
                    .where('slug', slug)
                    .first()
            )
        );
    },

    bySection(section) {
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .where('section', section)
                    .first()
            )
        );
    },

    bySectionAndSlug(section, slug) {
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('translated')
                    .where('section', section)
                    .where('slug', slug)
                    .first()
            )
        );
    }
}
