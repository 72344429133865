<template>
    <div v-if="item">
        <navigation-link
            :to="href"
            class="poster__img-container"
        >
            <PosterImg
                :content-type="contentType"
                :label="label"
                :item="item"
            />
        </navigation-link>

        <Card class="poster__card">
            <div
                v-if="programmeArea"
                class="poster__card__category"
                v-html="programmeArea.title"
            />


            <div class="flex-grow">
                <navigation-link :to="href">
                    <h2 class="poster__card__title">
                        <span v-html="item.title" />
                    </h2>
                </navigation-link>
            </div>

            <navigation-link
                :to="href"
                class="poster__card__discover"
            >
                {{ discoverStr }}
            </navigation-link>

            <slot name="share" />
        </Card>
    </div>
</template>

<script>
import Translation from "@/services/lookup/translation";

export default {
    props: {
        contentType: {
            type: String,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        href: {
            type: String,
            default: null,
        }
    },

    computed: {
        label() {
            return 'Project';
        }
    },

    data(){
        return {
            programmeArea: null,
            discoverStr: 'Discover',
        }
    },

    methods: {
        getDetails(){
            this.programmeArea = null;

            if (this.item.projectProgrammeAreas && this.item.projectProgrammeAreas.length) {
                this.programmeArea = this.item.projectProgrammeAreas[0].programmeAreas;
            }
        }
    },

    mounted(){
        this.getDetails();

        Translation.many(['discover']).then(translations => {
            if(translations){
                this.discoverStr = translations['discover'];
            }
        });
    },

    watch: {
        item(){
            this.getDetails();
        }
    }
}
</script>
