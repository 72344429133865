import Factory from '../factory';

const schema = Factory.schema('content-pullout');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('title').setType(String).setValue('Title');
attributes.prop('summary').setType(String).setValue('Summary');
attributes.prop('btnLabel').setType(String).setValue('Download');
attributes.prop('btnSrc').setType(String);
attributes.prop('image').setType(String).setValue('image-placeholder.svg');
attributes.prop('imageAlt').setType(String);

export default schema;
