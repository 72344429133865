import BaseRepository from "@/services/publication/repository";
import Factory from "../factory";

class Repository extends BaseRepository {
    static make(model) {
        return new Repository(model, Factory.schema(null));
    }

    children(id) {
        return this.transformer.many(
            this.try(
                this.baseQuery().where('parentId', id).get()
            )
        );
    }
}

export default Repository;
