import PivotRepository from "@/services/resources/pivot-repository";
import byProgrammeArea from "@/services/resources/mixins/query-by-programme-area";
import byTheme from "@/services/resources/mixins/query-by-theme";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byProgrammeArea).$mix(byTheme);
    }

    themesByProgrammeArea(id) {
        return this.relation('themes', 'programmeAreaId', id);
    }
}

export default Repository;
