<template>
    <div
        class="sticker"
        :class="classes"
        @click="$emit('click')"
    >
        <IconRenderer
            :name="icon"
            class="sticker__icon"
        />
    </div>
</template>

<script>
export default {
    props: {
        icon: String,
        variant: {
            type: String,
            default: 'alpha'
        },
        size: {
            type: String,
            default: 'md'
        },
    },

    computed: {
        classes() {
            return [`sticker--${this.variant}`, `sticker--${this.size}`];
        }
    }
}
</script>

<style lang="postcss">
.sticker {
    @apply flex items-center justify-center rounded-full bg-opacity-50 cursor-pointer;

    &--sm {
        @apply w-9 h-9;
    }

    &--md {
        @apply w-11 h-11;
    }

    &--alpha {
        @apply bg-white bg-opacity-50;

        .sticker__icon {
            @apply text-big-stone;
        }
    }

    &--beta {
        @apply bg-white bg-opacity-50;

        .sticker__icon {
            @apply text-white;
        }
    }

    &--gamma {
        @apply bg-swiss-coffee bg-opacity-50;

        .sticker__icon {
            @apply text-big-stone;
        }
    }

    &__icon {
        @apply w-5 fill-current transition-colors duration-700;
    }
}
</style>
