import PivotRepository from "@/services/resources/pivot-repository";
import byPublication from "@/services/resources/mixins/query-by-publication";
import byPublisher from "@/services/resources/mixins/query-by-publisher";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byPublication).$mix(byPublisher);
    }

    publicationsByPublisher(id) {
        return this.relation('publications', 'publisherId', id);
    }

    publishersByPublication(id) {
        return this.relation('publishers', 'publicationId', id, null, [], 'publishers');
    }
}

export default Repository;
