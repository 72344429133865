export default {
    type: 'podcasts',
    aliases: {
        camel: 'podcasts',
        singular: 'Podcast',
        plural: 'Podcasts',
        lowerSingular: 'podcast',
        lowerPlural: 'podcasts',
    },
    relations: {
        'themes': {
            adminLabel: "Themes",
            pivot: {
                type: 'podcast-themes',
                localKey: 'podcastId',
                foreignKey: 'themeId'
            }
        },
    },
    publishable: true,
    adminSection: 'library',
    componentType: 'base',
    filterOn: {
        'collections': {
            foreignKey: 'collectionId',
            method: 'podcastCollections',
        },
        'programme-areas': {
            foreignKey: 'programmeAreaId',
        },
        'themes': {
            foreignKey: 'themeId',
            in: 'podcastThemes',
        },
        'projects': {
            foreignKey: 'projectId',
        },
        'events': {
            foreignKey: 'eventId',
        },
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminRecord: {
            name: 'AdministerPodcastRecord',
            loader: () => import('./AdministerPodcastRecord')
        },
        adminCollection: {
            name: 'AdministerPodcastCollection',
            loader: () => import('./AdministerPodcastCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminTranslation: {
            name: 'AdministerPodcastTranslation',
            loader: () => import('./AdministerPodcastTranslation')
        },
        webRecord: {
            name: 'DisplayPodcastRecord',
            loader: () => import('./DisplayPodcastRecord')
        }
    },
};
