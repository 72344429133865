export default {
    type: 'button',
    name: 'Button',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleButton',
            loader: () => import('./ModuleButton')
        }
    }
};
