export default {
    type: 'news-item-keywords',
    aliases: {
        camel: 'newsItemKeywords',
        singular: 'News item keyword',
        plural: 'News item keywords',
        lowerSingular: 'news item keyword',
        lowerPlural: 'news item keywords',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
