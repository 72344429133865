import Factory from '../factory';

const schema = Factory.schema('button');
const attributes = schema.getProp('attributes').getValue();

attributes.prop('btnLabel').setType(String).setValue('Label');
attributes.prop('btnSrc').setType(String).setValue('');
attributes.prop('variant').setType(String).setValue('beta');

export default schema;
