export default {
    type: 'dynamic-pagination',
    name: 'Dynamic Pagination',
    osOnly: true,
    components: {
        root: {
            name: 'ModuleDynamicPagination',
            loader: () => import('./ModuleDynamicPagination')
        }
    }
};
