export default {
    type: 'vacancy-application-languages',
    aliases: {
        camel: 'vacancyApplicationLanguages',
        singular: 'vacancyApplicationLanguage',
        plural: 'Vacancy Application languages',
        lowerSingular: 'vacancy application languages',
        lowerPlural: 'vacancy application languages',
    },
    relations: {
    },
    adminSection: null,
    componentType: 'base',
    filterOn: {
    },
    components: {
    }
};
