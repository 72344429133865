import BaseRepository from "@/services/publication/repository";
import Validator from "@/services/resources/validator";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema, Validator.make(model));
    }

    byVacancyApplication(id) {
        return this.try(
            this.transformer.many(
                this.baseQuery().where('vacancyApplicationId', id).sort('sort').get()
            )
        );
    }
}

export default Repository;
