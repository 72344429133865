<template>
    <div v-if="item">
        <navigation-link
            :to="href"
            class="poster__img-container"
        >
            <PosterImg
                :content-type="contentType"
                :label="label"
                :item="item"
            />
        </navigation-link>

        <Card class="poster__card">
            <div class="flex-grow">
                <navigation-link :to="href">
                    <h2
                        class="poster__card__title"
                        v-html="item.title"
                    />
                </navigation-link>
                <p
                    class="poster__card__date"
                    v-html="dateLocation"
                />

                <p
                    v-if="people"
                    class="font-libre-franklin text-boulder text-sm m-0 p-0"
                    v-html="people"
                />
            </div>

            <navigation-link
                :to="href"
                class="poster__card__discover"
            >
                {{ discoverStr }}
            </navigation-link>

            <slot name="share" />
        </Card>
    </div>
</template>

<script>
import Kernel from "@oilstone/kernel";
import Context from "@/services/context";
import Translation from "@/services/lookup/translation";

export default {
    props: {
        contentType: {
            type: String,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        href: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            people: null,
            label: null,
            discoverStr: 'Discover',
        };
    },

    computed: {
        dateLocation() {
            let date = this.item.publishAt;

            let str = date ? "<span>" + this.$filters.date.format(date) + "</span>" : "";
            return str + (this.item.location && str ? ", " : "") + (this.item.location ? this.item.location : "");
        },
    },

    methods: {
        getDetails() {
            this.people = "";
            this.label = "";

            if(Context.getLanguage() !== "en") {
                Kernel.resolve("resourceRegistry").find("publication-types").repository.getLabels().then(results => {
                    results.forEach(row => {
                        if(row.translatesId === this.item.publicationTypeId){
                            this.label = row.title;
                        }
                    })
                });
            } else {
                if (this.item.publicationType) {
                    this.label = this.item.publicationType.title;
                } else if (this.item.publicationTypeId) {
                    Kernel.resolve("resourceRegistry")
                        .find("publication-types")
                        .repository.find(this.item.publicationTypeId)
                        .then(type => {
                            if (type.title) {
                                this.label = type.title;
                            }
                        });
                }
            }

            Context.setRelatedMasterRecord(this.item);

            Kernel.resolve("resourceRegistry")
                .find("publication-people")
                .repository.peopleByPublication(this.item.id)
                .then(people => {
                    this.people = "";

                    if (Array.isArray(people) && people.length) {
                        people.forEach(row => {
                            if (row) {
                                this.people += row.firstName + " " + row.lastName + ", ";
                            }
                        });

                        if (this.people) {
                            this.people = this.people.substring(0, this.people.length - 2);
                        }
                    }

                    if (this.item.externalAuthors && this.item.externalAuthors !== "") {
                        if (this.people !== "") {
                            this.people = this.people + ", " + this.item.externalAuthors.replaceAll(";", ", ");
                        } else {
                            this.people = this.item.externalAuthors.replaceAll(";", ", ");
                        }
                    }

                    Context.setRelatedMasterRecord(null);
                });
        },
    },

    mounted() {
        this.getDetails();

        Translation.many(['discover']).then(translations => {
            if(translations){
                this.discoverStr = translations['discover'];
            }
        });
    },

    watch: {
        item() {
            this.getDetails();
        },
    },
};
</script>
