<template>
    <FormLabel
        :text="strLabel"
        class="form-colour form-control"
        :class="classes"
    >
        <BaseColour
            v-bind="baseComponentBindings"
            @update:model-value="update"
        />
        <FormError :text="error"/>
        <FormHelp :text="helpText"/>
    </FormLabel>
</template>

<script>
import formInputComposable from "@/composables/forms/form-colour";

export default {
    mixins: [{
        props: formInputComposable.props()
    }],

    setup(props, {emit}) {
        return {
            ...formInputComposable.composables(props, emit)
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],

    computed: {
        strLabel(){
            return this.required && this.label ? `${this.label} *` : this.label;
        },
    },
}
</script>

<style lang="postcss">
.form-colour {
}
</style>
