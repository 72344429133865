export default {
    type: 'project-themes',
    aliases: {
        camel: 'projectThemes',
        singular: 'Project theme',
        plural: 'Project themes',
        lowerSingular: 'project theme',
        lowerPlural: 'project themes',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
