<template>
    <svg
        class="icon-account fill-current"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g transform="translate(-342.348 -539.49)">
            <path
                d="M24.348,556.49a9,9,0,1,0,9,9A9,9,0,0,0,24.348,556.49Zm.043,3.354a3.4,3.4,0,1,1-3.4,3.4A3.4,3.4,0,0,1,24.391,559.844Zm0,13.292A7.4,7.4,0,0,1,18.73,570.5a4.307,4.307,0,0,1,3.806-2.311.945.945,0,0,1,.274.043,4.828,4.828,0,0,0,3.161,0,.945.945,0,0,1,.274-.043,4.307,4.307,0,0,1,3.806,2.311A7.4,7.4,0,0,1,24.391,573.136Z"
                transform="translate(327 -17)"
            />
        </g>
    </svg>
</template>
