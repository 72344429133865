export default {
    type: 'enquiry',
    name: 'Quick enquiry',
    osOnly: true,
    components: {
        root: {
            name: 'ModuleEnquiry',
            loader: () => import('./ModuleEnquiry')
        }
    }
};
