export default {
    type: 'vacancy-application-education',
    aliases: {
        camel: 'vacancyApplicationEducation',
        singular: 'vacancyApplicationEducation',
        plural: 'Vacancy Application education',
        lowerSingular: 'vacancy application education',
        lowerPlural: 'vacancy application education',
    },
    relations: {
    },
    adminSection: null,
    componentType: 'base',
    filterOn: {
    },
    components: {
    }
};
