export default {
    type: 'network-and-partners',
    name: 'Network and Partners',
    osOnly: true,
    components: {
        root: {
            name: 'ModuleNetworkAndPartners',
            loader: () => import('./ModuleNetworkAndPartners')
        }
    }
};
