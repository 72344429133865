import BaseRepository from "@/services/publication/repository";
import queryByKeys from "@/services/resources/mixins/query-by-keys";
import queryByProject from "@/services/resources/mixins/query-by-project";
import queryByEvent from "@/services/resources/mixins/query-by-event";
import queryByProgrammeArea from "@/services/resources/mixins/query-by-programme-area";
import Kernel from "@oilstone/kernel";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(queryByKeys).$mix(queryByProject).$mix(queryByEvent).$mix(queryByProgrammeArea);
    }

    record(id) {
        return this.transformer.one(
            this.try(
                this.baseQuery()
                    .include('translations')
                    .include('collection-themes')
                    .find(id)
            )
        );
    }

    collection() {
        if (Kernel.resolve('router').currentRoute.value.query.section === 'blog-posts') {
            return this.blogCollections();
        }

        if (Kernel.resolve('router').currentRoute.value.query.section === 'podcasts') {
            return this.podcastCollections();
        }

        return [];
    }

    blogCollections() {
        return this.transformer.many(
            this.try(
                this.baseQuery().where('section', 'blog-posts').get()
            )
        );
    }

    podcastCollections() {
        return this.transformer.many(
            this.try(
                this.baseQuery().where('section', 'podcasts').get()
            )
        );
    }
}

export default Repository;
