<template>
    <TitledContainer
        :title="title"
        variant="beta"
        class="network-card-list"
        :class="clickable ? '' : 'network-card-list--inactive'"
    >
        <GridContainer v-if="data && data.length > 0">
            <GridColumn
                v-for="(row, index) in displayData"
                v-bind:key="index"
            >
                <NetworkCard
                    :key="row.id"
                    :resource="row"
                    :showImg="showImg"
                    :summary="summary"
                    :clickable="clickable"
                    :modal="modal"
                    :auxPage="auxPage"
                    :minimal="minimal"
                />
            </GridColumn>
        </GridContainer>

        <LayoutContainer v-else>
            <div class="flex justify-center my-8 w-full">
                <h5>Sorry, no results found</h5>
            </div>
        </LayoutContainer>

        <div
            v-if="paginate && showPagination"
            class="network-card-list__paginate"
        >
            <div class="network-card-list__paginate__container">
                <IconRenderer
                    @click="decrementPage"
                    name="Paginate"
                    class="decrement"
                    :class="page > 1 ? '' : 'disabled'"
                />
                <p>
                    <strong>{{ $filters.number.pad(page) }}</strong> - {{ $filters.number.pad(totalPages) }}
                </p>
                <IconRenderer
                    @click="incrementPage"
                    name="Paginate"
                    :class="page < totalPages ? '' : 'disabled'"
                />
            </div>
        </div>
    </TitledContainer>
</template>

<script>
export default {
    props: {
        data: Array,
        title: {
            type: String,
            default: null,
        },
        showImg: {
            type: Boolean,
            default: true,
        },
        paginate: {
            type: Boolean,
            default: false,
        },
        clickable: {
            type: Boolean,
            default: true,
        },
        modal: {
            type: Boolean,
            default: true,
        },
        summary: {
            type: Boolean,
            default: false,
        },
        auxPage: {
            type: Boolean,
            default: false,
        },
        minimal: {
            type: Boolean,
            default: true
        }
    },

    data(){
        return {
            page: 1,
            show: 16,
        }
    },

    computed: {
        showPagination(){
            if(!this.data){
                return false;
            }
            return this.data.length > this.show;
        },

        totalPages(){
            return Math.ceil(this.data.length / this.show);
        },

        displayData(){
            return this.data.slice(((this.show * this.page) - this.show), (this.show * this.page));
        }
    },

    methods: {
        incrementPage(){
            this.page = this.page+1;

            if(this.page >= this.totalPages){
                this.page = this.totalPages;
            }
        },

        decrementPage(){
            this.page = this.page-1;

            if(this.page <= 0){
                this.page = 1;
            }
        },
    },

    mounted(){
        this.page = 1;
    },

    watch:{
        data() {
            this.page = 1;
        }
    }
}
</script>

<style lang="postcss">
.network-card-list {
    padding-top: 1rem !important;
    padding-bottom: 2rem !important;

    .grid-container {
        @apply grid-cols-12 gap-8;

        .grid-column {
            @apply col-span-12 md:col-span-4 lg:col-span-3;

            .network-card {
                @apply h-full;
            }
        }
    }

    &--inactive {
        .network-card {
            @apply !cursor-auto;
        }
    }

    &__paginate {
        @apply flex flex-row justify-center mx-auto mt-8 relative;

        &__container {
            @apply flex flex-row justify-between items-center relative;

            p {
                @apply m-0 p-0 text-center;
            }

            svg {
                @apply w-6 ml-6 cursor-pointer;

                &.decrement {
                    @apply transform rotate-180 ml-0 mr-6;
                }

                &.disabled {
                    @apply cursor-auto text-silver;
                }
            }
        }
    }
}
</style>
