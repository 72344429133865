export default {
    type: 'programme-area-themes',
    aliases: {
        camel: 'programmeAreaThemes',
        singular: 'Programme area theme',
        plural: 'Programme area themes',
        lowerSingular: 'programme area theme',
        lowerPlural: 'programme area themes',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
