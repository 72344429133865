import Repository from "./repository";
import Context from '@/services/context';
import Kernel from "@oilstone/kernel/dist/kernel";

class PivotRepository extends Repository {
    relation(name, foreignKeyName, id, alias = null, merge = [], relationKey = null, injectedMasterRecord = null) {
        alias = alias || name;
        relationKey = relationKey || alias;

        const masterRecord = injectedMasterRecord || Context.getRelatedMasterRecord() || Context.getMasterRecord() || {};
        const relatedRepository = Kernel.resolve('resourceRegistry').find(name).repository;

        const mergeData = (pivot, relation) => {
            merge.forEach(key => {
                if (pivot && relation) {
                    relation[key] = pivot[key];
                }
            });

            return relation;
        };

        const query = this.baseQuery().include(alias).where(foreignKeyName, masterRecord.id).sort('sort');

        return this.transformer.many(
            this.try(
                query.get()
            )
        ).then(collection => {
            if (!collection.length) {
                return collection;
            }

            if (Context.getLanguage() === 'en' && parseInt(id) === parseInt(masterRecord.id)) {
                return collection.map(record => {
                    return mergeData(record, record[relationKey]);
                });
            }

            const ids = collection.map(record => {
                return record[relationKey].id;
            });

            const relationQuery = relatedRepository.baseQuery().where('translatesId', 'in', ids).where('language', Context.getLanguage());

            return relatedRepository.transformer.many(
                relatedRepository.try(relationQuery.get())
            ).then(translations => {
                return collection.map(record => {
                    const relation = translations.find(translation => {
                        return parseInt(translation.translatesId) === parseInt(record[relationKey].id);
                    });

                    if (!relation) {
                        return false;
                    }

                    return mergeData(record, relation);
                }).filter(item => item);
            })
        });
    }
}

export default PivotRepository;
