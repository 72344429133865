import merge from "lodash/merge";
import {Schema, Collection} from "@oilstone/rest-model-repository";
import Scope from "@/services/data-layer/store/scope";

class Factory {
    static make(schema, repository) {
        const state = {
            items: {}
        };

        const getters = {
            items(state) {
                return state.items;
            },

            item: (state) => (key) => {
                if (typeof state.items[key] === 'undefined') {
                    return null;
                }

                return state.items[key]
            },

            exists: (state, getters) => (key) => {
                const item = getters.item(key);

                if (!item) {
                    return false;
                }

                return !!(item[schema.getPrimaryKey().getName()]);
            },
        };

        const mutations = {
            putItem(state, payload) {
                state.items[payload.key] = payload.attributes;
            },

            dropItem(state, payload) {
                delete state.items[payload.key];
            },
        }

        const actions = {
            makeItem({commit}, key) {
                commit('putItem', {
                    key,
                    attributes: schema.blueprint()
                });
            },

            putItem({commit}, payload) {
                commit('putItem', payload);
            },

            dropItem({commit}, payload) {
                commit('dropItem', payload);
            },

            mergeItem({getters, commit}, payload) {
                const item = getters.item(payload.key);

                commit('putItem', {
                    key: payload.key,
                    attributes: merge({}, item, payload.attributes)
                });
            },

            hydrateItem({commit}, payload) {
                commit('putItem', {
                    key: payload.key,
                    attributes: payload.attributes,
                    // TODO: Removed to increase performance, revisit once data retrieval has been optimised
                    // attributes: merge({}, schema.blueprint(), payload.attributes)
                });
            },

            loadItem({dispatch}, payload) {
                return repository.find(payload.id).then(attributes => {
                    dispatch('hydrateItem', {
                        key: payload.key,
                        attributes
                    });
                });
            },

            overwriteItem({commit}, payload) {
                return repository.save(
                    payload.item
                ).then(attributes => {
                    commit('putItem', {
                        key: payload.key,
                        attributes
                    });

                    return attributes;
                });
            },

            saveItem({getters, commit}, key) {
                return repository.save(
                    getters.item(key)
                ).then(attributes => {
                    commit('putItem', {
                        key,
                        attributes
                    });

                    return attributes;
                });
            },

            saveItems({getters, dispatch}) {
                const promises = [];

                for (let key in getters.items) {
                    promises.push(dispatch('saveItem', key));
                }

                return Promise.all(promises);
            },

            destroyItem({getters, commit}, key) {
                return repository.destroy(
                    getters.id(key)
                ).then(() => {
                    commit('dropItem', {
                        key
                    });
                });
            },

            destroyItems({getters, dispatch}) {
                const promises = [];

                for (let key in getters.items) {
                    promises.push(dispatch('destroyItem', key));
                }

                return Promise.all(promises);
            }
        }

        const map = (schema, scope) => {
            const props = schema.getProps();

            for (let name in props) {
                let value = props[name].getValue();
                let path = scope.path().to(name);

                getters[path] = (state, getters) => (key) => {
                    const item = getters.item(key);
                    return item ? scope.for(item).prop(name) : null;
                };

                mutations[path] = (state, payload) => {
                    scope.for(state.items[payload.key]).set(name, payload[name]);
                };

                actions[path] = ({commit}, payload) => {
                    commit(path, payload);
                };

                if (value instanceof Schema) {
                    map(value, new Scope().inherit(scope).key(name));
                }

                if (value instanceof Collection) {
                    mutations[`${path}.putItem`] = (state, payload) => {
                        scope.for(state.items[payload.key]).prop(name)[payload.index] = payload.item;
                    };

                    mutations[`${path}.pushItem`] = (state, payload) => {
                        scope.for(state.items[payload.key]).prop(name).push(payload.attributes);
                    }

                    mutations[`${path}.dropItem`] = (state, payload) => {
                        const items = scope.for(state.items[payload.key]).prop(name);
                        items.splice(payload.index, 1);
                    };

                    actions[`${path}.putItem`] = ({commit}, payload) => {
                        commit(`${path}.putItem`, payload);
                    };

                    actions[`${path}.dropItem`] = ({commit}, payload) => {
                        commit(`${path}.dropItem`, payload);
                    };

                    actions[`${path}.makeItem`] = ({commit}, key) => {
                        commit(`${path}.pushItem`, {
                            key,
                            attributes: value.getSchema().blueprint()
                        });
                    };
                }
            }
        }

        map(schema, new Scope())

        return {
            namespaced: true,
            state,
            getters,
            mutations,
            actions
        };
    }
}

export default Factory;
