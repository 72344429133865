export default {
    type: 'news-item-modules',
    aliases: {
        camel: 'newsItemModules',
        singular: 'News item module',
        plural: 'News item modules',
        lowerSingular: 'news item module',
        lowerPlural: 'news item modules',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
