import Kernel from "@oilstone/kernel";

class KeyValue {
    static show(category, title) {
        return Kernel.resolve('resourceRegistry').find('key-values').repository.collection().then(keyValues => {
            const keyValue = keyValues.find(keyValue => keyValue.category === category && keyValue.title === title);

            if (!keyValue) {
                return null;
            }

            let value = keyValue.value;

            if (value === null && keyValue.translated) {
                value = keyValue.translated.value;
            }

            switch (keyValue.type) {
                case 'array':
                    return value.split(/\r?\n/);

                default:
                    return value;
            }
        });
    }

    static get(category, title) {
        return KeyValue.show(category, title).then(value => value);
    }
}

export default KeyValue;
