export default {
    type: 'project-countries',
    aliases: {
        camel: 'projectCountries',
        singular: 'Project country',
        plural: 'Project countries',
        lowerSingular: 'project country',
        lowerPlural: 'project countries',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
