export default {
    type: 'videos',
    aliases: {
        camel: 'videos',
        singular: 'Video',
        plural: 'Videos',
        lowerSingular: 'video',
        lowerPlural: 'videos',
    },
    relations: {
        'themes': {
            adminLabel: "Themes",
            pivot: {
                type: 'video-themes',
                localKey: 'videoId',
                foreignKey: 'themeId'
            }
        },
        'keywords': {
            adminLabel: 'Keywords',
            pivot: {
                type: 'video-keywords',
                localKey: 'videoId',
                foreignKey: 'keywordId'
            }
        },
    },
    publishable: true,
    adminSection: 'library',
    componentType: 'base',
    filterOn: {
        'programme-areas': {
            foreignKey: 'programmeAreaId',
        },
        'themes': {
            foreignKey: 'themeId',
            in: 'videoThemes',
        },
        'projects': {
            foreignKey: 'projectId',
        },
        'events': {
            foreignKey: 'eventId',
        },
        'other': ['publishYear', 'isPublished'],
    },
    components: {
        adminCollection: {
            name: 'AdministerPublishableCollection',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollection')
        },
        adminCollectionItem: {
            name: 'AdministerPublishableCollectionItem',
            loader: () => import('@/services/publication/ui/AdministerPublishableCollectionItem')
        },
        adminRecord: {
            name: 'AdministerVideoRecord',
            loader: () => import('./AdministerVideoRecord')
        },
        adminTranslation: {
            name: 'AdministerVideoTranslation',
            loader: () => import('./AdministerVideoTranslation')
        },
        webRecord: {
            name: 'DisplayVideoRecord',
            loader: () => import('./DisplayVideoRecord')
        }
    },
};
