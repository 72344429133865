<template>
    <div
        class="form-btn"
        :class="classes"
    >
        <BaseBtn @click.stop="click">
            <template v-if="resolvedWorking">
                <IconRenderer
                    name="Spinner"
                    class="form-btn__spinner"
                />
                Processing...
            </template>
            <span
                v-else
                class="form-btn__slot"
            >
                <span class="form-btn__slot__inner flex">
                    <slot />
                </span>
            </span>
        </BaseBtn>
    </div>
</template>

<script>
export default {
    props: {
        variant: {
            type: String,
            default: 'alpha'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        working: {
            type: Boolean,
            default: null
        }
    },

    inject: {
        injectedWorking: {
            default: () => {
                return {
                    value: false,
                }
            }
        }
    },

    computed: {
        classes() {
            const classes = [`form-btn--${this.variant}`];

            if (this.disabled) {
                classes.push('form-btn--disabled');
            }

            return classes;
        },

        resolvedWorking() {
            if (this.working !== null) {
                return this.working;
            }

            return this.injectedWorking ? this.injectedWorking.value : false;
        }
    },

    methods: {
        click() {
            if (!this.disabled && !this.resolvedWorking) {
                this.$emit('click')
            }
        }
    }
}
</script>

<style lang="postcss">
.form-btn {
    .base-btn {
        @apply flex justify-center items-center font-semibold;
    }

    &--disabled {
        .base-btn {
            @apply bg-silver;
        }

        &.form-btn--beta {
            .base-btn {
                @apply text-silver border-silver;
            }
        }
    }

    &--alpha {
        &:hover {
            .form-btn__slot__inner {
                &:after {
                    transform: none;
                }
            }
        }

        .form-btn__slot__inner {
            @apply padded-underline;

            &:after {
                transition: transform 400ms;
                transform: translateX(-101%);
            }
        }
    }

    &--beta {
        .base-btn {
            @apply border bg-transparent border-big-stone text-big-stone;
        }

        &:not(.form-btn--disabled) {
            .base-btn {
                @apply hover:bg-big-stone hover:text-white;
            }
        }
    }

    &--gamma {
        .base-btn {
            @apply border border-white bg-transparent hover:bg-white text-white hover:text-big-stone;
        }
    }

    &--delta {
        .base-btn {
            @apply border-0 bg-transparent text-ship-gray h-auto w-auto p-0;
        }
    }

    &--epsilon {
        .base-btn {
            @apply border border-yellow-1 bg-transparent hover:bg-yellow-1 text-white hover:text-grey-1 font-semibold text-base;
        }
    }

    &--zeta {
        .base-btn {
            @apply border-0 bg-royal-purple;
        }

        &:not(.form-btn--disabled) {
            .base-btn {
                @apply hover:opacity-80 hover:text-white;
            }
        }

        &:hover {
            .form-btn__slot__inner {
                &:after {
                    transform: none;
                }
            }
        }

        .form-btn__slot__inner {
            @apply padded-underline;

            &:after {
                transition: transform 400ms;
                transform: translateX(-101%);
            }
        }
    }

    &--eta {
        .base-btn {
            @apply border bg-transparent border-royal-purple text-royal-purple;
        }

        &:not(.form-btn--disabled) {
            .base-btn {
                @apply hover:bg-royal-purple hover:text-white;
            }
        }

        &:hover {
            .form-btn__slot__inner {
                &:after {
                    transform: none;
                }
            }
        }

        .form-btn__slot__inner {
            @apply padded-underline;

            &:after {
                transition: transform 400ms;
                transform: translateX(-101%);
            }
        }
    }

    &--theta {
        .base-btn {
            @apply border-0 bg-transparent text-royal-purple h-auto w-auto p-0 font-normal font-libre-franklin hover:underline ;
        }
    }

    &--iota {
        .base-btn {
            @apply bg-white text-red border border-red;
        }
    }

    &--cms {
        .base-btn {
            @apply bg-orange text-white font-sofia-pro font-normal py-0 hover:bg-cedar-wood-finish transition-colors duration-300 ease-in-out rounded-r-full rounded-l-full w-max;
            height: 40px;
        }
    }

    &--cms-outline {
        .base-btn {
            @apply border border-dove-gray bg-transparent w-max text-dove-gray font-sofia-pro font-normal py-0 hover:bg-cedar-wood-finish hover:text-white transition-all duration-300 ease-in-out rounded-r-full rounded-l-full;
            height: 40px;
        }
    }

    &--cms-outline-full {
         width: 40px;
         height: 40px;

        .base-btn {
            @apply  border border-dove-gray bg-transparent w-max text-dove-gray font-sofia-pro font-normal p-0 hover:bg-cedar-wood-finish hover:text-white transition-all duration-300 ease-in-out rounded-full;
            width: 40px;
            height: 40px;

            svg {
                @apply m-0 p-0 w-1/4 stroke-2 stroke-current;
            }
        }
    }

    &__spinner {
        @apply w-5 mr-4 fill-current animate-spin flex-shrink-0;
    }

    &__slot {
        @apply w-max h-full flex items-center overflow-x-hidden;

        &__inner {
            @apply flex flex-row justify-center items-center w-max;
            display: flex !important;

            svg {
                @apply w-4 ml-2;
            }
        }
    }
}
</style>
