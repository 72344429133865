<template>
    <div
        class="badge"
        :class="classes"
        @click="click"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        variant: {
            type: String,
            default: 'alpha'
        },
        linkHref: {
            type: String,
            default: null,
        },
    },

    computed: {
        classes() {
            const classes = [`badge--${this.variant}`];

            if (this.linkHref) {
                classes.push('badge--link');
            }

            return classes;
        }
    },

    methods: {
        click() {
            if (!this.linkHref) {
                return;
            }

            window.location.href = this.linkHref;
        }
    },
}
</script>

<style lang="postcss">
.badge {
    height: fit-content;
    @apply w-max text-xs py-2 px-3 rounded-lg flex items-center;

    &--link {
        @apply cursor-pointer;
    }

    &--alpha {
        @apply bg-dodger-blue text-white;
    }

    &--beta {
        @apply bg-tara text-fun-green;
    }

    &--gamma {
        @apply bg-medium-red-violet text-white;
    }

    &--delta {
        @apply bg-navajo-white;
    }

    &--epsilon {
        @apply bg-turquoise-blue;
    }

    &--zeta {
        @apply bg-cotton-candy;
    }
}
</style>
