export default {
    type: 'navigation-items',
    aliases: {
        camel: 'navigationItems',
        singular: 'Navigation item',
        plural: 'Navigation items',
        lowerSingular: 'navigation item',
        lowerPlural: 'navigation items',
    },
    publishable: false,
    adminSection: null,
    componentType: 'base',
    components: {}
};
