import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .make();

schema.prop('eventId').setType(Number);
schema.prop('country').setType(String);

export default schema;
