export default {
    type: 'unrisd-timeline-entry-types',
    aliases: {
        camel: 'unrisdTimelineEntryTypes',
        singular: 'UNRISD timeline entry type',
        plural: 'UNRISD timeline entry types',
        lowerSingular: 'timeline entry type',
        lowerPlural: 'timeline entry types',
    },
    publishable: false,
    adminSection: 'about',
    componentType: 'lookup',
    components: {},
};
