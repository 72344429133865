import {Provider} from "@oilstone/container";
import {createStore} from "vuex";
import asset from "@/store/asset";
import auth from "@/store/auth";
import cookie from "@/store/cookie";
import meta from "@/store/meta";
import modal from "@/store/modal";
import tracker from "@/store/tracker";
import ResourceFactory from "@/services/resources/factory"
import ModuleFactory from "@/shared/modules/factory";

export default new Provider().register(container => {
    container.singleton('vuexStore', () => {
        const debug = process.env.NODE_ENV !== 'production';
        const plugins = [];
        const resourceRegistry = container.resolve('resourceRegistry');

        // if (debug) {
        //     plugins.push(createLogger());
        // }

        return createStore({
            strict: debug,
            plugins,
            modules: {
                asset,
                auth,
                cookie,
                meta,
                modal,
                tracker,
                modules: ModuleFactory.store(
                    container.resolve('moduleRegistry'),
                    resourceRegistry.find('modules').repository,
                ),
                resources: ResourceFactory.stores(resourceRegistry)
            }
        });
    });
});
