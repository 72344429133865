<template>
    <svg
        viewBox="0 0 17 13.8"
        xmlns="http://www.w3.org/2000/svg"
    ><g><path
        d="M1247.43,1128.15a1.94,1.94,0,0,1-1.93-1.92v-7.82a1.93,1.93,0,0,1,.58-1.37,2,2,0,0,1,1.38-.56h2.64l1.31-1.91a.48.48,0,0,1,.41-.22h4.36a.51.51,0,0,1,.42.22l1.3,1.91h2.67a1.94,1.94,0,0,1,1.93,1.92v7.83h0a1.94,1.94,0,0,1-1.94,1.92h-13.13Zm0-10.67a1,1,0,0,0-.66.27.92.92,0,0,0-.28.66v7.82a.94.94,0,0,0,.94.92h13.1a1,1,0,0,0,1-.92v-7.82a1,1,0,0,0-.94-.93h-2.92a.53.53,0,0,1-.42-.21l-1.3-1.92h-3.84l-1.3,1.91a.51.51,0,0,1-.42.22h-2.92Zm14.56,8.75h0Z"
        transform="translate(-1245.5 -1114.35)"
    /><path
        d="M1254,1125.93a4.13,4.13,0,1,1,4.13-4.12h0A4.14,4.14,0,0,1,1254,1125.93Zm0-7.25a3.13,3.13,0,1,0,3.13,3.13h0a3.12,3.12,0,0,0-.92-2.22A3.09,3.09,0,0,0,1254,1118.68Z"
        transform="translate(-1245.5 -1114.35)"
    /></g></svg>
</template>
