import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .make();

schema.prop('vacancyApplicationId').setType(Number);
schema.prop('sort').setType(Number);
schema.prop('name').setType(String);
schema.prop('emailAddress').setType(String);
schema.prop('organisation').setType(String);
schema.prop('jobTitle').setType(String);

export default schema;
