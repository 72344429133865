<template>
    <AdminSelect
        :options="options"
        :model-value="modelValue"
        @update:modelValue="update"
        class="resource-input"
    />
</template>

<script>
import Kernel from "@oilstone/kernel";

const registry = Kernel.resolve('resourceRegistry');

export default {
    props: {
        modelValue: null,
        relation: {
            type: String,
            required: true,
        },
        method: {
            type: String,
            required: false,
            default: null,
        },
    },

    data() {
        return {
            options: [{label: ' - ' + registry.find(this.relation).config.aliases.singular, value: ''}],
        }
    },

    watch: {
        relation() {
            this.getOptions();
        }
    },

    methods: {
        update(value) {
            const intValue = parseInt(value);

            this.$emit('update:modelValue', isNaN(intValue) || intValue === 0 ? value : intValue);
        },

        getOptions() {
            return registry.find(this.relation).repository[this.method || 'all']().then(collection => {
                return collection.map(record => {
                    const value = parseInt(record.id);

                    return {
                        label: record.title,
                        value: isNaN(value) ? record.id : value,
                    }
                })
            }).then(options => {
                this.options = this.options.concat(options);
            })
        }
    },

    mounted() {
        this.getOptions();
    }
}
</script>
