<template>
    <div class="poster-group">
        <slot></slot>
    </div>
</template>

<style lang="postcss">
.poster-group {
    .module-poster {
        @apply mb-0;
    }
}
</style>
