<template>
    <svg
        class="icon-pin"
        viewBox="0 0 10 13.333"
        xmlns="http://www.w3.org/2000/svg"
    ><path
        d="M21.361,28.948c-3.786-5.485-4.486-6.048-4.486-8.064a5,5,0,0,1,10,0c0,2.016-.7,2.579-4.486,8.064a.626.626,0,0,1-.87.159A.645.645,0,0,1,21.361,28.948Z"
        transform="translate(-16.875 -15.884)"
    /></svg>
</template>
