import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .hasTitle()
    .make();

schema.prop('userRoleId').setType(Number).setValue(1);
schema.prop('email').setType(String);
schema.prop('emailConfirmed').setType(Boolean).setValue(true);
schema.prop('password').setType(String);
schema.prop('firstName').setType(String);
schema.prop('lastName').setType(String);
schema.prop('isAdmin').setType(Number).setType(Boolean).setValue(true);

export default schema;
