<template>
    <AdminFormGroup class="admin-rich-text">
        <FormLabel
            :text="label"
            class="form-control"
        >
            <div class="admin-rich-text__input">
                <FormRichText
                    :model-value="modelValue"
                    @update:model-value="update"
                    class="form-control__input"
                />
            </div>
        </FormLabel>
    </AdminFormGroup>
</template>

<script>
export default {
    props: {
        label: String,
        modelValue: null
    },

    methods: {
        update(value) {
            this.$emit('update:modelValue', value);
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],
}
</script>
