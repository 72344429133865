import PivotRepository from "@/services/resources/pivot-repository";
import byNewsItem from "@/services/resources/mixins/query-by-news-item";
import byTheme from "@/services/resources/mixins/query-by-theme";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byNewsItem).$mix(byTheme);
    }

    themesByNewsItem(id) {
        return this.relation('themes', 'newsItemId', id);
    }
}

export default Repository;
