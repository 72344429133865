<template>
    <svg
        class="icon-chevron fill-current"
        viewBox="0 0 10.693 6.115"
        xmlns="http://www.w3.org/2000/svg"
    ><path
        d="M10.609.564,10.131.086a.29.29,0,0,0-.41,0L5.347,4.45.973.085a.29.29,0,0,0-.41,0L.085.563a.29.29,0,0,0,0,.41L5.142,6.03a.29.29,0,0,0,.41,0L10.609.973A.291.291,0,0,0,10.609.564Z"
        transform="translate(0 0)"
    /></svg>
</template>
