<template>
    <div
        class="timeline"
        :class="variantClass"
    >
        <h3 class="my-8">
            Events at the V&A
        </h3>

        <GridContainer>
            <GridColumn class="timeline__line">
                <div class="timeline__line__container">
                    <div class="timeline__line__container__marker" />
                    <div class="timeline__line__container__pipe" />
                </div>
                <div class="timeline__title timeline__line__title">
                    <span>November 2020</span>
                </div>
            </GridColumn>
            <GridColumn class="timeline__content">
                <GridContainer class="timeline__content__container">
                    <div class="timeline__title timeline__content__container__title">
                        <span>November 2020</span>
                    </div>

                    <GridColumn class="timeline__content__container__item">
                        <PosterExhibition
                            :resource="{
                                image: 'prototype/postcard.jpg',
                                title: 'Kara Walker',
                                summary: 'For this year’s Hyundai Commission, Kara Walker transforms the Turbine Hall with an ambitious new work.',
                                location: {
                                    name: 'Tate modern',
                                    place: 'Greater London'
                                },
                                napOffer: {
                                    name: '50% off'
                                }
                            }"
                        />
                    </GridColumn>
                    <GridColumn class="timeline__content__container__item">
                        <PosterExhibition
                            :resource="{
                                image: 'prototype/postcard.jpg',
                                title: 'Kara Walker',
                                summary: 'For this year’s Hyundai Commission, Kara Walker transforms the Turbine Hall with an ambitious new work.',
                                location: {
                                    name: 'Tate modern',
                                    place: 'Greater London'
                                },
                                napOffer: {
                                    name: '50% off'
                                }
                            }"
                        />
                    </GridColumn>
                    <GridColumn class="timeline__content__container__item">
                        <PosterExhibition
                            :resource="{
                                image: 'prototype/postcard.jpg',
                                title: 'Kara Walker',
                                summary: 'For this year’s Hyundai Commission, Kara Walker transforms the Turbine Hall with an ambitious new work.',
                                location: {
                                    name: 'Tate modern',
                                    place: 'Greater London'
                                },
                                napOffer: {
                                    name: '50% off'
                                }
                            }"
                        />
                    </GridColumn>
                    <GridColumn class="timeline__content__container__item">
                        <PosterExhibition
                            :resource="{
                                image: 'prototype/postcard.jpg',
                                title: 'Kara Walker',
                                summary: 'For this year’s Hyundai Commission, Kara Walker transforms the Turbine Hall with an ambitious new work.',
                                location: {
                                    name: 'Tate modern',
                                    place: 'Greater London'
                                },
                                napOffer: {
                                    name: '50% off'
                                }
                            }"
                        />
                    </GridColumn>
                </GridContainer>
            </GridColumn>
        </GridContainer>

        <GridContainer>
            <GridColumn class="timeline__line">
                <div class="timeline__line__container">
                    <div class="timeline__line__container__marker" />
                    <div class="timeline__line__container__pipe" />
                </div>
                <div class="timeline__title timeline__line__title">
                    <span>October 2020</span>
                </div>
            </GridColumn>
            <GridColumn class="timeline__content">
                <GridContainer class="timeline__content__container">
                    <div class="timeline__title timeline__content__container__title">
                        <span>October 2020</span>
                    </div>

                    <GridColumn class="timeline__content__container__item">
                        <PosterExhibition
                            :resource="{
                                image: 'prototype/postcard.jpg',
                                title: 'Kara Walker',
                                summary: 'For this year’s Hyundai Commission, Kara Walker transforms the Turbine Hall with an ambitious new work.',
                                location: {
                                    name: 'Tate modern',
                                    place: 'Greater London'
                                },
                                napOffer: {
                                    name: '50% off'
                                }
                            }"
                        />
                    </GridColumn>
                    <GridColumn class="timeline__content__container__item">
                        <PosterExhibition
                            :resource="{
                                image: 'prototype/postcard.jpg',
                                title: 'Kara Walker',
                                summary: 'For this year’s Hyundai Commission, Kara Walker transforms the Turbine Hall with an ambitious new work.',
                                location: {
                                    name: 'Tate modern',
                                    place: 'Greater London'
                                },
                                napOffer: {
                                    name: '50% off'
                                }
                            }"
                        />
                    </GridColumn>
                </GridContainer>
            </GridColumn>
        </GridContainer>

        <GridContainer>
            <GridColumn
                colSpan="col-span-12"
                class="timeline__line--end"
            >
                <div class="timeline__line__container">
                    <div class="timeline__line__container__pipe" />
                </div>
            </GridColumn>
        </GridContainer>

    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },

        variant: {
            type: String,
            default: 'alpha'
        }
    },

    computed: {
        variantClass(){
            return this.variant ? `timeline--${this.variant}` : ''
        }
    }
}
</script>

<style lang="postcss">
.timeline {
    &__title {
        @apply text-blue-ribbon text-lg font-heading mb-4 lg:text-2xl -mt-1;
    }

    &__line {
        @apply col-span-1 lg:col-span-3 lg:flex align-top;

        &__container {
            @apply h-full;

            &__marker {
                @apply border-4 border-blue-ribbon rounded-full w-4 h-4 bg-white;
            }

            &__pipe {
                @apply w-1 bg-blue-ribbon text-blue-ribbon h-full ml-1.5;
            }

        }

        &__title {
            @apply hidden lg:flex ml-3;
        }

        &--end {
            .timeline__line__container__pipe {
                @apply bg-transparent h-12 !bg-gradient-to-b !from-current !via-current !to-transparent;
            }
        }
    }

    &__content {
        @apply col-span-11 lg:col-span-9;

        &__container {
            &__title {
                @apply col-span-12 lg:hidden;
            }

            &__item {
                @apply mb-8 col-span-12 md:col-span-6 lg:col-span-4;
            }
        }
    }

    &--beta {
        .timeline__line__container__marker {
            @apply border-malachite;
        }

        .timeline__line__container__pipe {
            @apply bg-malachite text-malachite;
        }

        .timeline__title {
            @apply text-malachite;
        }

        .timeline__line--end {
            .timeline__line__container__pipe {
                @apply bg-transparent;
            }
        }
    }
}
</style>
