import Binding from "./binding";
import Path from "./path";

class Scope {
    #ancestor;
    #prefix = '';
    #key;

    inherit(scope) {
        this.#ancestor = scope;
        return this;
    }

    prefix(prefix) {
        this.#prefix = prefix;
        return this;
    }

    key(key) {
        this.#key = key;
        return this;
    }

    getAncestor() {
        return this.#ancestor;
    }

    hasAncestor() {
        return typeof this.getAncestor() !== 'undefined';
    }

    getPrefix() {
        const pieces = [this.#prefix];

        if (this.hasAncestor()) {
            pieces.unshift(this.#ancestor.getPrefix());
        }

        return pieces.filter(item => item).join('');
    }

    getKey() {
        return this.#key;
    }

    for(ob) {
        return new Binding(this).for(ob);
    }

    path() {
        return new Path(this);
    }
}

export default Scope;
