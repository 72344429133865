import BaseRepository from "@/services/resources/repository";
import translatable from "@/services/resources/mixins/translatable-record";
import Context from "@/services/context";

class Repository extends BaseRepository {
    static #fetchPromises = {};

    static make(model, schema) {
        return new Repository(model, schema).$mix(translatable);
    }

    collection() {
        if (Repository.#fetchPromises[Context.getLanguage()]) {
            return Repository.#fetchPromises[Context.getLanguage()];
        }

        Repository.#fetchPromises[Context.getLanguage()] = this.transformer.many(
            this.try(
                this.baseQuery().include('translated').get()
            )
        );

        return Repository.#fetchPromises[Context.getLanguage()];
    }
}

export default Repository;
