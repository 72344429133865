import BaseRepository from "@/services/resources/repository";
import byNewsItem from "@/services/resources/mixins/query-by-news-item";
import byPerson from "@/services/resources/mixins/query-by-person";

class Repository extends BaseRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byNewsItem).$mix(byPerson);
    }
}

export default Repository;
