<template>
    <AdminFormGroup
        class="admin-textarea"
        :class="classes"
    >
        <FormLabel
            :text="label"
            class="form-control"
        >
            <div class="admin-textarea__input">
                <BaseTextarea
                    v-bind="baseComponentBindings"
                    @update:model-value="update"
                    class="form-control__input"
                />
            </div>
            <FormError :text="error"/>
            <FormHelp :text="helpText"/>
            <FormHelp
                v-if="maxLength"
                class="admin-textarea__help"
                :text="maxLengthText"
            />
        </FormLabel>
    </AdminFormGroup>
</template>

<script>
import formTextareaComposable from "@/composables/forms/form-textarea";

export default {
    mixins: [{
        props: formTextareaComposable.props()
    }],

    setup(props, {emit}) {
        return {
            ...formTextareaComposable.composables(props, emit)
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],

    computed: {
        maxLengthText() {
            const characters = this.modelValue ? this.modelValue.length : 0;

            return `${(this.maxLength - characters)} characters left`;
        },
    },
}
</script>

<style lang="postcss">
.admin-textarea {
    &__input {
        @apply relative block;
    }
}
</style>
