<template>
    <div
        class="dialogue"
        :class="classes"
    >
        <div
            @click.stop="blockerClick"
            class="dialogue__blocker"
        ></div>
        <div class="dialogue__box">
            <header
                v-if="!minimal"
                class="dialogue__header"
            >
                <div class="dialogue__header__slot">
                    <h2 class="dialogue__header__slot__title"><slot name="header"></slot></h2>
                </div>
                <IconRenderer
                    name="Cross"
                    class="dialogue__header__icon"
                    @click.stop="close"
                />
            </header>
            <div class="dialogue__content">
                <slot name="content"></slot>
            </div>
            <footer
                v-if="!minimal && btns && btns.length"
                class="dialogue__footer"
            >
                <FormBtn
                    v-for="(btn, index) in btns"
                    :key="index"
                    v-bind="btn.props"
                    @click="btnClick(btn)"
                    variant="cms"
                >
                    {{ btn.text }}
                </FormBtn>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: Boolean,
        btns: Array,
        minimal: {
            type: Boolean,
            default: false
        },
        closeOnBlockerClick: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        classes() {
            const classes = [];

            if (this.modelValue) {
                classes.push('dialogue--open');
            }

            if (this.minimal) {
                classes.push('dialogue--minimal');
            }

            return classes;
        },
    },

    methods: {
        close() {
            this.$emit('update:modelValue', false);
        },

        blockerClick() {
            if (this.closeOnBlockerClick) {
                this.close();
            }
        },

        btnClick(btn) {
            this.$emit(btn.click, btn);
        }
    }
}
</script>

<style lang="postcss">
.dialogue {
    @apply fixed z-50 top-0 left-0 w-screen max-w-full h-screen flex items-center justify-center opacity-0 invisible transition duration-500;

    &--open {
        @apply opacity-100 visible;

        .dialogue__box {
            @apply transform-none;
        }
    }

    &__blocker {
        @apply bg-big-stone opacity-50 absolute w-full h-full top-0 left-0;
    }

    &__box {
        @apply relative z-10 w-full overflow-x-hidden overflow-y-auto mx-6 bg-white transform -translate-y-12 transition duration-500;
        max-width: 1060px;
        max-height: calc(100vh - 48px);
    }

    &__header {
        @apply flex justify-between items-center px-5 pt-5 lg:px-20 lg:pt-20 text-xl;

        &__slot {
            @apply mr-5;

            &__title {
                @apply font-sofia-pro font-semibold text-3xl;
            }
        }

        &__icon {
            @apply w-6 ml-auto fill-current cursor-pointer
        }
    }

    &__content {
        @apply px-5 pb-5 lg:px-20 lg:pb-20;

        h4 {
            @apply font-sofia-pro border-t border-alto font-normal text-2xl pt-4 mb-6;
        }

        label {
            @apply font-sofia-pro;

            .form-label__text {
                @apply font-sofia-pro;
            }
        }

        &__repeater {
            @apply border-b border-alto pb-4 mb-4;

            &:last-child {
                @apply border-0;
            }
        }
    }

    &__footer {
        @apply border-t border-alto m-4 pt-4 lg:flex space-y-2 lg:space-y-0 lg:space-x-2;

        .form-btn {
            .base-btn {
                @apply rounded-r-full rounded-l-full;
            }
        }
    }
}
</style>
