<template>
    <PosterGroup>
        <PosterRenderer
            v-for="(item, index) in displayItems"
            :key="index"
            :item="item"
            :content-type="contentType"
            :variant="variant"
        />
    </PosterGroup>

    <template v-if="paginate">
        <div
            v-if="showMoreOption"
            class="poster-group-show poster-group-show--more"
            @click="showMore"
        >
            <span v-html="showMoreTranslation" />
            <IconRenderer name="Chevron" />
        </div>
    </template>
</template>

<script>
import Translation from "@/services/lookup/translation";

export default {
    props: {
        items: Array,
        variant: String,
        contentType: String,
        paginate: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            show: 4,
            page: 1,
            showMoreOption: true,
            showMoreTranslation: '',
        }
    },

    computed: {
        displayItems(){
            if(this.paginate) {
                return this.items.slice(0, (this.show * this.page));
            } else {
                return this.items;
            }
        },
    },

    methods: {
        displayShowMore(){
            if(this.paginate) {
                this.showMoreOption = true;

                if(this.page <= 0){
                    this.page = 1;
                }

                if ((this.page * this.show) >= this.items.length) {
                    this.showMoreOption = false;
                }
            }
        },

        showMore(){
            if(this.paginate) {
                this.page++;
                this.displayShowMore();
            }
        },
    },

    mounted(){
        this.displayShowMore();

        Translation.many(['show-more']).then(translations => {
            if(translations) {
                this.showMoreTranslation = translations['show-more'];
            }
        })
    },

    watch: {
        page(){
            this.displayShowMore();
        }
    }
}
</script>

<style lang="postcss">
.poster-group-show {
    @apply flex flex-row text-base items-center justify-center w-max cursor-pointer mx-auto mt-2 mb-8;

    svg {
        @apply w-3 ml-2;
    }

    &--less {
        svg {
            @apply transform rotate-180
        }
    }
}
</style>
