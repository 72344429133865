<template>
    <select
        class="base-select"
        :value="modelValue"
        :disabled="disabled"
        :name="name"
        @input="update($event.target.value)"
    >
        <option
            v-if="showEmpty"
            value=""
        >
            {{ pleaseSelectTranslation }}
        </option>

        <option
            v-for="(option, index) in options"
            :key="index"
            :value="option.value"
            :disabled="typeof option.disabled === 'undefined' ? false : option.disabled"
            :class="option.class || []"
        >
            {{ option.label }}
        </option>
    </select>
</template>

<script>
import Translation from "@/services/lookup/translation";

export default {
    props: {
        modelValue: {
            type: [String, Number, null],
            required: true,
        },
        name: {
            type: String,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: true,
        },
        showEmpty: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ["update:modelValue"],

    data(){
        return {
            pleaseSelectTranslation: '',
        }
    },

    methods: {
        update(value) {
            if (value === "" || value === 0 || value === "0") {
                value = null;
            }

            this.$emit("update:modelValue", value);
        },

        checkExists() {
            if (this.modelValue && this.options.length) {
                if (!this.options.find(option => option.value === this.modelValue)) {
                    this.update(null);
                }
            }
        },
    },

    mounted() {
        this.checkExists();

        Translation.many(['please-select']).then(translations => {
            if(translations) {
                this.pleaseSelectTranslation = translations['please-select'];
            }
        })
    },

    watch: {
        options() {
            this.checkExists();
        },
    },
};
</script>

<style lang="postcss">
.base-select {
    @apply block w-full outline-none text-big-stone border border-swiss-coffee p-3.5 cursor-pointer bg-white;
}
</style>
