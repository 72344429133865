<template>
    <FormLabel
        :text="label"
        class="form-date"
        :class="classes"
    >
        <div class="form-date__items">
            <div
                v-if="showDay"
                class="form-date__item"
            >
                <FormSelect
                    :model-value="day"
                    :options="dayOptions"
                    :border="formSelectBorder"
                    @update:model-value="update('day', $event)"
                />
            </div>
            <div
                v-if="showMonth"
                class="form-date__item"
            >
                <FormSelect
                    :model-value="month"
                    :options="monthOptions"
                    :border="formSelectBorder"
                    @update:model-value="update('month', $event)"
                />
            </div>
            <div
                v-if="showYear"
                class="form-date__item"
            >
                <FormSelect
                    :model-value="year"
                    :options="yearOptions"
                    :border="formSelectBorder"
                    @update:model-value="update('year', $event)"
                />
            </div>
        </div>
        <FormError :text="error"/>
    </FormLabel>
</template>

<script>
import dayjs from 'dayjs';
import formDateComposable from "@/composables/forms/form-date";

export default {
    mixins: [{
        props: formDateComposable.props()
    }],

    setup(props, {emit}) {
        return {
            ...formDateComposable.composables(props, emit)
        }
    },

    // TODO: Remove when appropriate
    // Needed to suppress a warning currently emitted due to current vue bug
    emits: ['update:modelValue'],

    props: {
        modelValue: null,
        display: {
            type: Array,
            default() {
                return ['day', 'month', 'year']
            }
        },
        format: {
            type: String,
            default: 'YYYY-MM-DD',
        },
        years: {
            type: Number,
            default: 5
        },
        direction: {
            type: String,
            default: 'backwards'
        }
    },

    data() {
        return {
            day: null,
            month: null,
            year: null
        }
    },

    watch: {
        modelValue(value) {
            if (value) {
                this.parse(value);
            }
        }
    },

    computed: {
        showDay() {
            return this.shouldShow('day');
        },

        showMonth() {
            return this.shouldShow('month');
        },

        showYear() {
            return this.shouldShow('year');
        },

        allSet() {
            return (this.day || !this.showDay) && (this.month !== null || !this.showMonth) && (this.year || !this.showYear);
        },

        dayOptions() {
            const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].map(day => {
                return {
                    text: String(day).padStart(2, '0'),
                    value: (day)
                }
            });

            return [
                {
                    text: 'DD',
                    value: null
                }
            ].concat(days);
        },

        monthOptions() {
            const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
                return {
                    text: String(month).padStart(2, '0'),
                    value: (month - 1)
                }
            });

            return [
                {
                    text: 'MM',
                    value: null
                }
            ].concat(months);
        },

        yearOptions() {
            const year = dayjs().startOf('year');
            const years = [];
            const manipulationMethod = this.direction === 'backwards' ? 'subtract' : 'add';

            for (let i = 0; i <= this.years; i++) {
                let newDate = dayjs(year)[manipulationMethod](i, 'year');

                years.push(
                    {
                        text: newDate.format('YYYY'),
                        value: newDate.year()
                    }
                )
            }

            return [
                {
                    text: 'YYYY',
                    value: null
                }
            ].concat(years);
        },

        formSelectBorder() {
            return this.error ? 'border border-red' : null;
        }
    },

    methods: {
        shouldShow(key) {
            return this.display.indexOf(key) !== -1
        },

        parse(value) {
            if (this.format === 'MMYY') {
                this.month = parseInt(value.slice(0, 2)) - 1;
                this.year = parseInt(`20${value.slice(2)}`);

                return;
            }

            const parsed = dayjs(value);

            this.day = parsed.date();
            this.month = parsed.month();
            this.year = parsed.year();
        },

        update(key, value) {
            this[key] = value;

            if (!this.allSet) {
                this.$emit('update:modelValue', null);
                return;
            }

            let date = dayjs().startOf('year');

            date = date.date(this.day);
            date = date.month(this.month);
            date = date.year(this.year)

            this.$emit('update:modelValue', date.format(this.format));
        }
    },

    mounted() {
        if (this.modelValue && this.modelValue !== '0000-00-00') {
            this.parse(this.modelValue);
        }
    }
}
</script>

<style lang="postcss">
.form-date {
    @apply w-full;

    &__items {
        @apply flex space-x-2;
    }

    &__item {
        @apply w-full;
    }
}
</style>
