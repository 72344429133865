import StoreFactory from "@/services/data-layer/store/factory"
import Scope from "@/services/data-layer/store/scope";

class Factory {
    static stores(registry) {
        const modules = {};

        registry.all().forEach(resource => {
            modules[resource.config.type] = StoreFactory.make(resource.schema, resource.repository);
        });

        return {
            namespaced: true,
            modules
        };
    }

    static scope(config) {
        const scope = new Scope();

        if (config) {
            scope.prefix(`resources/${config.type}/`);
        }

        return scope;
    }
}

export default Factory;
