import PivotRepository from "@/services/resources/pivot-repository";
import byEvent from "@/services/resources/mixins/query-by-event";
import byProject from "@/services/resources/mixins/query-by-project";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byEvent).$mix(byProject);
    }

    eventsByProject(id) {
        return this.relation('events', 'projectId', id);
    }

    projectsByEvent(id) {
        return this.relation('projects', 'eventId', id);
    }
}

export default Repository;
