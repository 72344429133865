<!--suppress JSUnresolvedVariable, JSUnfilteredForInLoop, ES6ModulesDependencies -->

<template>
    <div class="asset-library-blocker" />
    <div class="asset-library">
        <PreviewAsset />
        <BrandBar
            @refresh="getAssets"
            @upload="toggleUpload"
            @create="toggleMakeDir"
            @close="close"
        />
        <Breadcrumbs />
        <Results
            :loading="loading"
            @deleted="assetDeleted"
            @selected="assetSelected"
        />
        <UploadAsset
            :uploading="uploading"
            @close="toggleUpload"
            @uploaded="assetUploaded"
        />
        <MakeDirectory
            :make-dir="makeDir"
            @close="toggleMakeDir"
            @created="directoryCreated"
        />
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Kernel from "@oilstone/kernel";
import BrandBar from "./partials/BrandBar";
import Breadcrumbs from "./partials/Breadcrumbs";
import UploadAsset from "./UploadAsset";
import MakeDirectory from "./MakeDirectory";
import Results from "./Results";
import PreviewAsset from "./PreviewAsset";

export default {
    setup() {
        const settings = Kernel.resolve('resourceRegistry').find('file-assets');

        return {
            config: settings.config,
            assetRepository: settings.repository,
        }
    },

    emits: ['selected', 'close'],

    components: {
        PreviewAsset,
        Results,
        MakeDirectory,
        UploadAsset,
        Breadcrumbs,
        BrandBar,
    },

    data(){
        return {
            loading: true,
            uploading: false,
            makeDir: false,
        }
    },

    computed: {
        ...mapGetters('asset', {
            currentPath: 'currentPath',
            asset: 'asset',
            assets: 'assets',
            fetching: 'fetching',
        }),

        watchPath() {
            if(this.currentPath) {
                return this.currentPath;
            } else {
                return '';
            }
        },
    },

    methods: {
        ...mapMutations('asset', {
            setCurrentPath: 'setCurrentPath',
            fillAssets: 'fillAssets',
            setFetching: 'setFetching',
        }),

        close(){
            this.$emit('close', true);
        },

        assetDeleted(id){
            this.assetRepository.deleteAsset(id).then(() => {
                this.getAssets();
            })
        },

        directoryCreated(path){
            this.assetRepository.createDirectory(path).then(() => {
                this.toggleMakeDir();
                this.getAssets();
            })
        },

        assetUploaded(data){
            this.assetRepository.uploadAsset(data).then(() => {
                this.toggleUpload();
                this.getAssets();
            });
        },

        clearAssetStore(){
            let data = {};
            data['files'] = {};
            data['folders'] = {};
            this.fillAssets(data);
        },

        assetSelected(val){
            this.$emit('selected', val);
            this.close();
        },

        toggleMakeDir(){
            this.makeDir = !this.makeDir;
            this.uploading = false;
        },

        toggleUpload(){
            this.uploading = !this.uploading;
            this.makeDir = false;
        },

        getAssets(){
            this.loading = true;
            this.setFetching(true);
            this.clearAssetStore();

            this.assetRepository.getByPath(this.currentPath).then(response => {
                if(response){
                    let folders = [];
                    let files = [];

                    response.forEach(row => {
                        let t = row.id.substr(row.id.length - 4);
                        if (t === '_dir') {
                            if (row.path !== '' && row.path.indexOf('.') < 0) {
                                if (!(row.id in folders)) {
                                    folders.push(row);
                                }
                            }
                        } else {
                            if (row.path !== '' && row.path.indexOf('.gitignore') < 0) {
                                if (!(row.id in files)) {
                                    files.push(row);
                                }
                            }
                        }
                    });

                    let data = {};
                    data['files'] = files.sort((a,b) => {
                        return a.name < b.name ? -1 : 1;
                    });
                    data['folders'] = folders.sort((a,b) => {
                        return a.name < b.name ? -1 : 1;
                    });

                    this.fillAssets(data);
                    this.loading = false;
                    this.setFetching(false);
                }
            })
        },
    },

    mounted() {
        this.getAssets();
    },

    watch: {
        watchPath(){
            this.getAssets();
        }
    }
}
</script>

<style lang="postcss">
.asset-library-blocker {
    @apply fixed bg-cod-gray bg-opacity-60 w-screen h-screen top-0 left-0;
    z-index: 4000;
}

.asset-library {
    @apply fixed top-1/2 left-1/2 bg-white transform -translate-x-1/2 -translate-y-1/2 overflow-hidden;
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    z-index: 4001;
}
</style>
