import Factory from "@/schemas/factory";

const schema = new Factory()
    .hasId()
    .translatable()
    .make();

schema.prop('lookup').setType(String).setValue(null).addMetadata('mergeable', true);
schema.prop('value').setType(String).addMetadata('translatable', true);

export default schema;
