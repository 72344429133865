import Factory from '../factory';

const schema = Factory.schema('faqs');
const attributes = schema.getProp('attributes').getValue();


attributes.prop('title').setType(String).setValue('Frequently Asked Questions');
attributes.prop('summary').setType(String);
attributes.prop('faqCategoryId').setType(Number);
attributes.prop('variant').setType(String).setValue('beta');
attributes.prop('advert').setType(Boolean).setValue(true);

export default schema;
