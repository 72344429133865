export default {
    type: 'person-sectors',
    aliases: {
        camel: 'personSectors',
        singular: 'Person sector',
        plural: 'Person sectors',
        lowerSingular: 'person sector',
        lowerPlural: 'person sectors',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
