export default {
    type: 'cookie-list',
    name: 'Cookie List',
    osOnly: false,
    components: {
        root: {
            name: 'ModuleCookieList',
            loader: () => import('./ModuleCookieList')
        }
    }
};
