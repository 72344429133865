export default {
    type: 'news-item-projects',
    aliases: {
        camel: 'newsItemProjects',
        singular: 'News item project',
        plural: 'News item projects',
        lowerSingular: 'news item project',
        lowerPlural: 'news item projects',
    },
    publishable: false,
    adminSection: null,
    componentType: 'pivot',
    components: {}
};
