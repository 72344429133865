<template>
    <div
        v-if="resource.items && resource.items.length > 0"
        class="relative"
    >
        <div
            class="filter"
            :class="active ? 'filter--active' : ''"
            @click="toggleActive"
        >
            <div class="back">
                <IconRenderer name="Chevron" />
                <span>{{ translations.back }}</span>
            </div>
            <span v-html="resource.title" />
            <IconRenderer name="Chevron" />
        </div>

        <div
            v-if="active"
            class="filter-active"
        >
            <div class="filter-active__diamond" />
            <div class="filter-active__container">
                <FormInput
                    v-if="typeAhead && resource.items.length > 9"
                    v-model="qry"
                    :placeholder="translations.keywordSearch"
                />
                <div
                    v-for="(item, index) in displayItems"
                    :key="index"
                    class="filter-active__item"
                    @click="selectFilter(item)"
                >
                    <span>{{ item.title ? item.title : item.label }}</span>
                    <IconRenderer name="Plus" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Translation from "@/services/lookup/translation";

export default {
    props: {
        resource: {
            type: Object,
            default: null,
        },

        reset: {
            type: Boolean,
            default: false,
        },
    },

    data(){
        return {
            self: false,
            active: false,
            qry: '',
            translations: {
                back: null,
                keywordSearch: null,
            }
        }
    },

    computed: {
        typeAhead(){
            return this.resource.title !== "A-Z";
        },

        displayItems(){
            if(this.resource.items) {
                return this.resource.items.filter(row => {
                    return (row.label && row.label.toLowerCase().indexOf(this.qry.toLowerCase()) > -1) || (row.title && row.title.toLowerCase().indexOf(this.qry.toLowerCase()) > -1);
                });
            } else {
                return [];
            }
        }
    },

    methods: {
        toggleActive(){
            this.self = true;
            this.$emit('reset', true);
            this.active = !this.active;
            this.$emit('clicked', this.resource);
        },

        selectFilter(itm){
            this.$emit('selected',{
                type: this.resource.type,
                filter: itm
            });
            this.toggleActive();
        },

        defaultSelectFilter(itm){
            this.$emit('defaultSelected',{
                type: this.resource.type,
                filter: itm
            });
        }
    },

    mounted(){
        this.displayItems.forEach(row => {
            if(row.selected){
                this.defaultSelectFilter(row);
            }
        });

        Translation.many(['keyword-search','back']).then(translations => {
            if(translations){
                this.translations.keywordSearch = translations['keyword-search'];
                this.translations.back = translations['back'];
            }
        })
    },

    watch: {
        reset(val){
            if(val) {
                if (this.self) {
                    this.self = false;
                } else {
                    this.active = false;
                }
                this.$emit('reset', false);
            }
        },
    }
}
</script>

<style lang="postcss">
.filter {
    @apply hidden lg:flex justify-between items-center mr-6 cursor-pointer lg:mr-10;

    .back {
        @apply hidden;
    }

    &--active {
        > span {
            @apply relative;

            @screen lg {
                &:after {
                    @apply absolute w-full bg-cod-gray left-0 -bottom-4;
                    content: '';
                    height: 3px;
                }
            }
        }

        > svg {
            @apply transform rotate-180;
        }
    }

    > span {
        @apply mr-2;
    }

    > svg {
        @apply w-2 h-auto flex-shrink-0;
    }
}

.filter-active {
    @apply flex flex-col bg-white lg:border lg:border-cararra lg:z-20 lg:filter lg:drop-shadow lg:absolute lg:mt-10 lg:transform lg:-translate-x-1/2 lg:!mr-0;

    @screen lg {
        width: 300px;
        max-height: 400px;
        left: calc(50% - 27px);
    }

    &__diamond {
        @apply hidden lg:inline absolute border-cararra transform rotate-45;
        width: 20px;
        height: 20px;
        top: -10px;
        background: white;
        left: calc(50% - 10px);
    }

    &__container {
        @apply w-full h-full overflow-x-hidden overflow-y-auto p-4 ;
    }

    &__item {
        @apply border-b text-sm border-cotton-seed w-full py-3 px-0.5 flex flex-row justify-between items-center cursor-pointer lg:text-base;

        span {
            @apply flex-grow;
        }

        svg {
            @apply w-3 text-royal-purple stroke-2 flex-shrink-0;
        }
    }
}
</style>
