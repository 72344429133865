<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.18 47.28">
		<g>
			<path d="M28.21,45H2.45A2.45,2.45,0,0,1,0,42.52V2.45A2.45,2.45,0,0,1,2.45,0H22l8.71,8.66V42.52A2.44,2.44,0,0,1,28.21,45Z" style="fill: #e0e0e0"/>
			<path d="M24.41,8.66h6.25L22,0V6.2A2.46,2.46,0,0,0,24.41,8.66Z" style="fill: #eff2f4"/>
			<rect x="16.05" y="34.49" width="23.13" height="12.8" rx="1.47" style="fill: #474747"/>
			<g>
				<path d="M24.79,38.1v1H23V44H21.79V39.14H20v-1Z" style="fill: #fff"/>
				<path d="M26.45,44H25l1.89-3-1.8-2.88h1.47L27.63,40l1.08-1.91h1.42l-1.8,2.83,1.91,3H28.75l-1.12-2Z" style="fill: #fff"/>
				<path d="M35.24,38.1v1H33.48V44H32.24V39.14H30.47v-1Z" style="fill: #fff"/>
			</g>
		</g>
	</svg>
</template>