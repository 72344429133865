class Cache {
    #items = {};

    put(key, callback) {
        this.#items[key] = callback;

        return this;
    }

    resolve(key) {
        if (key in this.#items && typeof this.#items[key] === 'function') {
            this.#items[key] = this.#items[key]();
        }

        return this.#items[key];
    }

    has(key) {
        return typeof this.#items[key] !== 'undefined';
    }

    clear() {
        this.#items = {};
    }

    forEach(callback) {
        return Object.values(this.#items).forEach(callback);
    }
}

export default Cache;
