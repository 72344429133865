import PivotRepository from "@/services/resources/pivot-repository";
import byProject from "@/services/resources/mixins/query-by-project";
import byProgrammeArea from "@/services/resources/mixins/query-by-programme-area";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byProject).$mix(byProgrammeArea);
    }

    projectsByProgrammeArea(id) {
        return this.relation('projects', 'programmeAreaId', id);
    }

    programmeAreasByProject(id) {
        return this.relation('programme-areas', 'projectId', id, null, [], 'programmeAreas');
    }
}

export default Repository;
