export default {
    type: 'enquiries',
    aliases: {
        camel: 'enquiries',
        singular: 'Website enquiry',
        plural: 'Website enquiries',
        lowerSingular: 'enquiry',
        lowerPlural: 'enquiries',
    },
    publishable: false,
    adminSection: 'general',
    componentType: 'base',
    components: {
        adminRecord: {
            name: 'AdministerEnquiryRecord',
            loader: () => import('./AdministerEnquiryRecord')
        },
        adminCollection: {
            name: 'AdministerEnquiryCollection',
            loader: () => import('./AdministerEnquiryCollection')
        },
    },
    cms: {
        paginationAmount: () => {
            return 50
        },
    }
};
