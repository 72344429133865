import {computed, ref} from 'vue';
import Cache from "@/services/data-layer/composers/cache";
import Country from "@/services/lookup/country";
import Context from "@/services/context";

const cache = new Cache();
const cacheKey = id => Context.getLanguage() + '-' + id;

const boot = id => {
    cache.put(
        cacheKey(id),
        () => {
            let country = ref(null);

            Country.show(id).then(resolved => {
                country.value = resolved;
            });

            return computed(() => {
                return country.value;
            });
        }
    );
};

const resolve = (id) => {
    if (!cache.has(cacheKey(id))) {
        boot(id);
    }

    return cache.resolve(cacheKey(id));
};

export {boot, resolve};
export default resolve;
