<template>
    <grid-container class="admin-footer">
        <grid-column>
            <strong>We provide help at</strong><br />
            <a href="mailto:support@onlinesolutionsltd.com">support@onlinesolutionsltd.com</a>
        </grid-column>
        <grid-column>
            <strong>Give us a call</strong><br />
            +44 (0)20 7395 6535
        </grid-column>
        <grid-column>
            <strong>Website</strong><br />
            <a href="https://www.onlinesolutionsltd.com" target="_blank">www.onlinesolutionsltd.com</a>
        </grid-column>
    </grid-container>
</template>

<style lang="postcss">
.admin-footer {
    @apply w-full mt-8;

    .grid-column {
        @apply col-span-12 md:col-span-6 xl:col-span-3 text-base text-dusty-gray mb-4 xl:mb-0;
    }

    a:hover {
        @apply text-grey-1;
    }
}
</style>
