export default {
    type: "shortcuts",
    aliases: {
        camel: "shortcuts",
        singular: "Shortcut",
        plural: "Shortcuts",
        lowerSingular: "shortcut",
        lowerPlural: "shortcuts",
    },
    publishable: false,
    adminSection: "administration",
    componentType: "base",
    searchOn: ["path", "redirectTo"],
    components: {
        adminCollection: {
            name: "AdministerShortcutCollection",
            loader: () => import("./AdministerShortcutCollection"),
        },
        adminRecord: {
            name: "AdministerShortcutRecord",
            loader: () => import("./AdministerShortcutRecord"),
        },
    },
    cms: {
        paginationAmount: () => {
            return 100;
        },
    },
};
