import PivotRepository from "@/services/resources/pivot-repository";
import byEvent from "@/services/resources/mixins/query-by-event";
import byTheme from "@/services/resources/mixins/query-by-theme";

class Repository extends PivotRepository {
    static make(model, schema) {
        return new Repository(model, schema).$mix(byEvent).$mix(byTheme);
    }

    themesByEvent(id) {
        return this.relation('themes', 'eventId', id);
    }
}

export default Repository;
